import i18n from "@/i18n";

const stateVariants = [
  { id: "new", name: i18n.t("integrations.order_fulfillment.status.new") },
  { id: "pending", name: i18n.t("integrations.order_fulfillment.status.pending") },
  { id: "pending_approval", name: i18n.t("integrations.order_fulfillment.status.pending_approval") },
  { id: "published", name: i18n.t("integrations.order_fulfillment.status.published") },
  { id: "confirmed", name: i18n.t("integrations.order_fulfillment.status.confirmed") },
  { id: "processing", name: i18n.t("integrations.order_fulfillment.status.processing") },
  { id: "fulfilled", name: i18n.t("integrations.order_fulfillment.status.fulfilled") },
  { id: "cancelled", name: i18n.t("integrations.order_fulfillment.status.cancelled") },
  { id: "finished", name: i18n.t("integrations.order_fulfillment.status.finished") }
];

const stateVariantsFilter = [...stateVariants];

export { stateVariants, stateVariantsFilter };
