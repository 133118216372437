<template>
  <div v-if="loadingDone">
    <v-row v-for="preset in presets" :key="preset.code">
      <v-col>
        <v-card>
          <v-card-title>
            {{ preset.code }}
          </v-card-title>
          <v-list>
            <deployment-tenant v-for="deployment in preset.deployments" :key="deployment.id" :deployment="deployment" :preset="preset.code" :logo="preset.logo" />
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import restProvider from "@/providers/restProvider";
import admin from "@/plugins/admin";
import { GET_LIST } from "@skuhnow/vuetify-admin/src/providers/data/actions";
import DeploymentTenant from "@/components/dashboard/DeploymentTenant";

export default {
  components: { DeploymentTenant },
  data() {
    return {
      presets: null,
      loadingDone: false
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    async loadData() {
      const cmsPresetsByCode = await this.fetchPresets();
      const deployments = await this.fetchDeployments();

      this.presets = [];

      Object.keys(cmsPresetsByCode)
        .sort()
        .map(presetCode => {
          const preset = cmsPresetsByCode[presetCode];
          const deploymentData = [];
          deployments.map(deployment => {
            if (!(presetCode in deployment.config.presetTenantMapping)) {
              return;
            }

            deploymentData.push(deployment);
          });

          this.presets.push({
            code: presetCode,
            logo: preset.logo,
            deployments: deploymentData
          });
        });

      this.loadingDone = true;
    },
    async fetchPresets() {
      const responsePresets = await restProvider(admin.http)[GET_LIST]("presets", {
        pagination: { page: 1, perPage: 999 }
      });

      let presets = responsePresets.data;
      let cmsPresetsByCode = {};
      presets.forEach(preset => {
        cmsPresetsByCode[preset.code] = preset;
      });
      return cmsPresetsByCode;
    },
    async fetchDeployments() {
      const responseDeployments = await restProvider(admin.http)[GET_LIST]("deployments", {
        pagination: { page: 1, perPage: 999 }
      });
      return responseDeployments.data;
    }
  }
};
</script>
