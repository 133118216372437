<template>
  <v-row justify="center">
    <v-col sm="4">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <document-download :item="item" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import DocumentDownload from "@/resources/budgets/DocumentDownload.vue";

export default {
  components: {
    DocumentDownload
  },
  props: ["title", "item"]
};
</script>
