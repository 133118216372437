var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-material-card',{attrs:{"title":_vm.$t('integrations.campaign.product.title.list')}},[_c('va-list',{attrs:{"resource":"campaignProducts","disable-query-string":"","disable-create":"","disable-export":"","filters":_vm.filters,"filter":{
        _campaignCode: _vm.code
      }}},[_c('va-data-table',{attrs:{"fields":_vm.fields,"disable-show":"","disable-clone":"","disable-delete":"","disable-edit":""},scopedSlots:_vm._u([{key:"field.quantity",fn:function(ref){
      var item = ref.item;
      var value = ref.value;
return [(value)?_c('router-link',{attrs:{"to":{
              name: "orderFulfillments_list",
              query: {
                filter: JSON.stringify({
                  campaign: _vm.code,
                  product: item.id
                })
              }
            }}},[_vm._v(" "+_vm._s(value)+" ")]):_c('span',[_vm._v(_vm._s(value))])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }