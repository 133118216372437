<template>
  <va-text-input :label="$t('integrations.fields.reference')" v-model="reference" :readonly="true"></va-text-input>
</template>
<script>
export default {
  props: ["item"],
  computed: {
    reference() {
      let referenceArray = this.item.id.split("-");
      let arrayLength = referenceArray.length;

      return referenceArray[arrayLength - 3] + "-" + referenceArray[arrayLength - 2] + "-" + referenceArray[arrayLength - 1];
    }
  }
};
</script>
