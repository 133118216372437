<template>
  <v-input v-if="record" :label="getLabel" class="va-input">
    <template v-slot:append>
      <copy-to-clipboard-btn v-if="showCopyToClipboard" :value="JSON.stringify(parsedJson)" />
    </template>
    <vue-json-pretty :data="parsedJson" />
  </v-input>
</template>

<script>
import Field from "@skuhnow/vuetify-admin/src/mixins/field";
import CopyToClipboardBtn from "@/components/helper/CopyToClipboardBtn.vue";

export default {
  components: { CopyToClipboardBtn },
  mixins: [Field],
  props: {
    label: String,
    showCopyToClipboard: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    parsedJson() {
      try {
        return JSON.parse(this.value);
      } catch (e) {
        return this.value;
      }
    },
    getLabel() {
      return this.label || this.$admin.getSourceLabel(this.resource, this.labelKey || this.source);
    }
  }
};
</script>
