<template>
  <va-action-button :icon="icon" :label="action" text @click="exec(item)"></va-action-button>
</template>
<script>
import Button from "@skuhnow/vuetify-admin/src/mixins/button";
import admin from "@/plugins/admin";

export default {
  props: ["action", "icon"],
  mixins: [Button],
  methods: {
    exec(item) {
      admin.http
        .put(`${process.env.VUE_APP_API_URL}/workers/${item.id}`, {
          action: this.action
        })
        .then(() =>
          this.$store.commit(`messages/showToast`, {
            color: "success",
            message: `Worker action invoked: ${this.action}`
          })
        )
        .catch(() =>
          this.$store.commit(`messages/showToast`, {
            color: "error",
            message: `Error for action: ${this.action}`
          })
        );
    }
  }
};
</script>
