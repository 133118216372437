var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('va-action-button',{attrs:{"to":{ name: 'warehouses_list' },"label":_vm.$t('integrations.warehouse.back_to_warehouses')}}),_c('va-list',{ref:"packingSpacesList",staticClass:"mt-3",attrs:{"filters":_vm.filters,"disable-export":"","disable-create":"","items-per-page":100,"default-filter":{ enabled: 'true' }},scopedSlots:_vm._u([{key:"actions",fn:function(slotProps){return [(slotProps.currentFilter && slotProps.currentFilter._warehouse_code)?_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.openCreate}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Packing Spaces for "+_vm._s(slotProps.currentFilter._warehouse_code)+" ")],1):_vm._e(),_c('form-dialog',{attrs:{"warehouse-code":slotProps.currentFilter._warehouse_code,"item":_vm.item},on:{"warehousePackingSpacesCreated":_vm.refreshList},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})]}}])},[_c('va-data-table',{attrs:{"fields":_vm.fields,"disable-select":"","disable-show":"","disable-clone":"","disable-edit":"","disable-delete":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('va-action-button',{attrs:{"disabled":!_vm.canEditDelete(item),"label":_vm.$t('va.actions.edit'),"icon":"mdi-pencil","text":"","exact":""},on:{"click":function($event){return _vm.openEdit(item)}}}),_c('va-action-button',{attrs:{"disabled":!_vm.canEditDelete(item),"label":_vm.$t('va.actions.delete'),"icon":"mdi-delete","text":"","exact":"","loading":_vm.deleting === item.reference},on:{"click":function($event){return _vm.deletePackingSpaces(item)}}})]}},{key:"field.type",fn:function(ref){
var value = ref.value;
return [(value)?_c('div',[(value === 'envelope')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"success"}},on),[_vm._v("mdi-email-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("integrations.warehouse.packing_space.box_type.envelope")))])]):_vm._e(),(value === 'box')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"success"}},on),[_vm._v("mdi-package-variant")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("integrations.warehouse.packing_space.box_type.box")))])]):_vm._e(),(value === 'box_hazardous')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"success"}},on),[_vm._v("mdi-package-variant-remove")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("integrations.warehouse.packing_space.box_type.box_hazardous")))])]):_vm._e(),(value === 'pallet')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"success"}},on),[_vm._v("mdi-shipping-pallet")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("integrations.warehouse.packing_space.box_type.pallet")))])]):_vm._e(),(value === 'separate')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"success"}},on),[_vm._v("mdi-call-split")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("integrations.warehouse.packing_space.box_type.separate")))])]):_vm._e()],1):_vm._e()]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }