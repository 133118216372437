<template>
  <base-material-card :icon="resource.icon" :title="title">
    <template v-slot:subtitle>
      <info-text :resource="resource" crud-type="list" />
    </template>
    <va-list :filters="filters" disable-create :sort-by="['created']" :sort-desc="['true']" disable-export>
      <template v-slot:actions="slotProps">
        <export-poll-button :resource="resource.name" text :options="slotProps.listState.options" :filter="slotProps.currentFilter" />
      </template>
      <va-data-table :fields="fields" disable-edit disable-clone>
        <template v-slot:[`item.actions`]="{ item }">
          <open-close-switch :item="item" class="float-left" />
        </template>
      </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
import i18n from "@/i18n";
import OpenCloseSwitch from "@/components/contactrequests/OpenCloseSwitch";
import InfoText from "@/components/helper/InfoText";
import ExportPollButton from "../../components/export/ExportPollButton";

export default {
  components: { ExportPollButton, InfoText, OpenCloseSwitch },
  props: ["resource", "title"],
  data() {
    return {
      fields: [
        {
          label: i18n.t("integrations.fields.subject"),
          source: "subject"
        },
        {
          label: i18n.t("integrations.fields.user"),
          source: "email"
        },
        {
          label: i18n.t("integrations.fields.channel"),
          source: "channel_data",
          type: "reference",
          attributes: {
            reference: "channels",
            action: null
          }
        },
        {
          label: i18n.t("integrations.fields.product"),
          source: "product"
        },
        {
          label: i18n.t("integrations.fields.order"),
          source: "order"
        },
        {
          label: i18n.t("integrations.fields.created"),
          source: "created",
          type: "date",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.last_updated"),
          source: "last_updated",
          type: "date"
        }
      ],
      filters: [
        {
          label: i18n.t("integrations.fields.channel"),
          source: "channel",
          type: "autocomplete",
          alwaysOn: true,
          resettable: true,
          attributes: {
            reference: "channels_globals_as_defaults",
            searchQuery: "q",
            sortBy: ["name"]
          }
        },
        {
          label: i18n.t("integrations.fields.status"),
          source: "status",
          type: "select",
          attributes: {
            choices: [
              { id: "open", name: "open" },
              { id: "closed", name: "closed" }
            ],
            itemText: "name",
            itemValue: "id"
          },
          alwaysOn: true,
          resettable: true
        },
        {
          label: i18n.t("integrations.fields.created_since"),
          source: "created_since",
          resettable: true
        }
      ]
    };
  }
};
</script>
