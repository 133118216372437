<template>
  <va-action-button icon="mdi-email-send" label="Send test message" text @click="exec(item)"></va-action-button>
</template>
<script>
import Button from "@skuhnow/vuetify-admin/src/mixins/button";
import admin from "@/plugins/admin";

export default {
  props: ["action", "icon"],
  mixins: [Button],
  methods: {
    exec(item) {
      admin.http
        .post(`/communication-gateways/${item.id}/test`, {
          action: this.action
        })
        .then(() =>
          this.$store.commit(`messages/showToast`, {
            color: "success",
            message: `Test message was sent.`
          })
        )
        .catch(() =>
          this.$store.commit(`messages/showToast`, {
            color: "error",
            message: `Error while sending test message.`
          })
        );
    }
  }
};
</script>
