<template>
  <div>
    <v-input :label="$t('integrations.fields.' + fielddata.fieldname)" class="va-input" v-for="fielddata in this.values" :key="fielddata.fieldname">
      <div>
        <span>{{ fielddata.value }}</span>
      </div>
    </v-input>
  </div>
</template>

<script>
import Field from "@skuhnow/vuetify-admin/src/mixins/field";

export default {
  mixins: [Field],
  computed: {
    values() {
      if (!this.value) return [];

      return Object.entries(this.value).map(objectElement => {
        let fieldname = objectElement[0];
        let value = objectElement[1];

        if (fieldname === "reminded") {
          let date = new Date(objectElement[1] * 1000);
          value = this.$d(date, this.$admin.options.dateFormat);
        }
        if (fieldname === "decision") {
          value = this.$t(`integrations.confirmation_request.decision.${value}`);
        }

        return {
          fieldname,
          value
        };
      });
    }
  }
};
</script>
