<template>
  <div>
    <v-checkbox v-model="fullMessage" :label="$t('integrations.queue.details.show_full_message')" />
    <pre v-if="queueItem" class="overflow-auto">{{ queueItemData | pretty }}</pre>
  </div>
</template>
<script>
export default {
  props: ["queueItem"],
  data() {
    return {
      fullMessage: false
    };
  },
  filters: {
    pretty: function(value) {
      return JSON.stringify(value, null, 2);
    }
  },
  computed: {
    queueItemData() {
      if (this.fullMessage) {
        return this.queueItem;
      }
      if (this.queueItem.payload) {
        try {
          return JSON.parse(this.queueItem.payload);
        } catch (e) {
          return this.queueItem.payload;
        }
      }

      return {};
    }
  }
};
</script>
