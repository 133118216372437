import Vue from "vue";
import { ValidationProvider, ValidationObserver, extend, configure, setInteractionMode } from "vee-validate";
import { required, email, length, numeric, min, min_value, max, max_value, regex } from "vee-validate/dist/rules";
import i18n from "@/i18n";
import uniqueValidator from "@/validators/uniqueValidator";
import dateFuture from "@/validators/dateFuture";
import dateNotBefore from "@/validators/dateNotBefore";
import passwordEquals from "@/validators/passwordEquals";
import smallerThanOther from "@/validators/smallerThanOther";
import smallerThanOtherOrEqual from "@/validators/smallerThanOtherOrEqual";
import sshKeys from "@/validators/sshKeys";
import url from "@/validators/url";
import fqdn from "@/validators/fqdn";

configure({
  defaultMessage: (field, values) => {
    return i18n.t(`validation.${values._rule_}`, values);
  }
});

setInteractionMode("eager");
extend("required", required);
extend("email", email);
extend("length", length);
extend("min", min);
extend("max", max);
extend("regex", regex);
extend("numeric", numeric);
extend("min_value", min_value);
extend("max_value", max_value);

// custom validators
extend("password", passwordEquals);
extend("dateFuture", dateFuture);
extend("dateNotBefore", dateNotBefore);
extend("uniqueValidator", uniqueValidator);
extend("smallerThanOther", smallerThanOther);
extend("smallerThanOtherOrEqual", smallerThanOtherOrEqual);
extend("url", url);
extend("fqdn", fqdn);
extend("sshKeys", sshKeys);

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
