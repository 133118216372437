<template>
  <base-material-card :icon="resource.icon" :title="title">
    <template v-slot:subtitle>
      <info-text :resource="resource" crud-type="list" />
    </template>
    <va-list
      ref="batchJobList"
      :filters="filters"
      disable-export
      disable-create
      :sort-by="['created_at']"
      :sort-desc="['true']"
      :items-per-page-options="[100, 500, 1000, -1]"
      :items-per-page="100"
    >
      <template v-slot:actions>
        <create-new-batch-job-button @batchjob-created="onBatchJobCreated" />
      </template>
      <va-data-table :fields="fields" disable-edit disable-show disable-clone>
        <template v-slot:[`field.filename`]="{ item }">
          <a href="#" @click="downloadFile(item)">{{ item.filename }}</a>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <run-batch-job-button :item="item" />
          <link-button :label="$t('integrations.fields.show_logs')" icon="mdi-format-list-bulleted" :route="{ name: 'batchJobRuns', params: { batchJobId: item.id } }" />
        </template>
      </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
import InfoText from "@/components/helper/InfoText";
import RunBatchJobButton from "@/components/batchjobs/RunBatchJobButton";
import CreateNewBatchJobButton from "@/components/batchjobs/CreateNewBatchJobButton";
import i18n from "@/i18n";
import LinkButton from "@/components/actions/LinkButton";

export default {
  components: { LinkButton, CreateNewBatchJobButton, RunBatchJobButton, InfoText },
  props: ["resource", "title"],
  data() {
    return {
      fields: [
        {
          label: i18n.t("integrations.fields.type"),
          type: "function",
          source: "type",
          attributes: {
            callback: (value, record) => this.$i18n.t(`integrations.batchjobs.type.${record.type}`)
          },
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.filename"),
          source: "filename",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.attributes"),
          source: "attributes",
          type: "array-list",
          sortable: true,
          attributes: {
            showIndex: true,
            translateIndex: true
          }
        },
        {
          label: i18n.t("integrations.batchjobs.best_result"),
          type: "best-result"
        },
        {
          label: i18n.t("integrations.fields.created_by"),
          source: "created_by"
        },
        {
          label: i18n.t("integrations.fields.created_at"),
          source: "created_at",
          type: "date",
          sortable: true
        }
      ],
      filters: [
        {
          label: i18n.t("integrations.fields.type"),
          source: "type",
          type: "select",
          alwaysOn: true,
          resettable: true,
          attributes: {
            itemValue: "key",
            itemText: record => this.$t(`integrations.batchjobs.type.${record.key}`),
            reference: "batchJobTypes"
          }
        }
      ]
    };
  },
  methods: {
    async onBatchJobCreated(batchJobCreatedEvent) {
      if (batchJobCreatedEvent.runAfterUpload) {
        try {
          await this.$store.dispatch("batch/runJobByBatchJobLocation", batchJobCreatedEvent.location);
          await this.$router.push({ path: this.$store.state.batch.currentRunLocation });
        } catch (e) {
          this.$admin.toast.error(e.message);
          this.refreshList();
        }
      } else {
        this.refreshList();
      }
    },
    refreshList() {
      this.$refs.batchJobList.fetchData();
    },
    downloadFile(item) {
      window.open(`${process.env.VUE_APP_API_URL}/batchjobs/${item.id}/file`, "_blank");
    }
  }
};
</script>
