<template>
  <address>
    {{ item.billing_address.organization }}
    <br />
    {{ item.billing_address.department }}
    <br />
    {{ item.billing_address.first_name }} {{ item.billing_address.last_name }}
    <br />
    {{ item.billing_address.street }}
    <br />
    {{ item.billing_address.postcode + " " + item.billing_address.city }}
    <br />
    {{ item.billing_address.country_code }}
    <br />
    {{ item.billing_address.vatid }}
  </address>
</template>
<script>
export default {
  props: ["item"]
};
</script>
