import i18n from "@/i18n";

const AddressTypes = [
  {
    id: "BILLING",
    name: i18n.t("integrations.address.type.billing")
  },
  {
    id: "SHIPPING",
    name: i18n.t("integrations.address.type.shipping")
  },
  {
    id: "WAREHOUSE",
    name: i18n.t("integrations.address.type.warehouse")
  },
  {
    id: "DEFAULT_BILLING",
    name: i18n.t("integrations.address.type.default_billing")
  },
  {
    id: "DEFAULT_SHIPPING",
    name: i18n.t("integrations.address.type.default_shipping")
  },
  {
    id: "DEFAULT_WAREHOUSE",
    name: i18n.t("integrations.address.type.default_warehouse")
  },
  {
    id: "PRIMARY",
    name: i18n.t("integrations.address.type.primary")
  }
];

const CompanyAddressAttributeKeys = [
  { id: "externalId", name: i18n.t("integrations.address.attribute.externalId") },
  { id: "customerNo", name: i18n.t("integrations.address.attribute.customerNo") },
  { id: "departmentEmail", name: i18n.t("integrations.address.attribute.departmentEmail") },
  { id: "billingPo", name: i18n.t("integrations.address.attribute.billingPo") },
  { id: "billingCustomerNo", name: i18n.t("integrations.address.attribute.billingCustomerNo") },
  { id: "ccEmail", name: i18n.t("integrations.address.attribute.ccEmail") },
  { id: "inventoryBlockingStrategy", name: i18n.t("integrations.address.attribute.inventoryBlockingStrategy") },
  { id: "billingModel", name: i18n.t("integrations.address.attribute.billingModel") },
  { id: "predefined", name: i18n.t("integrations.address.attribute.predefined") },
  { id: "invoice_reminder_email", name: i18n.t("integrations.address.attribute.invoice_reminder_email") },
  { id: "reference_id", name: i18n.t("integrations.address.attribute.reference_id") }
];

export { AddressTypes, CompanyAddressAttributeKeys };
