<template>
  <span>
    <va-action-button icon="mdi-truck" :label="$t('integrations.btn.create.ofm')" hide-label text @click="dialog = true" />

    <v-dialog v-model="dialog" width="600">
      <validation-observer v-slot="{ handleSubmit }">
        <v-form @submit.prevent="handleSubmit(sendFeedback)">
          <v-card>
            <v-card-title>Create OrderFulfillmentMessage for {{ item.id }}</v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <validation-provider
                      :name="$t('integrations.fields.origin')"
                      :rules="{
                        required: true,
                        regex: /^[a-z0-9_-]+$/
                      }"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        :label="$t('integrations.fields.origin') + ' *'"
                        filled
                        v-model="formData.origin"
                        :hint="$t('integrations.helper.ofr.origin')"
                        :error-messages="errors"
                      ></v-text-field
                    ></validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <validation-provider :name="$t('integrations.fields.type')" rules="required" v-slot="{ errors }">
                      <va-select-input
                        :label="$t('integrations.fields.type') + ' *'"
                        v-model="formData.type"
                        :choices="messageTypeVariants"
                        item-text="name"
                        item-value="id"
                        :error-messages="errors"
                        :hint="$t('integrations.helper.ofr.type')"
                        persistent-hint
                      ></va-select-input>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field :label="$t('integrations.fields.message')" filled v-model="formData.message" :hint="$t('integrations.helper.ofr.message')"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <va-array-input :label="$t('integrations.fields.attributes')" v-model="attributesRaw" v-slot="props" :hint="$t('integrations.helper.ofr.attributes')">
                      <v-row>
                        <v-col cols="6">
                          <validation-provider :name="$t('integrations.attributes.key')" rules="required" v-slot="{ errors }">
                            <va-combobox-input
                              :label="$t('integrations.attributes.key') + ' *'"
                              v-bind="props"
                              v-model="props.item.key"
                              :choices="OrderFulfillmentMessageAttributeKeys"
                              item-text="name"
                              item-value="id"
                              :error-messages="errors"
                            />
                          </validation-provider>
                        </v-col>
                        <v-col cols="6"><va-text-input :label="$t('integrations.attributes.value')" filled v-bind="props" v-model="props.item.value"></va-text-input></v-col>
                      </v-row>
                    </va-array-input>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-divider />

            <v-card-actions>
              <v-spacer />

              <v-btn color="secondary" :disabled="sendingFeedback" @click="closeDialog">
                <v-icon left>mdi-close-circle</v-icon>
                {{ $t("integrations.btn.cancel") }}
              </v-btn>
              <v-btn color="primary" type="submit" :loading="sendingFeedback">
                <v-icon left>mdi-content-save</v-icon>
                {{ $t("integrations.btn.save") }}
              </v-btn>
            </v-card-actions>
          </v-card></v-form
        ></validation-observer
      >
    </v-dialog>
  </span>
</template>
<script>
import admin from "@/plugins/admin";
import { messageTypeVariants } from "@/resources/order-fulfillments/MessageTypeVariants";
import OrderFulfillmentMessageAttributeKeys from "./OrderFulfillmentMessageAttributeKeys";

export default {
  props: ["item"],
  data() {
    return {
      OrderFulfillmentMessageAttributeKeys: OrderFulfillmentMessageAttributeKeys,
      messageTypeVariants: messageTypeVariants,
      sendingFeedback: false,
      dialog: false,
      attributesRaw: [],
      formData: {
        origin: null,
        type: null,
        message: null,
        attributes: []
      }
    };
  },
  methods: {
    clearFormData() {
      this.formData.origin = null;
      this.formData.type = null;
      this.formData.message = null;
      this.formData.attributes = {};
      this.attributesRaw = [];
    },
    closeDialog() {
      this.dialog = false;
      this.clearFormData();
    },
    async sendFeedback() {
      const linkSendFeedback = `${process.env.VUE_APP_API_URL}/orders/*/fulfillments/${this.item.id}/messages`;

      this.formData.fulfillmentId = this.item.id;

      this.formData.attributes = {};
      this.attributesRaw.forEach(element => {
        this.formData.attributes[element.key] = element.value;
      });

      this.sendingFeedback = true;
      admin.http
        .post(linkSendFeedback, this.formData)
        .then(() => {
          this.$store.commit(`messages/showToast`, {
            color: "success",
            message: `Feedback was sent.`
          });
        })
        .catch(() =>
          this.$store.commit(`messages/showToast`, {
            color: "error",
            message: `Error while sending feedback`
          })
        )
        .finally(() => {
          this.$emit("feedbackSent");
          this.sendingFeedback = false;
          this.closeDialog();
        });
    }
  }
};
</script>
