import i18n from "@/i18n";

/**
 * Date format
 */
["en", "de"].forEach(locale => {
  i18n.setDateTimeFormat(locale, {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric"
    },
    long: {
      year: "numeric",
      month: "numeric",
      day: "numeric"
    },
    datetime: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric"
    }
  });
});

/**
 * Number format
 */
i18n.setNumberFormat("en", {
  currency: {
    style: "currency",
    currency: "USD"
  }
});
i18n.setNumberFormat("fr", {
  currency: {
    style: "currency",
    currency: "EUR"
  }
});
i18n.setNumberFormat("de", {
  currency: {
    style: "currency",
    currency: "EUR"
  }
});
