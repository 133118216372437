<template>
  <div>
    <va-list
      resource="temporaryReplacements"
      :filter="{
        replaced_user: item.username
      }"
      disable-query-string
      disable-global-search
      disable-create
      disable-export
      disable-persistent-filter
    >
      <va-data-table :fields="fields" disable-show disable-edit disable-clone disable-delete disable-select />
    </va-list>
  </div>
</template>
<script>
import i18n from "@/i18n";

export default {
  props: ["item"],
  created() {},
  data() {
    return {
      fields: [
        {
          label: i18n.t("integrations.fields.types"),
          source: "types",
          type: "array",
          attributes: {
            itemText: item => {
              return i18n.t("integrations.temporary_replacement.type." + item.toLowerCase());
            }
          }
        },
        {
          label: i18n.t("integrations.fields.channel"),
          source: "channel_data",
          type: "reference",
          attributes: {
            reference: "channels",
            action: null
          }
        },
        {
          label: i18n.t("integrations.fields.date_from"),
          source: "date_from",
          type: "date",
          attributes: {
            format: "short"
          }
        },
        {
          label: i18n.t("integrations.fields.date_to"),
          source: "date_to",
          type: "date",
          attributes: {
            format: "short"
          }
        },
        {
          label: i18n.t("integrations.fields.replacer_user"),
          source: "replacer_user"
        },
        {
          label: i18n.t("integrations.fields.state"),
          source: "state",
          type: "function",
          attributes: {
            callback: value => this.$i18n.t(`integrations.temporary_replacement.state.${value.toLowerCase()}`)
          }
        }
      ]
    };
  }
};
</script>
