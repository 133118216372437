<template>
  <validation-form :id="id" :item="item">
    <base-material-tabs-card :tabs="tabs">
      <template v-slot:general>
        <v-row>
          <v-col md="6" cols="12">
            <validation-provider
              :name="$t('integrations.fields.code')"
              :rules="{
                required: true,
                regex: /^[a-z\-]+$/,
                uniqueValidator: !isEdit ? { resource: 'sso', field: 'id' } : false
              }"
              v-slot="{ validate, errors }"
            >
              <va-text-input :readonly="isEdit" :autofocus="!isEdit" :label="$t('integrations.fields.code') + ' *'" source="id" @input="validate" :error-messages="errors" />
            </validation-provider>
          </v-col>
          <v-col md="6" cols="12">
            <validation-provider :name="$t('integrations.fields.tenant')" rules="required" v-slot="{ validate, errors }">
              <va-autocomplete-input
                :label="$t('integrations.fields.tenant') + ' *'"
                source="tenant"
                reference="tenants_companies"
                @input="validate"
                :error-messages="errors"
                search-query="name"
                :sort-by="['name']"
              ></va-autocomplete-input>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <validation-provider :name="$t('integrations.fields.saml_idp')" rules="required" v-slot="{ validate, errors }">
              <va-text-input :label="$t('integrations.fields.saml_idp') + ' *'" source="saml_idp" @input="validate" :error-messages="errors" />
            </validation-provider>
          </v-col>
          <v-col>
            <validation-provider :name="$t('integrations.fields.saml_entity_id')" rules="required" v-slot="{ validate, errors }">
              <va-text-input :label="$t('integrations.fields.saml_entity_id') + ' *'" source="saml_entity_id" @input="validate" :error-messages="errors" />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <validation-provider :name="$t('integrations.fields.saml_flow')" rules="required" v-slot="{ validate, errors }">
              <va-select-input
                :label="$t('integrations.fields.saml_flow') + ' *'"
                source="saml_flow"
                @input="validate"
                :error-messages="errors"
                :choices="[
                  { value: 'sp_initiated', text: 'SP-Initiated' },
                  { value: 'idp_initiated', text: 'IDP-Initiated' }
                ]"
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <va-array-input :label="$t('integrations.fields.single_sign_on_services')" source="single_sign_on_services" v-slot="props" :hide-mover="true">
              <v-row>
                <v-col cols="6">
                  <validation-provider :name="$t('integrations.fields.binding')" rules="required" v-slot="{ validate, errors }">
                    <va-select-input
                      :label="$t('integrations.fields.binding') + ' *'"
                      v-bind="props"
                      source="binding"
                      @input="validate"
                      :error-messages="errors"
                      :choices="[
                        { value: 'http_redirect', text: 'HTTP-Redirect' },
                        { value: 'http_post', text: 'HTTP-POST' }
                      ]"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="6">
                  <validation-provider :name="$t('integrations.fields.url')" rules="required|url" v-slot="{ validate, errors }">
                    <va-text-input :label="$t('integrations.fields.url') + ' *'" v-bind="props" source="url" @input="validate" :error-messages="errors" />
                  </validation-provider>
                </v-col>
              </v-row>
            </va-array-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <va-array-input :label="$t('integrations.fields.single_logout_services')" source="single_logout_services" v-slot="props" :hide-mover="true">
              <v-row>
                <v-col cols="6">
                  <validation-provider :name="$t('integrations.fields.binding')" rules="required" v-slot="{ validate, errors }">
                    <va-select-input
                      :label="$t('integrations.fields.binding') + ' *'"
                      v-bind="props"
                      source="binding"
                      @input="validate"
                      :error-messages="errors"
                      :choices="[
                        { value: 'http_redirect', text: 'HTTP-Redirect' },
                        { value: 'http_post', text: 'HTTP-POST' }
                      ]"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="6">
                  <validation-provider :name="$t('integrations.fields.url')" rules="required|url" v-slot="{ validate, errors }">
                    <va-text-input :label="$t('integrations.fields.url') + ' *'" v-bind="props" source="url" @input="validate" :error-messages="errors" />
                  </validation-provider>
                </v-col>
              </v-row>
            </va-array-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <va-array-input :label="$t('integrations.fields.keys')" source="keys" v-slot="props">
              <v-row>
                <v-col cols="6">
                  <validation-provider
                    :name="$t('integrations.fields.x509_certificate')"
                    :rules="{
                      required: true,
                      regex: /^[A-Za-z0-9+\/=\r\n]+$/
                    }"
                    v-slot="{ validate, errors }"
                  >
                    <va-text-input
                      :multiline="true"
                      :label="$t('integrations.fields.x509_certificate') + ' *'"
                      v-bind="props"
                      source="x509_certificate"
                      @input="validate"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="6">
                  <va-text-input :label="$t('integrations.fields.comment')" v-bind="props" source="comment"></va-text-input>
                </v-col>
              </v-row>
            </va-array-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <va-array-input :label="$t('integrations.fields.mapping')" source="mapping" v-slot="props" :hide-mover="true">
              <v-row>
                <v-col cols="6">
                  <validation-provider :name="$t('integrations.sso_connections.mapping.key')" rules="required" v-slot="{ validate, errors }">
                    <va-select-input
                      :label="$t('integrations.sso_connections.mapping.key')"
                      v-bind="props"
                      source="key"
                      :choices="SsoMappingKeys"
                      item-text="name"
                      item-value="id"
                      @input="validate"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="6">
                  <va-text-input :label="$t('integrations.sso_connections.mapping.value')" v-bind="props" source="value" />
                </v-col>
              </v-row>
            </va-array-input>
          </v-col>
        </v-row>
      </template>
      <template v-slot:footer>
        <div class="d-flex align-center"><va-save-button /><va-cancel-button class="ml-2" /></div>
      </template>
    </base-material-tabs-card>
  </validation-form>
</template>
<script>
import { SsoMappingKeys } from "@/resources/ssoConnections/SsoMappingKeys";

export default {
  props: ["id", "title", "item", "isEdit"],
  data() {
    return {
      SsoMappingKeys: SsoMappingKeys
    };
  },
  computed: {
    tabs() {
      return [
        {
          id: "general",
          label: this.isEdit ? this.$t("integrations.sso_connections.title.edit", { label: this.id }) : this.$t("integrations.sso_conncections.title.create")
        }
      ];
    }
  }
};
</script>
