<template>
  <v-data-table :headers="headers" :items="dataRows" />
</template>
<script>
export default {
  props: ["diffs"],
  data() {
    return {
      headers: [
        {
          text: this.$i18n.t("integrations.fields.field_name"),
          value: "fieldName"
        },
        {
          text: this.$i18n.t("integrations.fields.old"),
          value: "old"
        },
        {
          text: this.$i18n.t("integrations.fields.new"),
          value: "new"
        }
      ]
    };
  },
  computed: {
    dataRows() {
      let rows = [];
      for (let fieldName of Object.keys(this.diffs)) {
        rows.push({
          fieldName: fieldName,
          old: this.prepareOutput(this.diffs[fieldName].old),
          new: this.prepareOutput(this.diffs[fieldName].new)
        });
      }
      return rows;
    }
  },
  methods: {
    prepareOutput(value) {
      if (value && !!value.id) return value.id;
      if (value === true) return "true";
      if (value === false) return "false";
      return value;
    }
  }
};
</script>
