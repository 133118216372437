<template>
  <div>
    <va-list
      resource="orders"
      :filter="{
        customer: item.email,
        tenant: item.company
      }"
      disable-global-search
      disable-create
      disable-export
      disable-persistent-filter
      :sort-by="['order_date']"
      :sort-desc="[true]"
    >
      <va-data-table :fields="fields" disable-show disable-clone disable-delete disable-select disable-edit>
        <template v-slot:[`item.actions`]="{ item }">
          <link-button :label="$t('integrations.fields.details')" icon="mdi-eye" :route="{ name: 'orders_show', params: { id: item.id } }" />
        </template>
      </va-data-table>
    </va-list>
  </div>
</template>
<script>
import i18n from "@/i18n";
import { stateVariants } from "@/resources/orders/OrderStateVariants";
import LinkButton from "@/components/actions/LinkButton";

export default {
  components: { LinkButton },
  props: ["item"],
  created() {},
  data() {
    return {
      fields: [
        {
          label: i18n.t("integrations.fields.order_date"),
          type: "date",
          source: "order_date",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.reference"),
          source: "id"
        },
        {
          label: i18n.t("integrations.fields.items_count"),
          source: "items_count",
          align: "right",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.total"),
          type: "function",
          align: "right",
          sortable: true,
          attributes: {
            callback: (value, record) => {
              return new Intl.NumberFormat(i18n.locale, {
                style: "currency",
                currency: record.currency_code
              }).format(record.order_total / 100);
            }
          }
        },
        {
          label: i18n.t("integrations.fields.order_status"),
          source: "state",
          type: "select",
          alwaysOn: true,
          resettable: true,
          attributes: {
            choices: stateVariants,
            itemText: "name",
            itemValue: "id"
          }
        }
      ]
    };
  }
};
</script>
