var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-form',{attrs:{"id":_vm.id,"item":_vm.item}},[_c('base-material-tabs-card',{attrs:{"tabs":_vm.tabs},scopedSlots:_vm._u([{key:"company_information",fn:function(){return [_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.code'),"rules":{
              required: true,
              regex: /^[a-zA-Z0-9_-]+$/,
              uniqueValidator: !_vm.isEdit ? { resource: 'companies', field: 'codeEquals' } : false
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var validate = ref.validate;
            var errors = ref.errors;
return [_c('va-text-input',{attrs:{"autofocus":!_vm.isEdit,"readonly":_vm.isEdit,"label":_vm.$t('integrations.fields.code') + ' *',"source":"code","error-messages":errors},on:{"input":validate}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.customer_name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var validate = ref.validate;
            var errors = ref.errors;
return [_c('va-text-input',{attrs:{"label":_vm.$t('integrations.fields.customer_name') + ' *',"source":"name","error-messages":errors},on:{"input":validate}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.group'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var validate = ref.validate;
            var errors = ref.errors;
return [_c('va-autocomplete-input',{attrs:{"label":_vm.$t('integrations.fields.group') + ' *',"source":"group","reference":"company-groups","item-value":"code","search-query":"name","sort-by":['name'],"error-messages":errors,"readonly":_vm.isEdit},on:{"input":validate}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('va-autocomplete-input',{attrs:{"label":_vm.$t('integrations.fields.parent_company'),"source":"parent","reference":"companies","search-query":"name","render-callback":function (record) { return record.name + ' (' + record.group + ')'; },"sort-by":['name']}})],1)],1),_c('v-row',[_c('v-col',[_c('va-autocomplete-input',{attrs:{"label":_vm.$t('integrations.fields.owner'),"source":"owner","reference":"manager_companies","item-value":"id","render-callback":function (record) { return record.name + ' (' + record.group + ')'; },"search-query":"name","sort-by":['name']}})],1)],1),(false)?_c('v-row',[_c('v-col',[_c('va-array-input',{attrs:{"label":_vm.$t('integrations.fields.regions'),"source":"regions"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.code'),"rules":{
                    required: true,
                    regex: /^[a-zA-Z0-9_-]+$/
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var validate = ref.validate;
                  var errors = ref.errors;
return [_c('va-text-input',_vm._b({attrs:{"label":_vm.$t('integrations.fields.code') + ' *',"source":"id","error-messages":errors},on:{"input":validate}},'va-text-input',props,false))]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('va-autocomplete-input',_vm._b({attrs:{"label":_vm.$t('integrations.fields.country'),"source":"countries","reference":"countries","multiple":true,"search-query":"q"}},'va-autocomplete-input',props,false))],1),_c('v-col',{attrs:{"cols":"4"}},[_c('va-autocomplete-input',_vm._b({attrs:{"label":_vm.$t('integrations.fields.owner'),"source":"owner","reference":"manager_companies","render-callback":function (record) { return record.name + ' (' + record.group + ')'; },"search-query":"name","sort-by":['name']}},'va-autocomplete-input',props,false))],1)],1)]}}],null,false,2873872623)})],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('va-array-input',{attrs:{"label":_vm.$t('integrations.fields.attributes'),"source":"attributes_key_value"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.attributes.key'),"rules":{
                    required: true,
                    regex: /^[a-zA-Z0-9_-]+$/
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var validate = ref.validate;
                  var errors = ref.errors;
return [_c('va-combobox-input',_vm._b({attrs:{"label":_vm.$t('integrations.attributes.key'),"source":"key","choices":_vm.CompanyAttributeKeys,"item-text":"name","item-value":"id","error-messages":errors},on:{"input":validate}},'va-combobox-input',props,false))]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('va-text-input',_vm._b({attrs:{"label":_vm.$t('integrations.attributes.value'),"source":"value"}},'va-text-input',props,false))],1)],1)]}}])})],1)],1)]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex align-center"},[_c('va-save-button'),_c('va-cancel-button',{staticClass:"ml-2"})],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }