/* eslint-disable no-unused-vars */

export default (i18n, admin) => [
  {
    icon: "mdi-view-dashboard",
    text: i18n.t("menu.dashboard"),
    link: "/"
  },
  { divider: true },
  {
    icon: "mdi-domain",
    text: i18n.t("integrations.menu.company_management"),
    children: [
      admin.getResourceLink({
        name: "companies",
        text: i18n.t("integrations.menu.companies")
      }),
      admin.getResourceLink({
        name: "companyAddresses",
        text: i18n.t("integrations.menu.tenantAddresses")
      })
    ]
  },
  {
    icon: "mdi-account-multiple",
    text: i18n.t("integrations.menu.user_management"),
    children: [
      admin.getResourceLink({
        name: "users",
        text: i18n.t("integrations.menu.users")
      }),
      admin.getResourceLink({
        name: "useraddresses",
        text: i18n.t("integrations.menu.useraddresses")
      }),
      admin.getResourceLink({
        name: "usergroups",
        text: i18n.t("integrations.menu.usergroups")
      }),
      admin.getResourceLink({
        name: "temporaryReplacements",
        text: i18n.t("integrations.menu.temporary_replacements")
      })
    ]
  },
  {
    icon: "mdi-card-account-mail",
    text: i18n.t("integrations.menu.request_management"),
    children: [
      admin.getResourceLink({
        name: "contactrequests",
        text: i18n.t("integrations.menu.contactrequests")
      }),
      admin.getResourceLink({
        name: "confirmation-requests",
        text: i18n.t("integrations.menu.confirmation-requests")
      }),
      admin.getResourceLink({
        name: "formsubmissions",
        text: i18n.t("integrations.menu.formsubmissions")
      })
    ]
  },
  {
    icon: "mdi-cart-variant",
    text: i18n.t("integrations.menu.order_management"),
    children: [
      admin.getResourceLink({
        name: "orders",
        text: i18n.t("integrations.menu.orders")
      }),
      admin.getResourceLink({
        name: "orderFulfillments",
        text: i18n.t("integrations.menu.orderfulfillments")
      })
    ]
  },
  { divider: true },
  admin.getResourceLink({
    name: "products",
    text: i18n.t("integrations.menu.products")
  }),
  admin.getResourceLink({
    name: "budgets",
    text: i18n.t("integrations.menu.budgets")
  }),
  {
    icon: "mdi-bell-ring-outline",
    text: i18n.t("integrations.menu.marketing"),
    children: [
      admin.getResourceLink({
        name: "campaigns",
        text: i18n.t("integrations.menu.campaigns")
      }),
      admin.getResourceLink({
        name: "emailTemplates",
        text: i18n.t("integrations.menu.emailtemplates")
      })
    ]
  },
  {
    icon: "mdi-truck-fast-outline",
    text: i18n.t("integrations.menu.logistics"),
    children: [
      admin.getResourceLink({
        name: "warehouses",
        text: i18n.t("integrations.menu.warehouses")
      }),
      admin.getResourceLink({
        name: "inventory-blockings",
        text: i18n.t("integrations.menu.inventory_blockings")
      }),
      admin.getResourceLink({
        name: "shippingCalculations",
        text: i18n.t("integrations.menu.shipping_calculations")
      })
    ]
  },
  { divider: true },
  {
    icon: "mdi-cog",
    text: i18n.t("integrations.menu.management"),
    children: [
      admin.getResourceLink({
        name: "batchjobs",
        text: i18n.t("integrations.menu.batchjobs")
      }),
      admin.getResourceLink({
        name: "exports",
        text: i18n.t("integrations.menu.exports")
      }),
      admin.getResourceLink({
        name: "communication-gateways",
        text: i18n.t("integrations.menu.communication-gateways")
      }),
      admin.getResourceLink({
        name: "commands",
        text: i18n.t("integrations.menu.commands")
      }),
      admin.getResourceLink({
        name: "workers",
        text: i18n.t("integrations.menu.workers")
      }),
      admin.getResourceLink({
        name: "queues",
        text: i18n.t("integrations.menu.queues")
      }),
      admin.getResourceLink({
        name: "accounts",
        text: i18n.t("integrations.menu.accounts")
      }),
      admin.getResourceLink({
        name: "cronJobs",
        text: i18n.t("integrations.menu.cron_jobs")
      })
    ]
  },
  {
    icon: "mdi-shopping",
    text: i18n.t("integrations.menu.shop"),
    children: [
      admin.getResourceLink({
        name: "ssoConnections",
        text: i18n.t("integrations.menu.sso_connections")
      }),
      admin.getResourceLink({
        name: "deployments",
        text: i18n.t("integrations.menu.deployments")
      }),

      admin.getResourceLink({
        name: "domains",
        text: i18n.t("integrations.menu.domains")
      })
    ]
  }
];
