<template>
  <v-progress-linear :value="count" height="25" class="white--text">
    <strong>
      {{ distanceFromNow }}
    </strong>
  </v-progress-linear>
</template>
<script>
import { isPast, differenceInMinutes, formatDistanceToNow } from "date-fns";

export default {
  props: ["item"],
  computed: {
    count() {
      if (isPast(this.validUntilDate)) {
        return 100;
      }
      const daysFromStartToEnd = differenceInMinutes(this.validUntilDate, new Date(this.item.created_at));
      const daysFromStartToNow = differenceInMinutes(new Date(), new Date(this.item.created_at));

      return (daysFromStartToNow / daysFromStartToEnd) * 100;
    },
    distanceFromNow() {
      if (isPast(this.validUntilDate)) {
        return "";
      }
      return formatDistanceToNow(this.validUntilDate);
    },
    validUntilDate() {
      return new Date(this.item.valid_until);
    }
  }
};
</script>
