import Vue from "vue";
import http from "@/plugins/http";
import router from "@/router";

export default {
  namespaced: true,
  state: () => ({
    batchJob: null,
    batchJobRun: null,
    startedManually: null,
    currentRunLocation: null,
    stages: null,
    stepperIndex: 1
  }),
  actions: {
    async loadBatchJob(context, batchJobId) {
      const response = await http.get(`/batchjobs/${batchJobId}`);
      context.commit("setBatchJob", response.data);
    },
    async loadBatchJobRun(context, { batchJobId, batchRunId }) {
      const response = await http.get(`/batchjobs/${batchJobId}/runs/${batchRunId}`);
      context.commit("setBatchJobRun", response.data);
    },
    async runJob(context, batchJobId) {
      context.commit("setStartedManually", true);
      let response = await http.post(`/batchjobs/${batchJobId}/runs`);
      context.commit("setCurrentRunLocation", response.headers.location);
      response = await http.get(response.headers.location);
      context.commit("setBatchJobRun", response.data);
    },
    async runJobByBatchJobLocation(context, batchRunLocation) {
      context.commit("setStartedManually", true);
      const response = await http.post(`${batchRunLocation}/runs`);
      context.commit("setCurrentRunLocation", response.headers.location);
    },
    async loadStages(context, { batchJobId, batchRunId }) {
      const response = await http.get(`/batchjobs/${batchJobId}/runs/${batchRunId}/stages`);
      context.commit("setStages", response.data.data);
    }
  },
  mutations: {
    nextStage(state) {
      if (state.stepperIndex < state.stages.length + 1) {
        updateStepperIndex(state, state.stepperIndex + 1);
      }
    },
    prevStage(state) {
      if (state.stepperIndex > 1) {
        updateStepperIndex(state, state.stepperIndex - 1);
      }
    },
    setStepperIndex(state, stepperIndex) {
      if (state.stepperIndex === stepperIndex) {
        return;
      }

      if (state.stages) {
        // check generic steps for previous status; ignore resultStep
        const resultStepIndex = state.stages.length + 1;
        if (stepperIndex !== resultStepIndex) {
          if (stepperIndex > 1 && state.stages[stepperIndex - 2].status !== "finished") {
            return;
          }
        }
      }
      updateStepperIndex(state, stepperIndex);
    },
    setBatchJob(state, batchJob) {
      state.batchJob = batchJob;
    },
    setBatchJobRun(state, batchJobRun) {
      state.batchJobRun = batchJobRun;
    },
    setStages(state, stages) {
      state.stages = stages;

      // check stepperIndex
      const indexExceedsSteps = state.stepperIndex > state.stages.length + 1;
      if ((indexExceedsSteps || state.stepperIndex < 1) && state.stepperIndex !== 1) {
        updateStepperIndex(state, 1);
      }
    },
    setStage(state, stage) {
      state.currentStage = stage;

      // update global stage array
      let stageIndex = state.stages.findIndex(globalStage => globalStage.id === stage.id);
      if (stageIndex !== -1) {
        Vue.set(state.stages, stageIndex, stage);
      }
    },
    setCurrentRunLocation(state, currentRunLocation) {
      state.currentRunLocation = currentRunLocation;
      state.stages = null;
    },
    setStartedManually(state, value) {
      state.startedManually = value;
    }
  }
};

function updateStepperIndex(state, newValue) {
  if (state.stepperIndex !== newValue) {
    state.stepperIndex = newValue;
    router.push({ path: "", query: { step: newValue } });
  }
}
