<template>
  <v-dialog width="700" v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" text v-bind="attrs" v-on="on"> <v-icon left>mdi-format-list-bulleted</v-icon>Logs</v-btn>
    </template>
    <v-card>
      <v-card-title>Logs for {{ logData.system }} / {{ logData.unit }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col> Description: {{ logData.description }}</v-col>
        </v-row>
        <v-row>
          <v-col>
            <div>Log</div>
            <pre>{{ logData.log }}</pre>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import Button from "@skuhnow/vuetify-admin/src/mixins/button";

export default {
  mixins: [Button],
  props: ["item"],
  data() {
    return {
      dialog: false,
      logData: {}
    };
  },
  created() {
    this.loadLogData();
  },
  methods: {
    async loadLogData() {
      const response = await this.$admin.http.get(`${process.env.VUE_APP_API_URL}/workers/${this.item.system}/${this.item.unit}`);
      this.logData = response.data;

      // this.logData = {
      //   system: this.item.system,
      //   unit: this.item.unit,
      //   description: "Description of Unit",
      //   log: "some log\nmore log"
      // };
    }
  }
};
</script>
