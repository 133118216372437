import i18n from "@/i18n";

const StageStates = {
  pending: { icon: "mdi-clock-outline", color: "info" },
  running: { icon: "mdi-clock-fast", color: "info" },
  need_decision: { icon: "mdi-arrow-decision", color: "warning" },
  error: { icon: "mdi-alert-circle-outline", color: "error" },
  finished: { icon: "mdi-check", color: "success" },
  skipped: { icon: "mdi-alert", color: "warning" }
};

export default {
  data() {
    return {
      statusList: StageStates
    };
  },
  methods: {
    statusTranslation(status) {
      checkStatus(status);
      return i18n.t(`integrations.batchjobs.stages.status.${status}`);
    },
    statusIcon(status) {
      checkStatus(status);
      return StageStates[status].icon;
    },
    statusColor(status) {
      checkStatus(status);
      return StageStates[status].color;
    },
    statusColorCode(status) {
      checkStatus(status);
      return this.$vuetify.theme.themes.light[this.statusColor(status)];
    }
  }
};

function checkStatus(status) {
  if (!status) {
    console.error("Status is not set", status);
  }
  if (!(status in StageStates)) {
    console.error(`Unknown status`, status);
  }
}
