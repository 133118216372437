<template>
  <va-text-input :label="$t('integrations.fields.user')" v-model="user" :readonly="true"></va-text-input>
</template>
<script>
export default {
  props: ["ofr"],
  computed: {
    user() {
      let userStr = "";
      if (this.ofr !== undefined) {
        if (this.ofr.data !== undefined) {
          if (this.ofr.data[0] !== undefined) {
            if (this.ofr.data[0].order !== undefined) {
              userStr = this.ofr.data[0].order.restrict;
            }
          }
        }
      }

      return userStr;
    }
  }
};
</script>
