<template>
  <v-dialog width="700" @input="handleFormStatusChange($event)" v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
      <v-btn :disabled="item.messages === 0" color="success" text v-bind="attrs" v-on="on"> <v-icon left>mdi-eye</v-icon> {{ $t("integrations.fields.details") }} </v-btn>
    </template>
    <v-card>
      <v-card-title>{{ item.name }}</v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <queue-message :queue-item="lastQueueItem" />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="dialog = false">{{ $t("integrations.btn.close") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Button from "@skuhnow/vuetify-admin/src/mixins/button";
import QueueMessage from "@/resources/queues/components/QueueMessage";

export default {
  components: { QueueMessage },
  props: ["item"],
  mixins: [Button],
  data() {
    return {
      dialog: false,
      lastQueueItem: null
    };
  },
  methods: {
    async handleFormStatusChange(formOpen) {
      if (formOpen) {
        await this.loadLastQueueItem();
      }
    },
    async loadLastQueueItem() {
      const response = await this.$admin.http.get(`queues/${this.item.name}/messages/current`);
      if (response.data) {
        this.lastQueueItem = response.data;
      }
    }
  }
};
</script>
