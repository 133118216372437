<template>
  <validation-form :id="id" :item="item">
    <base-material-tabs-card :tabs="tabs">
      <template v-slot:general>
        <v-row>
          <v-col>
            <validation-provider :name="$t('integrations.fields.payload')" rules="required" v-slot="{ validate, errors }">
              <code-input
                :label="$t('integrations.fields.payload') + ' *'"
                :autofocus="!isEdit"
                :value="initialPayloadStr"
                source="payload"
                @update="validate"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row>
      </template>
      <template v-slot:footer>
        <div class="d-flex align-center"><va-save-button /><va-cancel-button class="ml-2" /></div>
      </template>
    </base-material-tabs-card>
  </validation-form>
</template>
<script>
import CodeInput from "@/components/inputs/CodeInput.vue";

export default {
  components: { CodeInput },
  props: ["id", "title", "item", "isEdit"],
  computed: {
    initialPayload() {
      return {
        channel: "acme@default",
        accounts: ["<account>"],
        origin_code: "<warehouse-code>",
        destination: {
          address_line1: "...",
          address_line2: "...",
          locality: "...",
          administrative_area: "...",
          postal_code: "...",
          country_code: ".."
        },
        items: [
          {
            sku: "<sku>",
            quantity: 1
          }
        ]
      };
    },
    initialPayloadStr() {
      return JSON.stringify(this.initialPayload, null, 4);
    },
    tabs() {
      return [
        {
          id: "general",
          label: this.$t("integrations.shipping_calculations.title.create")
        }
      ];
    }
  }
};
</script>
