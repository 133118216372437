<template>
  <v-row justify="center" v-if="ofr">
    <v-col sm="4">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <ofm-reference :item="item" />
                <ofm-company :ofr="ofr" />
                <ofm-user :ofr="ofr" />
                <v-input class="va-input" label="Link" :hint="$t('integrations.helper.ofr.order')" persistent-hint>
                  <div>
                    <ofm-link-to-order :item="item" />
                  </div>
                </v-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-input :label="$t('integrations.fields.type')" class="va-input">
                  <div>
                    <va-select-field :choices="typeVariants" source="type" item-text="name" item-value="id" />
                  </div>
                </v-input>
                <v-input :label="$t('integrations.fields.status')" class="va-input">
                  <div>
                    <va-select-field :choices="stateVariants" source="state" item-text="name" item-value="id" />
                  </div>
                </v-input>
                <v-input :label="$t('integrations.fields.supplier')" class="va-input">
                  <div>
                    <va-reference-field v-if="$admin.can(['ROLE_ADMIN'])" source="fulfillment_config.supplier_code" reference="fulfiller_companies" :fetch="true" :action="null" />
                    <va-text-field v-else source="fulfillment_config.supplier_code" />
                  </div>
                </v-input>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import OfmReference from "@/components/order-fulfillments/showFields/OfmReference";
import OfmCompany from "@/components/order-fulfillments/showFields/OfmCompany";
import OfmUser from "@/components/order-fulfillments/showFields/OfmUser";
import admin from "@/plugins/admin";
import OfmLinkToOrder from "@/components/order-fulfillments/showFields/OfmLinkToOrder";
import { typeVariants } from "@/resources/order-fulfillments/OfrTypeVariants";
import { stateVariants } from "@/resources/order-fulfillments/OrderFulfillmentStateVariants";

export default {
  components: { OfmLinkToOrder, OfmUser, OfmCompany, OfmReference },
  props: ["title", "item"],
  data() {
    return {
      ofr: null,
      typeVariants: typeVariants,
      stateVariants: stateVariants
    };
  },
  async created() {
    this.ofr = await this.getOfr();
  },
  methods: {
    async getOfr() {
      const response = await admin.http.get("/orders/*/fulfillments?_limit=1&id=~" + this.item.id);

      return response.data;
    }
  }
};
</script>
