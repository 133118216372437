<template>
  <v-simple-table>
    <thead>
      <tr>
        <th>
          {{ $t("integrations.fields.payment_method") }}
        </th>
        <th>
          {{ $t("integrations.fields.invoice_number") }}
        </th>
        <th>
          {{ $t("integrations.fields.invoice_date") }}
        </th>
        <th>
          {{ $t("integrations.fields.status") }}
        </th>
        <th>
          {{ $t("integrations.fields.payment_date") }}
        </th>
        <th>
          {{ $t("integrations.fields.account_holder") }}
        </th>
        <th>
          {{ $t("integrations.fields.details") }}
        </th>
        <th>
          {{ $t("integrations.fields.po") }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{ item.payment.method_code }}</td>
        <td>
          {{ item.payment.details.length !== 0 ? item.payment.details.invoice_number : "" }}
        </td>
        <td>
          {{ item.payment.details.length !== 0 ? new Date(item.payment.details.invoiced_date).toLocaleDateString() : "" }}
        </td>
        <td>{{ item.payment.state }}</td>
        <td>
          {{ item.payment.details.length !== null ? (item.payment.details.paid_date !== null ? item.payment.details.paid_date : null) : null }}
        </td>
        <td>
          {{
            item.payment.transaction_details !== null
              ? item.payment.transaction_details.wirecard_result !== undefined
                ? item.payment.transaction_details.wirecard_result.payment["account-holder"]["first-name"] +
                  " " +
                  item.payment.transaction_details.wirecard_result.payment["account-holder"]["last-name"]
                : null
              : null
          }}
        </td>
        <td>
          {{ item.payment.details !== null ? (item.payment.details.email !== null ? item.payment.details.email : null) : null }}
        </td>
        <td>
          {{ item.payment.details !== null ? (item.payment.details.code !== null ? item.payment.details.code : null) : null }}
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>
<script>
export default {
  props: ["item"]
};
</script>
