import sortFieldMapping from "@/providers/sortFieldMapping";

export default {
  mapField(resource, sourceField) {
    if (resource in sortFieldMapping) {
      const sortFieldMappingForResource = sortFieldMapping[resource];

      if (sourceField in sortFieldMappingForResource) {
        return sortFieldMappingForResource[sourceField];
      }
    }

    return sourceField;
  }
};
