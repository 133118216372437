<template>
  <div>
    <va-action-button :to="{ name: 'warehouses_list' }" :label="$t('integrations.warehouse.back_to_warehouses')" />

    <va-list class="mt-3" :filters="filters" ref="packingSpacesList" disable-export disable-create :items-per-page="100" :default-filter="{ enabled: 'true' }">
      <template v-slot:actions="slotProps">
        <v-btn v-if="slotProps.currentFilter && slotProps.currentFilter._warehouse_code" color="primary" text @click="openCreate">
          <v-icon left>mdi-plus</v-icon>
          Packing Spaces for {{ slotProps.currentFilter._warehouse_code }}
        </v-btn>

        <form-dialog :warehouse-code="slotProps.currentFilter._warehouse_code" @warehousePackingSpacesCreated="refreshList" v-model="dialog" :item="item" />
      </template>
      <va-data-table :fields="fields" disable-select disable-show disable-clone disable-edit disable-delete>
        <template v-slot:[`item.actions`]="{ item }">
          <va-action-button :disabled="!canEditDelete(item)" :label="$t('va.actions.edit')" icon="mdi-pencil" text exact @click="openEdit(item)" />
          <va-action-button
            :disabled="!canEditDelete(item)"
            :label="$t('va.actions.delete')"
            icon="mdi-delete"
            text
            exact
            :loading="deleting === item.reference"
            @click="deletePackingSpaces(item)"
          />
        </template>
        <template v-slot:[`field.type`]="{ value }">
          <div v-if="value">
            <v-tooltip bottom v-if="value === 'envelope'">
              <template v-slot:activator="{ on }">
                <v-icon color="success" v-on="on">mdi-email-outline</v-icon>
              </template>
              <span>{{ $t("integrations.warehouse.packing_space.box_type.envelope") }}</span>
            </v-tooltip>
            <v-tooltip bottom v-if="value === 'box'">
              <template v-slot:activator="{ on }">
                <v-icon color="success" v-on="on">mdi-package-variant</v-icon>
              </template>
              <span>{{ $t("integrations.warehouse.packing_space.box_type.box") }}</span>
            </v-tooltip>
            <v-tooltip bottom v-if="value === 'box_hazardous'">
              <template v-slot:activator="{ on }">
                <v-icon color="success" v-on="on">mdi-package-variant-remove</v-icon>
              </template>
              <span>{{ $t("integrations.warehouse.packing_space.box_type.box_hazardous") }}</span>
            </v-tooltip>
            <v-tooltip bottom v-if="value === 'pallet'">
              <template v-slot:activator="{ on }">
                <v-icon color="success" v-on="on">mdi-shipping-pallet</v-icon>
              </template>
              <span>{{ $t("integrations.warehouse.packing_space.box_type.pallet") }}</span>
            </v-tooltip>
            <v-tooltip bottom v-if="value === 'separate'">
              <template v-slot:activator="{ on }">
                <v-icon color="success" v-on="on">mdi-call-split</v-icon>
              </template>
              <span>{{ $t("integrations.warehouse.packing_space.box_type.separate") }}</span>
            </v-tooltip>
          </div>
        </template>
      </va-data-table>
    </va-list>
  </div>
</template>
<script>
import i18n from "@/i18n";
import FormDialog from "@/resources/packingSpaces/FormDialog.vue";
import { PackageTypes } from "@/resources/packingSpaces/PackageTypes";

export default {
  components: { FormDialog },
  methods: {
    refreshList() {
      this.$store.dispatch("api/refresh", this.$route.meta.resource);
    },
    setupBreadcrumbs() {
      const currentFilter = JSON.parse(this.$route.query.filter);
      if (!currentFilter) {
        return;
      }
      const currentWarehouse = currentFilter._warehouse_code;
      this.$store.commit("breadcrumbs/setItems", [
        {
          text: "Start",
          disabled: false,
          href: "/"
        },
        {
          text: this.$i18n.t("integrations.menu.warehouses"),
          exact: true,
          to: { name: "warehouses_list" }
        },
        {
          text: currentWarehouse
        },
        {
          text: this.$i18n.t("integrations.menu.packing_spaces")
        }
      ]);
    },
    openCreate() {
      this.item = null;
      this.dialog = true;
    },
    openEdit(item) {
      this.item = item;
      this.dialog = true;
    },
    async deletePackingSpaces(item) {
      const userConfirmed = await this.$admin.confirm(
        this.$i18n.t("va.confirm.delete_title", { id: item.reference }),
        this.$i18n.t("va.confirm.delete_message", { id: item.reference })
      );

      if (!userConfirmed) {
        return;
      }

      const urlParams = new URLSearchParams(window.location.search);
      const filter = JSON.parse(urlParams.get("filter"));
      const warehouseCode = filter._warehouse_code;

      this.deleting = item.reference;
      this.$admin.http
        .request({ method: "delete", url: `${process.env.VUE_APP_API_URL}/warehouses/${warehouseCode}/packing-spaces/${item.reference}` })
        .then(() => {
          this.$store.commit(`messages/showToast`, {
            color: "success",
            message: this.$i18n.t("integrations.warehouse.messages.packing_spaces.deleted", { reference: item.reference })
          });
        })
        .catch(() =>
          this.$store.commit(`messages/showToast`, {
            color: "error",
            message: this.$i18n.t("integrations.warehouse.messages.packing_spaces.error_deleted", { reference: item.reference })
          })
        )
        .finally(() => {
          this.refreshList();
          this.deleting = null;
        });
    },
    formatWithmeasurementSystemUnit(measurementSystem, value, type) {
      if (!value) {
        return "--";
      }
      let formattedValue = new Intl.NumberFormat(i18n.locale).format(value);
      if (type === "weight") {
        return `${formattedValue} ${measurementSystem === "metric" ? "g" : "oz"}`;
      }
      return `${formattedValue} ${measurementSystem === "metric" ? "mm" : "in"}`;
    },
    canEditDelete(item) {
      return item.type !== "separate";
    }
  },
  created() {
    this.refreshList();
  },
  mounted() {
    this.setupBreadcrumbs();
  },
  data() {
    return {
      dialog: false,
      item: null,
      deleting: false,
      fields: [
        {
          label: i18n.t("integrations.fields.type"),
          source: "type"
        },
        {
          label: i18n.t("integrations.fields.reference"),
          source: "reference"
        },
        {
          label: i18n.t("integrations.fields.description"),
          source: "description"
        },
        {
          label: i18n.t("integrations.fields.outer_width"),
          source: "outer_width",
          type: "function",
          attributes: {
            callback: (value, record) => {
              return this.formatWithmeasurementSystemUnit(record.measurement_system, value, "length");
            }
          }
        },
        {
          label: i18n.t("integrations.fields.outer_height"),
          source: "outer_height",
          type: "function",
          attributes: {
            callback: (value, record) => {
              return this.formatWithmeasurementSystemUnit(record.measurement_system, value, "length");
            }
          }
        },
        {
          label: i18n.t("integrations.fields.outer_length"),
          source: "outer_length",
          type: "function",
          attributes: {
            callback: (value, record) => {
              return this.formatWithmeasurementSystemUnit(record.measurement_system, value, "length");
            }
          }
        },
        {
          label: i18n.t("integrations.fields.max_weight"),
          source: "max_weight",
          type: "function",
          attributes: {
            callback: (value, record) => {
              return this.formatWithmeasurementSystemUnit(record.measurement_system, value, "weight");
            }
          }
        },
        {
          label: i18n.t("integrations.fields.enabled"),
          source: "enabled",
          type: "boolean"
        }
      ],
      filters: [
        {
          label: i18n.t("integrations.fields.warehouse"),
          source: "_warehouse_code",
          type: "autocomplete",
          alwaysOn: true,
          attributes: {
            clearable: false,
            disabled: true,
            reference: "warehouses",
            itemText: record => record.label + " (" + record.code + ")",
            itemValue: "code"
          }
        },
        {
          label: i18n.t("integrations.fields.enabled"),
          source: "enabled",
          type: "select",
          alwaysOn: true,
          attributes: {
            choices: [
              { value: "true", text: i18n.t("integrations.fields.yes") },
              { value: "false", text: i18n.t("integrations.fields.no") }
            ]
          }
        },
        {
          label: i18n.t("integrations.fields.type"),
          source: "type",
          type: "select",
          alwaysOn: true,
          attributes: {
            choices: PackageTypes
          }
        }
      ]
    };
  }
};
</script>
