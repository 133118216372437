<template>
  <base-material-card :icon="resource.icon" :title="title">
    <template v-slot:subtitle>
      <info-text :resource="resource" crud-type="list" />
    </template>
    <va-list disable-create disable-global-search disable-export :items-per-page="500">
      <va-data-table :fields="fields" disable-show disable-edit disable-clone disable-delete disable-select>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="item-actions">
            <run-command-button :item="item" icon />
            <command-log-button :item="item" icon />
            <command-run-log-button :item="item" icon />
          </div>
        </template>
      </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
import i18n from "@/i18n";
import RunCommandButton from "@/components/command/RunCommandButton";
import CommandLogButton from "@/components/command/CommandLogButton";
import InfoText from "@/components/helper/InfoText";
import CommandRunLogButton from "@/components/command/CommandRunLogButton";

export default {
  components: { CommandRunLogButton, InfoText, CommandLogButton, RunCommandButton },
  props: ["resource", "title"],
  data() {
    return {
      fields: [
        {
          label: i18n.t("integrations.fields.description"),
          source: "description"
        }
      ]
    };
  }
};
</script>
