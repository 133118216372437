<template>
  <va-edit-layout :title="title">
    <v-alert v-if="hasEditIntroduction" border="left" color="blue" type="info">{{ $t("integrations.helper.budget.introduction.edit") }}</v-alert>
    <budgets-form :id="id" :item="item" :isEdit="true"></budgets-form>
  </va-edit-layout>
</template>

<script>
export default {
  props: ["id", "title", "item"],
  computed: {
    hasEditIntroduction() {
      return this.$t("integrations.helper.budget.introduction.edit") != "";
    }
  }
};
</script>
