var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('base-material-card',{attrs:{"icon":_vm.resource.icon,"title":_vm.title},scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_c('info-text',{attrs:{"resource":_vm.resource,"crud-type":"list"}})]},proxy:true}])},[_c('va-list',{attrs:{"disable-create":"","disable-export":"","filters":_vm.filters,"sort-by":['code'],"items-per-page":500}},[_c('va-data-table',{attrs:{"fields":_vm.fields,"disable-show":"","disable-edit":"","disable-clone":"","disable-delete":"","disable-select":""},scopedSlots:_vm._u([{key:"field.code",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('router-link',{attrs:{"to":{
                  name: "companyAddresses_edit",
                  params: { id: item.code }
                }}},[_vm._v(" "+_vm._s(item.label)+" ("+_vm._s(value)+") ")])]}},{key:"field.product_count",fn:function(ref){
                var item = ref.item;
                var value = ref.value;
return [_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.$router.push({
                    name: "warehouseProducts_list",
                    query: {
                      filter: JSON.stringify({
                        _warehouse_code: item.code
                      })
                    }
                  })}}},[_vm._v(" "+_vm._s(_vm.$n(value))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-shape-outline")])],1)]}},{key:"field.packing_spaces_count",fn:function(ref){
                  var item = ref.item;
                  var value = ref.value;
return [_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.$router.push({
                    name: "packingSpaces_list",
                    query: {
                      filter: JSON.stringify({
                        _warehouse_code: item.code,
                        enabled: 'true'
                      })
                    }
                  })}}},[_vm._v(" "+_vm._s(_vm.$n(value))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-package-variant-closed")])],1)]}},{key:"field.inventory_blocking",fn:function(ref){
                  var item = ref.item;
                  var value = ref.value;
return [(value)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-icon',_vm._g({on:{"click":function($event){_vm.$router.push({
                        name: "inventory-blockings_list",
                        query: {
                          filter: JSON.stringify({
                            warehouse: item.code
                          })
                        }
                      })}}},on),[_vm._v("mdi-garage-lock")])]}}],null,true)},[_c('span',[_vm._v("Inventory blocking active")])]):_c('span')]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }