<template>
  <v-card class="mb-2" v-if="internalSystems">
    <v-card-title v-if="dashboardMode">
      Platform Status
    </v-card-title>
    <v-card-text>
      <v-list>
        <v-list-item two-line>
          <v-list-item-avatar>
            <v-icon small class="success">mdi-check</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Last Run</v-list-item-title>
            <v-list-item-subtitle>{{ lastRun }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-avatar>
            <v-icon small class="success" v-if="testCount.success">mdi-check</v-icon>
            <v-icon small class="error" v-else>mdi-exclamation</v-icon>
          </v-list-item-avatar>
          <v-list-item-content> {{ testCount.succeeded }} / {{ testCount.sum }} checks passed </v-list-item-content>
        </v-list-item>
      </v-list>
      <div v-if="dashboardMode">
        <v-btn @click="goToStatus">Go to Status</v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import admin from "@/plugins/admin";

export default {
  props: { systems: Array, lastModified: Date, dashboardMode: { type: Boolean, default: false } },
  created() {
    if (this.systems && this.lastModified) {
      this.internalSystems = this.systems;
      this.internalLastModified = this.lastModified;
    } else {
      this.fetchStatus();
    }
  },
  data() {
    return {
      internalSystems: null,
      internalLastModified: null
    };
  },
  methods: {
    async fetchStatus() {
      const response = await admin.http.get("/monitor/status");
      this.internalSystems = response.data.data;
      this.internalLastModified = new Date(response.headers["last-modified"]);
    },
    goToStatus() {
      this.$router.push({ name: "status" });
    }
  },
  computed: {
    lastRun() {
      return this.$d(new Date(this.internalLastModified), "datetime");
    },
    testCount() {
      let sum = 0;
      let succeeded = 0;
      this.internalSystems.forEach(system => {
        system.assessments.forEach(assessment => {
          sum++;
          if (!assessment.problem) {
            succeeded++;
          }
        });
      });
      return {
        success: sum === succeeded,
        sum,
        succeeded
      };
    }
  }
};
</script>
