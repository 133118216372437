<template>
  <validation-form ref="validationForm" :id="id" :item="item" v-model="model">
    <base-material-tabs-card :tabs="tabs">
      <template v-slot:communication-gateways_default>
        <v-row>
          <v-col>
            <va-boolean-input source="enabled" :label="$t('integrations.fields.enabled')" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <validation-provider :name="$t('integrations.fields.company')" rules="required" v-slot="{ validate, errors }">
              <va-autocomplete-input
                :label="$t('integrations.fields.company') + ' *'"
                source="company"
                :readonly="isEdit"
                :autofocus="!isEdit"
                reference="companies"
                @input="validate"
                :error-messages="errors"
                search-query="name"
                @change="changeType"
                :render-callback="record => record.name + ' (' + record.group + ')'"
                :sort-by="['name']"
              ></va-autocomplete-input>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <validation-provider :name="$t('integrations.fields.type')" rules="required" v-slot="{ validate, errors }">
              <va-select-input
                :label="$t('integrations.fields.type') + ' *'"
                source="type"
                :readonly="isEdit"
                :choices="TypeVariants"
                item-text="name"
                item-value="id"
                @input="validate"
                @change="changeType"
                :error-messages="errors"
              ></va-select-input>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <validation-provider :name="$t('integrations.fields.scopes')" rules="required" v-slot="{ validate, errors }">
              <va-select-input
                :label="$t('integrations.fields.scopes') + ' *'"
                source="scopes"
                filled
                :choices="[
                  { value: 'shipping', text: $t('integrations.communication-gateways.scope.shipping') },
                  { value: 'voucher', text: $t('integrations.communication-gateways.scope.voucher') },
                  { value: 'payment', text: $t('integrations.communication-gateways.scope.payment') },
                  { value: 'generic', text: $t('integrations.communication-gateways.scope.generic') }
                ]"
                multiple
                @input="validate"
                :error-messages="errors"
              ></va-select-input>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row v-if="hasEndpoint">
          <v-col>
            <validation-provider
              :name="$t('integrations.fields.endpoint')"
              :rules="{
                max: 2083,
                required: true,
                url: model.type !== 'email',
                email: model.type === 'email'
              }"
              v-slot="{ validate, errors }"
            >
              <va-text-input
                :label="$t('integrations.fields.endpoint') + ' *'"
                v-model="model.endpoint"
                :readonly="model.type === 'filesystem'"
                source="endpoint"
                @input="validate"
                show-copy-paste
                :hint="$t('integrations.helper.communication-gateways.endpoint')"
                :error-messages="errors.length ? [...errors, $t('integrations.helper.communication-gateways.endpoint')] : errors"
              />
            </validation-provider>
          </v-col>
          <!--<v-col v-if="model.type === 'filesystem'">
            <validation-provider :name="$t('integrations.fields.username')" rules="required" v-slot="{ validate, errors }">
              <va-text-input :label="$t('integrations.fields.username') + ' *'" disabled v-model="model.options.username" source="options.username" @input="validate" :error-messages="errors" />
            </validation-provider>
          </v-col>-->
        </v-row>
        <v-row v-if="model.type === 'filesystem'">
          <v-col>
            <validation-provider
              :name="$t('integrations.fields.ssh_public_key')"
              :rules="{
                required: true,
                sshKeys: true
              }"
              v-slot="{ validate, errors }"
            >
              <va-text-input multiline :label="$t('integrations.fields.ssh_public_key') + ' *'" source="credentials.public_key" @input="validate" :error-messages="errors" />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row v-if="hasTopics">
          <v-col>
            <va-select-input
              :label="$t('integrations.fields.topics') + ' *'"
              :readonly="isEdit"
              source="topics"
              :choices="TopicsVariants"
              item-text="name"
              item-value="id"
              multiple
              filled
              :rules="rules"
            ></va-select-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <code-input :label="$t('integrations.fields.options')" value="" source="options" />
          </v-col>
        </v-row>
      </template>
      <template v-slot:footer>
        <div class="d-flex align-center">
          <va-save-button /><va-cancel-button class="ml-2" />
          <test-gateway-button v-if="isEdit" :item="item" />
        </div>
      </template>
    </base-material-tabs-card>
  </validation-form>
</template>
<script>
import { TypeVariants, TopicsVariants } from "./CommunicationChannelChoices";
import TestGatewayButton from "@/components/communication-gateways/TestGatewayButton";
import CodeInput from "@/components/inputs/CodeInput.vue";

export default {
  components: { CodeInput, TestGatewayButton },
  props: ["id", "title", "item", "isEdit"],
  data() {
    return {
      model: {
        company: null,
        endpoint: null,
        topics: [],
        type: this.item ? this.item.type : "",
        options: null
      }
    };
  },
  mounted() {
    this.refreshValidation();
  },
  created() {
    this.model.company = this.currentUser.company;
  },
  methods: {
    refreshValidation() {
      this.$refs.validationForm.validate();
    },
    changeType() {
      if (this.isTypeFileSystem) {
        this.model.endpoint = "sftp://" + this.model.company + "@share.prominate-platform.com";
      } else {
        this.model.options = null;
      }
      this.refreshValidation();
    }
  },
  computed: {
    TypeVariants() {
      return TypeVariants.filter(
        function(typeVariant) {
          if (!typeVariant.permissions) {
            return true;
          }
          // always allow currently selected type
          if (this.item && this.item.type === typeVariant.id) {
            return true;
          }
          return typeVariant.permissions.filter(permission => !this.$admin.can(permission)).length === 0;
        }.bind(this)
      );
    },
    TopicsVariants() {
      if (this.isEdit) {
        return TopicsVariants;
      }

      // only allow "ofr_cxml" for now
      return TopicsVariants.filter(function(topicVariant) {
        return topicVariant.id === "ofr_cxml" || topicVariant.id === "ofr_pdf";
      });
    },
    selectedType() {
      return this.model.type;
    },
    rules() {
      const rules = [];
      const rule = v => {
        return (v || "").length >= 1 || this.$t("integrations.error.format_greater_or_equals_one");
      };
      rules.push(rule);
      return rules;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    tabs() {
      return [
        {
          id: "communication-gateways_default",
          label: this.$t("integrations.menu.communication-gateways")
        }
      ];
    },
    isTypeFileSystem() {
      return this.model.type === "filesystem";
    },
    hasTopics() {
      return this.TypeVariants.filter(type => type.id === this.model.type && type.has_topics === true).length > 0;
    },
    hasEndpoint() {
      return this.TypeVariants.filter(type => type.id === this.model.type && type.has_endpoint === true).length > 0;
    }
  }
};
</script>
