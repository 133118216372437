<template>
  <v-row justify="center">
    <v-col sm="4">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <order-link-to-json-button :item="item" />
                <attachments :order-id="item.id" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import OrderLinkToJsonButton from "@/components/orders/OrderLinkToJsonButton";
import Attachments from "@/components/orders/Attachments";

export default {
  components: {
    Attachments,
    OrderLinkToJsonButton
  },
  props: ["title", "item"]
};
</script>
