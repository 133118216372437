<template>
  <v-dialog v-model="dialog" width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" text v-bind="attrs" v-on="on"> <v-icon left>mdi-pencil</v-icon> {{ $t("integrations.btn.edit") }} </v-btn>
    </template>
    <validation-observer v-slot="{ handleSubmit }">
      <v-form @submit.prevent="handleSubmit(updateProductStock)">
        <v-card>
          <v-card-title v-if="item">
            {{
              $t("integrations.warehouse.update_item_stock.title", {
                productCode: item.product_code,
                warehouseId: item.warehouse_code
              })
            }}
          </v-card-title>
          <v-card-text
            ><v-container>
              <v-row>
                <v-col>
                  <validation-provider :name="$t('integrations.fields.reported_quantity')" rules="required|numeric" v-slot="{ errors }">
                    <v-text-field
                      :label="$t('integrations.fields.reported_quantity') + ' *'"
                      v-model="formData.amount"
                      :error-messages="errors"
                      :hint="$t('integrations.helper.inventory.inventory_level')"
                      persistent-hint
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-menu v-model="nextIntakeDatePicker" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formData.next_intake_date"
                        :label="$t('integrations.fields.next_intake_date')"
                        :hint="$t('integrations.helper.inventory.next_intake_date')"
                        persistent-hint
                        readonly
                        clearable
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="formData.next_intake_date" @input="nextIntakeDatePicker = false"></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  ><validation-provider :name="$t('integrations.fields.next_intake_quantity')" rules="numeric" v-slot="{ errors }">
                    <v-text-field
                      :label="$t('integrations.fields.next_intake_quantity')"
                      v-model="formData.next_intake_amount"
                      :error-messages="errors"
                      :hint="$t('integrations.helper.inventory.next_intake_quantity')"
                      persistent-hint
                    ></v-text-field> </validation-provider
                ></v-col>
              </v-row> </v-container
          ></v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="secondary" :disabled="updating" @click="closeDialog">
              <v-icon left>mdi-close-circle</v-icon>
              {{ $t("integrations.btn.cancel") }}
            </v-btn>
            <v-btn color="primary" type="submit" :loading="updating">
              <v-icon left>mdi-content-save</v-icon>
              {{ $t("integrations.btn.save") }}
            </v-btn>
          </v-card-actions>
        </v-card></v-form
      ></validation-observer
    >
  </v-dialog>
</template>
<script>
import admin from "@/plugins/admin";

export default {
  props: ["item"],
  data() {
    return {
      updating: false,
      dialog: false,
      nextIntakeDatePicker: false,
      formData: {
        amount: null,
        next_intake_date: null,
        next_intake_amount: null
      }
    };
  },
  created() {
    this.formData.amount = this.item.reported_quantity;
    if (this.item.next_intake_date) {
      const nextIntakeDate = new Date(this.item.next_intake_date);
      this.formData.next_intake_date = nextIntakeDate.toISOString().substr(0, 10);
    }

    this.formData.next_intake_amount = this.item.next_intake_quantity;
  },
  computed: {
    titleSource() {
      return this.item.product_code ? this.item.product_code : this.item.id ? this.item.id : "- unnamed -";
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    async updateProductStock() {
      const linkUpdate = `${process.env.VUE_APP_API_URL}/warehouses/${this.item.warehouse_code}/products/${this.item.product_code}/inventory`;

      const payload = {};
      for (let fieldName of Object.keys(this.formData)) {
        payload[fieldName] = this.formData[fieldName] === "" ? null : this.formData[fieldName];
      }

      this.updating = true;
      admin.http
        .put(linkUpdate, payload)
        .then(() => {
          this.$store.commit(`messages/showToast`, {
            color: "success",
            message: this.$i18n.t("integrations.warehouse.messages.item_stock.updated", { product_code: this.item.product_code })
          });
          this.$emit("productUpdated");
        })
        .catch(() =>
          this.$store.commit(`messages/showToast`, {
            color: "error",
            message: this.$i18n.t("integrations.warehouse.messages.item_stock.error_updated", { product_code: this.item.product_code })
          })
        )
        .finally(() => {
          this.updating = false;
          this.closeDialog();
        });
    }
  }
};
</script>
