import i18n from "@/i18n";

export default {
  validate(value) {
    let today = new Date();

    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0");
    let yyyy = today.getFullYear();

    return value >= yyyy + "-" + mm + "-" + dd;
  },
  message: i18n.t("integrations.error.less_then_today")
};
