<template>
  <div id="wrapper" style="position: relative; height: 30px">
    <horizontal-bar-chart ref="chart" :chartData="chartData" :options="chartOptions" style="height: 30px" />
  </div>
</template>
<script>
import HorizontalBarChart from "@/components/charts/base-components/HorizontalBarChart";
import StageStatusHelper from "@/resources/batchjobs/mixins/StageStatusHelper";

export default {
  props: ["statistics"],
  components: { HorizontalBarChart },
  mixins: [StageStatusHelper],
  data() {
    return {
      showProgressbar: true,
      statisticsOrder: ["pending", "running", "need_decision", "finished", "error"],
      chartOptions: {
        plugins: {
          datalabels: {
            color: "black",
            formatter: function(value, context) {
              return value + " " + context.dataset.label;
            }
          }
        },
        animation: {
          duration: 0
        },
        tooltips: {
          enabled: false
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          yAxes: [
            {
              stacked: true,
              display: false
            }
          ],
          xAxes: [
            {
              stacked: true,
              display: false
            }
          ]
        },
        onClick: this.handleOnClick
      }
    };
  },
  methods: {
    handleOnClick(point) {
      const chart = this.$refs.chart.$data._chart;
      let datasetIndex = chart.getElementAtEvent(point)[0]._datasetIndex;
      this.$emit("element-clicked", this.statisticsNormalised[datasetIndex]);
    }
  },
  computed: {
    statisticsNormalised() {
      return this.statisticsOrder
        .filter(statisticIndex => statisticIndex in this.statistics)
        .map(statisticIndex => {
          return {
            status: statisticIndex,
            value: this.statistics[statisticIndex]
          };
        });
    },
    chartData() {
      return {
        labels: ["Progress"],
        datasets: this.statisticsNormalised.map(data => {
          return {
            label: this.statusTranslation(data.status),
            data: [data.value],
            categoryPercentage: 1.0,
            barPercentage: 1.0,
            backgroundColor: this.statusColorCode(data.status)
          };
        })
      };
    }
  }
};
</script>
