var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-form',{attrs:{"id":_vm.id,"item":_vm.item}},[_c('base-material-tabs-card',{attrs:{"tabs":_vm.tabs},scopedSlots:_vm._u([{key:"general",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.code'),"rules":{
              required: true,
              regex: /^[a-z\-]+$/,
              uniqueValidator: !_vm.isEdit ? { resource: 'sso', field: 'id' } : false
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var validate = ref.validate;
            var errors = ref.errors;
return [_c('va-text-input',{attrs:{"readonly":_vm.isEdit,"autofocus":!_vm.isEdit,"label":_vm.$t('integrations.fields.code') + ' *',"source":"id","error-messages":errors},on:{"input":validate}})]}}])})],1),_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.tenant'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var validate = ref.validate;
            var errors = ref.errors;
return [_c('va-autocomplete-input',{attrs:{"label":_vm.$t('integrations.fields.tenant') + ' *',"source":"tenant","reference":"tenants_companies","error-messages":errors,"search-query":"name","sort-by":['name']},on:{"input":validate}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.saml_idp'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var validate = ref.validate;
            var errors = ref.errors;
return [_c('va-text-input',{attrs:{"label":_vm.$t('integrations.fields.saml_idp') + ' *',"source":"saml_idp","error-messages":errors},on:{"input":validate}})]}}])})],1),_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.saml_entity_id'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var validate = ref.validate;
            var errors = ref.errors;
return [_c('va-text-input',{attrs:{"label":_vm.$t('integrations.fields.saml_entity_id') + ' *',"source":"saml_entity_id","error-messages":errors},on:{"input":validate}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.saml_flow'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var validate = ref.validate;
            var errors = ref.errors;
return [_c('va-select-input',{attrs:{"label":_vm.$t('integrations.fields.saml_flow') + ' *',"source":"saml_flow","error-messages":errors,"choices":[
                { value: 'sp_initiated', text: 'SP-Initiated' },
                { value: 'idp_initiated', text: 'IDP-Initiated' }
              ]},on:{"input":validate}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('va-array-input',{attrs:{"label":_vm.$t('integrations.fields.single_sign_on_services'),"source":"single_sign_on_services","hide-mover":true},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.binding'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var validate = ref.validate;
              var errors = ref.errors;
return [_c('va-select-input',_vm._b({attrs:{"label":_vm.$t('integrations.fields.binding') + ' *',"source":"binding","error-messages":errors,"choices":[
                      { value: 'http_redirect', text: 'HTTP-Redirect' },
                      { value: 'http_post', text: 'HTTP-POST' }
                    ]},on:{"input":validate}},'va-select-input',props,false))]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.url'),"rules":"required|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var validate = ref.validate;
                    var errors = ref.errors;
return [_c('va-text-input',_vm._b({attrs:{"label":_vm.$t('integrations.fields.url') + ' *',"source":"url","error-messages":errors},on:{"input":validate}},'va-text-input',props,false))]}}],null,true)})],1)],1)]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('va-array-input',{attrs:{"label":_vm.$t('integrations.fields.single_logout_services'),"source":"single_logout_services","hide-mover":true},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.binding'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var validate = ref.validate;
                    var errors = ref.errors;
return [_c('va-select-input',_vm._b({attrs:{"label":_vm.$t('integrations.fields.binding') + ' *',"source":"binding","error-messages":errors,"choices":[
                      { value: 'http_redirect', text: 'HTTP-Redirect' },
                      { value: 'http_post', text: 'HTTP-POST' }
                    ]},on:{"input":validate}},'va-select-input',props,false))]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.url'),"rules":"required|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var validate = ref.validate;
                    var errors = ref.errors;
return [_c('va-text-input',_vm._b({attrs:{"label":_vm.$t('integrations.fields.url') + ' *',"source":"url","error-messages":errors},on:{"input":validate}},'va-text-input',props,false))]}}],null,true)})],1)],1)]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('va-array-input',{attrs:{"label":_vm.$t('integrations.fields.keys'),"source":"keys"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.x509_certificate'),"rules":{
                    required: true,
                    regex: /^[A-Za-z0-9+\/=\r\n]+$/
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var validate = ref.validate;
                  var errors = ref.errors;
return [_c('va-text-input',_vm._b({attrs:{"multiline":true,"label":_vm.$t('integrations.fields.x509_certificate') + ' *',"source":"x509_certificate","error-messages":errors},on:{"input":validate}},'va-text-input',props,false))]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('va-text-input',_vm._b({attrs:{"label":_vm.$t('integrations.fields.comment'),"source":"comment"}},'va-text-input',props,false))],1)],1)]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('va-array-input',{attrs:{"label":_vm.$t('integrations.fields.mapping'),"source":"mapping","hide-mover":true},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.sso_connections.mapping.key'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var validate = ref.validate;
                  var errors = ref.errors;
return [_c('va-select-input',_vm._b({attrs:{"label":_vm.$t('integrations.sso_connections.mapping.key'),"source":"key","choices":_vm.SsoMappingKeys,"item-text":"name","item-value":"id","error-messages":errors},on:{"input":validate}},'va-select-input',props,false))]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('va-text-input',_vm._b({attrs:{"label":_vm.$t('integrations.sso_connections.mapping.value'),"source":"value"}},'va-text-input',props,false))],1)],1)]}}])})],1)],1)]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex align-center"},[_c('va-save-button'),_c('va-cancel-button',{staticClass:"ml-2"})],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }