<template>
  <v-data-table :headers="headers" :items="dataRows" disable-pagination show-expand item-key="id" :expanded.sync="expanded">
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length"><order-shipment-info :item="item" /></td>
    </template>
  </v-data-table>
</template>
<script>
import OrderShipmentInfo from "@/resources/orders/OrderShipmentInfo";
export default {
  components: { OrderShipmentInfo },
  props: ["item"],
  async created() {
    const response = await this.$admin.http.get(`${process.env.VUE_APP_API_URL}/orders/${this.item.id}/shipments`);
    this.dataRows = response.data.data;
  },
  data() {
    return {
      expanded: [],
      dataRows: [],
      headers: [
        {
          text: this.$i18n.t("integrations.fields.shipment_number"),
          value: "id"
        },
        {
          text: this.$i18n.t("integrations.fields.shipping_date"),
          value: "shipment.shipping_date"
        },
        {
          text: this.$i18n.t("integrations.fields.shipped_date"),
          value: "shipment.shipped_date"
        },
        {
          text: this.$i18n.t("integrations.fields.sender_address"),
          value: "sender_address_string"
        },
        {
          text: this.$i18n.t("integrations.fields.delivery_address"),
          value: "recipient_address_string"
        },
        {
          text: this.$i18n.t("integrations.fields.shipment_method"),
          value: "shipment.method.name"
        },
        {
          text: this.$i18n.t("integrations.fields.tracking_id"),
          value: "shipment.tracking_code"
        },
        { text: "", value: "data-table-expand" }
      ],
      rows: this.item.messages
    };
  }
};
</script>
