var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","text":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v(" "+_vm._s(_vm.$t("integrations.btn.edit"))+" ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.updateProductStock)}}},[_c('v-card',[(_vm.item)?_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("integrations.warehouse.update_item_stock.title", { productCode: _vm.item.product_code, warehouseId: _vm.item.warehouse_code }))+" ")]):_vm._e(),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.reported_quantity'),"rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('integrations.fields.reported_quantity') + ' *',"error-messages":errors,"hint":_vm.$t('integrations.helper.inventory.inventory_level'),"persistent-hint":""},model:{value:(_vm.formData.amount),callback:function ($$v) {_vm.$set(_vm.formData, "amount", $$v)},expression:"formData.amount"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('integrations.fields.next_intake_date'),"hint":_vm.$t('integrations.helper.inventory.next_intake_date'),"persistent-hint":"","readonly":"","clearable":""},model:{value:(_vm.formData.next_intake_date),callback:function ($$v) {_vm.$set(_vm.formData, "next_intake_date", $$v)},expression:"formData.next_intake_date"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.nextIntakeDatePicker),callback:function ($$v) {_vm.nextIntakeDatePicker=$$v},expression:"nextIntakeDatePicker"}},[_c('v-date-picker',{on:{"input":function($event){_vm.nextIntakeDatePicker = false}},model:{value:(_vm.formData.next_intake_date),callback:function ($$v) {_vm.$set(_vm.formData, "next_intake_date", $$v)},expression:"formData.next_intake_date"}})],1)],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.next_intake_quantity'),"rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('integrations.fields.next_intake_quantity'),"error-messages":errors,"hint":_vm.$t('integrations.helper.inventory.next_intake_quantity'),"persistent-hint":""},model:{value:(_vm.formData.next_intake_amount),callback:function ($$v) {_vm.$set(_vm.formData, "next_intake_amount", $$v)},expression:"formData.next_intake_amount"}})]}}],null,true)})],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","disabled":_vm.updating},on:{"click":_vm.closeDialog}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close-circle")]),_vm._v(" "+_vm._s(_vm.$t("integrations.btn.cancel"))+" ")],1),_c('v-btn',{attrs:{"color":"primary","type":"submit","loading":_vm.updating}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v(" "+_vm._s(_vm.$t("integrations.btn.save"))+" ")],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }