import i18n from "@/i18n";

const messageTypeVariants = [
  { id: "debug", name: i18n.t("integrations.message.type.DEBUG") },
  { id: "info", name: i18n.t("integrations.message.type.INFO") },
  { id: "warning", name: i18n.t("integrations.message.type.WARNING") },
  { id: "error", name: i18n.t("integrations.message.type.ERROR") },
  { id: "failed", name: i18n.t("integrations.message.type.FAILED") },
  { id: "success", name: i18n.t("integrations.message.type.SUCCESS") }
];

export { messageTypeVariants };
