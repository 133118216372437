<template>
  <v-card>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col>
            <span class="font-weight-bold">{{ $t("integrations.fields.shipping_address") }}</span>
            <address>
              {{ item.shipment.recipient_address.organization }}<br />
              {{ item.shipment.recipient_address.street }}<br />
              {{ item.shipment.recipient_address.postcode }}<br />
              {{ item.shipment.recipient_address.city }}<br />
              {{ item.shipment.recipient_address.country_code }}
            </address>
          </v-col>
          <v-col>
            <p>
              {{ $t("integrations.fields.shipment_method") }}<br />
              {{ item.shipment.method.name }}
            </p>
            <p v-if="item.shipment.shipped_date">
              {{ $t("integrations.fields.shipment_date") }}<br />
              {{ item.shipment.shipped_date }}
            </p>
            <p v-if="item.shipment.tracking_code">
              {{ $t("integrations.fields.tracking_id") }}<br />
              {{ item.shipment.tracking_code }}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <order-shipment-info-table v-if="item.shipment" :shipment="item.shipment" />
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
import OrderShipmentInfoTable from "@/resources/orders/OrderShipmentInfoTable";
export default {
  components: { OrderShipmentInfoTable },
  props: ["item"]
};
</script>
