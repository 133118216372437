<template>
  <va-show-layout :title="title" disable-edit disable-clone disable-delete>
    <va-show :item="item">
      <base-material-tabs-card :tabs="tabs">
        <template #general_information>
          <show-order-general-informations :item="item" />
        </template>
        <template #items>
          <show-order-items :order="item" />
        </template>
        <template #shipments>
          <show-order-shipments :item="item" />
        </template>
        <template #payments>
          <show-order-payments :order="item" />
        </template>
        <template #fulfillments>
          <show-order-fulfillments :item="item" />
        </template>
        <template #adjustments>
          <show-order-adjustments :order="item" />
        </template>
        <template #documents>
          <show-documents :item="item" />
        </template>
        <template #actions>
          <show-order-actions :item="item" />
        </template>
      </base-material-tabs-card>
    </va-show>
  </va-show-layout>
</template>

<script>
import ShowDocuments from "@/resources/orders/ShowDocuments";
import ShowOrderFulfillments from "@/resources/orders/ShowOrderFulfillments";
import ShowOrderPayments from "@/resources/orders/ShowOrderPayments";
import ShowOrderItems from "@/resources/orders/ShowOrderItems";
import ShowOrderGeneralInformations from "@/resources/orders/ShowOrderGeneralInformations";
import ShowOrderShipments from "@/resources/orders/ShowOrderShipments";
import ShowOrderActions from "@/resources/orders/ShowOrderActions";
import ShowOrderAdjustments from "@/resources/orders/ShowOrderAdjustments.vue";

export default {
  components: {
    ShowOrderAdjustments,
    ShowOrderActions,
    ShowOrderShipments,
    ShowOrderGeneralInformations,
    ShowOrderItems,
    ShowOrderPayments,
    ShowOrderFulfillments,
    ShowDocuments
  },
  props: ["title", "item"],
  data() {
    return {
      tabs: [
        {
          id: "general_information",
          label: this.$t("integrations.order.form.general_information")
        },
        {
          id: "items",
          label: this.$t("integrations.order.form.items")
        },
        {
          id: "shipments",
          label: this.$t("integrations.order.form.shipments")
        },
        {
          id: "payments",
          label: this.$t("integrations.order.form.payments")
        },
        {
          id: "fulfillments",
          label: this.$t("integrations.order.form.fulfillments")
        },
        {
          id: "adjustments",
          label: this.$t("integrations.order.form.adjustments")
        },
        {
          id: "documents",
          label: this.$t("integrations.fields.documents")
        },
        {
          id: "actions",
          label: this.$t("integrations.order.form.actions.title")
        }
      ]
    };
  }
};
</script>
