<template>
  <div>
    <ul v-for="(element, index) in this.value" :key="index">
      <li>
        <span v-if="showIndex">
          <b v-if="translateIndex">
            <span v-if="isNumericIndex">{{ translateMessage("integrations.fields." + element.key) }}</span>
            <span v-else>{{ translateMessage("integrations.fields." + index) }}</span>
          </b>
          <b v-else>
            <span v-if="isNumericIndex">{{ element.key }}</span>
            <span v-else>{{ index }}</span> </b
          >:
        </span>
        <span v-if="isNumericIndex">{{ element.value }}</span>
        <span v-else>{{ element }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import Field from "@skuhnow/vuetify-admin/src/mixins/field";
import TranslationHelper from "@/mixins/TranslationHelper";

export default {
  props: ["showIndex", "translateIndex"],
  mixins: [Field, TranslationHelper],
  computed: {
    isNumericIndex() {
      const keys = Object.keys(this.value);
      return keys.length > 0 && keys[0] === "0";
    }
  }
};
</script>
