<template>
  <va-action-button
    icon="mdi-format-list-bulleted"
    :label="$t('integrations.menu.command_runs')"
    text
    :hide-label="icon"
    :to="{ name: 'commands_show', params: { id: item.id } }"
  ></va-action-button>
</template>
<script>
import Button from "@skuhnow/vuetify-admin/src/mixins/button";

export default {
  mixins: [Button],
  methods: {
    showLog() {
      window.open(`${process.env.VUE_APP_API_URL}/commands/${this.item.id}/logs`, "_blank");
    }
  }
};
</script>
