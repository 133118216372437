<template>
  <va-action-button icon="mdi-login" :disabled="!isTenant" :label="$t('integrations.btn.login_as')" text @click="loginAs"></va-action-button>
</template>
<script>
import Button from "@skuhnow/vuetify-admin/src/mixins/button";

export default {
  mixins: [Button],
  methods: {
    loginAs() {
      window.open(`${process.env.VUE_APP_HOST_URL}/launcher/frontend?username=${this.item.username}`, this.item.username);
    }
  },
  computed: {
    isTenant() {
      return this.item && this.item.company_group === "tenant" && !!this.item.username;
    }
  }
};
</script>
