<template>
  <div v-if="systems">
    <status-summary :systems="systems" :last-modified="lastModified" />
    <v-card>
      <v-card-text>
        <v-list v-for="system in systems" :key="system.status_provider">
          <system-status :system="system" />
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import admin from "@/plugins/admin";
import SystemStatus from "@/components/platform-status/SystemStatus";
import StatusSummary from "@/components/platform-status/StatusSummary";

export default {
  components: { StatusSummary, SystemStatus },
  created() {
    this.fetchStatus();
  },
  data() {
    return {
      systems: null,
      lastModified: null
    };
  },
  methods: {
    async fetchStatus() {
      const response = await admin.http.get("/monitor/status");
      this.systems = response.data.data;
      this.lastModified = new Date(response.headers["last-modified"]);
    }
  }
};
</script>
