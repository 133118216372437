<template>
  <base-material-card :icon="resource.icon" :title="title">
    <template v-slot:subtitle>
      <info-text :resource="resource" crud-type="list" />
    </template>
    <va-list :filters="filters" disable-export>
      <va-data-table :fields="fields" disable-clone disable-show>
        <template v-slot:[`item.actions`]="{ item }">
          <show-graylog-button :item="item" />
          <va-action-button icon="mdi-account" text :label="$t('integrations.menu.users')" @click="goToUsers(item)" hide-label />
        </template>
      </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
import InfoText from "@/components/helper/InfoText";
import i18n from "@/i18n";
import ShowGraylogButton from "@/resources/ssoConnections/components/ShowGraylogButton";

export default {
  components: { ShowGraylogButton, InfoText },
  props: ["resource", "title"],
  data() {
    return {
      fields: [
        { label: i18n.t("integrations.fields.code"), source: "code" },
        {
          label: i18n.t("integrations.fields.company"),
          source: "tenant_data",
          type: "reference",
          sortable: true,
          attributes: {
            reference: "companies",
            action: null
          }
        },
        {
          label: i18n.t("integrations.fields.mapping"),
          type: "array-list",
          source: "mapping",
          attributes: {
            showIndex: true
          }
        },
        { label: i18n.t("integrations.fields.updated_at"), type: "date", source: "updated_at" }
      ],
      filters: [
        {
          label: i18n.t("integrations.fields.company"),
          source: "tenant",
          type: "autocomplete",
          alwaysOn: true,
          resettable: true,
          attributes: {
            reference: "companies",
            searchQuery: "q",
            sortBy: ["name"]
          }
        }
      ]
    };
  },
  methods: {
    goToUsers(item) {
      this.$router.push({
        name: "users_list",
        query: {
          filter: JSON.stringify({ source: item.id })
        }
      });
    }
  }
};
</script>
