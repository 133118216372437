<template>
  <va-action-button icon="mdi-clipboard-list" :label="$t('integrations.fields.show_logs')" text @click="showLog(item)" />
</template>
<script>
import Button from "@skuhnow/vuetify-admin/src/mixins/button";

export default {
  mixins: [Button],
  methods: {
    showLog() {
      window.open(
        `https://log.prominate-platform.com/search?q=ctxt_event%3Amcs.event.Sso.synchronize+AND+json_source%3A${this.item.id}&rangetype=relative&relative=2592000`,
        "graylog_sso"
      );
    }
  }
};
</script>
