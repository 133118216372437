<template>
  <v-dialog width="700" v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" :disabled="!canForceResume" v-bind="attrs" v-on="on">
        <v-icon left dark>mdi-close</v-icon>
        {{ $t("integrations.order.form.actions.force_resume") }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>{{ $t("integrations.order.resume.confirmation_title") }}</v-card-title>
      <v-card-text>
        {{ $t("integrations.order.form.actions.force_resume_description", { orderId: item.id }) }}
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="secondary" :disabled="resuming" @click="closeDialog">
          <v-icon left>mdi-close-circle</v-icon>
          {{ $t("va.actions.abort") }}
        </v-btn>
        <v-btn color="primary" :loading="resuming" @click="forceResume">
          <v-icon left>mdi-close</v-icon>
          {{ $t("integrations.order.form.actions.force_resume") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Button from "@skuhnow/vuetify-admin/src/mixins/button";

export default {
  mixins: [Button],
  data() {
    return {
      dialog: false,
      resuming: false
    };
  },
  computed: {
    canForceResume() {
      const resumeableStates = ["problematic"];
      return resumeableStates.includes(this.item.state);
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    forceResume() {
      this.resuming = true;
      this.$admin.http
        .patch(`/orders/${this.item.id}`, { state: "forceResume" })
        .then(() => {
          this.$store.commit(`messages/showToast`, {
            color: "success",
            message: `Order "${this.item.id}" was resumed.`
          });
          this.$emit("orderResumed");
          this.closeDialog();
        })
        .catch(() =>
          this.$store.commit(`messages/showToast`, {
            color: "error",
            message: `Error while resuming order "${this.item.id}"`
          })
        )
        .finally(() => {
          this.resuming = false;
          this.$store.dispatch("api/refresh", this.$route.meta.resource);
        });
    }
  }
};
</script>
