<template>
  <span>{{ lookupValue }}</span>
</template>

<script>
export default {
  props: ["value", "lookupData"],
  computed: {
    lookupValue() {
      if (!this.value) return "";
      const filtered = this.lookupData.filter(row => row.id === this.value);
      return filtered.length ? filtered[0].name : this.value;
    }
  }
};
</script>
