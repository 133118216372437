<template>
  <div>
    <ul>
      <li>{{ fallbackIcon }}</li>
      <li>{{ fallbackTooltip }}</li>
      <li>{{ iconMapping }}</li>
      <li>{{ valueAsFallbackTooltip }}</li>
    </ul>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-icon v-on="on">{{ icon }}</v-icon>
      </template>
      <span>{{ tooltip }}</span>
    </v-tooltip>
  </div>
</template>
<script>
import Field from "@skuhnow/vuetify-admin/src/mixins/field";

export default {
  mixins: [Field],
  props: [
    {
      name: "fallbackIcon",
      type: String,
      default: "mdi-help"
    },
    {
      name: "fallbackTooltip",
      type: String,
      default: "Unknown"
    },
    {
      name: "iconMapping",
      type: Object,
      required: true
    },
    {
      name: "valueAsFallbackTooltip",
      type: Boolean,
      default: false
    }
  ],
  computed: {
    icon() {
      if (!this.iconMapping || !(this.value in this.iconMapping)) {
        return this.fallbackIcon;
      }
      return this.iconMapping[this.value].icon;
    },
    tooltip() {
      if (!this.iconMapping || !(this.value in this.iconMapping)) {
        return this.fallbackTooltip;
      }
      if (this.valueAsFallbackTooltip) {
        return this.value;
      }
      return this.$i18n.t(this.iconMapping[this.value].tooltip);
    }
  }
};
</script>
