<template>
  <va-action-button :disabled="disabled" :icon="icon" :label="label" text @click="open(item)"></va-action-button>
</template>
<script>
import Button from "@skuhnow/vuetify-admin/src/mixins/button";

export default {
  mixins: [Button],
  props: {
    route: {
      type: Object,
      required: true
    },
    label: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: "mdi-eye"
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    open() {
      this.$router.push(this.route);
    }
  }
};
</script>
