<template>
  <div v-if="systems">
    <v-row v-if="hasSystemsShared">
      <v-col>
        <v-card>
          <v-card-title>{{ $t("integrations.infrastructure.title") }}</v-card-title>
          <v-card-subtitle class="pt-3">
            {{ $t("integrations.infrastructure.shared") }}
          </v-card-subtitle>
          <system-list :group="systems.shared" />
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="hasSystemsUser">
      <v-col>
        <v-card>
          <v-card-title>{{ $t("integrations.infrastructure.title") }}</v-card-title>
          <v-card-subtitle class="pt-3">
            {{ $t("integrations.infrastructure.user") }}
          </v-card-subtitle>
          <system-list :group="systems.user" />
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="hasSystemsSharedAdmin">
      <v-col>
        <v-card v-if="$admin.can('ROLE_ADMIN')">
          <v-card-title>{{ $t("integrations.infrastructure.title") }}</v-card-title>
          <v-card-subtitle class="pt-3">
            {{ $t("integrations.infrastructure.shared_admin") }}
          </v-card-subtitle>
          <system-list :group="systems.shared_admin" />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import SystemList from "@/components/dashboard/SystemList";
import admin from "@/plugins/admin";

export default {
  components: { SystemList },
  data: () => ({
    systems: null,
    show: false
  }),
  computed: {
    hasSystemsShared() {
      return this.systems && this.systems.shared && this.systems.shared.length > 0;
    },
    hasSystemsSharedAdmin() {
      return this.systems && this.systems.shared_admin && this.systems.shared_admin.length > 0;
    },
    hasSystemsUser() {
      return this.systems && this.systems.user && this.systems.user.length > 0;
    }
  },
  created() {
    admin.http
      .get("/systems")
      .then(response => response.data)
      .then(responseData => {
        this.systems = responseData;
      });
  }
};
</script>
