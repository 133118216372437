import i18n from "@/i18n";

export default [
  // Main resources
  {
    name: "companies",
    displayName: i18n.t("integrations.menu.companies"),
    label: "name",
    icon: "mdi-domain",
    permissions: ["ROLE_COMPANY_MANAGER"]
  },
  {
    name: "batchjobs",
    displayName: i18n.t("integrations.menu.batchjobs"),
    label: "filename",
    icon: "mdi-file-upload-outline",
    permissions: ["ROLE_API_BATCH"]
  },
  {
    name: "batchJobRuns",
    label: "filename",
    icon: "mdi-stack-overflow",
    permissions: ["ROLE_ADMIN"]
  },
  {
    name: "companyAddresses",
    displayName: i18n.t("integrations.menu.tenantAddresses"),
    label: "code",
    icon: "mdi-map-marker",
    permissions: ["ROLE_API_COMPANIES"]
  },
  {
    name: "users",
    displayName: i18n.t("integrations.menu.users"),
    label: "username",
    icon: "mdi-account",
    permissions: ["ROLE_API_USERS"]
  },
  {
    name: "useraddresses",
    displayName: i18n.t("integrations.menu.useraddresses"),
    label: "organization",
    icon: "mdi-map-marker",
    permissions: ["ROLE_API_USERS"]
  },
  {
    name: "usergroups",
    displayName: i18n.t("integrations.menu.usergroups"),
    label: "name",
    icon: "mdi-account-group",
    permissions: ["ROLE_API_USERS"]
  },
  {
    name: "userroles",
    label: "label",
    icon: "mdi-shield-check",
    permissions: ["ROLE_API_USERS"]
  },
  {
    name: "contactrequests",
    displayName: i18n.t("integrations.menu.contactrequests"),
    label: "id",
    icon: "mdi-card-account-mail",
    permissions: ["ROLE_API_CONTACTREQUESTS"]
  },
  {
    name: "confirmation-requests",
    displayName: i18n.t("integrations.menu.confirmation-requests"),
    label: "id",
    icon: "mdi-account-question-outline",
    permissions: ["ROLE_API_CONFIRMATIONREQUESTS"]
  },
  {
    name: "productapprovals",
    label: "id",
    icon: "mdi-thumb-up",
    permissions: ["ROLE_API_PRODUCTAPPROVALS"]
  },
  {
    name: "formsubmissions",
    displayName: i18n.t("integrations.menu.formsubmissions"),
    label: "id",
    icon: "mdi-order-bool-ascending-variant",
    permissions: ["ROLE_API_FORMS"]
  },
  {
    name: "orders",
    displayName: i18n.t("integrations.menu.orders"),
    label: "id",
    icon: "mdi-cart",
    permissions: ["ROLE_API_ORDERS"]
  },
  {
    name: "orderFulfillments",
    displayName: i18n.t("integrations.menu.orderfulfillments"),
    label: "id",
    icon: "mdi-clipboard-check",
    permissions: ["ROLE_API_ORDERFULFILLMENTS"]
  },
  {
    name: "budgets",
    displayName: i18n.t("integrations.menu.budgets"),
    label: "code",
    icon: "mdi-currency-usd",
    permissions: ["ROLE_API_BUDGETS"]
  },
  {
    name: "campaigns",
    displayName: i18n.t("integrations.menu.campaigns"),
    label: "code",
    icon: "mdi-bullhorn",
    permissions: ["ROLE_API_CAMPAIGNS"]
  },
  {
    name: "emailTemplates",
    displayName: i18n.t("integrations.menu.emailtemplates"),
    api: "email-templates",
    label: "code",
    icon: "mdi-email-newsletter",
    permissions: ["ROLE_API_EMAIL_TEMPLATES"]
  },
  {
    name: "warehouses",
    displayName: i18n.t("integrations.menu.warehouses"),
    label: "code",
    icon: "mdi-garage"
  },
  {
    name: "inventory-blockings",
    displayName: i18n.t("integrations.menu.inventory_blockings"),
    label: "product_code",
    icon: "mdi-garage-lock"
  },
  {
    name: "communication-gateways",
    displayName: i18n.t("integrations.menu.communication-gateways"),
    label: "endpoint",
    icon: "mdi-lan-connect"
  },
  {
    name: "deployments",
    displayName: i18n.t("integrations.menu.deployments"),
    label: "id",
    icon: "mdi-server-network",
    permissions: ["ROLE_API_DEPLOYMENTS"]
  },
  {
    name: "commands",
    displayName: i18n.t("integrations.menu.commands"),
    label: "id",
    icon: "mdi-gesture-tap-button",
    permissions: ["ROLE_API_COMMANDS"]
  },
  {
    name: "commandRuns",
    displayName: i18n.t("integrations.menu.command_runs"),
    label: "id",
    icon: "mdi-gesture-tap-button",
    permissions: ["ROLE_API_COMMANDS"]
  },
  {
    name: "workers",
    displayName: i18n.t("integrations.menu.workers"),
    label: "id",
    icon: "mdi-play-network-outline",
    permissions: ["ROLE_API_WORKERS"]
  },
  {
    name: "queues",
    displayName: i18n.t("integrations.menu.queues"),
    label: "id",
    icon: "mdi-tray-full",
    permissions: ["ROLE_ADMIN"]
  },
  {
    name: "ssoConnections",
    api: "sso",
    displayName: i18n.t("integrations.menu.sso_connections"),
    icon: "mdi-account-key-outline",
    permissions: ["ROLE_ADMIN"]
  },
  // Additional resources
  { name: "locales", label: "name" },
  {
    name: "campaignCommands",
    label: "id",
    api: "campaigns",
    permissions: ["ROLE_API_CAMPAIGNS"]
  },
  {
    name: "campaignProducts",
    label: "product_code",
    permissions: ["ROLE_API_CAMPAIGNS"]
  },
  {
    name: "temporaryReplacements",
    icon: "mdi-account-clock",
    api: "temporary-replacements",
    permissions: ["ROLE_API_TEMPORARY_REPLACEMENTS"]
  },
  {
    name: "domains",
    icon: "mdi-link",
    api: "domains",
    permissions: ["ROLE_ADMIN"]
  },
  {
    name: "domains_entities",
    permissions: ["ROLE_ADMIN"]
  },
  {
    name: "accounts",
    icon: "mdi-account-lock",
    permissions: ["ROLE_ADMIN"]
  },
  { name: "countries", label: "name" },
  { name: "company-groups", label: "name" },
  { name: "channels", label: "name" },
  { name: "channels_specific", label: "name" },
  { name: "channels_globals_as_defaults", label: "name" },
  { name: "onboarded-users", label: "username" },
  { name: "tenants_companies", label: "name" },
  { name: "fulfiller_companies", label: "name" },
  { name: "seller_companies", label: "name" },
  { name: "manager_companies", label: "name" },
  { name: "products", icon: "mdi-shape-outline", label: "name" },
  { name: "warehouseProducts", label: "name" },
  { name: "product-variants", label: "identifiers.public" },
  { name: "userHistories", label: "id" },
  { name: "budgetHistories", label: "id" },
  { name: "companyAddressesWarehouses", label: "code" },
  { name: "companyAddressesOfUsers", label: "code" },
  { name: "batchJobTypes", api: "batchjob_types", label: "code" },
  { name: "companyAddressesSelfTypeBilling", label: "code" },
  { name: "currencies", label: "name" },
  { name: "exports", label: "id", icon: "mdi-file-download-outline" },
  { name: "presets", label: "code" },
  { name: "usersSimple", label: "username" },
  { name: "companyRegions", label: "code", icon: "mdi-earth" },
  { name: "packingSpaces", label: "code", icon: "mdi-package-variant-closed" },
  { name: "cronJobs", api: "cron-jobs", icon: "mdi-calendar-clock", permissions: ["ROLE_API_CRONJOBS"] },
  { name: "shippingCalculations", api: "shipping/calculations", icon: "mdi-calculator-variant-outline", permissions: ["ROLE_API_SHIPPING_CALCULATIONS"] }
];
