import { LOGIN, LOGOUT, CHECK_AUTH, CHECK_ERROR, GET_NAME, GET_EMAIL, GET_PERMISSIONS } from "@skuhnow/vuetify-admin/src/providers/auth/actions";

export default (axios, params = {}) => {
  params = {
    routes: {
      login: "/login",
      logout: `${process.env.VUE_APP_HOST_URL}/saml/logout`,
      authCheck: "/whoami"
    },
    getName: u => u.name,
    getEmail: u => u.email,
    getPermissions: u => u.roles,
    ...params
  };

  let { routes, getName, getEmail, getPermissions } = params;

  function clearAllLocalStorageFilter() {
    const localStorageKeys = Object.keys(localStorage);
    localStorageKeys.filter(localStorageKey => localStorageKey.startsWith("filter_")).forEach(localStorageKey => localStorage.removeItem(localStorageKey));
  }

  return {
    [LOGIN]: async () => {
      throw new Error("Login only over SSO");
    },
    [LOGOUT]: async () => {
      window.location.href = routes.logout;
    },
    [CHECK_AUTH]: async () => {
      const currentLocation = new URL(window.location.href);
      if (currentLocation.pathname !== "/login") {
        localStorage.setItem("redirectAfterSaml", currentLocation.href);
      }

      const response = await axios.get(routes.authCheck);
      const urlParams = new URLSearchParams(window.location.search);
      const samlLoginSuccessful = urlParams.get("login_successful") === "true";
      if (samlLoginSuccessful) {
        clearAllLocalStorageFilter();
      }

      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      return response;
    },
    [CHECK_ERROR]: error => {
      if (error && error.response) {
        const status = error.response.status;
        if (status === 401) {
          return Promise.reject();
        }
      }
      return Promise.resolve();
    },
    [GET_NAME]: user => getName(user),
    [GET_EMAIL]: user => getEmail(user),
    [GET_PERMISSIONS]: user => getPermissions(user)
  };
};
