import i18n from "@/i18n";

const stateVariants = [
  { id: "new", name: i18n.t("integrations.order.status.new") },
  { id: "validated", name: i18n.t("integrations.order.status.validated") },
  { id: "pending_payment", name: i18n.t("integrations.order.status.pending_payment") },
  { id: "processing", name: i18n.t("integrations.order.status.processing") },
  { id: "finished", name: i18n.t("integrations.order.status.finished") },
  { id: "problematic", name: i18n.t("integrations.order.status.problematic") },
  { id: "cancelled", name: i18n.t("integrations.order.status.cancelled") }
];

const stateVariantsFilter = [...stateVariants];

export { stateVariants, stateVariantsFilter };
