<template>
  <div>
    <v-dialog v-model="showDialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="secondary" v-bind="attrs" v-on="on">
          {{ $t("integrations.btn.create_password") }}
        </v-btn>
      </template>

      <v-card>
        <validation-observer v-slot="{ handleSubmit, invalid }">
          <v-form ref="form" @submit.prevent="handleSubmit(resetPassword)">
            <v-card-title>
              {{ $t("integrations.btn.reset_password") }}
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <validation-provider
                      vid="confirm"
                      :name="$t('integrations.users.form.password')"
                      :rules="{
                        regex: /^(?=.*[A-Za-z])(?=.*\d)[^ \t]{8,}$/
                      }"
                      v-slot="{ errors }"
                    >
                      <va-text-input v-model="password" :label="$t('integrations.users.form.password')" :error-messages="errors" filled type="password" />
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <validation-provider :name="$t('integrations.users.form.repeat_password')" rules="required|password:@confirm" v-slot="{ errors }">
                      <v-text-field
                        v-model="repeatPassword"
                        data-cy="register-repeatPassword"
                        :label="$t('integrations.users.form.repeat_password')"
                        :error-messages="errors"
                        filled
                        prepend-inner-icon="mdi-lock"
                        type="password"
                      ></v-text-field>
                    </validation-provider> </v-col
                ></v-row>
              </v-container>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="closeDialog">
                {{ $t("va.actions.cancel") }}
              </v-btn>
              <v-btn color="primary" text type="submit" :disabled="invalid" :loading="isLoading">
                {{ $t("integrations.btn.reset_password") }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </validation-observer>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { PasswordResetService } from "@/services/user/PasswordResetService";

export default {
  props: ["userId"],
  data() {
    return {
      showDialog: false,
      isLoading: false,
      password: "",
      repeatPassword: ""
    };
  },
  methods: {
    closeDialog() {
      this.showDialog = false;
    },
    async resetPassword() {
      this.isLoading = true;
      await PasswordResetService.resetPassword(this.userId, this.password);
      this.isLoading = false;
      this.closeDialog();
    }
  }
};
</script>
