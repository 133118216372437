<template>
  <validation-form :id="id" :item="item">
    <base-material-tabs-card :tabs="tabs">
      <template v-slot:inventory-blockings_default>
        <v-row>
          <v-col>
            <validation-provider v-if="!isEdit" :name="$t('integrations.fields.sku')" rules="required" v-slot="{ validate, errors }">
              <va-autocomplete-input
                :label="$t('integrations.fields.sku') + ' *'"
                source="product_code"
                reference="product-variants"
                @input="validate"
                :error-messages="errors"
                item-value="identifiers.internal"
                :render-callback="record => productLabel(record)"
                search-query="q"
              />
            </validation-provider>
            <va-label-input
              v-if="isEdit"
              :label="$t('integrations.fields.sku')"
              source="product_code"
              reference="product-variants"
              :render-callback="(id, record) => (record ? productLabel(record) : id)"
            />
          </v-col>
          <v-col>
            <validation-provider v-if="!isEdit" :name="$t('integrations.fields.warehouse')" rules="required" v-slot="{ validate, errors }">
              <va-autocomplete-input
                :label="$t('integrations.fields.warehouse') + ' *'"
                item-text="label"
                source="warehouse_code"
                reference="warehouses"
                @input="validate"
                :render-callback="record => (record.label ? record.code + ' (' + record.label + ')' : record.code)"
                :error-messages="errors"
              />
            </validation-provider>
            <va-label-input
              v-if="isEdit"
              :label="$t('integrations.fields.warehouse')"
              source="warehouse_code"
              reference="warehouses"
              :render-callback="
                (id, record) => (record ? (record.label ? record.code + ' (' + record.label + ')' : record.code) : id + ' (' + $t('integrations.warehouse.messages.virtual') + ')')
              "
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col md="6" cols="12" v-if="isEdit" order="2">
            <validation-provider :name="$t('integrations.fields.original_quantity')" rules="required|numeric|min_value:0" v-slot="{ validate, errors }">
              <va-number-input :label="$t('integrations.fields.original_quantity') + ' *'" source="original_quantity" @input="validate" :error-messages="errors" />
            </validation-provider>
          </v-col>
          <v-col :md="isEdit ? 6 : 12" cols="12" order="1">
            <validation-provider
              :name="$t('integrations.fields.quantity')"
              :rules="{
                required: true,
                numeric: true,
                min_value: 0,
                max_value: isEdit ? '@' + $t('integrations.fields.original_quantity') : false
              }"
              v-slot="{ validate, errors }"
            >
              <va-text-input :label="$t('integrations.fields.quantity') + ' *'" source="quantity" @input="validate" :error-messages="errors" persistent-hint></va-text-input>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <validation-provider :name="$t('integrations.fields.reference')" rules="required" v-slot="{ validate, errors }">
              <va-text-input :label="$t('integrations.fields.reference') + ' *'" source="reference" multiline @input="validate" :error-messages="errors" />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row v-if="isEdit">
          <v-col>
            <va-text-input :label="$t('integrations.fields.usages')" source="usages" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <va-date-input :label="$t('integrations.fields.scheduled_for')" source="scheduled_for" format="long"></va-date-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <va-array-input :label="$t('integrations.fields.attributes')" source="attributes_key_value" v-slot="props">
              <v-row>
                <v-col cols="6">
                  <validation-provider
                    :name="$t('integrations.attributes.key')"
                    :rules="{
                      required: true
                    }"
                    v-slot="{ validate, errors }"
                  >
                    <va-combobox-input
                      :label="$t('integrations.attributes.key') + ' *'"
                      v-bind="props"
                      source="key"
                      :choices="InventoryBlockingsAttributes"
                      @input="validate"
                      item-text="name"
                      item-value="id"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="6">
                  <va-text-input :label="$t('integrations.attributes.value')" v-bind="props" source="value" />
                </v-col>
              </v-row>
            </va-array-input>
          </v-col>
        </v-row>
      </template>
      <template v-slot:footer>
        <div class="d-flex align-center"><va-save-button /><va-cancel-button class="ml-2" /></div>
      </template>
    </base-material-tabs-card>
  </validation-form>
</template>
<script>
import { TranslationService } from "@/services/i18n/TranslationService";
import InventoryBlockingsAttributes from "./InventoryBlockingsAttributes";

export default {
  props: ["id", "title", "item", "isEdit"],
  created() {
    this.TranslationService = TranslationService;
  },
  data() {
    return {
      TranslationService: null,
      InventoryBlockingsAttributes: InventoryBlockingsAttributes
    };
  },
  methods: {
    productLabel(record) {
      const internalSku = record.identifiers.internal;
      const publicSku = record.identifiers.internal === record.identifiers.public ? "" : " [" + record.identifiers.public + "]";
      return internalSku + publicSku + " - " + TranslationService.getValueByLocaleKey(record.name);
    }
  },
  computed: {
    tabs() {
      return [
        {
          id: "inventory-blockings_default",
          label: this.$t("integrations.menu.inventory_blockings")
        }
      ];
    }
  }
};
</script>
