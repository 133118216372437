import admin from "@/plugins/admin";

export class PasswordResetService {
  static async resetPassword(userId, password) {
    await admin.http.put(`/users/${userId}/password`, {
      id: null,
      email: "",
      group: [],
      password: password,
      repeatPassword: password
    });
  }
}
