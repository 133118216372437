var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.rows,"show-expand":"","expanded":_vm.expanded,"disable-pagination":true,"item-key":"id"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.created_at ? _vm.$d(new Date(item.created_at), "datetime") : "")+" ")]}},{key:"item.type",fn:function(ref){
var value = ref.value;
return [_c('lookup-field',{attrs:{"lookup-data":_vm.messageTypeVariants,"value":value}})]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('order-fulfillment-message',{attrs:{"item":item}})],1)]}},{key:"item.message",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.message ? (item.message.length > 50 ? item.message.slice(0, 50) + "..." : item.message) : "")+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }