var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.value,"width":"900"},on:{"input":_vm.closeDialog,"click:outside":_vm.closeDialog}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.createUpdateCompanyRegion)}}},[_c('v-card',[(_vm.isEdit)?_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("integrations.company.edit_region.title", { code: _vm.item.id, companyCode: _vm.companyCode }))+" ")]):_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("integrations.company.create_region.title", { companyCode: _vm.companyCode }))+" ")]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.code'),"rules":{
                    required: true,
                    regex: /^[a-zA-Z0-9_-]+$/
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var validate = ref.validate;
                  var errors = ref.errors;
return [_c('va-text-input',{attrs:{"autofocus":!_vm.isEdit,"disabled":_vm.isEdit,"label":_vm.$t('integrations.fields.code') + ' *',"error-messages":errors},on:{"input":validate},model:{value:(_vm.formData.id),callback:function ($$v) {_vm.$set(_vm.formData, "id", $$v)},expression:"formData.id"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('va-autocomplete-input',{attrs:{"filled":"","label":_vm.$t('integrations.fields.country'),"reference":"countries","multiple":true,"search-query":"q"},model:{value:(_vm.formData.countries),callback:function ($$v) {_vm.$set(_vm.formData, "countries", $$v)},expression:"formData.countries"}})],1)],1),_c('v-row',[_c('v-col',[_c('va-autocomplete-input',{attrs:{"label":_vm.$t('integrations.fields.owner'),"reference":"manager_companies","item-text":function (record) { return record.name + ' (' + record.group + ')'; },"search-query":"name","sort-by":['name']},model:{value:(_vm.formData.owner),callback:function ($$v) {_vm.$set(_vm.formData, "owner", $$v)},expression:"formData.owner"}})],1)],1),_c('v-row',[_c('v-col',[_c('va-array-input',{attrs:{"label":_vm.$t('integrations.fields.attributes')},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.attributes.key'),"rules":{
                          required: true,
                          regex: /^[a-zA-Z0-9_-]+$/
                        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('va-combobox-input',_vm._b({attrs:{"label":_vm.$t('integrations.attributes.key'),"choices":_vm.CompanyRegionsAttributeKeys,"item-text":"name","item-value":"id","error-messages":errors},model:{value:(props.item.key),callback:function ($$v) {_vm.$set(props.item, "key", $$v)},expression:"props.item.key"}},'va-combobox-input',props,false))]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('va-text-input',_vm._b({attrs:{"label":_vm.$t('integrations.attributes.value')},model:{value:(props.item.value),callback:function ($$v) {_vm.$set(props.item, "value", $$v)},expression:"props.item.value"}},'va-text-input',props,false))],1)],1)]}}],null,true),model:{value:(_vm.formData.keyValues),callback:function ($$v) {_vm.$set(_vm.formData, "keyValues", $$v)},expression:"formData.keyValues"}})],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","disabled":_vm.saving},on:{"click":_vm.closeDialog}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close-circle")]),_vm._v(" "+_vm._s(_vm.$t("integrations.btn.cancel"))+" ")],1),_c('v-btn',{attrs:{"color":"primary","type":"submit","loading":_vm.saving}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v(" "+_vm._s(_vm.$t("integrations.btn.save"))+" ")],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }