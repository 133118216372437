<template>
  <base-material-card :icon="resource.icon" :title="title">
    <template v-slot:subtitle>
      <info-text i18n="integrations.helper.cronJobs.list" />
    </template>
    <va-list disable-global-search disable-export disable-create disable-pagination :filters="filters" :items-per-page="500">
      <va-data-table :fields="fields" disable-show disable-clone disable-edit disable-delete disable-select>
        <template v-slot:[`item.actions`]="{ item }">
          <va-action-button icon="mdi-format-list-bulleted" :label="$t('integrations.fields.show_logs')" @click="goToLog(item)" />
        </template>
      </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
import i18n from "@/i18n";
import InfoText from "@/components/helper/InfoText";

export default {
  components: {
    InfoText
  },
  props: ["resource", "title"],
  methods: {
    goToLog(item) {
      const logUrl = `https://log.prominate-platform.com/search?q=full_message: "*${item.command.replace(/"/g, '\\"')}*"&rangetype=relative&from=300`;
      window.open(logUrl, "cronjob").focus();
    }
  },
  data() {
    return {
      fields: [
        {
          label: i18n.t("integrations.fields.status"),
          source: "status"
        },
        {
          label: i18n.t("integrations.fields.time_interval_text"),
          source: "time_interval_text"
        },
        {
          label: i18n.t("integrations.fields.command"),
          source: "command"
        },
        {
          label: i18n.t("integrations.fields.last_run"),
          source: "last_run",
          type: "date"
        }
      ],
      filters: []
    };
  }
};
</script>
