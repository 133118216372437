<template>
  <span>{{ productName }}</span>
</template>

<script>
import Field from "@skuhnow/vuetify-admin/src/mixins/field";
import i18n from "@/i18n";
import { TranslationService } from "@/services/i18n/TranslationService";

export default {
  mixins: [Field],
  computed: {
    productName() {
      return TranslationService.getValueByLocaleKey(this.value);
    },
    locale() {
      return i18n.locale.substring(0, 2);
    }
  }
};
</script>
