<template>
  <span>
    <va-action-button icon="mdi-refresh" :label="$t('integrations.btn.ofr.resend')" hide-label text @click="dialog = true" :disabled="!canResend" />
    <v-dialog v-model="dialog" width="600">
      <v-card>
        <v-card-title>{{ $t("integrations.ofr.form.confirm_resend", { id: item.id }) }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" :disabled="resending" @click="closeDialog">
            <v-icon left>mdi-close-circle</v-icon>
            {{ $t("integrations.btn.cancel") }}
          </v-btn>
          <v-btn color="primary" @click="resendOrder" :loading="resending">
            <v-icon left>mdi-content-save</v-icon>
            {{ $t("integrations.btn.ofr.resend") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>
<script>
import admin from "@/plugins/admin";

export default {
  props: ["item"],
  data() {
    return {
      resending: false,
      dialog: false
    };
  },
  computed: {
    canResend() {
      const resendableStates = ["published", "confirmed", "processing", "fulfilled", "cancelled", "finished"];
      return resendableStates.includes(this.item.state);
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    async resendOrder() {
      const linkResendOrder = `${process.env.VUE_APP_API_URL}/orders/*/fulfillments/${this.item.id}`;

      this.resending = true;
      admin.http
        .patch(linkResendOrder, { state: "approved" })
        .then(() => {
          this.$store.commit(`messages/showToast`, {
            color: "success",
            message: this.$i18n.t("integrations.notification.ofr.resend")
          });
        })
        .catch(() =>
          this.$store.commit(`messages/showToast`, {
            color: "error",
            message: this.$i18n.t("integrations.notification.ofr.resend_error")
          })
        )
        .finally(() => {
          this.$emit("orderResent");
          this.resending = false;
          this.closeDialog();
        });
    }
  }
};
</script>
