var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-material-card',{attrs:{"icon":_vm.resource.icon,"title":_vm.title},scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_c('info-text',{attrs:{"resource":_vm.resource,"crud-type":"list"}})]},proxy:true}])},[_c('va-list',{attrs:{"filters":_vm.filters,"sort-by":['date_from'],"sort-desc":[true],"disable-export":""},scopedSlots:_vm._u([{key:"actions",fn:function(slotProps){return [_c('export-poll-button',{attrs:{"resource":_vm.resource.name,"text":"","options":slotProps.listState.options,"filter":slotProps.currentFilter}})]}}])},[_c('va-data-table',{attrs:{"fields":_vm.fields,"disable-clone":"","disable-delete":"","disable-show":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('link-to-commands-button',{attrs:{"item":item}}),_c('link-button',{attrs:{"label":_vm.$t('integrations.campaign.product.title.list'),"route":{ name: 'campaignProducts', params: { code: item.code } }}})]}},{key:"field.order_fulfillment_count",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [(value)?_c('router-link',{attrs:{"to":{
            name: "orderFulfillments_list",
            query: {
              filter: JSON.stringify({
                campaign: item.code,
                test: 'false'
              })
            }
          }}},[_vm._v(_vm._s(value))]):_c('span',[_vm._v(_vm._s(value))])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }