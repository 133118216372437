<template>
  <div>
    <info-text i18n="integrations.helper.user_address.introduction.create" />
    <va-create-layout>
      <useraddresses-form :item="item" />
    </va-create-layout>
  </div>
</template>

<script>
import InfoText from "@/components/helper/InfoText";
export default {
  components: { InfoText },
  props: ["resource", "title", "item"]
};
</script>
