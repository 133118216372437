<template>
  <v-menu offset-y v-if="hasAction('list')">
    <template v-slot:activator="{ on, attrs }">
      <v-btn :color="color || 'success'" text v-bind="attrs" v-on="on" :loading="exportRunning">
        <v-icon left>
          mdi-download
        </v-icon>
        <slot name="label">
          {{ $t("va.actions.export") }}
        </slot>
      </v-btn>
    </template>
    <v-list-item-group>
      <v-list>
        <v-list-item @click="onExport('csv')">
          <v-list-item-icon>
            <v-icon>mdi-file-delimited-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Csv</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="onExport('xlsx')">
          <v-list-item-icon>
            <v-icon>mdi-microsoft-excel</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Xlsx</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-list-item-group>
  </v-menu>
</template>

<script>
import Resource from "@skuhnow/vuetify-admin/src/mixins/resource";
import Button from "@skuhnow/vuetify-admin/src/mixins/button";
import { GET_EXPORT } from "@skuhnow/vuetify-admin/src/providers/data/actions";

/**
 * Action button for export all data from a list iterator, aka VaList.
 * Use current state of VaList, i.e. keep current filters and sorts while removing pagination limitation.
 * Will provoke a download of a CSV file generated on client side thanks to papaparse library.
 */
export default {
  mixins: [Resource, Button],
  props: {
    /**
     * Current state of list, with mainly current sorting.
     */
    options: {
      type: Object,
      default: () => {}
    },
    /**
     * Current filter state of the list.
     */
    filter: {
      type: Object,
      default: () => {}
    }
  },
  destroyed() {
    this.stopPolling();
  },
  data() {
    return {
      exportRunning: false,
      pollingActive: false,
      pollingInterval: null,
      pollingCount: 0,
      pollingMaxCount: 3
    };
  },
  methods: {
    async onExport(format) {
      this.exportRunning = true;
      /**
       * Generate CSV string from JSON api
       */
      let params = {
        filter: this.filter,
        format: format
      };

      if (this.options) {
        const { sortBy, sortDesc } = this.options;

        params.sort = sortBy.map((by, index) => {
          return { by, desc: sortDesc[index] };
        });
      }
      let data = await this.createExport(params);

      if (data.state === "done") {
        this.downloadFile(data);
        this.exportRunning = false;
      } else if (data.state === "error") {
        this.showErrorToast(data.id);
        this.exportRunning = false;
      } else {
        this.startPolling(data);
      }
    },
    startPolling(data) {
      if (this.pollingActive) {
        this.stopPolling();
      }
      this.pollingActive = true;
      this.pollingCount = 0;
      this.pollingInterval = setInterval(
        function() {
          this.poll(data);
        }.bind(this),
        2000
      );
    },
    stopPolling() {
      clearInterval(this.pollingInterval);
      this.pollingActive = false;
      this.exportRunning = false;
    },
    async poll(data) {
      this.pollingCount++;
      const updatedData = await this.getExportStatus(data.id);
      if (updatedData.state === "error") {
        this.stopPolling();
        this.showErrorToast(updatedData.id);
      }
      if (updatedData.state === "done") {
        this.stopPolling();
        this.downloadFile(updatedData);
      }
      if (this.pollingCount >= this.pollingMaxCount) {
        this.stopPolling();

        this.$store.commit(`messages/showToast`, {
          color: "warning",
          message: this.$t("integrations.export.notifications.polling_max_count_reached", { id: updatedData.id }),
          options: { timeout: -1 }
        });
      }
    },
    downloadFile(responseData) {
      const filenameWithoutS3Prefix = responseData.result.filename.substring(5);
      window.open(`${process.env.VUE_APP_API_URL}/files/${filenameWithoutS3Prefix}`, "_blank");
    },
    async createExport(params) {
      const { data } = await this.$store.dispatch(`${this.resource}/${GET_EXPORT}`, params);
      return data;
    },
    async getExportStatus(id) {
      const { data } = await this.$admin.http.get(`/commands/ExportCommandConfig/runs/${id}`);
      return data;
    },
    showErrorToast(id) {
      this.$store.commit(`messages/showToast`, {
        color: "error",
        message: this.$t("integrations.export.notifications.error", { id }),
        options: { timeout: -1 }
      });
    }
  }
};
</script>
