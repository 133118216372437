import Vue from "vue";
import AdminLayout from "@/layouts/Admin";
import Dashboard from "@/views/Dashboard";
import Profile from "@/views/Profile";
import Error from "@/views/Error";
import i18n from "@/i18n";
import Demo from "@/views/Demo";
import CampaignProducts from "@/resources/campaigns/CampaignProducts";
import Run from "@/resources/batchjobs/Run";
import RunList from "@/resources/batchjobs/RunList";
import Status from "@/views/Status";
import RestClient from "@/views/RestClient";
import EmailTester from "@/resources/emailTemplates/EmailTester";

/**
 * Error component
 */
Vue.component("Error", Error);

export default {
  path: "",
  component: AdminLayout,
  meta: {
    title: i18n.t("routes.home")
  },
  children: [
    {
      path: "batchjobs/:batchJobId/runs",
      name: "batchJobRuns",
      component: RunList,
      props: true
    },
    {
      path: "batchjobs/:batchJobId/runs/:batchRunId",
      name: "batchJobRun",
      component: Run,
      props: true,
      meta: {
        title: i18n.t("integrations.menu.batchjobs")
      }
    },
    {
      path: "campaigns/:code/products",
      name: "campaignProducts",
      component: CampaignProducts,
      props: true
    },
    {
      path: "/demo",
      name: "demo",
      component: Demo
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: Dashboard,
      meta: {
        title: i18n.t("routes.dashboard")
      }
    },
    {
      path: "/email-templates/:preset/:templateCode",
      name: "emailTemplates_emailtester",
      component: EmailTester,
      props: true,
      meta: {
        title: "Test Emailtemplates"
      }
    },
    {
      path: "/status",
      name: "status",
      component: Status,
      meta: {
        title: "Status"
      }
    },
    {
      path: "/restClient",
      name: "restClient",
      component: RestClient,
      meta: {
        title: "Rest Client"
      }
    },
    {
      path: "/profile",
      name: "profile",
      component: Profile,
      meta: {
        title: i18n.t("routes.profile")
      }
    },
    {
      path: "*",
      component: Error,
      meta: {
        title: i18n.t("routes.not_found")
      }
    }
  ]
};
