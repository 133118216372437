import { render, staticRenderFns } from "./FilesEndpointDownload.vue?vue&type=template&id=4e9456eb&"
import script from "./FilesEndpointDownload.vue?vue&type=script&lang=js&"
export * from "./FilesEndpointDownload.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports