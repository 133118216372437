<template>
  <div>{{ usernameCompany }}</div>
</template>
<script>
export default {
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      user: null
    };
  },
  async created() {
    let { data } = await this.$store.dispatch(`users/getOne`, {
      id: this.userId
    });
    this.user = data;
  },
  computed: {
    usernameCompany() {
      return this.user ? this.user.username + " (" + this.user.company_name + ")" : "";
    }
  }
};
</script>
