<template>
  <v-col>
    <v-row no-gutters>
      <v-col>
        <validation-provider
          :name="$t('integrations.fields.campaign_code')"
          :rules="{
            required: true,
            regex: /^[a-zA-Z0-9_-]+$/
          }"
          v-slot="{ errors }"
        >
          <v-text-field
            filled
            :label="$t('integrations.fields.campaign_code') + ' *'"
            v-model="attributes.campaign_code"
            :error-messages="errors"
            @input="updateAttributes"
          ></v-text-field>
        </validation-provider>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <validation-provider
          :name="$t('integrations.fields.billingaddress_code')"
          :rules="{
            required: true,
            regex: /^[a-z0-9_-]+$/
          }"
          v-slot="{ errors }"
        >
          <va-autocomplete-input
            :taggable="true"
            :label="$t('integrations.fields.billingaddress_code') + ' *'"
            v-model="attributes.billingaddress_code"
            reference="companyAddressesSelfTypeBilling"
            placeholder="batchjob-billing-default"
            item-value="code"
            search-query="q"
            :sort-by="['code']"
            @input="updateAttributes"
            :error-messages="errors"
          />
        </validation-provider>
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
export default {
  props: ["value"],
  data() {
    return {
      attributes: {}
    };
  },
  watch: {
    value: {
      deep: true,
      handler(newValue) {
        this.attributes = Object.assign({}, newValue);
      }
    }
  },
  methods: {
    updateAttributes() {
      if (this.attributes.billingaddress_code === "") {
        this.attributes.billingaddress_code = "batchjob-billing-default";
      }
      this.$emit("input", this.attributes);
    }
  }
};
</script>
