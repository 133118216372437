var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("integrations.btn.create_password"))+" ")])]}}]),model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.resetPassword)}}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("integrations.btn.reset_password"))+" ")]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"vid":"confirm","name":_vm.$t('integrations.users.form.password'),"rules":{
                      regex: /^(?=.*[A-Za-z])(?=.*\d)[^ \t]{8,}$/
                    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('va-text-input',{attrs:{"label":_vm.$t('integrations.users.form.password'),"error-messages":errors,"filled":"","type":"password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.users.form.repeat_password'),"rules":"required|password:@confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"data-cy":"register-repeatPassword","label":_vm.$t('integrations.users.form.repeat_password'),"error-messages":errors,"filled":"","prepend-inner-icon":"mdi-lock","type":"password"},model:{value:(_vm.repeatPassword),callback:function ($$v) {_vm.repeatPassword=$$v},expression:"repeatPassword"}})]}}],null,true)})],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":_vm.closeDialog}},[_vm._v(" "+_vm._s(_vm.$t("va.actions.cancel"))+" ")]),_c('v-btn',{attrs:{"color":"primary","text":"","type":"submit","disabled":invalid,"loading":_vm.isLoading}},[_vm._v(" "+_vm._s(_vm.$t("integrations.btn.reset_password"))+" ")])],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }