import i18n from "@/i18n";

const TypeVariants = [
  {
    id: "email",
    name: i18n.t("integrations.communication-gateways.type.email"),
    has_endpoint: true,
    has_topics: true
  },
  {
    id: "api",
    name: i18n.t("integrations.communication-gateways.type.api"),
    has_endpoint: true,
    has_topics: true
  },
  {
    id: "filesystem",
    name: i18n.t("integrations.communication-gateways.type.filesystem"),
    has_endpoint: true,
    has_topics: true
  },
  {
    id: "budget_voucher",
    name: "Voucher (Budget)"
  },
  {
    id: "agcod",
    name: "Custom (agcod)",
    permissions: ["ROLE_ADMIN"]
  },
  {
    id: "cadooz",
    name: "Custom (cadooz)",
    permissions: ["ROLE_ADMIN"]
  },
  {
    id: "wex",
    name: "Custom (wex)",
    permissions: ["ROLE_ADMIN"]
  }
];

const TopicsVariants = [
  {
    id: "ofr_cxml",
    name: i18n.t("integrations.communication-gateways.topic.ofr_cxml")
  },
  {
    id: "ofr_json",
    name: i18n.t("integrations.communication-gateways.topic.ofr_json")
  },
  {
    id: "ofr_csv",
    name: i18n.t("integrations.communication-gateways.topic.ofr_csv")
  },
  {
    id: "ofr_pdf",
    name: i18n.t("integrations.communication-gateways.topic.ofr_pdf")
  },
  {
    id: "inventory_cxml",
    name: i18n.t("integrations.communication-gateways.topic.inventory_cxml")
  },
  {
    id: "siemens_3i_csv",
    name: i18n.t("integrations.communication-gateways.topic.siemens_3i_csv")
  }
];

export { TypeVariants, TopicsVariants };
