<template>
  <base-material-card :icon="resource.icon" :title="title">
    <template v-slot:subtitle>
      <info-text i18n="integrations.helper.communication-gateways.list" />
    </template>
    <va-list disable-global-search disable-export :filters="filters" :items-per-page="500" :default-filter="{ enabled: 1 }">
      <va-data-table :fields="fields" disable-show disable-clone>
        <template v-slot:[`field.endpoint`]="{ item }">
          <div class="text-break">{{ item.endpoint }}</div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <test-gateway-button :item="item" />
        </template>
      </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
import i18n from "@/i18n";
import { TypeVariants } from "./CommunicationChannelChoices";
import InfoText from "@/components/helper/InfoText";
import TestGatewayButton from "@/components/communication-gateways/TestGatewayButton";

export default {
  components: {
    InfoText,
    TestGatewayButton
  },
  props: ["resource", "title"],
  data() {
    return {
      fields: [
        {
          label: i18n.t("integrations.fields.company"),
          source: "company_data",
          type: "reference-array",
          permissions: ["ROLE_ADMIN"],
          attributes: {
            reference: "companies",
            action: "edit",
            actionReference: "companies"
          }
        },
        {
          label: i18n.t("integrations.fields.enabled"),
          source: "enabled",
          type: "boolean"
        },
        {
          label: i18n.t("integrations.fields.status"),
          type: "status-tooltip"
        },
        {
          source: "type",
          type: "select",
          attributes: {
            choices: TypeVariants,
            itemText: "name",
            itemValue: "id"
          }
        },
        {
          label: "Last message",
          source: "last_message_at",
          type: "date"
        },
        {
          source: "endpoint"
        },
        {
          source: "topics",
          type: "array",
          attributes: {
            itemText: item => {
              return i18n.t("integrations.communication-gateways.topic." + item.toLowerCase());
            }
          }
        }
      ],
      filters: [
        {
          label: i18n.t("integrations.fields.company"),
          source: "company",
          type: "autocomplete",
          resettable: true,
          attributes: {
            reference: "companies",
            searchQuery: "q",
            itemText: record => record.name + " (" + record.group + ")",
            sortBy: ["name"]
          },
          alwaysOn: true
        },
        {
          label: i18n.t("integrations.fields.type"),
          source: "type",
          type: "select",
          attributes: {
            choices: TypeVariants.filter(
              function(typeVariant) {
                if (!typeVariant.permissions) {
                  return true;
                }

                return typeVariant.permissions.filter(permission => !this.$admin.can(permission)).length === 0;
              }.bind(this)
            ),
            itemText: "name",
            itemValue: "id"
          },
          alwaysOn: true,
          resettable: true
        },
        {
          label: i18n.t("integrations.fields.enabled"),
          source: "enabled",
          type: "select",
          alwaysOn: true,
          attributes: {
            choices: [
              { value: 1, text: i18n.t("integrations.fields.yes") },
              { value: 0, text: i18n.t("integrations.fields.no") }
            ]
          }
        },
        {
          label: i18n.t("integrations.fields.status"),
          source: "status",
          type: "select",
          attributes: {
            choices: [
              { id: "ready", name: "ready" },
              { id: "disabled", name: "disabled" },
              { id: "exception", name: "exception" },
              { id: "problem", name: "problem" }
            ],
            itemText: "name",
            itemValue: "id"
          }
        }
      ]
    };
  }
};
</script>
