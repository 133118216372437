<template>
  <div>{{ numberFormat }}</div>
</template>

<script>
import Field from "@skuhnow/vuetify-admin/src/mixins/field";
import i18n from "@/i18n";

export default {
  mixins: [Field],
  props: ["currency"],
  computed: {
    numberFormat() {
      return new Intl.NumberFormat(i18n.locale, {
        style: "currency",
        currency: this.item.currency ? this.item.currency : "EUR"
      }).format(this.value / 100);
    }
  }
};
</script>
