<template>
  <div class="log-chart-container">
    <bar-chart :data="chartData" :options="chartOptions" />
  </div>
</template>
<script>
import BarChart from "@/components/charts/base-components/BarChart";
export default {
  components: { BarChart },
  props: ["eventData"],
  data() {
    return {
      chartData: {
        labels: this.eventData.map(element => new Date(element.k * 1000).toLocaleDateString()),
        datasets: [
          {
            data: this.eventData.map(element => element.v),
            backgroundColor: "rgba(255, 0, 0, 0.2)",
            borderWidth: 1
          }
        ]
      },
      chartOptions: {
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              display: false
            }
          ],
          yAxes: [
            {
              display: false
            }
          ]
        }
      }
    };
  }
};
</script>

<style>
.log-chart-container {
  margin: 0;
  width: 200px;
}
</style>
