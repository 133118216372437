const ConfirmationRequestTypeVariants = [
  { id: "newsletter_subscription", name: "Newsletter Subscription" },
  { id: "magic_link", name: "Magic Login" },
  { id: "password_reset", name: "Password Reset Request" },
  { id: "onboarding_doi", name: "Onboarding DOI" },
  { id: "onboarding_approval", name: "Onboarding Approval Request" },
  { id: "order_approval", name: "Order Approval Request" },
  { id: "punchout_order", name: "Punchout Order" },
  { id: "payment", name: "Payment" }
];

const ConfirmationRequestStateVariants = [
  { id: "pending", name: "Pending" },
  { id: "timeout", name: "Timeout" },
  { id: "confirmed", name: "Confirmed" }
];

export { ConfirmationRequestTypeVariants, ConfirmationRequestStateVariants };
