<template>
  <v-data-table :headers="headers" :items="dataRows" disable-pagination>
    <template v-slot:item.channelGateways="{ item }">
      <channel-endpoints :item="item" />
    </template>
    <template v-slot:item.orderDate="{ item }">
      {{ item.order.date ? $d(new Date(item.order.date), "datetime") : "" }}
    </template>
    <template v-slot:item.actions="{ item }">
      <link-button :label="$t('integrations.fields.details')" icon="mdi-eye" :route="{ name: 'orderFulfillments_show', params: { id: item.id } }" />
    </template>
  </v-data-table>
</template>
<script>
import LinkButton from "@/components/actions/LinkButton";
import ChannelEndpoints from "@/resources/order-fulfillments/components/ChannelEndpoints";
export default {
  components: { ChannelEndpoints, LinkButton },
  props: ["item"],
  async created() {
    const response = await this.$admin.http.get(`${process.env.VUE_APP_API_URL}/orders/${this.item.id}/fulfillments`);
    this.dataRows = response.data.data;
  },
  data() {
    return {
      dataRows: [],
      headers: [
        { text: this.$i18n.t("integrations.fields.reference"), value: "id" },
        {
          text: this.$i18n.t("integrations.fields.order_date"),
          value: "orderDate",
          sortable: false
        },
        { text: this.$i18n.t("integrations.fields.status"), value: "state" },
        {
          text: this.$i18n.t("integrations.fields.distributor"),
          value: "fulfillment_config.distributor"
        },
        {
          text: this.$i18n.t("integrations.fields.fulfillment_endpoints"),
          value: "channelGateways",
          sortable: false
        },
        { value: "actions", sortable: false }
      ]
    };
  }
};
</script>
