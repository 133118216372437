var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.channel'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
return [_c('va-autocomplete-input',{attrs:{"label":_vm.$t('integrations.fields.channel') + ' *',"reference":"channels_specific","item-value":"id","search-query":"q","sort-by":['name'],"error-messages":errors},on:{"input":_vm.updateAttributes},model:{value:(_vm.attributes.channel),callback:function ($$v) {_vm.$set(_vm.attributes, "channel", $$v)},expression:"attributes.channel"}})]}}])})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.email_template'),"rules":{
          required: true,
          regex: /^[a-z0-9_-]+$/
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('integrations.fields.email_template') + ' *',"error-messages":errors,"filled":""},on:{"input":_vm.updateAttributes},model:{value:(_vm.attributes.email_template),callback:function ($$v) {_vm.$set(_vm.attributes, "email_template", $$v)},expression:"attributes.email_template"}})]}}])})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-checkbox',{attrs:{"label":_vm.$t('integrations.field.test_order')},on:{"change":_vm.updateAttributes},model:{value:(_vm.attributes.test),callback:function ($$v) {_vm.$set(_vm.attributes, "test", $$v)},expression:"attributes.test"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }