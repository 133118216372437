import i18n from "@/i18n";

const stateVariants = [
  { id: "new", name: i18n.t("integrations.form.status.new") },
  { id: "pending", name: i18n.t("integrations.form.status.pending") },
  { id: "approved", name: i18n.t("integrations.form.status.approved") },
  { id: "declined", name: i18n.t("integrations.form.status.declined") },
  { id: "delegated", name: i18n.t("integrations.form.status.delegated") },
  { id: "submitted", name: i18n.t("integrations.form.status.submitted") }
];

export { stateVariants };
