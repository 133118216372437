import Vue from "vue";
import VuetifyAdmin from "@skuhnow/vuetify-admin";
import "@skuhnow/vuetify-admin/src/loader";
import authProvider from "@/providers/authProvider";
import restProvider from "@/providers/restProvider";
import router from "@/router";
import routes from "@/router/admin";
import store from "@/store";
import i18n from "@/i18n";
import resources from "@/resources";
import http from "@/plugins/http";

/**
 * Load Admin UI components
 */
Vue.use(VuetifyAdmin);

router.afterEach(from => {
  const regex = /\?step=(?<step>\d+)/;
  let matches;

  if ((matches = regex.exec(from.fullPath)) !== null) {
    let step = parseInt(matches[1]) || 1;
    store.commit("batch/setStepperIndex", step);
  }
});

/**
 * Init admin
 */
export default new VuetifyAdmin({
  router,
  store,
  i18n,
  title: "Prominate Platform",
  routes,
  locales: {},
  translations: ["en"],
  dataProvider: restProvider(http),
  authProvider: authProvider(http),
  resources,
  http,
  options: {
    dateFormat: "datetime",
    list: {
      multiSort: false
    }
  }
});
