<template>
  <validation-form :id="id" :item="item">
    <base-material-tabs-card :tabs="tabs">
      <template v-slot:company_information>
        <v-row
          ><v-col md="6" cols="12">
            <validation-provider v-if="!isEdit" :name="$t('integrations.fields.company')" rules="required" v-slot="{ validate, errors }">
              <va-autocomplete-input
                :label="$t('integrations.fields.company') + ' *'"
                :autofocus="!isEdit"
                source="company"
                reference="companies"
                :hint="$t('integrations.helper.user.company')"
                @input="validate"
                :render-callback="record => record.name + ' (' + record.group + ')'"
                :error-messages="errors"
                search-query="name"
                :sort-by="['name']"
              ></va-autocomplete-input>
            </validation-provider>
            <va-autocomplete-input v-else readonly :label="$t('integrations.fields.company') + ' *'" source="company" reference="companies"></va-autocomplete-input>
          </v-col>
          <v-col md="6" cols="12">
            <validation-provider
              :name="$t('integrations.fields.code')"
              :rules="{
                required: true,
                regex: /^[a-z0-9_-]+$/,
                uniqueValidator: !isEdit ? { resource: 'companyAddresses', field: 'codeEquals' } : false
              }"
              v-slot="{ validate, errors }"
            >
              <va-text-input
                :readonly="isEdit"
                :label="$t('integrations.fields.code') + ' *'"
                source="code"
                @input="validate"
                :error-messages="errors"
                :hint="!isEdit ? $t('integrations.helper.company_address.code') : ''"
              ></va-text-input>
            </validation-provider> </v-col
        ></v-row>
        <v-row>
          <v-col>
            <va-select-input
              :label="$t('integrations.fields.address_type')"
              source="address_types"
              :choices="AddressTypes"
              item-text="name"
              item-value="id"
              multiple
            ></va-select-input>
          </v-col>
        </v-row>
        <v-row
          ><v-col> <va-text-input :label="$t('integrations.fields.label')" source="label" :hint="$t('integrations.helper.company_address.label')"></va-text-input> </v-col
        ></v-row>
        <v-row
          ><v-col md="6" cols="12">
            <va-text-input
              :label="$t('integrations.fields.organization')"
              source="organization"
              :hint="$t('integrations.helper.company_address.organization')"
            ></va-text-input> </v-col
          ><v-col md="6" cols="12"> <va-text-input :label="$t('integrations.fields.department')" source="department"></va-text-input> </v-col
        ></v-row>
        <v-row
          ><v-col md="6" cols="12">
            <validation-provider :name="$t('integrations.fields.street')" rules="required" v-slot="{ validate, errors }">
              <va-text-input :label="$t('integrations.fields.street')" source="street" @input="validate" :error-messages="errors"></va-text-input
            ></validation-provider> </v-col
          ><v-col md="6" cols="12"> <va-text-input :label="$t('integrations.fields.street2')" source="street2"></va-text-input> </v-col
        ></v-row>
        <v-row
          ><v-col md="4" cols="12">
            <validation-provider :name="$t('integrations.fields.postal_code')" rules="required" v-slot="{ validate, errors }">
              <va-text-input :label="$t('integrations.fields.postal_code')" source="postal_code" @input="validate" :error-messages="errors"></va-text-input
            ></validation-provider> </v-col
          ><v-col md="8" cols="12">
            <validation-provider :name="$t('integrations.fields.city')" rules="required" v-slot="{ validate, errors }">
              <va-text-input :label="$t('integrations.fields.city')" source="city" @input="validate" :error-messages="errors"></va-text-input
            ></validation-provider> </v-col
        ></v-row>
        <v-row
          ><v-col> <va-text-input :label="$t('integrations.fields.region_text')" source="region_text"></va-text-input> </v-col
        ></v-row>
        <v-row>
          <v-col>
            <validation-provider :name="$t('integrations.fields.country')" rules="required" v-slot="{ validate, errors }">
              <va-autocomplete-input
                :label="$t('integrations.fields.country')"
                source="country"
                reference="countries"
                search-query="q"
                @input="validate"
                :error-messages="errors"
              ></va-autocomplete-input
            ></validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="6" cols="12"> <va-text-input :label="$t('integrations.fields.phone')" source="phone"></va-text-input> </v-col>
          <v-col md="6" cols="12"> <va-text-input :label="$t('integrations.fields.email')" source="email"></va-text-input> </v-col>
        </v-row>
        <v-row>
          <v-col md="6" cols="12">
            <va-array-input :label="$t('integrations.fields.tax')" source="tax_ids_key_value" v-slot="props">
              <v-row>
                <v-col cols="6">
                  <validation-provider :name="$t('integrations.fields.taxes_key')" rules="required" v-slot="{ validate, errors }">
                    <va-combobox-input
                      :label="$t('integrations.fields.tax_number_id')"
                      v-bind="props"
                      source="key"
                      :choices="TaxIdKeys"
                      item-text="name"
                      item-value="id"
                      @input="validate"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="6">
                  <va-text-input :label="$t('integrations.fields.tax_number_value')" v-bind="props" source="value"></va-text-input>
                </v-col>
              </v-row>
            </va-array-input>
          </v-col>
          <v-col md="6" cols="12">
            <va-array-input :label="$t('integrations.fields.attributes')" :hint="$t('integrations.helper.company_address.attributes')" source="attributes_key_value" v-slot="props">
              <v-row>
                <v-col cols="6">
                  <validation-provider :name="$t('integrations.attributes.key')" rules="required" v-slot="{ validate, errors }">
                    <va-combobox-input
                      :label="$t('integrations.attributes.key')"
                      v-bind="props"
                      source="key"
                      :choices="CompanyAddressAttributeKeys"
                      item-text="name"
                      item-value="id"
                      @input="validate"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="6">
                  <va-text-input :label="$t('integrations.attributes.value')" v-bind="props" :readonly="props.item.key === 'packingSpaces'" source="value"></va-text-input>
                </v-col>
              </v-row>
            </va-array-input>
          </v-col>
        </v-row>
      </template>
      <template v-slot:footer>
        <div class="d-flex align-center"><va-save-button /><va-cancel-button class="ml-2" /></div>
      </template>
    </base-material-tabs-card>
  </validation-form>
</template>
<script>
import { AddressTypes, CompanyAddressAttributeKeys } from "@/resources/company-addresses/Helper";
import { TaxIdKeys } from "@/resources/addresses/TaxIdKeys";

export default {
  props: ["id", "title", "item", "isEdit"],
  data() {
    return {
      CompanyAddressAttributeKeys: CompanyAddressAttributeKeys,
      AddressTypes: AddressTypes,
      TaxIdKeys: TaxIdKeys
    };
  },
  computed: {
    tabs() {
      return [
        {
          id: "company_information",
          label: this.isEdit ? this.$t("integrations.address.title.edit", { label: this.id }) : this.$t("integrations.address.title.create")
        }
      ];
    }
  }
};
</script>
