<template>
  <base-material-card :icon="resource.icon" :title="title">
    <template v-slot:subtitle>
      <info-text :resource="resource" crud-type="list" />
    </template>
    <va-list :filters="filters" :sort-by="['created_at']" :sort-desc="[true]" disable-export>
      <template v-slot:actions="slotProps">
        <export-poll-button :resource="resource.name" text :options="slotProps.listState.options" :filter="slotProps.currentFilter" />
        <export-poll-button label="Export Histories" resource="budgetHistories" text :options="slotProps.listState.options" :filter="slotProps.currentFilter">
          <template v-slot:label>
            {{ $t("va.actions.export_history") }}
          </template>
        </export-poll-button>
      </template>
      <va-data-table :fields="fields" disable-show disable-clone></va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
import i18n from "@/i18n";
import { BudgetStateVariants, BudgetTypeVariants } from "./BudgetChoices";
import InfoText from "@/components/helper/InfoText";
import ExportPollButton from "@/components/export/ExportPollButton";

export default {
  components: { ExportPollButton, InfoText },
  props: ["resource", "title"],
  data() {
    return {
      fields: [
        {
          label: i18n.t("integrations.fields.code"),
          source: "code"
        },
        {
          label: i18n.t("integrations.fields.type_usage"),
          source: "type",
          type: "select",
          attributes: {
            choices: BudgetTypeVariants,
            itemText: "name",
            itemValue: "id"
          }
        },
        {
          label: i18n.t("integrations.fields.channel"),
          source: "channel_data",
          type: "reference",
          attributes: {
            reference: "channels",
            action: null
          }
        },
        {
          label: i18n.t("integrations.fields.state"),
          source: "state",
          type: "select",
          attributes: {
            choices: BudgetStateVariants,
            itemText: "name",
            itemValue: "id"
          }
        },
        {
          label: i18n.t("integrations.fields.original_amount"),
          source: "amount",
          type: "money-format",
          align: "right"
        },
        {
          label: i18n.t("integrations.fields.remaining"),
          source: "remaining",
          type: "money-format",
          align: "right"
        },
        {
          label: i18n.t("integrations.fields.expiry_date"),
          source: "expiry_date",
          type: "date"
        },
        {
          label: i18n.t("integrations.fields.created_at"),
          source: "created_at",
          type: "date",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.created_by"),
          source: "created_by",
          sortable: true
        }
      ],
      filters: [
        {
          label: i18n.t("integrations.fields.channel"),
          source: "channel",
          type: "autocomplete",
          alwaysOn: true,
          resettable: true,
          attributes: {
            reference: "channels_globals_as_defaults",
            searchQuery: "q",
            sortBy: ["name"]
          }
        },
        {
          label: i18n.t("integrations.fields.tenant"),
          source: "tenant",
          type: "autocomplete",
          resettable: true,
          attributes: {
            reference: "tenants_companies",
            searchQuery: "q",
            sortBy: ["name"]
          }
        },
        {
          label: i18n.t("integrations.fields.issuer"),
          source: "issuer",
          type: "autocomplete",
          resettable: true,
          attributes: {
            reference: "fulfiller_companies",
            searchQuery: "q",
            itemText: record => record.name + " (" + record.group + ")",
            sortBy: ["name"]
          }
        },
        {
          label: i18n.t("integrations.fields.cost_center"),
          source: "cost_center",
          resettable: true
        },
        {
          label: i18n.t("integrations.fields.state"),
          source: "state",
          type: "select",
          attributes: {
            choices: BudgetStateVariants,
            itemText: "name",
            itemValue: "id"
          },
          resettable: true
        },
        {
          label: i18n.t("integrations.fields.type_usage"),
          source: "type",
          type: "select",
          attributes: {
            choices: BudgetTypeVariants,
            itemText: "name",
            itemValue: "id"
          },
          resettable: true
        },
        {
          label: i18n.t("integrations.fields.restrict"),
          source: "restrict",
          resettable: true
        },
        {
          label: i18n.t("integrations.fields.source"),
          source: "source",
          resettable: true
        },
        {
          label: i18n.t("integrations.fields.expiry_date_from"),
          source: "_expiry_date_from",
          type: "date",
          attributes: {
            format: "short"
          },
          resettable: true
        },
        {
          label: i18n.t("integrations.fields.expiry_date_until"),
          source: "_expiry_date_to",
          type: "date",
          attributes: {
            format: "short"
          },
          resettable: true
        },
        {
          label: i18n.t("integrations.fields.created_at_from"),
          source: "_created_at_from",
          type: "date",
          resettable: true,
          alwaysOn: true,
          attributes: {
            format: "short"
          }
        },
        {
          label: i18n.t("integrations.fields.created_at_until"),
          source: "_created_at_to",
          type: "date",
          resettable: true,
          alwaysOn: true,
          attributes: {
            format: "short"
          }
        }
      ]
    };
  }
};
</script>
