<template>
  <base-material-card :icon="resource.icon" :title="title">
    <template v-slot:subtitle>
      <info-text :resource="resource" crud-type="list" />
    </template>
    <va-list disable-create disable-global-search disable-export :items-per-page="500">
      <va-data-table :fields="fields" disable-show disable-edit disable-clone disable-delete disable-select>
        <template v-slot:[`field.load`]="{ value }">
          <status-active-loaded :value="value" />
        </template>
        <template v-slot:[`field.active`]="{ value }">
          <status-active-loaded :value="value" />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <worker-log-button :item="item" />
          <worker-command-button :item="item" action="start" icon="mdi-play" />
          <worker-command-button :item="item" action="stop" icon="mdi-stop" />
          <worker-command-button :item="item" action="restart" icon="mdi-restart" />
        </template>
      </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
import WorkerCommandButton from "@/components/workers/WorkerCommandButton";
import InfoText from "@/components/helper/InfoText";
import StatusActiveLoaded from "@/components/workers/StatusActiveLoaded.vue";
import WorkerLogButton from "@/components/workers/WorkerLogButton.vue";

export default {
  components: { WorkerLogButton, StatusActiveLoaded, InfoText, WorkerCommandButton },
  props: ["resource", "title"],
  data() {
    return {
      fields: [
        {
          source: "load"
        },
        {
          source: "active"
        },
        {
          source: "system"
        },
        {
          source: "unit"
        },
        {
          source: "description"
        }
      ]
    };
  }
};
</script>
