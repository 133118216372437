<template>
  <div>
    <v-alert v-if="batchJobRun" outlined :color="statusColor(batchJobRun.status)" border="left">
      <template #prepend>
        <v-icon large class="mr-2" :color="statusColor(batchJobRun.status)">{{ statusIcon(batchJobRun.status) }}</v-icon>
      </template>
      <template>
        <ul v-if="batchJobRun.messages && batchJobRun.messages.length">
          <li v-for="(message, index) in batchJobRun.messages" :key="index" v-html="translateMessageObject(message, `integrations.batchjobs.messages.`)" />
        </ul>
        <span v-else> Status: {{ statusTranslation(batchJobRun.status) }} </span>
      </template>
    </v-alert>
    <v-btn class="ml-2" :to="{ name: 'batchjobs_list' }">
      {{ $t("integrations.batchjobs.actions.back_to_list") }}
    </v-btn>
  </div>
</template>
<script>
import StageStatusHelper from "@/resources/batchjobs/mixins/StageStatusHelper";
import TranslationHelper from "@/mixins/TranslationHelper";

export default {
  props: ["batchJobId", "batchRunId"],
  mixins: [StageStatusHelper, TranslationHelper],
  mounted() {
    this.getBatchRun();
  },
  computed: {
    batchJob() {
      return this.$store.state.batch.batchJob;
    },
    batchJobRun() {
      return this.$store.state.batch.batchJobRun;
    }
  },
  methods: {
    async getBatchRun() {
      await this.$store.dispatch("batch/loadBatchJobRun", {
        batchJobId: this.batchJobId,
        batchRunId: this.batchRunId
      });
    }
  }
};
</script>
