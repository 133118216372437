<template>
  <validation-form :id="id" :item="item">
    <base-material-tabs-card :tabs="tabs">
      <template v-slot:useraddresses_information>
        <v-row v-if="isEdit">
          <v-col>
            <v-input :label="$t('integrations.fields.user')" class="va-input">
              <div>
                <username-field :user-id="item.user" />
              </div>
            </v-input>
          </v-col>
          <v-col>
            <v-input :label="$t('integrations.fields.company')" class="va-input">
              <div>
                {{ item.company }}
              </div>
            </v-input>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col>
            <validation-provider :name="$t('integrations.fields.user')" rules="required" v-slot="{ validate, errors }">
              <va-autocomplete-input
                :readonly="isEdit"
                :autofocus="!isEdit"
                :label="$t('integrations.fields.user') + ' *'"
                source="user"
                reference="onboarded-users"
                :hint="!isEdit ? $t('integrations.helper.user_address.user') : ''"
                @input="validate"
                :render-callback="record => record.username + ' (' + record.company_name + ')'"
                :error-messages="errors"
                search-query="username"
                :sort-by="['username']"
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <va-text-input :label="$t('integrations.fields.first_name')" source="first_name" />
          </v-col>
          <v-col cols="6">
            <validation-provider :name="$t('integrations.fields.last_name')" rules="required" v-slot="{ validate, errors }">
              <va-text-input :label="$t('integrations.fields.last_name') + ' *'" source="last_name" @input="validate" :error-messages="errors" />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <va-text-input :label="$t('integrations.fields.organization')" source="organization" />
          </v-col>
          <v-col cols="6">
            <va-text-input :label="$t('integrations.fields.department')" source="department" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <validation-provider :name="$t('integrations.fields.street')" rules="required" v-slot="{ validate, errors }">
              <va-text-input :label="$t('integrations.fields.street') + ' *'" source="street" @input="validate" :error-messages="errors" />
            </validation-provider>
          </v-col>
          <v-col cols="6">
            <va-text-input :label="$t('integrations.fields.street2')" source="street2" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <validation-provider :name="$t('integrations.fields.postal_code')" rules="required" v-slot="{ validate, errors }">
              <va-text-input :label="$t('integrations.fields.postal_code') + ' *'" source="postal_code" @input="validate" :error-messages="errors" />
            </validation-provider>
          </v-col>
          <v-col cols="6">
            <validation-provider :name="$t('integrations.fields.city')" rules="required" v-slot="{ validate, errors }">
              <va-text-input :label="$t('integrations.fields.city') + ' *'" source="city" @input="validate" :error-messages="errors" />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <va-text-input :label="$t('integrations.fields.region_text')" source="region_text" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <validation-provider :name="$t('integrations.fields.country')" rules="required" v-slot="{ validate, errors }">
              <va-autocomplete-input
                :label="$t('integrations.fields.country') + ' *'"
                source="country"
                reference="countries"
                search-query="q"
                @input="validate"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <va-text-input :label="$t('integrations.fields.phone')" source="phone" />
          </v-col>
        </v-row>
        <v-row>
          <v-col md="6" cols="12">
            <va-array-input :label="$t('integrations.fields.tax')" source="tax_ids_key_value" v-slot="props">
              <v-row>
                <v-col cols="6">
                  <validation-provider :name="$t('integrations.fields.taxes_key')" rules="required" v-slot="{ validate, errors }">
                    <va-combobox-input
                      :label="$t('integrations.fields.tax_number_id')"
                      v-bind="props"
                      source="key"
                      :choices="TaxIdKeys"
                      item-text="name"
                      item-value="id"
                      @input="validate"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="6">
                  <va-text-input :label="$t('integrations.fields.tax_number_value')" v-bind="props" source="value"></va-text-input>
                </v-col>
              </v-row>
            </va-array-input>
          </v-col>
        </v-row>
      </template>
      <template v-slot:footer>
        <div class="d-flex align-center"><va-save-button /><va-cancel-button class="ml-2" /></div>
      </template>
    </base-material-tabs-card>
  </validation-form>
</template>
<script>
import UsernameField from "@/components/user/UsernameField";
import { TaxIdKeys } from "@/resources/addresses/TaxIdKeys";

export default {
  components: { UsernameField },
  props: ["id", "title", "item", "isEdit"],
  data() {
    return {
      TaxIdKeys: TaxIdKeys
    };
  },
  computed: {
    tabs() {
      return [
        {
          id: "useraddresses_information",
          label: this.isEdit ? this.$t("integrations.user_address.title.edit", { full_name: this.item.full_name }) : this.$t("integrations.user_address.title.create")
        }
      ];
    }
  }
};
</script>
