<template>
  <base-material-card :icon="resource.icon" :title="title">
    <template v-slot:subtitle>
      <info-text :resource="resource" crud-type="list" />
    </template>
    <va-list ref="batchJobList" :filters="filters" disable-export disable-create>
      <template v-slot:actions="slotProps">
        <export-poll-button :resource="resource.name" text :options="slotProps.listState.options" :filter="slotProps.currentFilter" />
      </template>
      <va-data-table :fields="fields" disable-edit disable-show disable-clone disable-delete item-key="identifiers.internal">
        <template v-slot:[`field.stock`]="{ item, value }">
          <ul v-if="value > 0">
            <li v-for="(warehouse, index) in item.inventories" :key="index">
              {{ warehouse.code }}:
              <router-link
                v-if="value"
                :to="{
                  name: `warehouseProducts_list`,
                  query: {
                    filter: JSON.stringify({
                      _warehouse_code: warehouse.code,
                      product_code: item.identifiers.internal
                    })
                  }
                }"
              >
                {{ warehouse.stock }}
              </router-link>
            </li>
          </ul>
          <span v-else>{{ value }}</span>
        </template>
      </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
import InfoText from "@/components/helper/InfoText";
import i18n from "@/i18n";
import { productFamilyVariants } from "@/resources/products/ProductFamilyVariants";
import ExportPollButton from "@/components/export/ExportPollButton";

export default {
  components: { ExportPollButton, InfoText },
  props: ["resource", "title"],
  computed: {
    fields() {
      return [
        {
          label: i18n.t("integrations.fields.sku"),
          source: "identifiers.internal"
        },
        {
          label: i18n.t("integrations.fields.name"),
          type: "product-name",
          source: "name"
        },
        {
          label: i18n.t("integrations.fields.family"),
          source: "family",
          type: "select",
          attributes: {
            choices: productFamilyVariants
          }
        },
        {
          label: i18n.t("integrations.fields.stock"),
          source: "stock",
          align: "right",
          type: "number"
        },
        {
          label: i18n.t("integrations.fields.enabled"),
          source: "enabled",
          type: "boolean"
        },
        {
          label: i18n.t("integrations.fields.tenant"),
          source: "tenant_data",
          type: "reference-array",
          attributes: {
            reference: "companies",
            action: this.$admin.can(["ROLE_ADMIN"]) ? "edit" : null
          }
        },
        {
          label: i18n.t("integrations.fields.distributor"),
          source: "distributor_data",
          type: "reference-array",
          attributes: {
            reference: "companies",
            action: this.$admin.can(["ROLE_ADMIN"]) ? "edit" : null
          }
        },
        {
          label: i18n.t("integrations.fields.supplier"),
          source: "supplier_data",
          type: "reference-array",
          attributes: {
            reference: "companies",
            action: this.$admin.can(["ROLE_ADMIN"]) ? "edit" : null
          }
        }
      ];
    }
  },
  data() {
    return {
      filters: [
        {
          label: i18n.t("integrations.fields.sku"),
          source: "variant_code",
          alwaysOn: true,
          resettable: true
        },
        {
          label: i18n.t("integrations.fields.tenant"),
          permissions: ["ROLE_ADMIN"],
          source: "preset",
          type: "autocomplete",
          alwaysOn: true,
          resettable: true,
          attributes: {
            reference: "tenants_companies",
            searchQuery: "q",
            sortBy: ["name"]
          }
        },
        {
          label: i18n.t("integrations.fields.supplier"),
          permissions: ["ROLE_ADMIN"],
          source: "supplier",
          type: "autocomplete",
          alwaysOn: true,
          resettable: true,
          attributes: {
            reference: "companies",
            searchQuery: "q",
            sortBy: ["name"]
          }
        },
        {
          label: i18n.t("integrations.fields.distributor"),
          permissions: ["ROLE_ADMIN"],
          source: "distributor",
          type: "autocomplete",
          alwaysOn: true,
          resettable: true,
          attributes: {
            reference: "companies",
            searchQuery: "q",
            sortBy: ["name"]
          }
        },
        {
          label: i18n.t("integrations.fields.family"),
          source: "family",
          type: "select",
          attributes: {
            choices: productFamilyVariants
          },
          alwaysOn: true,
          resettable: true
        }
      ]
    };
  }
};
</script>
