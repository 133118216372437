<template>
  <v-dialog width="700" @input="handleFormStatusChange($event)" v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
      <v-btn :disabled="item.messages === 0" color="error" text v-bind="attrs" v-on="on"> <v-icon left>mdi-trash-can</v-icon> {{ $t("va.actions.delete") }} </v-btn>
    </template>
    <v-card>
      <v-card-title>{{ $t("integrations.fields.delete_entry_permanent") }}</v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <queue-message :queue-item="lastQueueItem" />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="secondary" :disabled="deleting" @click="closeDialog">
          <v-icon left>mdi-close-circle</v-icon>
          {{ $t("va.actions.cancel") }}
        </v-btn>
        <v-btn :disabled="item.messages === 0" color="primary" :loading="deleting" @click="deleteQueueItem">
          <v-icon left>mdi-trash-can</v-icon>
          {{ $t("va.actions.delete") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Button from "@skuhnow/vuetify-admin/src/mixins/button";
import QueueMessage from "@/resources/queues/components/QueueMessage";

export default {
  components: { QueueMessage },
  props: ["item"],
  mixins: [Button],
  data() {
    return {
      dialog: false,
      lastQueueItem: null,
      deleting: false
    };
  },
  filters: {
    pretty: function(value) {
      return JSON.stringify(value, null, 2);
    }
  },
  methods: {
    async handleFormStatusChange(formOpen) {
      if (formOpen) {
        await this.loadLastQueueItem();
      }
    },
    async loadLastQueueItem() {
      const response = await this.$admin.http.get(`queues/${this.item.name}/messages/current`);
      if (response.data) {
        this.lastQueueItem = response.data;
      }
    },
    closeDialog() {
      this.dialog = false;
    },
    async deleteQueueItem() {
      this.deleting = true;

      this.$admin.http
        .delete(`queues/${this.item.name}/messages/current`)
        .then(() => {
          this.$store.commit(`messages/showToast`, {
            color: "success",
            message: `Queue Item deleted.`
          });
          this.$emit("queueItemDeleted");
        })
        .catch(() =>
          this.$store.commit(`messages/showToast`, {
            color: "error",
            message: `Error while deleting queue item`
          })
        )
        .finally(() => {
          this.deleting = false;
          this.closeDialog();
        });
    }
  }
};
</script>
