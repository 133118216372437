<template>
  <v-col>
    <v-row no-gutters>
      <v-col>
        <validation-provider :name="$t('integrations.fields.channel')" rules="required" v-slot="{ validate, errors }">
          <va-autocomplete-input
            :label="$t('integrations.fields.channel') + ' *'"
            v-model="attributes.channel"
            reference="channels_specific"
            item-value="id"
            search-query="q"
            :sort-by="['name']"
            :error-messages="errors"
            @input="updateAttributes"
          />
        </validation-provider>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <validation-provider
          :name="$t('integrations.fields.email_template')"
          :rules="{
            required: true,
            regex: /^[a-z0-9_-]+$/
          }"
          v-slot="{ errors }"
        >
          <v-text-field
            :label="$t('integrations.fields.email_template') + ' *'"
            v-model="attributes.email_template"
            :error-messages="errors"
            @input="updateAttributes"
            filled
          ></v-text-field>
        </validation-provider>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-checkbox v-model="attributes.test" :label="$t('integrations.field.test_order')" @change="updateAttributes" />
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
export default {
  props: ["value"],
  data() {
    return {
      attributes: {
        channel: "3i@default",
        test: false,
        email_template: "incentives-import"
      }
    };
  },
  mounted() {
    this.updateAttributes();
  },
  watch: {
    value: {
      deep: true,
      handler(newValue) {
        this.attributes = Object.assign({}, newValue);
      }
    }
  },
  methods: {
    updateAttributes() {
      this.$emit("input", this.attributes);
    }
  }
};
</script>
