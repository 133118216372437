<script>
import { HorizontalBar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;
import ChartDataLabels from "chartjs-plugin-datalabels";

export default {
  extends: HorizontalBar,
  mixins: [reactiveProp],
  props: ["options"],
  mounted() {
    this.addPlugin(ChartDataLabels);
    this.renderChart(this.chartData, this.options);
  }
};
</script>
