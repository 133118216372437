<template>
  <v-row>
    <v-col>
      <v-card>
        <v-stepper v-model="currentStep" vertical>
          <v-card-title>
            {{ $t("integrations.dashboard.status.title") }}
          </v-card-title>
          <v-list>
            <v-list-item>
              <text-field-readonly-copy :label="$t('integrations.dashboard.status.account.username')" :value="currentUser.username" />
            </v-list-item>
            <v-list-item class="pt-2">
              <text-field-readonly-copy :label="$t('integrations.dashboard.status.account.company_code')" :value="currentUser.company" />
            </v-list-item>
          </v-list>

          <!-- Account -->
          <v-stepper-step complete :color="colorFromCode(currentUser.status.account.code)" :edit-icon="iconFromCode(currentUser.status.account.code)" step="1">
            <div>
              <span v-html="$t(`integrations.dashboard.status.account.active.title`)" /><br />
              <small v-html="$t(`integrations.dashboard.status.account.active.subtitle`)" />
            </div>
          </v-stepper-step>
          <v-stepper-content step="1" />

          <!-- Integration -->
          <v-stepper-step
            complete
            :editable="currentUser.status.integration.code === 'active'"
            :color="colorFromCode(currentUser.status.integration.code)"
            :edit-icon="iconFromCode(currentUser.status.integration.code)"
            :complete-icon="iconFromCode(currentUser.status.integration.code)"
            step="2"
          >
            <div v-if="currentUser.status.integration.code === 'active'">
              <span v-html="$t(`integrations.dashboard.status.integration.active.title`)" /><br />
              <small v-html="$t(`integrations.dashboard.status.integration.active.subtitle`)" />
            </div>
            <div v-else>
              <span v-html="$t(`integrations.dashboard.status.integration.pending.title`)" /><br />
              <small v-html="$t(`integrations.dashboard.status.integration.pending.subtitle`)" />
            </div>
          </v-stepper-step>
          <v-stepper-content step="2">
            <v-card>
              <p v-html="$t('integrations.dashboard.status.integration.api_hint')"></p>
            </v-card>
          </v-stepper-content>

          <!-- Products -->
          <v-stepper-step
            :complete="stepProductsActive"
            :color="colorFromCode(currentUser.status.products.code)"
            :complete-icon="iconFromCode(currentUser.status.products.code)"
            step="3"
          >
            <div v-if="currentUser.status.products.code === 'active'">
              <span
                v-html="
                  $t(`integrations.dashboard.status.products.active.title`, {
                    count: currentUser.status.products.count
                  })
                "
              /><br />
              <small v-html="$t(`integrations.dashboard.status.products.active.subtitle`)" />
            </div>
            <div v-else>
              <span v-html="$t(`integrations.dashboard.status.products.pending.title`, { count: currentUser.status.products.count })" /><br />
              <small v-html="$t(`integrations.dashboard.status.products.pending.subtitle`)" />
            </div>
          </v-stepper-step>
          <v-stepper-content step="3" />

          <!-- OrderFulfillment -->
          <v-stepper-step
            :complete="stepOrderFulfillmentsActive"
            :color="colorFromCode(currentUser.status.fulfillments.code)"
            :complete-icon="iconFromCode(currentUser.status.fulfillments.code)"
            step="4"
          >
            <div>
              <span
                v-html="
                  $t(`integrations.dashboard.status.orderFulfillments.${currentUser.status.fulfillments.code}.title`, {
                    count: currentUser.status.fulfillments.count,
                    countOverdue: currentUser.status.fulfillments.count_overdue
                  })
                "
              /><br />
              <small v-html="$t(`integrations.dashboard.status.orderFulfillments.${currentUser.status.fulfillments.code}.subtitle`)" />
            </div>
          </v-stepper-step>
          <v-stepper-content step="4" />
        </v-stepper>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import TextFieldReadonlyCopy from "@/components/inputs/TextFieldReadonlyCopy";

export default {
  components: {
    TextFieldReadonlyCopy
  },
  data() {
    return {
      currentStep: 1
    };
  },
  computed: {
    stepProductsActive() {
      return this.currentUser.status.integration.code === "active";
    },
    stepOrderFulfillmentsActive() {
      return this.currentUser.status.products.code === "active";
    },
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  methods: {
    isComplete(code) {
      return code === "active" || code === "clear";
    },
    colorFromCode(code) {
      if (this.isComplete(code)) return "success";
      if (code === "waiting") return "info";

      return "warning";
    },
    iconFromCode(code) {
      if (this.isComplete(code)) return "mdi-check";
      if (code === "waiting") return "mdi-information-variant";

      return "mdi-exclamation";
    }
  }
};
</script>
