<template>
  <va-show-layout :title="title" disable-edit disable-clone disable-delete>
    <va-show :item="item">
      <base-material-tabs-card :tabs="tabs">
        <template v-slot:general_information>
          <show-general-informations :item="item" />
        </template>
        <template v-slot:items>
          <show-items :item="item" />
        </template>
        <template v-slot:ofm>
          <send-feedback :item="item" />
          <show-message-history :item="item" />
        </template>
      </base-material-tabs-card>
    </va-show>
  </va-show-layout>
</template>

<script>
import ShowGeneralInformations from "@/resources/order-fulfillments/ShowGeneralInformations";
import ShowItems from "@/resources/order-fulfillments/ShowItems";
import ShowMessageHistory from "@/resources/order-fulfillments/ShowMessageHistory";
import SendFeedback from "@/components/order-fulfillments/SendFeedback";

export default {
  components: {
    ShowMessageHistory,
    ShowItems,
    ShowGeneralInformations,
    SendFeedback
  },
  props: ["title", "item"],
  data() {
    return {
      tabs: [
        {
          id: "general_information",
          label: this.$t("integrations.order.form.general_information")
        },
        {
          id: "items",
          label: this.$t("integrations.order.form.items")
        },
        {
          id: "ofm",
          label: this.$t("integrations.order.form.ofm")
        }
      ]
    };
  }
};
</script>
