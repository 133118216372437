import i18n from "@/i18n";

const productFamilyVariants = [
  { value: "default", text: i18n.t("integrations.product.family.default") },
  { value: "dependent", text: i18n.t("integrations.product.family.dependent") },
  { value: "download", text: i18n.t("integrations.product.family.download") },
  { value: "dropshipping", text: i18n.t("integrations.product.family.dropshipping") },
  { value: "enquiry", text: i18n.t("integrations.product.family.enquiry") },
  { value: "fee", text: i18n.t("integrations.product.family.fee") },
  { value: "license", text: i18n.t("integrations.product.family.license") },
  { value: "on_demand", text: i18n.t("integrations.product.family.on_demand") },
  { value: "remote", text: i18n.t("integrations.product.family.remote") },
  { value: "voucher", text: i18n.t("integrations.product.family.voucher") },
  { value: "web2print", text: i18n.t("integrations.product.family.web2print") },
  { value: "web2produce", text: i18n.t("integrations.product.family.web2produce") }
];

export { productFamilyVariants };
