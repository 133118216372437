<template>
  <div>
    <va-list
      resource="useraddresses"
      :filter="{
        userId: item.id
      }"
      :sort-by="['created']"
      :sort-desc="[true]"
      disable-global-search
      disable-create
      disable-export
      disable-persistent-filter
    >
      <va-data-table :fields="fields" disable-show disable-clone disable-delete disable-select />
    </va-list>
  </div>
</template>
<script>
import i18n from "@/i18n";

export default {
  props: ["item"],
  created() {},
  data() {
    return {
      fields: [
        {
          label: i18n.t("integrations.fields.first_name"),
          source: "first_name",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.last_name"),
          source: "last_name",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.organization"),
          source: "organization",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.street"),
          source: "street",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.postal_code"),
          source: "postal_code",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.city"),
          source: "city",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.country"),
          source: "country"
        },
        {
          label: i18n.t("integrations.fields.created_at"),
          type: "date",
          source: "created",
          sortable: true
        }
      ]
    };
  }
};
</script>
