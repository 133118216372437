<template>
  <va-action-button :loading="batchJobStarting" icon="mdi-play" :label="$t('integrations.batchjobs.run_batchjob')" text @click="runJob" />
</template>
<script>
import Button from "@skuhnow/vuetify-admin/src/mixins/button";

export default {
  mixins: [Button],
  data() {
    return {
      batchJobStarting: false
    };
  },
  methods: {
    async runJob() {
      this.batchJobStarting = true;

      try {
        await this.$store.dispatch("batch/runJob", this.item.id);
        await this.$router.push({ path: this.$store.state.batch.currentRunLocation, query: { step: 1 } });
      } catch (e) {
        this.$admin.toast.error(e.message);
      } finally {
        this.batchJobStarting = false;
      }
    }
  }
};
</script>
