import i18n from "@/i18n";

const paymentMethodVariants = [
  { id: "balance", name: i18n.t("integrations.order.payment_method.balance") },
  { id: "budget", name: i18n.t("integrations.order.payment_method.budget") },
  { id: "cc", name: i18n.t("integrations.order.payment_method.cc") },
  { id: "costcenter", name: i18n.t("integrations.order.payment_method.costcenter") },
  { id: "debit", name: i18n.t("integrations.order.payment_method.debit") },
  { id: "delegate", name: i18n.t("integrations.order.payment_method.delegate") },
  { id: "free", name: i18n.t("integrations.order.payment_method.free") },
  { id: "giropay", name: i18n.t("integrations.order.payment_method.giropay") },
  { id: "invoice", name: i18n.t("integrations.order.payment_method.invoice") },
  { id: "paypal", name: i18n.t("integrations.order.payment_method.paypal") },
  { id: "prepayment", name: i18n.t("integrations.order.payment_method.prepayment") },
  { id: "punchout", name: i18n.t("integrations.order.payment_method.punchout") },
  { id: "sepa", name: i18n.t("integrations.order.payment_method.sepa") },
  { id: "sofortueberweisung", name: i18n.t("integrations.order.payment_method.sofortueberweisung") },
  { id: "voucher", name: i18n.t("integrations.order.payment_method.voucher") }
];

export { paymentMethodVariants };
