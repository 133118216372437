<template>
  <base-material-card :icon="resource.icon" :title="title">
    <template v-slot:subtitle>
      <info-text :resource="resource" crud-type="list" />
    </template>
    <va-list ref="queueList" disable-create disable-global-search disable-export :items-per-page="500">
      <va-data-table :fields="fields" disable-show disable-edit disable-clone disable-delete disable-select>
        <template v-slot:[`item.actions`]="{ item }">
          <queue-details :item="item" />
          <queue-delete-item :item="item" @queueItemDeleted="refreshList" />
        </template>
      </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
import i18n from "@/i18n";
import InfoText from "@/components/helper/InfoText";
import QueueDetails from "@/resources/queues/components/QueueDetails";
import QueueDeleteItem from "@/resources/queues/components/QueueDeleteItem";

export default {
  components: { QueueDeleteItem, QueueDetails, InfoText },
  props: ["resource", "title"],
  data() {
    return {
      fields: [
        {
          label: i18n.t("integrations.fields.name"),
          source: "name"
        },
        {
          label: i18n.t("integrations.fields.ready"),
          source: "messages_ready",
          type: "number"
        },
        {
          label: i18n.t("integrations.fields.unacknowledged"),
          source: "messages_unacknowledged",
          type: "number"
        },
        {
          label: i18n.t("integrations.fields.total"),
          source: "messages",
          type: "number"
        }
      ]
    };
  },
  methods: {
    refreshList() {
      this.$refs.queueList.fetchData();
    }
  }
};
</script>
