<template>
  <v-dialog v-model="showDialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon text v-bind="attrs" v-on="on">
        <v-icon>mdi-play</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-form @submit.prevent="runCommand">
        <v-card-title>{{ item.description }}</v-card-title>
        <v-card-actions>
          <v-btn @click="showOptionalParameters = !showOptionalParameters">
            {{ $t("integrations.command.form.show_optional_parameter") }}
            <v-icon>{{ showOptionalParameters ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
          </v-btn>
        </v-card-actions>
        <v-expand-transition>
          <div v-show="showOptionalParameters">
            <v-divider></v-divider>
            <v-card-text>
              <div v-html="$t(`integrations.command.form.optional_parameter_hint`)" />
              <v-file-input :label="$t('integrations.command.form.file_upload')" chips multiple counter show-size v-model="formData.files" />
              <v-text-field :label="$t('integrations.command.form.parameter')" v-model="formData.cli_params" :placeholder="paramsPlaceholder" />
            </v-card-text>
          </div>
        </v-expand-transition>
        <v-card-actions>
          <v-btn color="primary" :loading="isSubmitting" type="submit">{{ $t("integrations.btn.run") }}</v-btn>
          <v-btn @click="closeDialog">{{ $t("integrations.btn.cancel") }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import Button from "@skuhnow/vuetify-admin/src/mixins/button";
import admin from "@/plugins/admin";

export default {
  mixins: [Button],
  data() {
    return {
      isSubmitting: false,
      showDialog: false,
      showOptionalParameters: false,
      formData: {
        id: null,
        name: undefined,
        files: [],
        cli_params: ""
      }
    };
  },
  computed: {
    paramsPlaceholder() {
      return this.item.cli_params === undefined ? "--param=10 --param2=true Hallo" : this.item.cli_params;
    }
  },
  methods: {
    resetForm() {
      this.formData = {
        id: null,
        name: undefined,
        files: [],
        cli_params: ""
      };
    },
    openDialog() {
      this.resetForm();
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
    },
    runCommand() {
      this.isSubmitting = true;

      const formData = new FormData();
      formData.append("id", this.item.id);
      formData.append("name", this.item.name);
      if (this.formData.cli_params !== undefined) {
        formData.append("cli_params", this.formData.cli_params);
      }

      if (this.formData.files !== undefined) {
        this.formData.files.forEach(function(file) {
          formData.append("files[]", file);
        });
      }

      admin.http
        .post(`${process.env.VUE_APP_API_URL}/commands/${this.item.id}/runs`, formData)
        .then(() =>
          this.$store.commit(`messages/showToast`, {
            color: "success",
            message: `Command "${this.item.id}" is run.`
          })
        )
        .catch(() =>
          this.$store.commit(`messages/showToast`, {
            color: "error",
            message: `Error while executing command "${this.item.id}"`
          })
        )
        .finally(() => {
          this.isSubmitting = false;
          this.closeDialog();
        });
    }
  }
};
</script>
