import i18n from "@/i18n.js";

export default {
  methods: {
    stringToMoney(value) {
      let result = String(value);

      if (result.length === 0) {
        result = "000" + result;
      }

      if (result.length === 1) {
        result = "00" + result;
      }

      if (result.length === 2) {
        result = "0" + result;
      }

      return result.slice(0, -2) + "." + result.slice(-2 + Math.abs(0));
    },
    formatWithCurrency(value, currency) {
      return new Intl.NumberFormat(i18n.locale, {
        style: "currency",
        currency: currency
      }).format(value / 100);
    }
  }
};
