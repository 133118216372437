import admin from "@/plugins/admin";
import { GET_ONE, GET_LIST } from "@skuhnow/vuetify-admin/src/providers/data/actions";

export default {
  async validate(value, args) {
    let filter = {};
    let response;
    if (args.field === "id") {
      try {
        response = await admin.dataProvider[GET_ONE](args.resource, {
          id: value
        });
      } catch (e) {
        if (e.response.status === 404) return true;
        throw e;
      }
    } else {
      filter[args.field] = value;
      response = await admin.dataProvider[GET_LIST](args.resource, {
        filter: filter,
        pagination: { page: 1, perPage: 1 }
      });
    }
    return response.total === 0;
  },
  params: ["resource", "field"],
  message: "{_field_} is already taken"
};
