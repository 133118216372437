<template>
  <va-show-layout :title="title" disable-delete disable-edit disable-clone>
    <va-show :item="item">
      <v-row justify="center">
        <v-col sm="10">
          <v-card v-if="item">
            <v-card-text>
              <va-field source="state"><v-progress-circular v-if="pollingActive" color="primary" indeterminate></v-progress-circular> {{ item.state }}</va-field>
              <va-field source="created_at" type="date"></va-field>
              <va-field source="created_by" type="email"></va-field>
              <v-input :label="$t('integrations.fields.filename')" class="va-input">
                <div>
                  <files-endpoint-download v-if="item && item.result && item.result.filename" :uri="item.result.filename" />
                </div>
              </v-input>
              <va-display-json-field :label="$t('integrations.fields.payload')" source="payload" />
              <va-display-json-field :label="$t('integrations.fields.result')" source="result" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </va-show>
  </va-show-layout>
</template>
<script>
import FilesEndpointDownload from "../../components/Files/FilesEndpointDownload";

export default {
  components: { FilesEndpointDownload },
  props: ["title", "item"],
  created() {
    if (this.item.state === "running") {
      this.startPolling();
    }
  },
  destroyed() {
    this.stopPolling();
  },
  data() {
    return {
      pollingActive: false,
      pollingInterval: null
    };
  },
  methods: {
    startPolling() {
      this.pollingActive = true;
      this.pollingInterval = setInterval(
        function() {
          this.poll();
        }.bind(this),
        2000
      );
    },
    stopPolling() {
      clearInterval(this.pollingInterval);
      this.pollingActive = false;
    },
    async poll() {
      await this.$store.dispatch("api/refresh", this.$route.meta.resource);
      if (this.item && this.item.state !== "running") {
        this.stopPolling();
      }
    }
  }
};
</script>
