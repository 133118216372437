export default {
  methods: {
    translateMessage(message, params) {
      const translationKey = message;
      const translation = this.$t(translationKey, params || {});
      if (translationKey === translation) {
        return message;
      }
      return translation;
    },
    translateMessageObject(messageObject, translationKeyPrefix) {
      if (typeof messageObject === "object") {
        if ("text" in messageObject) {
          return messageObject.text;
        }
        return this.$t(`${translationKeyPrefix}${messageObject.key}`, messageObject.params);
      }
      return messageObject;
    }
  }
};
