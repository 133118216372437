<template>
  <v-select :items="items" :value="value" @input="$emit('input', $event)" clearable :label="$t('integrations.fields.status')" />
</template>
<script>
import StageStatusHelper from "@/resources/batchjobs/mixins/StageStatusHelper";

export default {
  mixins: [StageStatusHelper],
  props: ["value"],
  computed: {
    items() {
      return Object.keys(this.statusList).map(status => {
        return {
          text: this.statusTranslation(status),
          value: status
        };
      });
    }
  }
};
</script>
