<template>
  <va-show-layout :title="title" disable-edit disable-clone disable-delete>
    <va-show :item="item">
      <base-material-tabs-card :tabs="tabs">
        <template v-slot:content><show-content :item="item" /> </template>
        <template v-slot:details><show-details :item="item" /> </template>
        <template v-slot:history><show-history :item="item" /> </template>
      </base-material-tabs-card>
    </va-show>
  </va-show-layout>
</template>

<script>
import ShowContent from "@/resources/contactrequests/ShowContent";
import ShowDetails from "@/resources/contactrequests/ShowDetails";
import ShowHistory from "@/resources/contactrequests/ShowHistory";

export default {
  components: { ShowHistory, ShowDetails, ShowContent },
  props: ["title", "item"],
  data() {
    return {
      tabs: [
        {
          id: "content",
          label: this.$t("integrations.contact_request.form.content")
        },
        {
          id: "details",
          label: this.$t("integrations.contact_request.form.details")
        },
        {
          id: "history",
          label: this.$t("integrations.contact_request.form.history")
        }
      ]
    };
  }
};
</script>
