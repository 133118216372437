<template>
  <validation-form :id="id" :item="item">
    <base-material-tabs-card :tabs="tabs">
      <template v-slot:user_information>
        <v-row>
          <v-col>
            <va-boolean-input :label="$t('integrations.fields.enabled')" source="enabled" :hint="enabledHint" v-model="enabledStatus" />
          </v-col>
        </v-row>
        <v-row>
          <v-col :md="isEdit ? 4 : 6" cols="12">
            <validation-provider :name="$t('integrations.fields.email')" rules="required" v-slot="{ validate, errors }">
              <va-text-input
                :autofocus="!isEdit"
                :label="$t('integrations.fields.email') + ' *'"
                source="email"
                type="email"
                @input="validate"
                :error-messages="errors"
              ></va-text-input>
            </validation-provider>
          </v-col>
          <v-col :md="isEdit ? 4 : 6" cols="12">
            <!-- Company -->
            <validation-provider v-if="!isEdit" :name="$t('integrations.fields.company')" rules="required" v-slot="{ validate, errors }">
              <va-autocomplete-input
                :label="$t('integrations.fields.company') + ' *'"
                source="company"
                reference="companies"
                :hint="$t('integrations.helper.user.company')"
                @input="validate"
                :error-messages="errors"
                search-query="name"
                :render-callback="record => record.name + ' (' + record.group + ')'"
                :sort-by="['name']"
              ></va-autocomplete-input>
            </validation-provider>
            <va-autocomplete-input v-else readonly :label="$t('integrations.fields.company') + ' *'" source="company" reference="companies"></va-autocomplete-input>
          </v-col>
          <v-col md="4" cols="12" v-if="isEdit">
            <va-text-input :label="$t('integrations.fields.channel')" disabled source="channel"></va-text-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4" cols="12">
            <!-- Firstname -->
            <va-text-input :label="$t('integrations.fields.first_name')" source="first_name"></va-text-input>
          </v-col>
          <v-col md="4" cols="12">
            <!-- Second firstname -->
            <va-text-input :label="$t('integrations.fields.middle_name')" source="middle_name"></va-text-input>
          </v-col>
          <v-col md="4" cols="12">
            <!-- surname -->
            <va-text-input :label="$t('integrations.fields.last_name')" source="last_name"></va-text-input>
          </v-col>
        </v-row>
        <!-- language -->
        <validation-provider :name="$t('integrations.fields.language')" rules="required" v-slot="{ validate, errors }">
          <va-autocomplete-input
            :label="$t('integrations.fields.language')"
            source="locale"
            reference="locales"
            search-query="name"
            :hint="$t('integrations.helper.user.language')"
            :sort-by="['name']"
            @input="validate"
            :error-messages="errors"
          />
        </validation-provider>
        <!-- currency -->
        <va-select-input :label="$t('integrations.fields.currency')" source="currency" reference="currencies" item-text="name" item-value="id"></va-select-input>
        <!-- origin country -->
        <va-autocomplete-input :label="$t('integrations.fields.origin_country')" source="country_origin" reference="countries" search-query="q"></va-autocomplete-input>
        <!-- origin id -->
        <va-text-input :label="$t('integrations.fields.id_origin')" source="id_origin"></va-text-input>
        <!-- organization id -->
        <va-text-input :label="$t('integrations.fields.id_organization')" source="organization"></va-text-input>
        <!-- organization unit -->
        <va-text-input :label="$t('integrations.fields.company_unit')" source="company_unit"></va-text-input>
        <!-- costcenter -->
        <va-text-input :label="$t('integrations.fields.costcenter')" source="costcenter" :hint="$t('integrations.helper.user.cost_center')"></va-text-input>
        <!-- socialsecuritynumber -->
        <va-text-input :label="$t('integrations.fields.ssn')" source="ssn"></va-text-input>
        <!-- phone -->
        <va-text-input :label="$t('integrations.fields.phone')" source="phone"></va-text-input>
        <!-- mobile -->
        <va-text-input :label="$t('integrations.fields.mobile')" source="mobile"></va-text-input>
        <va-array-input :label="$t('integrations.fields.attributes')" source="attributes_key_value" v-slot="props">
          <v-row>
            <v-col cols="6">
              <validation-provider :name="$t('integrations.attributes.key')" rules="required" v-slot="{ validate, errors }">
                <va-combobox-input
                  :label="$t('integrations.attributes.key')"
                  v-bind="props"
                  source="key"
                  :choices="UserAttributeKeys"
                  item-text="name"
                  item-value="id"
                  @input="validate"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col cols="6">
              <va-text-input :label="$t('integrations.attributes.value')" v-bind="props" source="value" />
            </v-col>
          </v-row>
        </va-array-input>
      </template>
      <template v-slot:groups_roles>
        <v-row>
          <v-col cols="6">
            <va-autocomplete-input
              v-if="isEdit && item && item.company_group === 'tenant'"
              :label="$t('integrations.fields.groups')"
              source="groups"
              reference="usergroups"
              :filter="{ tenantOrNull: item.company }"
              search-query="name"
              :multiple="true"
              :sort-by="['name']"
              :hint="$t('integrations.helper.user.groups')"
            />
            <va-autocomplete-input
              v-else
              :label="$t('integrations.fields.groups')"
              source="groups"
              reference="usergroups"
              search-query="name"
              :multiple="true"
              :sort-by="['name']"
              :hint="$t('integrations.helper.user.groups')"
            />
          </v-col>
          <v-col cols="6">
            <va-autocomplete-input
              :label="$t('integrations.fields.roles')"
              source="roles"
              reference="userroles"
              search-query="label"
              :multiple="true"
              :sort-by="['label']"
              :filter="{ allowed_for_creation: 1 }"
              :hint="$t('integrations.helper.user.roles')"
            ></va-autocomplete-input>
          </v-col>
        </v-row>
      </template>
      <template v-slot:addresses>
        <user-addresses :item="item" />
      </template>
      <template v-slot:orders>
        <user-orders :item="item" />
      </template>
      <template v-slot:history>
        <audit-history v-if="item" resource="userHistories" :filter="{ userId: item.id }" :item="item" />
      </template>
      <template v-slot:temporaryReplacements>
        <user-temporary-replacements :item="item" />
      </template>
      <template v-slot:footer>
        <div class="d-flex align-center">
          <va-save-button /><va-cancel-button class="ml-2" />
          <reset-password class="ml-2" v-if="isEdit" :user-id="item.id"></reset-password>
        </div>
      </template>
    </base-material-tabs-card>
  </validation-form>
</template>

<script>
import ResetPassword from "@/components/user/ResetPassword";
import UserAddresses from "@/resources/users/UserAddresses";
import UserOrders from "@/resources/users/UserOrders";
import UserTemporaryReplacements from "@/resources/users/UserTemporaryReplacements.vue";
import AuditHistory from "@/components/audit/AuditHistory.vue";
import UserAttributeKeys from "@/resources/users/UserAttributeKeys.js";

export default {
  components: { AuditHistory, UserTemporaryReplacements, UserOrders, UserAddresses, ResetPassword },
  props: ["id", "item", "isEdit"],
  data() {
    return {
      dialog: false,
      UserAttributeKeys: UserAttributeKeys,
      enabledStatus: false
    };
  },
  computed: {
    enabledHint() {
      if (this.isEdit) return "";
      if (this.enabledStatus) return "";
      return this.$t("integrations.user.form.doi_warning");
    },
    isTenant() {
      return this.item && this.item.company_group === "tenant";
    },
    tabs() {
      let tabs = [
        {
          id: "user_information",
          label: this.$t("integrations.user.form.user_information")
        },
        {
          id: "groups_roles",
          label: this.$t("integrations.user.form.groups_roles")
        }
      ];
      if (this.isEdit) {
        tabs.push({
          id: "addresses",
          label: this.$t("integrations.user.form.addresses")
        });

        if (this.item.company_group === "tenant" && this.item.email) {
          tabs.push({
            id: "orders",
            label: this.$t("integrations.user.form.orders")
          });
        }

        tabs.push({
          id: "history",
          label: this.$t("integrations.user.form.history")
        });

        tabs.push({
          id: "temporaryReplacements",
          label: this.$t("integrations.user.form.temporary_replacements")
        });
      }

      return tabs;
    }
  }
};
</script>
