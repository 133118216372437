<template>
  <v-row v-if="hasChannels">
    <v-col>
      <v-card>
        <v-card-title>{{ $t("integrations.fields.channels") }}</v-card-title>
        <v-list>
          <v-list-item two-line v-for="channel in channelsNoGlobals" :key="channel.id">
            <v-list-item-content>
              <v-list-item-title>
                {{ channel.id }}
                <v-btn class="ml-2" @click="copyToClipboard(channel.id)" x-small text outlined><v-icon x-small>mdi-content-copy</v-icon></v-btn>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn @click="goToView(channel)" small text outlined><v-icon>mdi-eye</v-icon></v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      channels: null
    };
  },
  async created() {
    this.loadChannels();
  },
  computed: {
    hasChannels() {
      return this.channels && this.channels.length > 0;
    },
    channelsNoGlobals() {
      return this.channels.filter(channel => channel.region !== null);
    }
  },
  methods: {
    async loadChannels() {
      let { data } = await this.$store.dispatch(`channels_globals_as_defaults/getList`);
      this.channels = data;
    },
    goToView(channel) {
      this.$router.push({
        name: "orderFulfillments_list",
        query: {
          filter: JSON.stringify({ channel: channel.id, test: "false" })
        }
      });
    },
    copyToClipboard(value) {
      this.$copyText(value).then(() => {
        this.$store.commit(`messages/showToast`, {
          color: "success",
          message: `Text copied!`
        });
      });
    }
  }
};
</script>
