<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>{{ $t("integrations.auth.not_logged_in.title") }}</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn @click="logout">{{ $t("integrations.auth.not_logged_in.saml_logout") }}</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
    }
  }
};
</script>
