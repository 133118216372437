<template>
  <base-material-card :icon="resource.icon" :title="title">
    <template v-slot:subtitle>
      <info-text :resource="resource" crud-type="list" />
    </template>
    <va-list :filters="filters" :include="{ expand: ['tenant'] }" :sort-by="['created_at']" :sort-desc="['true']" disable-export>
      <template v-slot:actions="slotProps">
        <export-poll-button :resource="resource.name" text :options="slotProps.listState.options" :filter="slotProps.currentFilter" />
        <export-poll-button label="Export Histories" resource="userHistories" text :options="slotProps.listState.options" :filter="slotProps.currentFilter">
          <template v-slot:label>
            {{ $t("va.actions.export_history") }}
          </template>
        </export-poll-button>
      </template>
      <va-data-table :fields="fields" disable-show dense disable-clone>
        <template v-slot:[`item.actions`]="{ item }">
          <login-as-btn :item="item"></login-as-btn>
        </template>
      </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
import i18n from "@/i18n";
import InfoText from "@/components/helper/InfoText";
import ExportPollButton from "../../components/export/ExportPollButton";

export default {
  components: { ExportPollButton, InfoText },
  props: ["resource", "title"],
  data() {
    return {
      fields: [
        {
          label: i18n.t("integrations.fields.username"),
          source: "username",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.email"),
          source: "email",
          type: "email",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.first_name"),
          source: "first_name",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.last_name"),
          source: "last_name",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.channel"),
          source: "channel_data",
          type: "reference",
          attributes: {
            reference: "channels",
            action: null
          }
        },
        {
          label: i18n.t("integrations.fields.id_origin"),
          source: "id_origin",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.costcenter"),
          source: "costcenter",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.source"),
          source: "source",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.origin_country"),
          source: "country_origin",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.created_at"),
          source: "created_at",
          type: "date",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.last_login"),
          source: "last_login",
          type: "date",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.enabled"),
          source: "enabled",
          type: "boolean"
        },
        {
          label: i18n.t("integrations.fields.confirmed"),
          source: "confirmed",
          type: "boolean"
        }
      ],
      filters: [
        {
          label: i18n.t("integrations.fields.channel"),
          source: "channel",
          type: "autocomplete",
          alwaysOn: true,
          resettable: true,
          attributes: {
            reference: "channels_globals_as_defaults",
            searchQuery: "q",
            sortBy: ["name"]
          }
        },
        {
          label: i18n.t("integrations.fields.company"),
          source: "company",
          type: "autocomplete",
          alwaysOn: true,
          resettable: true,
          attributes: {
            reference: "companies",
            searchQuery: "q",
            sortBy: ["name"]
          }
        },
        {
          label: i18n.t("integrations.fields.roles"),
          source: "roles",
          type: "autocomplete",
          resettable: true,
          attributes: {
            multiple: true,
            reference: "userroles",
            itemText: "label",
            searchQuery: "q",
            sortBy: ["label"]
          }
        },
        {
          label: i18n.t("integrations.fields.groups"),
          source: "groups",
          type: "autocomplete",
          resettable: true,
          attributes: {
            multiple: true,
            reference: "usergroups",
            searchQuery: "q",
            sortBy: ["name"]
          }
        },
        {
          label: i18n.t("integrations.fields.cost_center"),
          source: "costcenter",
          alwaysOn: true,
          resettable: true
        },
        {
          label: i18n.t("integrations.fields.id_origin"),
          source: "id_origin",
          resettable: true
        },
        {
          label: i18n.t("integrations.fields.source"),
          source: "source",
          resettable: true
        },
        {
          label: i18n.t("integrations.fields.company_unit"),
          source: "company_unit",
          resettable: true
        },
        {
          label: i18n.t("integrations.fields.id_organization"),
          source: "organization",
          resettable: true
        },
        {
          label: i18n.t("integrations.fields.enabled"),
          source: "enabled",
          type: "select",
          attributes: {
            choices: [
              { id: 1, name: i18n.t("integrations.fields.yes") },
              { id: 0, name: i18n.t("integrations.fields.no") }
            ],
            itemText: "name",
            itemValue: "id"
          }
        },
        {
          label: i18n.t("integrations.fields.confirmed"),
          source: "confirmed",
          type: "select",
          attributes: {
            choices: [
              { id: 1, name: i18n.t("integrations.fields.yes") },
              { id: 0, name: i18n.t("integrations.fields.no") }
            ],
            itemText: "name",
            itemValue: "id"
          }
        },
        {
          label: i18n.t("integrations.fields.origin_country"),
          source: "country_origin",
          type: "autocomplete",
          resettable: true,
          attributes: {
            reference: "countries",
            searchQuery: "q"
          }
        },
        {
          label: i18n.t("integrations.fields.created_since"),
          type: "date",
          source: "created_at",
          resettable: true
        },
        {
          label: i18n.t("integrations.fields.last_login_since"),
          type: "date",
          source: "last_login",
          resettable: true
        },
        {
          label: i18n.t("integrations.fields.newsletter"),
          source: "newsletter",
          type: "select",
          attributes: {
            choices: [
              { id: "subscribed", name: i18n.t("integrations.fields.subscribed") },
              { id: "unsubscribed", name: i18n.t("integrations.fields.unsubscribed") }
            ],
            itemText: "name",
            itemValue: "id"
          }
        }
      ],
      asideTitle: null,
      id: null,
      item: null,
      create: false,
      edit: false
    };
  }
};
</script>
