<template>
  <base-material-card :icon="resource.icon" :title="title">
    <template v-slot:subtitle>
      <info-text :resource="resource" crud-type="list" />
    </template>
    <va-list :filters="filters" disable-export>
      <va-data-table :fields="fields" disable-show disable-clone :can-edit="canEditDelete" :can-delete="canEditDelete">
        <template v-slot:[`field.ssl_monitor`]="{ value }">
          <ssl-monitor :status="value" />
        </template>
        <template v-slot:[`field.full_qualified_domain_name`]="{ value }">
          <div class="d-flex justify-space-between align-center">
            <span>{{ value }}</span> <v-btn icon small text color="primary" @click="openInNewTab(value)"><v-icon>mdi-open-in-new</v-icon></v-btn>
          </div>
        </template>
      </va-data-table>
    </va-list>
  </base-material-card>
</template>
<script>
import i18n from "@/i18n";
import InfoText from "@/components/helper/InfoText";
import SslMonitor from "@/components/domains/SslMonitor.vue";

export default {
  components: { SslMonitor, InfoText },
  props: ["resource", "title"],
  data() {
    return {
      fields: [
        {
          label: i18n.t("integrations.fields.domain"),
          source: "full_qualified_domain_name"
        },
        {
          label: i18n.t("integrations.fields.company"),
          source: "company_data",
          type: "reference",
          sortable: true,
          attributes: {
            reference: "companies",
            action: null
          }
        },
        {
          label: i18n.t("integrations.fields.sso_connection"),
          source: "sso_connection",
          type: "boolean"
        },
        {
          label: i18n.t("integrations.fields.reachable"),
          source: "reachable",
          type: "boolean"
        },
        {
          label: i18n.t("integrations.fields.uptime_monitor"),
          source: "uptime_monitor"
        },
        {
          label: i18n.t("integrations.fields.ssl_monitor"),
          source: "ssl_monitor"
        },
        {
          label: i18n.t("integrations.fields.visitor_tracking_id"),
          source: "visitor_tracking_id"
        }
      ],
      filters: [
        {
          label: i18n.t("integrations.fields.domain"),
          source: "full_qualified_domain_name",
          alwaysOn: true,
          resettable: true
        },
        {
          label: i18n.t("integrations.fields.company"),
          source: "company",
          type: "autocomplete",
          alwaysOn: true,
          resettable: true,
          attributes: {
            reference: "companies",
            searchQuery: "q",
            sortBy: ["name"]
          }
        }
      ]
    };
  },
  methods: {
    canEditDelete(item) {
      return item && !!item.id;
    },
    openInNewTab(url) {
      let fullUrl = url;
      if (!/^https?:\/\//i.test(url)) {
        fullUrl = "https://" + url;
      }
      window.open(fullUrl, "_blank").focus();
    }
  }
};
</script>
