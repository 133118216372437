<template>
  <div v-if="$admin.can('ROLE_ADMIN')">
    <h1>REST Client</h1>
    <v-text-field label="Method" v-model="method" />
    <v-text-field label="Endpoint" v-model="endpoint" />
    <v-textarea label="Payload" v-model="payload" placeholder="Enter Codes, one for every row" />
    <v-btn @click="execute" :loading="isRunning">Run</v-btn>

    <div>
      <h2>Result</h2>
      {{ response }}
    </div>
  </div>
</template>
<script>
import admin from "@/plugins/admin.js";

export default {
  data() {
    return {
      method: "GET",
      endpoint: "/users",
      payload: "",
      isRunning: false,
      response: null
    };
  },
  methods: {
    async execute() {
      this.isRunning = true;
      this.response = null;
      try {
        const request = {
          method: this.method,
          url: this.endpoint
        };
        if (this.payload) {
          request.data = JSON.parse(this.payload);
        }
        this.response = await admin.http(request);
      } catch (e) {
        this.response = e;
      } finally {
        this.isRunning = false;
      }
    }
  }
};
</script>
