<template>
  <validation-form :id="id" :item="item">
    <base-material-tabs-card :tabs="tabs">
      <template #budget_information>
        <v-row>
          <v-col md="6" cols="12">
            <validation-provider :name="$t('integrations.fields.channel')" rules="required" v-slot="{ validate, errors }">
              <va-autocomplete-input
                :label="$t('integrations.fields.channel') + ' *'"
                source="channel"
                reference="channels_globals_as_defaults"
                item-value="id"
                search-query="q"
                :sort-by="['name']"
                @input="validate"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
          <v-col md="6" cols="12">
            <validation-provider :name="$t('integrations.fields.state')" rules="required" v-slot="{ validate, errors }">
              <va-select-input
                :label="$t('integrations.fields.state') + ' *'"
                source="state"
                :hint="$t('integrations.helper.budget.state')"
                :choices="BudgetStateVariants"
                item-text="name"
                item-value="id"
                @input="validate"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="6" cols="12">
            <validation-provider
              :name="$t('integrations.fields.code')"
              :rules="{
                max: 32,
                required: true,
                regex: /^[a-zA-Z0-9_-]+$/,
                uniqueValidator: !isEdit ? { resource: 'budgets', field: 'codeEquals' } : false
              }"
              v-slot="{ validate, errors }"
            >
              <va-text-input
                :disabled="isEdit"
                :label="$t('integrations.fields.code') + ' *'"
                :hint="$t('integrations.helper.budget.code')"
                source="code"
                @input="validate"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
          <v-col md="6" cols="12">
            <va-autocomplete-input
              :label="$t('integrations.fields.issuer')"
              :hint="$t('integrations.helper.budget.issuer')"
              source="issuer"
              reference="fulfiller_companies"
              item-value="code"
              :render-callback="record => record.name + ' (' + record.group + ')'"
              search-query="name"
              :sort-by="['name']"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col :md="isEdit ? 4 : 6" cols="12">
            <validation-provider :name="$t('integrations.fields.original_amount')" rules="required|min_value:0" v-slot="{ validate, errors }">
              <va-number-input
                :autofocus="!isEdit"
                :label="$t('integrations.fields.original_amount') + ' *'"
                :hint="$t('integrations.helper.budget.amount')"
                source="amount"
                @input="validate"
                :error-messages="errors"
                :parse="v => v / 100"
                :format="v => parseFloat(v) * 100"
              />
            </validation-provider>
          </v-col>
          <v-col md="4" cols="12" v-if="isEdit">
            <validation-provider :name="$t('integrations.fields.remaining')" rules="min_value:0" v-slot="{ validate, errors }">
              <va-number-input
                :label="$t('integrations.fields.remaining')"
                source="remaining"
                @input="validate"
                :error-messages="errors"
                :parse="v => v / 100"
                :format="v => parseFloat(v) * 100"
              />
            </validation-provider>
          </v-col>
          <v-col :md="isEdit ? 4 : 6" cols="12">
            <validation-provider :name="$t('integrations.fields.currency')" rules="required" v-slot="{ validate, errors }">
              <va-select-input
                :label="$t('integrations.fields.currency') + ' *'"
                source="currency"
                reference="currencies"
                item-text="name"
                item-value="id"
                @input="validate"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="6" cols="12">
            <va-text-input :label="$t('integrations.fields.reference')" source="reference" :hint="$t('integrations.helper.budget.reference')" />
          </v-col>
          <v-col md="6" cols="12">
            <va-text-input :label="$t('integrations.fields.source')" source="source" :hint="$t('integrations.helper.budget.source')" />
          </v-col>
        </v-row>
        <v-row>
          <v-col md="6" cols="12">
            <validation-provider :name="$t('integrations.fields.type_usage')" rules="required" v-slot="{ validate, errors }">
              <va-select-input
                :label="$t('integrations.fields.type_usage') + ' *'"
                source="type"
                :hint="$t('integrations.helper.budget.usage_type')"
                :choices="BudgetTypeVariants"
                item-text="name"
                item-value="id"
                @input="validate"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
          <v-col md="6" cols="12">
            <validation-provider :name="$t('integrations.fields.scope')" rules="required" v-slot="{ validate, errors }">
              <va-select-input
                :label="$t('integrations.fields.scope') + ' *'"
                source="scope"
                :hint="$t('integrations.helper.budget.scope')"
                :choices="BudgetScopeVariants"
                item-text="name"
                item-value="id"
                @input="validate"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="6" cols="12">
            <validation-provider
              :name="$t('integrations.fields.restrict')"
              :rules="{
                regex: /^([A-Z0-9\-_]+)(=[1-9]\d*)?(,([A-Z0-9\-_]+)(=[1-9]\d*)?)*$/
              }"
              v-slot="{ validate, errors }"
            >
              <va-text-input :label="$t('integrations.fields.restrict')" source="restrict" :hint="restrictHint" @input="validate" :error-messages="errors" />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="6" cols="12">
            <validation-provider
              :name="$t('integrations.fields.username')"
              :rules="{
                regex: /^[A-Za-z0-9!#$%&'*+\-\/=?^_`{|}~@.]+$/
              }"
              v-slot="{ validate, errors }"
            >
              <va-text-input
                :label="$t('integrations.fields.username')"
                source="username"
                @input="validate"
                :error-messages="errors"
                :hint="$t('integrations.helper.budget.username')"
              />
            </validation-provider>
          </v-col>
          <v-col md="6" cols="12">
            <va-text-input :label="$t('integrations.fields.cost_center')" source="cost_center" :hint="$t('integrations.helper.budget.cost_center')" />
          </v-col>
        </v-row>
        <v-row>
          <v-col :md="isEdit ? 6 : 12" cols="12">
            <va-date-input :label="$t('integrations.fields.expiry_date')" :clearable="true" source="expiry_date" :hint="$t('integrations.helper.budget.expiry_date')" />
          </v-col>
          <v-col md="3" cols="12" v-if="isEdit">
            <va-date-input :label="$t('integrations.fields.created_at')" :disabled="true" source="created_at" />
          </v-col>
          <v-col md="3" cols="12" v-if="isEdit">
            <va-text-input :label="$t('integrations.fields.created_by')" :disabled="true" source="created_by" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <va-text-input :label="$t('integrations.fields.comment')" source="comment" />
          </v-col>
        </v-row>
        <v-row v-if="isEdit">
          <v-col>
            <va-text-input :label="$t('integrations.fields.usages')" source="usages" />
          </v-col>
        </v-row>
      </template>
      <template v-slot:history>
        <audit-history resource="budgetHistories" :filter="{ budgetId: item.id }" :item="item" />
      </template>
      <template #documents>
        <show-documents :item="item" />
      </template>
      <template #footer>
        <div class="d-flex align-center">
          <va-save-button />
          <va-cancel-button class="ml-2" />
        </div>
      </template>
    </base-material-tabs-card>
  </validation-form>
</template>
<script>
import { BudgetScopeVariants, BudgetStateVariants, BudgetTypeVariants } from "./BudgetChoices";
import AuditHistory from "@/components/audit/AuditHistory.vue";
import ShowDocuments from "@/resources/budgets/ShowDocuments.vue";

export default {
  components: { ShowDocuments, AuditHistory },
  props: ["id", "title", "item", "isEdit"],
  data() {
    return {
      BudgetStateVariants: BudgetStateVariants,
      BudgetTypeVariants: BudgetTypeVariants,
      BudgetScopeVariants: BudgetScopeVariants,
      allDayActive: false
    };
  },
  computed: {
    tabs() {
      let tabs = [
        {
          id: "budget_information",
          label: this.isEdit ? this.$t("integrations.budget.title.edit", { code: this.item.id }) : this.$t("integrations.budget.title.create")
        }
      ];

      if (this.isEdit) {
        tabs.push({
          id: "history",
          label: this.$t("integrations.user.form.history")
        });
        if (this.item.documents && this.item.documents.length) {
          tabs.push({
            id: "documents",
            label: this.$t("integrations.fields.documents")
          });
        }
      }

      return tabs;
    },
    restrictHint() {
      return `${this.$t("integrations.fields.restrict_helper_text")} https://projects.mcs-promotion.com/confluence/display/DOC/Feature+Budget+management`;
    }
  }
};
</script>
