<template>
  <base-material-card :icon="resource.icon" :title="title">
    <template v-slot:subtitle>
      <info-text i18n="integrations.confirmation-requests.title.list" />
    </template>
    <va-list :filters="filters" disable-create :sort-by="['created_at']" :sort-desc="['true']" disable-export>
      <template v-slot:actions="slotProps">
        <export-poll-button :resource="resource.name" text :options="slotProps.listState.options" :filter="slotProps.currentFilter" />
      </template>
      <va-data-table :fields="fields" disable-edit disable-clone disable-delete disable-select>
        <template v-slot:[`field.time_left`]="{ item }">
          <valid-until-progress v-if="showProgressBar(item)" :item="item" />
        </template>
      </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
import { isPast, subYears } from "date-fns";
import i18n from "@/i18n";
import { ConfirmationRequestTypeVariants, ConfirmationRequestStateVariants } from "@/resources/confirmation-requests/helper";
import InfoText from "@/components/helper/InfoText";
import ExportPollButton from "../../components/export/ExportPollButton";
import ValidUntilProgress from "@/resources/confirmation-requests/ValidUntilProgress";

export default {
  components: { ValidUntilProgress, ExportPollButton, InfoText },
  props: ["resource", "title"],
  methods: {
    showProgressBar(item) {
      const validUntilDateMinusOneYear = subYears(new Date(item.valid_until), 1);
      return item.state === "pending" && isPast(validUntilDateMinusOneYear);
    }
  },
  data() {
    return {
      fields: [
        {
          label: i18n.t("integrations.fields.state"),
          source: "state"
        },
        {
          label: i18n.t("integrations.fields.channel"),
          source: "channel_data",
          type: "reference",
          attributes: {
            reference: "channels",
            action: null
          }
        },
        {
          label: i18n.t("integrations.fields.type"),
          source: "type",
          type: "select",
          attributes: {
            choices: ConfirmationRequestTypeVariants,
            itemText: "name",
            itemValue: "id"
          }
        },
        {
          label: i18n.t("integrations.fields.references"),
          source: "references"
        },
        {
          label: i18n.t("integrations.fields.confirmed_at"),
          source: "confirmed_at",
          type: "date",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.valid_until"),
          source: "valid_until",
          type: "date",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.created_at"),
          source: "created_at",
          type: "date",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.time_left"),
          source: "time_left",
          width: "200px",
          sortable: true
        }
      ],
      filters: [
        {
          label: i18n.t("integrations.fields.channel"),
          source: "channel",
          type: "autocomplete",
          alwaysOn: true,
          resettable: true,
          attributes: {
            reference: "channels_globals_as_defaults",
            searchQuery: "q",
            sortBy: ["name"]
          }
        },
        {
          label: i18n.t("integrations.fields.company"),
          source: "company",
          type: "autocomplete",
          resettable: true,
          alwaysOn: false,
          attributes: {
            reference: "companies",
            searchQuery: "q",
            sortBy: ["name"]
          }
        },
        {
          label: i18n.t("integrations.fields.type"),
          source: "type",
          type: "select",
          attributes: {
            choices: ConfirmationRequestTypeVariants,
            itemText: "name",
            itemValue: "id"
          },
          alwaysOn: true,
          resettable: true
        },
        {
          label: i18n.t("integrations.fields.status"),
          source: "state",
          type: "select",
          attributes: {
            choices: ConfirmationRequestStateVariants,
            itemText: "name",
            itemValue: "id"
          },
          alwaysOn: true,
          resettable: true
        },
        {
          label: i18n.t("integrations.fields.valid_until"),
          source: "valid_until",
          type: "date",
          resettable: true
        },
        {
          label: i18n.t("integrations.fields.created"),
          source: "created_at",
          type: "date",
          format: "long",
          resettable: true
        }
      ]
    };
  }
};
</script>
