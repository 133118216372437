<template>
  <div v-if="group && group.length > 0">
    <v-list v-for="(system, index) in group" :key="index" two-line>
      <v-list-item :href="system.url" :target="system.id">
        <v-list-item-avatar min-width="100" rounded="0">
          <v-img :src="getLogo(system.logo)" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ system.title }}</v-list-item-title>
          <v-list-item-subtitle>{{ system.url }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>
<script>
export default {
  props: ["group"],
  methods: {
    getLogo(logo) {
      return `https://res.cloudinary.com/djfwuiclm/image/upload/w_100,h_40,c_pad,b_white,f_auto,q_auto/v1686318788/logos/${logo}`;
    }
  }
};
</script>
