<template>
  <div>
    <info-text i18n="integrations.helper.communication-gateways.edit" />
    <va-create-layout>
      <communication-gateways-form :id="id" :item="item" :isEdit="true" />
    </va-create-layout>
  </div>
</template>

<script>
import InfoText from "@/components/helper/InfoText";
export default {
  components: { InfoText },
  props: ["id", "resource", "title", "item"]
};
</script>
