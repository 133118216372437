<template>
  <div>
    <h1>Status</h1>
    <platform-status />
  </div>
</template>
<script>
import PlatformStatus from "@/components/platform-status/PlatformStatus";
export default {
  components: { PlatformStatus }
};
</script>
