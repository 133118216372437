<template>
  <base-material-card :icon="resource.icon" :title="title">
    <template v-slot:subtitle>
      <info-text :resource="resource" crud-type="list" />
    </template>
    <va-list :filters="filters" disable-export>
      <va-data-table :fields="fields" disable-clone disable-select disable-show />
    </va-list>
  </base-material-card>
</template>

<script>
import i18n from "@/i18n";
import InfoText from "@/components/helper/InfoText.vue";
import TemporaryReplacementTypes from "@/resources/temporaryReplacements/TemporaryReplacementTypes.js";
import TemporaryReplacementStates from "@/resources/temporaryReplacements/TemporaryReplacementStates.js";

export default {
  components: { InfoText },
  props: ["resource", "title"],
  data() {
    return {
      fields: [
        {
          label: i18n.t("integrations.fields.types"),
          source: "types",
          type: "array",
          attributes: {
            itemText: item => {
              return i18n.t("integrations.temporary_replacement.type." + item.toLowerCase());
            }
          }
        },
        {
          label: i18n.t("integrations.fields.channel"),
          source: "channel_data",
          type: "reference",
          attributes: {
            reference: "channels",
            action: null
          }
        },
        {
          label: i18n.t("integrations.fields.date_from"),
          source: "date_from",
          type: "date",
          attributes: {
            format: "short"
          }
        },
        {
          label: i18n.t("integrations.fields.date_to"),
          source: "date_to",
          type: "date",
          attributes: {
            format: "short"
          }
        },
        {
          label: i18n.t("integrations.fields.replaced_user"),
          source: "replaced_user"
        },
        {
          label: i18n.t("integrations.fields.replacer_user"),
          source: "replacer_user"
        },
        {
          label: i18n.t("integrations.fields.state"),
          source: "state",
          type: "function",
          attributes: {
            callback: value => this.$i18n.t(`integrations.temporary_replacement.state.${value.toLowerCase()}`)
          }
        }
      ],
      filters: [
        {
          label: i18n.t("integrations.fields.channel"),
          source: "channel",
          type: "autocomplete",
          alwaysOn: true,
          resettable: true,
          attributes: {
            reference: "channels_globals_as_defaults",
            searchQuery: "q",
            sortBy: ["name"]
          }
        },
        {
          label: i18n.t("integrations.fields.types"),
          source: "type",
          type: "select",
          alwaysOn: true,
          attributes: {
            choices: TemporaryReplacementTypes,
            itemText: "name",
            itemValue: "id"
          }
        },
        {
          label: i18n.t("integrations.fields.state"),
          source: "state",
          type: "select",
          alwaysOn: true,
          attributes: {
            choices: TemporaryReplacementStates,
            itemText: "name",
            itemValue: "id"
          }
        }
      ]
    };
  }
};
</script>
