<template>
  <div>
    <v-tabs v-model="currentTab" background-color="transparent">
      <v-tab>Translations</v-tab>
      <v-tab>Who am i?</v-tab>
      <v-tab>Env Vars</v-tab>
      <v-tab>Routes</v-tab>
      <v-tab>Order Documents</v-tab>
    </v-tabs>
    <v-tabs-items v-model="currentTab">
      <v-tab-item>
        <v-card>
          <v-card-text>
            <p>
              <span>
                <code>integrations.address.title.create:</code>{{ $t("integrations.address.title.create") }}<br />
                {{ $t("va.pages.list") }}<br />
              </span>
              <span>Locale: {{ $i18n.locale }}</span
              ><br />
              <span>Fallback locale: {{ $i18n.fallbackLocale }}</span
              ><br />
            </p>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card>
          <v-card-text>
            <pre>{{ JSON.stringify(whoami, null, "\t") }}</pre>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card>
          <v-card-text>
            <p>
              VUE_APP_I18N_LOCALE: {{ i18n_locale }}<br />
              VUE_APP_I18N_FALLBACK_LOCALE:
              {{ i18n_fallback_locale }}<br />
              VUE_APP_BASE_URL: {{ base_url }}<br />
              VUE_APP_HOST_URL: {{ host_url }}<br />
              VUE_APP_API_URL: {{ api_url }}
            </p>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card>
          <v-card-text>
            <ul>
              <li v-for="item in routerItems" :key="item.name">{{ item.name }} ### {{ item.path }}</li>
            </ul>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card>
          <v-card-text>
            <order-link-to-json-button :item="{ id: '123' }" />
            <attachments order-id="123" />
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import OrderLinkToJsonButton from "@/components/orders/OrderLinkToJsonButton";
import Attachments from "@/components/orders/Attachments";
export default {
  components: { Attachments, OrderLinkToJsonButton },
  data() {
    return {
      currentTab: null,
      routerItems: [],
      whoami: null,
      i18n_locale: process.env.VUE_APP_I18N_LOCALE,
      i18n_fallback_locale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
      base_url: process.env.VUE_APP_BASE_URL,
      host_url: process.env.VUE_APP_HOST_URL,
      api_url: process.env.VUE_APP_API_URL
    };
  },

  async created() {
    this.whoami = await this.$admin.http.get("/whoami", {
      headers: {
        "Content-Type": "application/json"
      }
    });
    this.$router.getRoutes().forEach(route => {
      this.routerItems.push({
        name: route.name,
        path: route.path
      });
    });
  }
};
</script>
