<template>
  <v-list-item two-line>
    <v-list-item-avatar min-width="100" rounded="0" style="cursor: pointer" @click="goToLauncher('web')">
      <v-img v-if="this.logo" :src="logoUrl"></v-img>
      <v-icon v-else>
        mdi-open-in-new
      </v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        {{ deployment.config.presetHostMapping[preset] ? deployment.config.presetHostMapping[preset] : deployment.config.presetHostMapping["_default_"] }}
      </v-list-item-title>
      <v-list-item-subtitle>{{ preset }}</v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-container>
        <v-row>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-if="$admin.can('ROLE_ADMIN')" @click="goToPresetConfig" small text outlined v-bind="attrs" v-on="on"><v-icon small>mdi-cog</v-icon> </v-btn>
            </template>
            <span>Configure</span>
          </v-tooltip>
          <v-btn @click="goToCms" small text outlined>CMS</v-btn>
          <v-btn v-if="buttonActiveAriba" @click="goToCXmlLauncher('ariba')" small text outlined>Ariba</v-btn>
          <v-btn v-if="buttonActiveCoupa" @click="goToCXmlLauncher('coupa')" small text outlined>Coupa</v-btn>
          <v-btn v-if="buttonActiveJaggaer" @click="goToCXmlLauncher('jaggaer')" small text outlined>Jaggaer</v-btn>
          <v-btn v-if="buttonActiveOci" @click="goToLauncher('oci')" small text outlined>OCI</v-btn>
          <v-btn v-if="buttonActiveEdi" @click="goToLauncher('oci/edi')" small text outlined>EDI</v-btn>
        </v-row>
      </v-container>
    </v-list-item-action>
  </v-list-item>
</template>
<script>
export default {
  props: ["deployment", "preset", "logo"],
  computed: {
    logoUrl() {
      let bgBlackCommand = "";
      const presetBgBlack = ["mendix", "mendix-us", "siemens-energy", "siemens-energy-us"];
      if (presetBgBlack.includes(this.preset)) {
        bgBlackCommand = ",b_black";
      }
      return `https://res.cloudinary.com/djfwuiclm/image/upload/w_100,h_40,c_pad,f_auto,q_auto${bgBlackCommand}/v1584198000/cms/${this.preset}/${this.logo.name}`;
    },
    buttonActiveAriba() {
      return this.buttonActive("Ariba");
    },
    buttonActiveOci() {
      return this.buttonActive("Oci");
    },
    buttonActiveCoupa() {
      return this.buttonActive("Coupa");
    },
    buttonActiveJaggaer() {
      return this.buttonActive("Jaggaer");
    },
    buttonActiveEdi() {
      return this.buttonActive("Edi");
    }
  },
  methods: {
    buttonActive(name) {
      return this.deployment.config.features && this.deployment.config.features.indexOf(name) > -1;
    },
    goToCms() {
      window.open(`https://cms.prominate-platform.com/${this.preset}/admin`, "_blank");
    },
    goToLauncher(type) {
      window.open(`/launcher/${type}?deployment=${this.deployment.id}&preset=${this.preset}`, `${this.deployment.id}_${this.preset}_${type}`);
    },
    goToCXmlLauncher(type) {
      window.open(`/launcher/cxml?type=${type}&deployment=${this.deployment.id}&preset=${this.preset}`, `${this.deployment.id}_${this.preset}_${type}`);
    },
    goToPresetConfig() {
      window.open(`https://cms.prominate-platform.com/${this.preset}/admin/content/shop_config`, "_blank");
    }
  }
};
</script>
