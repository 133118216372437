<template>
  <va-action-button icon="mdi-play" label="Commands" text @click="showCommands(item)"></va-action-button>
</template>
<script>
import Button from "@skuhnow/vuetify-admin/src/mixins/button";

export default {
  mixins: [Button],
  methods: {
    showCommands() {
      this.$router.push({
        name: "campaignCommands_show",
        params: { id: this.item.id }
      });
    }
  }
};
</script>
