<template>
  <va-action-button icon="mdi-server-plus" label="Deploy now" text @click="exec(item)" />
</template>
<script>
import Button from "@skuhnow/vuetify-admin/src/mixins/button";
import admin from "@/plugins/admin";

export default {
  props: ["icon"],
  mixins: [Button],
  methods: {
    exec(item) {
      admin.http
        .post(`${process.env.VUE_APP_API_URL}/deployments/${item.id}/runs`, {})
        .then(() =>
          this.$store.commit(`messages/showToast`, {
            color: "success",
            message: "Deployment started"
          })
        )
        .catch(e => {
          console.error(e);
          this.$store.commit(`messages/showToast`, {
            color: "error",
            message: "Deployment could not be started"
          });
        });
    }
  }
};
</script>
