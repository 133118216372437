<template>
  <span>{{ item.message }}{{ attributesFlat }}</span>
</template>
<script>
export default {
  props: ["item"],
  computed: {
    attributesFlat() {
      for (const key of ["to", "email_address"]) {
        if (key in this.item.attributes) {
          return this.concatToAttributeKey(key);
        }
      }
      return "";
    }
  },
  methods: {
    concatToAttributeKey(key) {
      const toList = this.item.attributes[key];
      return " to: " + (Array.isArray(toList) ? toList.join(", ") : toList);
    }
  }
};
</script>
