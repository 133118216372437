<template>
  <div v-if="attachments">
    <div class="body-1 my-2">{{ $t("integrations.fields.attachments") }}</div>
    <div v-for="attachment of attachments" :key="attachment.id">
      <va-action-button icon="mdi-attachment" :label="attachment.name" text @click="showAttachment(attachment.link)"></va-action-button>
    </div>
  </div>
  <v-progress-circular v-else indeterminate color="primary" />
</template>
<script>
import admin from "@/plugins/admin";

export default {
  props: ["orderId"],
  data() {
    return {
      attachments: null
    };
  },
  async created() {
    this.attachments = await this.loadAttachments();
  },
  methods: {
    showAttachment(attachmentLink) {
      window.open(attachmentLink, "_blank");
    },
    async loadAttachments() {
      const response = await admin.http.get(`/orders/${this.orderId}/attachments`);

      return response.data.data;
    }
  }
};
</script>
