<template>
  <va-boolean-input :value="item.status === 'open'" @input="saveChange" />
</template>
<script>
import admin from "@/plugins/admin";
export default {
  props: ["item"],
  data() {
    return {
      state: false
    };
  },
  methods: {
    saveChange(value) {
      const newOpenCloseStatus = value ? "open" : "closed";
      admin.http
        .patch(`/contactrequests/${this.item.id}`, {
          tenant: this.item.tenant,
          status: newOpenCloseStatus,
          description: this.item.description
        })
        .then(() =>
          this.$store.commit(`messages/showToast`, {
            color: "success",
            message: this.$i18n.t(`integrations.notification.contactrequests.status.${newOpenCloseStatus}`)
          })
        )
        .catch(() =>
          this.$store.commit(`messages/showToast`, {
            color: "error",
            message: this.$i18n.t("integrations.message.type.ERROR")
          })
        );
    }
  }
};
</script>
