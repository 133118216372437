<template>
  <v-simple-table>
    <thead>
      <tr>
        <th>
          {{ $t("integrations.fields.sku") }}
        </th>
        <th>
          {{ $t("integrations.fields.name") }}
        </th>
        <th>
          {{ $t("integrations.fields.quantity") }}
        </th>
        <th>
          {{ $t("integrations.fields.unit_price") }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in shipmentItems" :key="item.id">
        <td>{{ item.variant.product_code }}</td>
        <td>{{ item.variant.name }}</td>
        <td>{{ resolveShipmentQuantity(item) }}</td>
        <td>{{ stringToMoney(item.unit_price) }}</td>
      </tr>
    </tbody>
  </v-simple-table>
</template>
<script>
import MoneyHelper from "@/mixins/MoneyHelper";

export default {
  props: ["shipment"],
  mixins: [MoneyHelper],
  computed: {
    shipmentItems() {
      return this.shipment.items;
    }
  },
  methods: {
    resolveShipmentQuantity(item) {
      if (this.shipment.id in item.shipment_quantities) {
        return item.shipment_quantities[this.shipment.id];
      }
      return "--";
    }
  }
};
</script>
