<template>
  <v-dialog :value="value" @input="closeDialog" @click:outside="closeDialog" width="900">
    <validation-observer v-slot="{ handleSubmit }">
      <v-form @submit.prevent="handleSubmit(createUpdateCompanyRegion)">
        <v-card>
          <v-card-title v-if="isEdit">
            {{ $t("integrations.company.edit_region.title", { code: item.id, companyCode: companyCode }) }}
          </v-card-title>
          <v-card-title v-else>
            {{ $t("integrations.company.create_region.title", { companyCode: companyCode }) }}
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <validation-provider
                    :name="$t('integrations.fields.code')"
                    :rules="{
                      required: true,
                      regex: /^[a-zA-Z0-9_-]+$/
                    }"
                    v-slot="{ validate, errors }"
                  >
                    <va-text-input
                      :autofocus="!isEdit"
                      :disabled="isEdit"
                      :label="$t('integrations.fields.code') + ' *'"
                      v-model="formData.id"
                      @input="validate"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <va-autocomplete-input filled :label="$t('integrations.fields.country')" v-model="formData.countries" reference="countries" :multiple="true" search-query="q" />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <va-autocomplete-input
                    :label="$t('integrations.fields.owner')"
                    v-model="formData.owner"
                    reference="manager_companies"
                    :item-text="record => record.name + ' (' + record.group + ')'"
                    search-query="name"
                    :sort-by="['name']"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <va-array-input :label="$t('integrations.fields.attributes')" v-model="formData.keyValues" v-slot="props">
                    <v-row>
                      <v-col cols="6">
                        <validation-provider
                          :name="$t('integrations.attributes.key')"
                          :rules="{
                            required: true,
                            regex: /^[a-zA-Z0-9_-]+$/
                          }"
                          v-slot="{ errors }"
                        >
                          <va-combobox-input
                            :label="$t('integrations.attributes.key')"
                            v-bind="props"
                            v-model="props.item.key"
                            :choices="CompanyRegionsAttributeKeys"
                            item-text="name"
                            item-value="id"
                            :error-messages="errors"
                          />
                        </validation-provider>
                      </v-col>
                      <v-col cols="6">
                        <va-text-input :label="$t('integrations.attributes.value')" v-bind="props" v-model="props.item.value" />
                      </v-col>
                    </v-row>
                  </va-array-input>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="secondary" :disabled="saving" @click="closeDialog">
              <v-icon left>mdi-close-circle</v-icon>
              {{ $t("integrations.btn.cancel") }}
            </v-btn>

            <v-btn color="primary" type="submit" :loading="saving">
              <v-icon left>mdi-content-save</v-icon>
              {{ $t("integrations.btn.save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </validation-observer>
  </v-dialog>
</template>
<script>
import admin from "@/plugins/admin";
import CompanyRegionsAttributeKeys from "@/resources/companyRegions/CompanyRegionsAttributeKeys.js";

export default {
  props: ["companyCode", "item", "value"],
  data() {
    return {
      CompanyRegionsAttributeKeys: CompanyRegionsAttributeKeys,
      saving: false,
      keyValues: [],
      formData: {
        id: null,
        owner: null,
        countries: [],
        keyValues: []
      }
    };
  },
  watch: {
    value(value) {
      if (value && this.item) {
        this.formData = { ...this.item };
      }
    }
  },
  computed: {
    isEdit() {
      return this.item !== null;
    }
  },
  methods: {
    clearFormData() {
      this.formData.id = null;
      this.formData.owner = null;
      this.formData.countries = [];
      this.formData.keyValues = [];
    },
    closeDialog() {
      this.clearFormData();
      this.$emit("input", false);
    },
    async createUpdateCompanyRegion() {
      const linkBase = `/companies/${this.companyCode}/regions`;
      this.saving = true;
      const method = this.item ? "put" : "post";
      const url = this.item ? `${linkBase}/${this.item.id}` : linkBase;

      admin.http
        .request({ method: method, url: url, data: this.formData })
        .then(() => {
          this.$emit("companyRegionCreated");
        })
        .catch(() =>
          this.$store.commit(`messages/showToast`, {
            color: "error",
            message: this.$i18n.t("integrations.company.messages.region.error_upsert", { product_code: this.formData.product_code })
          })
        )
        .finally(() => {
          this.saving = false;
          this.closeDialog();
        });
    }
  }
};
</script>
