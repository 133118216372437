<template>
  <div>
    <p v-if="getTranslation" v-html="getTranslation" />
  </div>
</template>
<script>
export default {
  props: ["resource", "crudType", "i18n"],
  computed: {
    getTranslation() {
      const translationKey = this.i18n ? this.i18n : "integrations.helper." + this.resource.name + "." + this.crudType;
      const translatedText = this.$t(translationKey);

      if (translatedText === translationKey) {
        console.warn(`No translation for "${translationKey}"`);
      }
      return translatedText === translationKey ? "" : translatedText;
    }
  }
};
</script>
