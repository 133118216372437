var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-material-card',{attrs:{"icon":_vm.resource.icon,"title":_vm.title},scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_c('info-text',{attrs:{"resource":_vm.resource,"crud-type":"list"}})]},proxy:true}])},[_c('va-list',{attrs:{"filters":_vm.filters,"disable-export":"","disable-create":"","sort-by":['template_code'],"sort-desc":[false]}},[_c('va-data-table',{attrs:{"fields":_vm.fields,"disable-show":"","dense":"","disable-clone":"","disable-edit":"","disable-delete":"","disable-select":""},scopedSlots:_vm._u([{key:"field.template_code",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [(item.cms_data)?_c('cms-link',{attrs:{"text":"","preset":item.preset.code,"content":"email_templates","id":item.cms_data.id}},[_vm._v(" "+_vm._s(value)+" ")]):_c('span',[_vm._v(_vm._s(value))])]}},{key:"field.cms_data.layout_id",fn:function(ref){
var value = ref.value;
return [(value)?_c('a',{attrs:{"href":("https://my.brevo.com/camp/template/" + value + "/message-setup"),"target":"brevo"}},[_vm._v(_vm._s(_vm.$t("integrations.fields.layout"))+" ("+_vm._s(value)+")")]):_c('span')]}},{key:"field.cms_data.code",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(value)?_c('div',[(item.cms_data.status === 'published')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"success"}},on),[_vm._v("mdi-email-check")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("integrations.emailtemplates.notifications.has_cms_template")))])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"warning"}},on),[_vm._v("mdi-email-off")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("integrations.emailtemplates.notifications.has_draft_cms_template")))])])],1):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"error"}},on),[_vm._v("mdi-close")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("integrations.emailtemplates.notifications.no_cms_template")))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('link-button',{attrs:{"icon":"mdi-email-fast","disabled":item.cms_data == null,"label":_vm.$t('integrations.emailtemplates.actions.send_test'),"route":{ name: 'emailTemplates_emailtester', params: { preset: item.preset.code, templateCode: item.template_code } }}})]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }