<template>
  <div class="d-flex align-center" style="gap: 10px">
    <v-tooltip bottom v-if="tooltip">
      <template v-slot:activator="{ on }">
        <span v-on="on">{{ actualValue }}</span>
      </template>
      <span>{{ tooltip }}</span>
    </v-tooltip>
    <div v-else>{{ actualValue }}</div>
    <v-btn small text outlined v-if="actualValue === 'exception'" @click="resetStatus">Reset</v-btn>
  </div>
</template>

<script>
import Field from "@skuhnow/vuetify-admin/src/mixins/field";
import admin from "@/plugins/admin";

export default {
  mixins: [Field],
  props: ["mapping"],
  computed: {
    actualValue() {
      return this.value.status;
    },
    tooltip() {
      if (this.value.status === "exception") {
        return this.value.exception;
      }
      return null;
    }
  },
  methods: {
    resetStatus() {
      admin.http.patch("/communication-gateways/" + this.value.id, { exception: "" }).then(() => {
        setTimeout(() => {
          this.$store.dispatch("api/refresh", this.$route.meta.resource);
        }, 3000);
      });
    }
  }
};
</script>
