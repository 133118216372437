<template>
  <v-list-item>
    <v-list-item-avatar>
      <v-icon small class="error" v-if="hasProblem">mdi-exclamation</v-icon>
      <v-icon small class="success" v-else>mdi-check</v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <div v-if="url">
        <a :href="url" :target="urlTarget">{{ statusNameOrId }}</a>
      </div>
      <div v-else>{{ statusNameOrId }}</div>
      <div v-if="hasProblem">
        <b>{{ assessment.problem.message }}</b>
        <span v-if="assessment.problem.value">
          <br />
          {{ assessment.problem.value }}
        </span>
      </div>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
export default {
  props: ["system", "assessment"],
  computed: {
    hasProblem() {
      return !!this.assessment.problem;
    },
    url() {
      return this.assessment.status_data.details.url;
    },
    urlTarget() {
      return this.system.status_provider + "_status";
    },
    statusNameOrId() {
      return this.assessment.status_data.details.name ?? this.assessment.status_data.id ?? this.assessment.status_data.details.id;
    }
  }
};
</script>
