<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" lg="6">
        <status-summary v-if="$admin.can('ROLE_ADMIN')" :dashboard-mode="true" />
        <status />
        <deployments v-if="$admin.can('ROLE_API_DEPLOYMENTS')" />
      </v-col>
      <v-col cols="12" lg="6">
        <channel-order-fulfillments />
        <systems />
        <events v-if="$admin.can('ROLE_ADMIN')" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Events from "@/components/dashboard/Events";
import Deployments from "@/components/dashboard/Deployments";
import Systems from "@/components/dashboard/Systems";
import Status from "@/components/dashboard/Status";
import StatusSummary from "@/components/platform-status/StatusSummary";
import ChannelOrderFulfillments from "@/components/dashboard/ChannelOrderFulfillments";

export default {
  components: { ChannelOrderFulfillments, StatusSummary, Status, Systems, Deployments, Events }
};
</script>
