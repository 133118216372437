<template>
  <v-dialog v-model="dialog" width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" text v-bind="attrs" v-on="on">
        <v-icon left>mdi-play</v-icon>
        {{ $t("integrations.btn.run") }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>{{ $t("integrations.campaign.command.run_dialog") }}</v-card-title>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="dialog = false">{{ $t("integrations.btn.cancel") }}</v-btn>
        <v-btn color="primary" :loading="isSubmitting" @click="runCampaignCommand">{{ $t("integrations.btn.run") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import admin from "@/plugins/admin";

export default {
  props: ["item"],
  data() {
    return {
      isSubmitting: false,
      dialog: false
    };
  },
  methods: {
    async runCampaignCommand() {
      this.isSubmitting = true;

      const campaignCode = this.$store.state.campaignCommands.item.code;

      admin.http
        .post(`${process.env.VUE_APP_API_URL}/commands/${this.item.id}/runs`, { cli_params: campaignCode })
        .then(() => {
          this.$store.commit(`messages/showToast`, {
            color: "success",
            message: this.$i18n.t("integrations.notification.campaign.command.run")
          });
        })
        .catch(() =>
          this.$store.commit(`messages/showToast`, {
            color: "error",
            message: this.$i18n.t("integrations.notification.campaign.command.run_error")
          })
        )
        .finally(() => {
          this.isSubmitting = false;
          this.dialog = false;
        });
    }
  }
};
</script>
