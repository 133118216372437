<template>
  <div>
    <ul v-for="(domain, preset) in presetHostMapping" :key="preset">
      <li v-if="preset === '_default_'">
        <a href="#" @click="goToDomain(domain)">{{ domain }}</a>
      </li>
      <li v-else>
        <a href="#" @click="goToDomain(domain)">{{ domain }} ({{ preset }})</a>
      </li>
    </ul>
  </div>
</template>

<script>
import Field from "@skuhnow/vuetify-admin/src/mixins/field";

export default {
  mixins: [Field],
  methods: {
    goToDomain(domain) {
      this.$router.push({
        name: `domains_list`,
        query: {
          filter: JSON.stringify({
            full_qualified_domain_name: domain
          })
        }
      });
    }
  },
  computed: {
    presetHostMapping() {
      return this.item.config.presetHostMapping;
    }
  }
};
</script>
