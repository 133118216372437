<template>
  <div>
    <va-action-button :to="{ name: 'warehouses_list' }" :label="$t('integrations.warehouse.back_to_warehouses')" />

    <va-list class="mt-3" disable-create :filters="filters" ref="productList" disable-export>
      <template v-slot:actions="slotProps">
        <create-product
          v-if="slotProps.currentFilter && slotProps.currentFilter._warehouse_code"
          :warehouse-id="slotProps.currentFilter._warehouse_code"
          @productCreated="refreshList"
        />
        <export-poll-button resource="warehouseProducts" text :options="slotProps.listState.options" :filter="slotProps.currentFilter" />
      </template>
      <template v-slot:default="slotProps">
        <va-data-table :fields="fields" disable-show disable-clone disable-delete disable-edit>
          <template v-slot:[`item.actions`]="{ item }">
            <edit-product :item="item" @productUpdated="refreshList" />
            <delete-product :item="item" @productDeleted="refreshList" />
          </template>
          <template v-if="slotProps.currentFilter && slotProps.currentFilter._warehouse_code" v-slot:[`field.blocked_quantity`]="{ item: localItem, value }">
            <router-link
              v-if="value"
              :to="{
                name: `inventory-blockings_list`,
                query: {
                  filter: JSON.stringify({
                    warehouse: slotProps.currentFilter._warehouse_code,
                    product: localItem.product_code,
                    status: 'active'
                  })
                }
              }"
              >{{ value }}</router-link
            >
          </template>
        </va-data-table>
      </template>
    </va-list>
  </div>
</template>
<script>
import i18n from "@/i18n";
import DeleteProduct from "@/components/warehouses/DeleteProduct";
import EditProduct from "@/components/warehouses/EditProduct";
import CreateProduct from "@/components/warehouses/CreateProduct";
import ExportPollButton from "../../components/export/ExportPollButton";

export default {
  components: { ExportPollButton, EditProduct, CreateProduct, DeleteProduct },
  props: ["item"],
  methods: {
    refreshList() {
      this.$store.dispatch("api/refresh", this.$route.meta.resource);
    },
    setupBreadcrumbs() {
      const currentFilter = JSON.parse(this.$route.query.filter);
      if (!currentFilter) {
        return;
      }
      const currentWarehouse = currentFilter._warehouse_code;
      this.$store.commit("breadcrumbs/setItems", [
        {
          text: "Start",
          disabled: false,
          href: "/"
        },
        {
          text: this.$i18n.t("integrations.menu.warehouses"),
          exact: true,
          to: { name: "warehouses_list" }
        },
        {
          text: currentWarehouse
        },
        {
          text: this.$i18n.t("integrations.menu.products")
        }
      ]);
    }
  },
  created() {
    this.refreshList();
  },
  mounted() {
    this.setupBreadcrumbs();
  },
  data() {
    return {
      fields: [
        {
          label: i18n.t("integrations.fields.sku"),
          source: "product_code",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.name"),
          type: "product-name",
          source: "product_name"
        },
        {
          label: i18n.t("integrations.fields.warehouse"),
          source: "warehouse_code"
        },
        {
          label: i18n.t("integrations.fields.soh_quantity"),
          type: "number",
          source: "soh_quantity",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.reported_quantity"),
          type: "number",
          source: "reported_quantity",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.blocked_quantity"),
          type: "number",
          source: "blocked_quantity",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.next_intake_date"),
          type: "date",
          source: "next_intake_date"
        },
        {
          label: i18n.t("integrations.fields.next_intake_quantity"),
          type: "number",
          source: "next_intake_quantity"
        },
        {
          label: i18n.t("integrations.fields.last_updated"),
          type: "date",
          source: "lastchanged_at",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.lastchanged_by"),
          source: "lastchanged_by",
          sortable: true
        }
      ],
      filters: [
        {
          label: i18n.t("integrations.fields.warehouse"),
          source: "_warehouse_code",
          type: "autocomplete",
          alwaysOn: true,
          attributes: {
            clearable: false,
            disabled: true,
            reference: "warehouses",
            itemText: record => record.label + " (" + record.code + ")",
            itemValue: "code"
          }
        },
        {
          label: i18n.t("integrations.fields.sku"),
          source: "product_code",
          alwaysOn: true,
          resettable: true
        },
        {
          label: i18n.t("integrations.fields.name"),
          source: "product_name",
          alwaysOn: true,
          resettable: true
        }
      ]
    };
  }
};
</script>
