<template>
  <va-action-button icon="mdi-server-network" :label="$t('integrations.btn.deploy_all')" text @click="exec()"></va-action-button>
</template>
<script>
import admin from "@/plugins/admin";

export default {
  methods: {
    exec() {
      admin.http
        .post(`${process.env.VUE_APP_API_URL}/deployments/*/runs`, {})
        .then(() =>
          this.$store.commit(`messages/showToast`, {
            color: "success",
            message: "Deployment started"
          })
        )
        .catch(e => {
          console.error(e);
          this.$store.commit(`messages/showToast`, {
            color: "error",
            message: "Deployment could not be started"
          });
        });
    }
  }
};
</script>
