<template>
  <div>
    <info-text :resource="resource" crud-type="edit" />
    <va-edit-layout :title="title" disable-clone>
      <temporary-replacements-form :id="id" :item="item" :isEdit="true" />
    </va-edit-layout>
  </div>
</template>

<script>
import InfoText from "@/components/helper/InfoText";

export default {
  components: { InfoText },
  props: ["id", "title", "item", "resource"]
};
</script>
