<template>
  <span>{{ getText }} </span>
</template>

<script>
import Field from "@skuhnow/vuetify-admin/src/mixins/field";

export default {
  mixins: [Field],
  computed: {
    getText() {
      return this.value && this.value.length > 0 ? this.$i18n.t("integrations.fields.yes") : this.$i18n.t("integrations.fields.no");
    }
  }
};
</script>
