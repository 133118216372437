<template>
  <validation-form :id="id" :item="item">
    <base-material-tabs-card :tabs="tabs">
      <template v-slot:company_information>
        <v-row>
          <v-col>
            <validation-provider
              :name="$t('integrations.fields.code')"
              :rules="{
                required: true,
                regex: /^[a-zA-Z0-9_-]+$/,
                uniqueValidator: !isEdit ? { resource: 'companies', field: 'codeEquals' } : false
              }"
              v-slot="{ validate, errors }"
            >
              <va-text-input
                :autofocus="!isEdit"
                :readonly="isEdit"
                :label="$t('integrations.fields.code') + ' *'"
                source="code"
                @input="validate"
                :error-messages="errors"
              ></va-text-input>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <validation-provider :name="$t('integrations.fields.customer_name')" rules="required" v-slot="{ validate, errors }">
              <va-text-input :label="$t('integrations.fields.customer_name') + ' *'" source="name" @input="validate" :error-messages="errors"></va-text-input>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <validation-provider :name="$t('integrations.fields.group')" rules="required" v-slot="{ validate, errors }">
              <va-autocomplete-input
                :label="$t('integrations.fields.group') + ' *'"
                source="group"
                reference="company-groups"
                item-value="code"
                search-query="name"
                :sort-by="['name']"
                @input="validate"
                :error-messages="errors"
                :readonly="isEdit"
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <va-autocomplete-input
              :label="$t('integrations.fields.parent_company')"
              source="parent"
              reference="companies"
              search-query="name"
              :render-callback="record => record.name + ' (' + record.group + ')'"
              :sort-by="['name']"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <va-autocomplete-input
              :label="$t('integrations.fields.owner')"
              source="owner"
              reference="manager_companies"
              item-value="id"
              :render-callback="record => record.name + ' (' + record.group + ')'"
              search-query="name"
              :sort-by="['name']"
            />
          </v-col>
        </v-row>
        <v-row v-if="false">
          <v-col>
            <va-array-input :label="$t('integrations.fields.regions')" source="regions" v-slot="props">
              <v-row>
                <v-col cols="4">
                  <validation-provider
                    :name="$t('integrations.fields.code')"
                    :rules="{
                      required: true,
                      regex: /^[a-zA-Z0-9_-]+$/
                    }"
                    v-slot="{ validate, errors }"
                  >
                    <va-text-input :label="$t('integrations.fields.code') + ' *'" source="id" v-bind="props" @input="validate" :error-messages="errors" />
                  </validation-provider>
                </v-col>
                <v-col cols="4">
                  <va-autocomplete-input :label="$t('integrations.fields.country')" source="countries" v-bind="props" reference="countries" :multiple="true" search-query="q" />
                </v-col>
                <v-col cols="4">
                  <va-autocomplete-input
                    :label="$t('integrations.fields.owner')"
                    source="owner"
                    v-bind="props"
                    reference="manager_companies"
                    :render-callback="record => record.name + ' (' + record.group + ')'"
                    search-query="name"
                    :sort-by="['name']"
                  />
                </v-col>
              </v-row>
            </va-array-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <va-array-input :label="$t('integrations.fields.attributes')" source="attributes_key_value" v-slot="props">
              <v-row>
                <v-col cols="6">
                  <validation-provider
                    :name="$t('integrations.attributes.key')"
                    :rules="{
                      required: true,
                      regex: /^[a-zA-Z0-9_-]+$/
                    }"
                    v-slot="{ validate, errors }"
                  >
                    <va-combobox-input
                      :label="$t('integrations.attributes.key')"
                      v-bind="props"
                      source="key"
                      :choices="CompanyAttributeKeys"
                      item-text="name"
                      item-value="id"
                      @input="validate"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="6">
                  <va-text-input :label="$t('integrations.attributes.value')" v-bind="props" source="value" />
                </v-col>
              </v-row>
            </va-array-input>
          </v-col>
        </v-row>
      </template>
      <template v-slot:footer>
        <div class="d-flex align-center"><va-save-button /><va-cancel-button class="ml-2" /></div>
      </template>
    </base-material-tabs-card>
  </validation-form>
</template>
<script>
import CompanyAttributeKeys from "@/resources/companies/CompanyAttributeKeys.js";

export default {
  props: ["id", "title", "item", "isEdit"],
  data() {
    return {
      CompanyAttributeKeys: CompanyAttributeKeys
    };
  },
  computed: {
    tabs() {
      return [
        {
          id: "company_information",
          label: this.isEdit ? this.$t("integrations.company.title.edit", { code: this.id }) : this.$t("integrations.company.title.create")
        }
      ];
    }
  }
};
</script>
