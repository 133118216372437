<template>
  <div>
    <base-material-card :title="$t('integrations.campaign.product.title.list')">
      <va-list
        resource="campaignProducts"
        disable-query-string
        disable-create
        disable-export
        :filters="filters"
        :filter="{
          _campaignCode: code
        }"
      >
        <va-data-table :fields="fields" disable-show disable-clone disable-delete disable-edit>
          <template v-slot:[`field.quantity`]="{ item, value }">
            <router-link
              v-if="value"
              :to="{
                name: `orderFulfillments_list`,
                query: {
                  filter: JSON.stringify({
                    campaign: code,
                    product: item.id
                  })
                }
              }"
            >
              {{ value }}
            </router-link>
            <span v-else>{{ value }}</span>
          </template>
        </va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>
<script>
import i18n from "@/i18n";

export default {
  props: ["code"],
  destroyed() {
    this.$store.commit("breadcrumbs/setItems", null);
  },
  async mounted() {
    this.$store.commit("breadcrumbs/setItems", [
      {
        text: "Start",
        disabled: false,
        href: "/"
      },
      {
        text: this.$i18n.t("integrations.menu.campaigns"),
        exact: true,
        to: { name: "campaigns_list" }
      },
      {
        text: this.code,
        exact: true,
        to: { name: "campaigns_edit", params: { id: this.code } }
      },
      {
        text: this.$i18n.t("integrations.campaign.product.title.list"),
        exact: true
      }
    ]);
  },
  data() {
    return {
      fields: [
        {
          label: i18n.t("integrations.fields.product"),
          source: "id"
        },
        {
          label: i18n.t("integrations.fields.quantity"),
          source: "quantity",
          type: "number"
        }
      ],
      filters: [
        {
          label: i18n.t("integrations.fields.sku"),
          source: "product_code",
          alwaysOn: true,
          resettable: true
        }
      ]
    };
  }
};
</script>
