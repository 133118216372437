const TaxIdKeys = [
  { name: "VAT", id: "vatin" },
  { name: "GST", id: "gstin" },
  { name: "TIN", id: "tin" },
  { name: "Brasilian CNPJ", id: "cnpj" },
  { name: "Brasilian CPF", id: "cpf" },
  { name: "Argentinian CUIT", id: "cuit" },
  { name: "Mexican RFC", id: "rfc" },
  { name: "Columbian NIT", id: "nit" }
];

export { TaxIdKeys };
