<template>
  <v-card>
    <v-card-title> {{ $t("integrations.fields.billing_address") }}</v-card-title>
    <v-card-text>
      <order-payment-info-address :item="item" />
      <order-payment-info-table v-if="item.payment" :item="item" />
    </v-card-text>
  </v-card>
</template>
<script>
import OrderPaymentInfoAddress from "@/resources/orders/OrderPaymentInfoAddress";
import OrderPaymentInfoTable from "@/resources/orders/OrderPaymentInfoTable";
export default {
  components: { OrderPaymentInfoTable, OrderPaymentInfoAddress },
  props: ["item"]
};
</script>
