export default {
  budgets: [
    {
      lookupField: "channel",
      lookupResource: "channels_globals_as_defaults"
    }
  ],
  campaigns: [
    {
      lookupField: "channel",
      lookupResource: "channels_globals_as_defaults"
    }
  ],
  "communication-gateways": [
    {
      lookupField: "company",
      lookupResource: "companies"
    }
  ],
  domains: [
    {
      lookupField: "company",
      lookupResource: "companies"
    }
  ],
  warehouses: [
    {
      lookupField: "company",
      lookupResource: "fulfiller_companies"
    }
  ],
  companies: [
    {
      lookupField: "owner",
      lookupResource: "companies"
    }
  ],
  companyAddresses: [
    {
      lookupField: "company",
      lookupResource: "companies"
    }
  ],
  contactrequests: [
    {
      lookupField: "channel",
      lookupResource: "channels_globals_as_defaults"
    }
  ],
  "confirmation-requests": [
    {
      lookupField: "company",
      lookupResource: "companies"
    },
    {
      lookupField: "channel",
      lookupResource: "channels_globals_as_defaults"
    }
  ],
  orderFulfillments: [
    {
      lookupField: "order.channel",
      lookupResource: "channels_globals_as_defaults"
    },
    {
      lookupField: "fulfillment_config.supplier",
      lookupResource: "fulfiller_companies"
    }
  ],
  formsubmissions: [
    {
      lookupField: "channel",
      lookupResource: "channels_globals_as_defaults"
    }
  ],
  "inventory-blockings": [
    {
      lookupField: "warehouse_code",
      lookupResource: "companyAddressesWarehouses",
      lookupResourceIdField: "code",
      permissions: ["ROLE_ADMIN"]
    }
  ],
  orders: [
    {
      lookupField: "channel",
      lookupResource: "channels_globals_as_defaults"
    }
  ],
  //"order-fulfillments": [
  //  {
  //    lookupField: "fulfillment_config.distributor",
  //    lookupResource: "fulfiller_companies"
  //  }
  //],
  users: [
    {
      lookupField: "channel",
      lookupResource: "channels_globals_as_defaults"
    }
  ],
  useraddresses: [
    {
      lookupField: "company",
      lookupResource: "companies"
    },
    {
      lookupField: "user",
      lookupResource: "users"
    }
  ],
  usergroups: [
    {
      lookupField: "tenant",
      lookupResource: "companies"
    },
    {
      lookupField: "channel",
      lookupResource: "channels_globals_as_defaults"
    }
  ],
  products: [
    {
      lookupField: "tenant",
      lookupResource: "companies",
      permissions: ["ROLE_ADMIN"]
    },
    {
      lookupField: "supplier",
      lookupResource: "companies",
      permissions: ["ROLE_ADMIN"]
    }
  ],
  "temporary-replacements": [
    {
      lookupField: "channel",
      lookupResource: "channels_globals_as_defaults"
    }
  ],
  sso: [
    {
      lookupField: "tenant",
      lookupResource: "companies"
    }
  ]
};
