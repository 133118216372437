var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-form',{attrs:{"id":_vm.id,"item":_vm.item}},[_c('base-material-tabs-card',{attrs:{"tabs":_vm.tabs},scopedSlots:_vm._u([{key:"budget_information",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.channel'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
return [_c('va-autocomplete-input',{attrs:{"label":_vm.$t('integrations.fields.channel') + ' *',"source":"channel","reference":"channels_globals_as_defaults","item-value":"id","search-query":"q","sort-by":['name'],"error-messages":errors},on:{"input":validate}})]}}])})],1),_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.state'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
return [_c('va-select-input',{attrs:{"label":_vm.$t('integrations.fields.state') + ' *',"source":"state","hint":_vm.$t('integrations.helper.budget.state'),"choices":_vm.BudgetStateVariants,"item-text":"name","item-value":"id","error-messages":errors},on:{"input":validate}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.code'),"rules":{
              max: 32,
              required: true,
              regex: /^[a-zA-Z0-9_-]+$/,
              uniqueValidator: !_vm.isEdit ? { resource: 'budgets', field: 'codeEquals' } : false
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var validate = ref.validate;
            var errors = ref.errors;
return [_c('va-text-input',{attrs:{"disabled":_vm.isEdit,"label":_vm.$t('integrations.fields.code') + ' *',"hint":_vm.$t('integrations.helper.budget.code'),"source":"code","error-messages":errors},on:{"input":validate}})]}}])})],1),_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('va-autocomplete-input',{attrs:{"label":_vm.$t('integrations.fields.issuer'),"hint":_vm.$t('integrations.helper.budget.issuer'),"source":"issuer","reference":"fulfiller_companies","item-value":"code","render-callback":function (record) { return record.name + ' (' + record.group + ')'; },"search-query":"name","sort-by":['name']}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":_vm.isEdit ? 4 : 6,"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.original_amount'),"rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var validate = ref.validate;
            var errors = ref.errors;
return [_c('va-number-input',{attrs:{"autofocus":!_vm.isEdit,"label":_vm.$t('integrations.fields.original_amount') + ' *',"hint":_vm.$t('integrations.helper.budget.amount'),"source":"amount","error-messages":errors,"parse":function (v) { return v / 100; },"format":function (v) { return parseFloat(v) * 100; }},on:{"input":validate}})]}}])})],1),(_vm.isEdit)?_c('v-col',{attrs:{"md":"4","cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.remaining'),"rules":"min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var validate = ref.validate;
            var errors = ref.errors;
return [_c('va-number-input',{attrs:{"label":_vm.$t('integrations.fields.remaining'),"source":"remaining","error-messages":errors,"parse":function (v) { return v / 100; },"format":function (v) { return parseFloat(v) * 100; }},on:{"input":validate}})]}}],null,false,3666783258)})],1):_vm._e(),_c('v-col',{attrs:{"md":_vm.isEdit ? 4 : 6,"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.currency'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var validate = ref.validate;
            var errors = ref.errors;
return [_c('va-select-input',{attrs:{"label":_vm.$t('integrations.fields.currency') + ' *',"source":"currency","reference":"currencies","item-text":"name","item-value":"id","error-messages":errors},on:{"input":validate}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('va-text-input',{attrs:{"label":_vm.$t('integrations.fields.reference'),"source":"reference","hint":_vm.$t('integrations.helper.budget.reference')}})],1),_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('va-text-input',{attrs:{"label":_vm.$t('integrations.fields.source'),"source":"source","hint":_vm.$t('integrations.helper.budget.source')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.type_usage'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var validate = ref.validate;
            var errors = ref.errors;
return [_c('va-select-input',{attrs:{"label":_vm.$t('integrations.fields.type_usage') + ' *',"source":"type","hint":_vm.$t('integrations.helper.budget.usage_type'),"choices":_vm.BudgetTypeVariants,"item-text":"name","item-value":"id","error-messages":errors},on:{"input":validate}})]}}])})],1),_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.scope'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var validate = ref.validate;
            var errors = ref.errors;
return [_c('va-select-input',{attrs:{"label":_vm.$t('integrations.fields.scope') + ' *',"source":"scope","hint":_vm.$t('integrations.helper.budget.scope'),"choices":_vm.BudgetScopeVariants,"item-text":"name","item-value":"id","error-messages":errors},on:{"input":validate}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.restrict'),"rules":{
              regex: /^([A-Z0-9\-_]+)(=[1-9]\d*)?(,([A-Z0-9\-_]+)(=[1-9]\d*)?)*$/
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var validate = ref.validate;
            var errors = ref.errors;
return [_c('va-text-input',{attrs:{"label":_vm.$t('integrations.fields.restrict'),"source":"restrict","hint":_vm.restrictHint,"error-messages":errors},on:{"input":validate}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.username'),"rules":{
              regex: /^[A-Za-z0-9!#$%&'*+\-\/=?^_`{|}~@.]+$/
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var validate = ref.validate;
            var errors = ref.errors;
return [_c('va-text-input',{attrs:{"label":_vm.$t('integrations.fields.username'),"source":"username","error-messages":errors,"hint":_vm.$t('integrations.helper.budget.username')},on:{"input":validate}})]}}])})],1),_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('va-text-input',{attrs:{"label":_vm.$t('integrations.fields.cost_center'),"source":"cost_center","hint":_vm.$t('integrations.helper.budget.cost_center')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":_vm.isEdit ? 6 : 12,"cols":"12"}},[_c('va-date-input',{attrs:{"label":_vm.$t('integrations.fields.expiry_date'),"clearable":true,"source":"expiry_date","hint":_vm.$t('integrations.helper.budget.expiry_date')}})],1),(_vm.isEdit)?_c('v-col',{attrs:{"md":"3","cols":"12"}},[_c('va-date-input',{attrs:{"label":_vm.$t('integrations.fields.created_at'),"disabled":true,"source":"created_at"}})],1):_vm._e(),(_vm.isEdit)?_c('v-col',{attrs:{"md":"3","cols":"12"}},[_c('va-text-input',{attrs:{"label":_vm.$t('integrations.fields.created_by'),"disabled":true,"source":"created_by"}})],1):_vm._e()],1),_c('v-row',[_c('v-col',[_c('va-text-input',{attrs:{"label":_vm.$t('integrations.fields.comment'),"source":"comment"}})],1)],1),(_vm.isEdit)?_c('v-row',[_c('v-col',[_c('va-text-input',{attrs:{"label":_vm.$t('integrations.fields.usages'),"source":"usages"}})],1)],1):_vm._e()]},proxy:true},{key:"history",fn:function(){return [_c('audit-history',{attrs:{"resource":"budgetHistories","filter":{ budgetId: _vm.item.id },"item":_vm.item}})]},proxy:true},{key:"documents",fn:function(){return [_c('show-documents',{attrs:{"item":_vm.item}})]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex align-center"},[_c('va-save-button'),_c('va-cancel-button',{staticClass:"ml-2"})],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }