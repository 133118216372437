import i18n from "@/i18n";

export class TranslationService {
  static getValueByLocaleKey(value, wrapped) {
    if (!value || value.length === 0) {
      return "";
    }

    let neededName = null;
    const locale = i18n.locale.substring(0, 2);
    for (const property in value) {
      if (property === locale) {
        neededName = value[property];
        break;
      }
    }

    if (neededName === null) {
      neededName = value[Object.keys(value)[0]];
    }

    if (wrapped) {
      return " (" + neededName + ")";
    }
    return neededName;
  }
}
