<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-subtitle>
            {{ $t("integrations.order.form.actions.cancel_description") }}
          </v-card-subtitle>
          <v-card-text>
            <order-actions-cancel-button :item="item" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-subtitle>
            {{ $t("integrations.order.form.actions.force_resume_description") }}
          </v-card-subtitle>
          <v-card-text>
            <order-actions-force-resume-button :item="item" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import OrderActionsCancelButton from "@/components/orders/OrderActionsCancelButton";
import OrderActionsForceResumeButton from "@/components/orders/OrderActionsForceResumeButton";
export default {
  components: { OrderActionsForceResumeButton, OrderActionsCancelButton },
  props: ["item"]
};
</script>
