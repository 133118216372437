<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <base-material-card :icon="resource.icon" :title="title">
          <template v-slot:subtitle>
            <info-text :resource="resource" crud-type="list" />
          </template>
          <va-list disable-create disable-export :filters="filters" :sort-by="['code']" :items-per-page="500">
            <va-data-table :fields="fields" disable-show disable-edit disable-clone disable-delete disable-select>
              <template v-slot:[`field.code`]="{ item, value }">
                <router-link
                  :to="{
                    name: `companyAddresses_edit`,
                    params: { id: item.code }
                  }"
                >
                  {{ item.label }} ({{ value }})
                </router-link>
              </template>
              <template v-slot:[`field.product_count`]="{ item, value }">
                <v-btn
                  text
                  @click="
                    $router.push({
                      name: `warehouseProducts_list`,
                      query: {
                        filter: JSON.stringify({
                          _warehouse_code: item.code
                        })
                      }
                    })
                  "
                >
                  {{ $n(value) }} <v-icon right>mdi-shape-outline</v-icon>
                </v-btn>
              </template>
              <template v-slot:[`field.packing_spaces_count`]="{ item, value }">
                <v-btn
                  text
                  @click="
                    $router.push({
                      name: `packingSpaces_list`,
                      query: {
                        filter: JSON.stringify({
                          _warehouse_code: item.code,
                          enabled: 'true'
                        })
                      }
                    })
                  "
                >
                  {{ $n(value) }} <v-icon right>mdi-package-variant-closed</v-icon>
                </v-btn>
              </template>
              <template v-slot:[`field.inventory_blocking`]="{ item, value }">
                <v-tooltip bottom v-if="value">
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      @click="
                        $router.push({
                          name: `inventory-blockings_list`,
                          query: {
                            filter: JSON.stringify({
                              warehouse: item.code
                            })
                          }
                        })
                      "
                      >mdi-garage-lock</v-icon
                    >
                  </template>
                  <span>Inventory blocking active</span>
                </v-tooltip>

                <span v-else></span>
              </template>
            </va-data-table>
          </va-list>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import i18n from "@/i18n";
import InfoText from "@/components/helper/InfoText";

export default {
  components: { InfoText },
  props: ["resource", "title"],
  data() {
    return {
      fields: [
        {
          label: i18n.t("integrations.fields.warehouse"),
          source: "code",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.company"),
          source: "company_data",
          type: "reference",
          sortable: true,
          attributes: {
            reference: "companies",
            action: null
          }
        },
        {
          label: " ",
          source: "inventory_blocking"
        },
        {
          label: i18n.t("integrations.fields.products_count"),
          source: "product_count",
          type: "number",
          attributes: {
            showZero: true
          }
        },
        {
          label: i18n.t("integrations.fields.packing_spaces_count"),
          source: "packing_spaces_count",
          type: "number",
          attributes: {
            showZero: true
          }
        }
      ],
      filters: [
        {
          label: i18n.t("integrations.fields.company"),
          source: "company",
          type: "autocomplete",
          alwaysOn: true,
          resettable: true,
          attributes: {
            reference: "fulfiller_companies",
            searchQuery: "q",
            itemText: record => record.name + " (" + record.group + ")",
            sortBy: ["name"]
          }
        }
      ]
    };
  }
};
</script>
