<template>
  <v-data-table :headers="headers" :items="dataRows" disable-pagination show-expand item-key="created_at" :expanded.sync="expanded">
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length"><order-payment-info :item="item" /></td>
    </template>
    <template v-slot:item.paymentAmount="{ item }">
      {{ formatWithCurrency(item.payment.amount, order.currency_code) }}
    </template>
  </v-data-table>
</template>
<script>
import OrderPaymentInfo from "@/resources/orders/OrderPaymentInfo";
import MoneyHelper from "@/mixins/MoneyHelper";

export default {
  components: { OrderPaymentInfo },
  props: ["order"],
  mixins: [MoneyHelper],
  async created() {
    const response = await this.$admin.http.get(`${process.env.VUE_APP_API_URL}/orders/${this.order.id}/payments`);
    this.dataRows = response.data.data;
  },
  data() {
    return {
      expanded: [],
      dataRows: [],
      headers: [
        {
          text: this.$i18n.t("integrations.fields.invoice_number"),
          value: "payment.details.invoice_number"
        },
        {
          text: this.$i18n.t("integrations.fields.invoice_date"),
          value: "payment.details.invoiced_date"
        },
        {
          text: this.$i18n.t("integrations.fields.payment_status"),
          value: "payment.state"
        },
        {
          text: this.$i18n.t("integrations.fields.amount"),
          value: "paymentAmount",
          align: "right"
        },
        {
          text: this.$i18n.t("integrations.fields.payment_method"),
          value: "payment.method_code"
        },
        {
          text: this.$i18n.t("integrations.fields.delegated"),
          value: "payment.details.email"
        },
        { text: "", value: "data-table-expand" }
      ]
    };
  }
};
</script>
