<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-icon v-on="on">{{ icon }}</v-icon>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>
<script>
export default {
  props: {
    status: {
      required: true
    }
  },
  computed: {
    icon() {
      switch (this.status) {
        case "CERT_OK":
          return "mdi-check";
        case "CERT_EXPIRING":
          return "mdi-alert";
        default:
          return "mdi-close";
      }
    },
    tooltip() {
      return this.status ?? "No SSL Monitoring";
    }
  }
};
</script>
