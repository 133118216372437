var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-material-card',{attrs:{"icon":_vm.resource.icon,"title":_vm.title},scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_c('info-text',{attrs:{"resource":_vm.resource,"crud-type":"list"}})]},proxy:true}])},[_c('va-list',{attrs:{"filters":_vm.filters,"sort-by":['created_at'],"sort-desc":['true'],"disable-export":"","default-filter":{ status: 'active' }}},[_c('va-data-table',{attrs:{"fields":_vm.fields,"disable-clone":"","disable-show":"","can-edit":_vm.canEdit},scopedSlots:_vm._u([{key:"field.reference",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [(item.attributes && 'order_id' in item.attributes)?_c('router-link',{attrs:{"to":{
            name: "orders_list",
            query: {
              filter: JSON.stringify({
                q: item.attributes.order_id
              })
            }
          }}},[_vm._v(" "+_vm._s(value)+" ")]):_c('span',[_vm._v(_vm._s(value))])]}},{key:"field.warehouse_code_data",fn:function(ref){
          var item = ref.item;
          var value = ref.value;
return [(value.length > 0 && typeof value[0] === 'object')?_c('router-link',{attrs:{"to":{
            name: "warehouseProducts_list",
            query: {
              filter: JSON.stringify({
                _warehouse_code: value[0].code
              })
            }
          }}},[_vm._v(" "+_vm._s(value[0].label)+" ")]):_c('span',[_vm._v(_vm._s(value[0])+" ("+_vm._s(_vm.$t("integrations.warehouse.messages.virtual"))+")")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }