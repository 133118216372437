import i18n from "@/i18n.js";

const PackageTypes = [
  { value: "envelope", useable: true, text: i18n.t("integrations.warehouse.packing_space.box_type.envelope"), icon: "mdi-email-outline" },
  { value: "box", useable: true, text: i18n.t("integrations.warehouse.packing_space.box_type.box"), icon: "mdi-package-variant" },
  { value: "box_hazardous", useable: true, text: i18n.t("integrations.warehouse.packing_space.box_type.box_hazardous"), icon: "mdi-package-variant-remove" },
  { value: "pallet", useable: true, text: i18n.t("integrations.warehouse.packing_space.box_type.pallet"), icon: "mdi-shipping-pallet" },
  { value: "separate", useable: false, text: i18n.t("integrations.warehouse.packing_space.box_type.separate"), icon: "mdi-shipping-pallet" }
];

const PackageTypesCreateEdit = PackageTypes.filter(packageType => packageType.useable === true);

export { PackageTypes, PackageTypesCreateEdit };
