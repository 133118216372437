import { render, staticRenderFns } from "./ShowDetails.vue?vue&type=template&id=c982e8e6&"
import script from "./ShowDetails.vue?vue&type=script&lang=js&"
export * from "./ShowDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VInput } from 'vuetify/lib/components/VInput';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardText,VCol,VContainer,VInput,VRow})
