<template>
  <v-data-table :headers="headers" :items="dataRows" />
</template>
<script>
export default {
  props: ["relations"],
  data() {
    return {
      headers: [
        {
          text: this.$i18n.t("integrations.fields.field_name"),
          value: "relationName"
        },
        {
          text: this.$i18n.t("integrations.fields.action"),
          value: "type"
        },
        {
          text: this.$i18n.t("integrations.fields.values"),
          value: "values"
        }
      ]
    };
  },
  computed: {
    dataRows() {
      let rows = [];
      for (let relationName of Object.keys(this.relations)) {
        for (let actionType of Object.keys(this.relations[relationName])) {
          let values = this.relations[relationName][actionType];
          if (Array.isArray(values)) {
            values = values.join(", ");
          }
          rows.push({
            relationName: relationName,
            type: this.$i18n.t("integrations.audit.relation.types." + actionType),
            values: values
          });
        }
      }
      return rows;
    }
  },
  methods: {
    prepareOutput(value) {
      if (value && !!value.id) return value.id;
      if (value === true) return "true";
      if (value === false) return "false";
      return value;
    }
  }
};
</script>
