<template>
  <v-icon color="primary" @click="openJson(item)">mdi-eye</v-icon>
</template>
<script>
import Button from "@skuhnow/vuetify-admin/src/mixins/button";

export default {
  mixins: [Button],
  props: ["resourceName"],
  methods: {
    openJson(item) {
      window.location.href = `${process.env.VUE_APP_API_URL}/${this.resourceName}/${item.id}`;
    }
  }
};
</script>
