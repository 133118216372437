var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"700"},on:{"input":function($event){return _vm.handleFormStatusChange($event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","text":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("va.actions.create"))+" ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("integrations.batchjobs.title.create")))]),_c('v-card-text',[_c('v-row',{staticClass:"mt-2"},[_c('v-col',[_c('v-select',{attrs:{"filled":"","items":_vm.availableTypes,"label":_vm.$t('integrations.batchjobs.form.type')},model:{value:(_vm.formType),callback:function ($$v) {_vm.formType=$$v},expression:"formType"}}),(_vm.availableTypes.length && _vm.formType)?_c('v-btn',{attrs:{"outlined":"","text":""},on:{"click":_vm.downloadTemplate}},[_vm._v(_vm._s(_vm.$t("integrations.batchjobs.form.download_template")))]):_vm._e()],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.batchjobs.form.file'),"immediate":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{attrs:{"filled":"","truncate-length":"50","show-size":"","label":_vm.$t('integrations.batchjobs.form.file'),"error-messages":errors},model:{value:(_vm.formFile),callback:function ($$v) {_vm.formFile=$$v},expression:"formFile"}})]}}],null,true)})],1)],1),_c('batch-job-attributes',{staticClass:"mt-2",attrs:{"batch-job-type":_vm.formType},model:{value:(_vm.formAttributes),callback:function ($$v) {_vm.formAttributes=$$v},expression:"formAttributes"}}),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-checkbox',{attrs:{"label":_vm.$t('integrations.batchjobs.form.run_after_upload')},model:{value:(_vm.runAfterUpload),callback:function ($$v) {_vm.runAfterUpload=$$v},expression:"runAfterUpload"}})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","type":"submit","disabled":invalid,"loading":_vm.isLoading}},[_vm._v(_vm._s(_vm.$t("va.actions.create")))]),_c('v-btn',{on:{"click":function($event){_vm.dialog = false}}},[_vm._v(_vm._s(_vm.$t("va.actions.cancel")))])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }