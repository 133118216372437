<template>
  <va-show-layout :title="title" disable-delete disable-edit disable-clone>
    <va-show :item="item">
      <v-row justify="center">
        <v-col sm="10">
          <v-card v-if="item">
            <v-card-text>
              <va-field source="form"></va-field>
              <va-field source="title"></va-field>
              <va-field source="tenant"></va-field>
              <va-field source="submitter"></va-field>
              <va-field source="assignee"></va-field>
              <va-field source="workflow"></va-field>
              <va-field source="state"></va-field>
              <va-field source="channel"></va-field>
              <va-field source="created_at" type="date"></va-field>
              <va-field source="updated_at" type="date"></va-field>
              <va-display-json-field source="form_data" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </va-show>
  </va-show-layout>
</template>

<script>
export default {
  props: ["title", "item"]
};
</script>
