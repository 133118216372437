<template>
  <v-row justify="center">
    <v-col sm="4">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-input :label="$t('integrations.fields.status')" class="va-input"
                  ><div>
                    <va-text-field source="status" />
                  </div>
                </v-input>
                <v-input :label="$t('integrations.fields.tenant')" class="va-input"
                  ><div>
                    <va-text-field source="tenantName" />
                  </div>
                </v-input>
                <v-input :label="$t('integrations.fields.user')" class="va-input"
                  ><div>
                    <va-text-field source="email" />
                  </div>
                </v-input>

                <v-input :label="$t('integrations.fields.phone')" class="va-input"
                  ><div>
                    <va-text-field source="phone" />
                  </div>
                </v-input>
                <v-input :label="$t('integrations.fields.contact_method')" class="va-input"
                  ><div>
                    <va-text-field source="contact_method" />
                  </div>
                </v-input>
                <v-input :label="$t('integrations.fields.locale')" class="va-input"
                  ><div>
                    <va-text-field source="additional_data.locale" />
                  </div>
                </v-input>
                <v-input :label="$t('integrations.fields.distributor')" class="va-input"
                  ><div>
                    <va-text-field source="distributor.code" />
                  </div>
                </v-input>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: ["item"]
};
</script>
