<template>
  <div>
    <va-action-button :to="{ name: 'companies_list' }" :label="$t('integrations.company.back_to_companies')" />

    <base-material-card :icon="resource.icon" :title="title">
      <va-list disable-export disable-create disable-global-search>
        <template v-slot:actions="slotProps">
          <v-btn v-if="slotProps.currentFilter && slotProps.currentFilter._companyCode" color="primary" text @click="openCreate">
            <v-icon left>mdi-plus</v-icon>
            Region for {{ slotProps.currentFilter._companyCode }}
          </v-btn>

          <form-dialog :company-code="slotProps.currentFilter._companyCode" @companyRegionCreated="refreshList" v-model="dialog" :item="item" />
        </template>
        <va-data-table :fields="fields" disable-show disable-clone disable-delete disable-edit item-key="identifiers.internal">
          <template v-slot:[`item.actions`]="{ item }">
            <va-action-button :label="$t('va.actions.edit')" icon="mdi-pencil" text exact @click="openEdit(item)" />
            <va-action-button :label="$t('va.actions.delete')" icon="mdi-delete" text exact :loading="deleting" @click="deleteRegion(item)" />
          </template>
        </va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
import i18n from "@/i18n";
import FormDialog from "@/resources/companyRegions/FormDialog.vue";

export default {
  components: { FormDialog },
  props: ["resource", "title"],
  data() {
    return {
      dialog: false,
      item: null,
      deleting: false
    };
  },
  computed: {
    fields() {
      return [
        {
          label: i18n.t("integrations.fields.code"),
          source: "id"
        },
        {
          label: i18n.t("integrations.fields.owner"),
          source: "owner"
        },
        {
          label: i18n.t("integrations.fields.country"),
          source: "countries",
          type: "array"
        }
      ];
    }
  },
  methods: {
    deleteRegion(item) {
      const urlParams = new URLSearchParams(window.location.search);
      const filter = JSON.parse(urlParams.get("filter"));
      const companyCode = filter._companyCode;

      this.deleting = true;
      this.$admin.http
        .request({ method: "delete", url: `${process.env.VUE_APP_API_URL}/companies/${companyCode}/regions/${item.id}`, data: this.formData })
        .then(() => {
          this.$store.commit(`messages/showToast`, {
            color: "success",
            message: this.$i18n.t("integrations.company.messages.region.deleted", { region_code: item.id })
          });
        })
        .catch(() =>
          this.$store.commit(`messages/showToast`, {
            color: "error",
            message: this.$i18n.t("integrations.company.messages.region.error_deleted", { region_code: item.id })
          })
        )
        .finally(() => {
          this.refreshList();
          this.deleting = false;
        });
    },
    openCreate() {
      this.item = null;
      this.dialog = true;
    },
    openEdit(item) {
      this.item = item;
      this.dialog = true;
    },
    refreshList() {
      this.$store.dispatch("api/refresh", this.$route.meta.resource);
    }
  }
};
</script>
