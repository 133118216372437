<template>
  <v-col>
    <v-row no-gutters>
      <v-col>
        <validation-provider
          :name="$t('integrations.fields.email_template')"
          :rules="{
            regex: /^[a-z0-9_-]+$/
          }"
          v-slot="{ errors }"
        >
          <v-text-field
            :label="$t('integrations.fields.email_template')"
            v-model="attributes.email_template"
            :error-messages="errors"
            @input="updateAttributes"
            filled
            :hint="$t('integrations.fields.email_template_hint')"
            persistent-hint
          ></v-text-field>
        </validation-provider>
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
export default {
  props: ["value"],
  data() {
    return {
      emailTemplates: [],
      attributes: {
        email_template: "budget-import"
      }
    };
  },
  watch: {
    value: {
      deep: true,
      handler(newValue) {
        this.attributes = Object.assign({}, newValue);
      }
    }
  },
  mounted() {
    this.updateAttributes();
  },
  methods: {
    updateAttributes() {
      this.$emit("input", this.attributes);
    }
  }
};
</script>
