<template>
  <v-dialog v-model="dialog" width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" text v-bind="attrs" v-on="on">
        <v-icon left>mdi-plus</v-icon>
        {{ $t("integrations.btn.create.product") }} ({{ warehouseId }})
      </v-btn>
    </template>
    <validation-observer v-slot="{ handleSubmit }">
      <v-form @submit.prevent="handleSubmit(createProductStock)">
        <v-card>
          <v-card-title>
            {{ $t("integrations.warehouse.create_item_stock.title", { warehouseId: warehouseId }) }}
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <validation-provider :name="$t('integrations.fields.sku')" rules="required" v-slot="{ errors }">
                    <va-autocomplete-input
                      :label="$t('integrations.fields.sku') + ' *'"
                      v-model="formData.product_code"
                      reference="product-variants"
                      :hint="$t('integrations.helper.inventory.sku')"
                      :error-messages="errors"
                      clearable
                      item-value="identifiers.internal"
                      :render-callback="record => productLabel(record)"
                      search-query="q"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <validation-provider :name="$t('integrations.fields.reported_quantity')" rules="required|numeric" v-slot="{ errors }">
                    <v-text-field
                      :label="$t('integrations.fields.reported_quantity') + ' *'"
                      v-model="formData.amount"
                      :error-messages="errors"
                      :hint="$t('integrations.helper.inventory.inventory_level')"
                      persistent-hint
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-menu v-model="nextIntakeDatePicker" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formData.next_intake_date"
                        :label="$t('integrations.fields.next_intake_date')"
                        :hint="$t('integrations.helper.inventory.next_intake_date')"
                        persistent-hint
                        readonly
                        clearable
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="formData.next_intake_date" @input="nextIntakeDatePicker = false"></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  ><validation-provider :name="$t('integrations.fields.next_intake_quantity')" rules="numeric" v-slot="{ errors }">
                    <v-text-field
                      :label="$t('integrations.fields.next_intake_quantity')"
                      v-model="formData.next_intake_amount"
                      :error-messages="errors"
                      :hint="$t('integrations.helper.inventory.next_intake_quantity')"
                      persistent-hint
                    ></v-text-field> </validation-provider
                ></v-col>
              </v-row> </v-container
          ></v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="secondary" :disabled="creating" @click="closeDialog">
              <v-icon left>mdi-close-circle</v-icon>
              {{ $t("integrations.btn.cancel") }}
            </v-btn>
            <v-btn color="primary" type="submit" :loading="creating">
              <v-icon left>mdi-content-save</v-icon>
              {{ $t("integrations.btn.save") }}
            </v-btn>
          </v-card-actions>
        </v-card></v-form
      ></validation-observer
    >
  </v-dialog>
</template>
<script>
import admin from "@/plugins/admin";
import { TranslationService } from "@/services/i18n/TranslationService";

export default {
  props: ["warehouseId"],
  data() {
    return {
      TranslationService: null,
      creating: false,
      dialog: false,
      nextIntakeDatePicker: false,
      formData: {
        product_code: null,
        amount: null,
        next_intake_date: null,
        next_intake_amount: null
      }
    };
  },
  created() {
    this.TranslationService = TranslationService;
  },
  methods: {
    clearFormData() {
      this.formData.product_code = null;
      this.formData.amount = null;
      this.formData.next_intake_date = null;
      this.formData.next_intake_amount = null;
    },
    closeDialog() {
      this.dialog = false;
      this.clearFormData();
    },
    async createProductStock() {
      const linkCreate = `${process.env.VUE_APP_API_URL}/warehouses/${this.warehouseId}/products/${this.formData.product_code}/inventory`;

      this.creating = true;
      admin.http
        .put(linkCreate, this.formData)
        .then(() => {
          this.$store.commit(`messages/showToast`, {
            color: "success",
            message: this.$i18n.t("integrations.warehouse.messages.item_stock.created", { product_code: this.formData.product_code })
          });
          this.$emit("productCreated");
        })
        .catch(() =>
          this.$store.commit(`messages/showToast`, {
            color: "error",
            message: this.$i18n.t("integrations.warehouse.messages.item_stock.error_created", { product_code: this.formData.product_code })
          })
        )
        .finally(() => {
          this.creating = false;
          this.closeDialog();
        });
    },
    productLabel(record) {
      const internalSku = record.identifiers.internal;
      const publicSku = record.identifiers.internal === record.identifiers.public ? "" : " [" + record.identifiers.public + "]";
      return internalSku + publicSku + " - " + TranslationService.getValueByLocaleKey(record.name);
    }
  }
};
</script>
