<template>
  <base-material-card :icon="resource.icon" :title="title">
    <template v-slot:subtitle>
      <info-text :resource="resource" crud-type="list" />
    </template>
    <va-list :filters="filters" disable-export :items-per-page="100" :default-filter="{ created_before: '1week' }">
      <va-data-table :fields="fields" disable-clone disable-delete disable-edit disable-select>
        <template v-slot:[`field.status`]="{ value }">
          <div v-if="value">
            <v-tooltip bottom v-if="value === 'success'">
              <template v-slot:activator="{ on }">
                <v-icon color="success" v-on="on">mdi-check</v-icon>
              </template>
              <span>{{ $t("integrations.shipping_calculations.status.success") }}</span>
            </v-tooltip>
            <v-tooltip bottom v-else-if="value === 'no_rates'">
              <template v-slot:activator="{ on }">
                <v-icon color="error" v-on="on">mdi-close</v-icon>
              </template>
              <span>{{ $t("integrations.shipping_calculations.status.no_rates") }}</span>
            </v-tooltip>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on }">
                <v-icon color="warning" v-on="on">mdi-alert</v-icon>
              </template>
              <span>{{ value }}</span>
            </v-tooltip>
          </div>
        </template>
      </va-data-table>
    </va-list>
  </base-material-card>
</template>
<script>
import i18n from "@/i18n";
import InfoText from "@/components/helper/InfoText";

export default {
  components: { InfoText },
  props: ["resource", "title"],
  data() {
    return {
      fields: [
        {
          label: i18n.t("integrations.fields.status"),
          source: "status"
        },
        {
          label: i18n.t("integrations.fields.carrier"),
          source: "carrier"
        },
        {
          label: i18n.t("integrations.fields.accounts"),
          source: "accounts",
          type: "array"
        },
        {
          label: i18n.t("integrations.fields.channel"),
          source: "channel"
        },
        {
          label: i18n.t("integrations.fields.order_number"),
          source: "order_id"
        },
        {
          label: i18n.t("integrations.fields.username"),
          source: "user"
        },
        {
          label: i18n.t("integrations.fields.created_at"),
          source: "created_at",
          type: "date"
        }
      ],
      filters: [
        {
          label: i18n.t("integrations.fields.channel"),
          source: "channel",
          type: "autocomplete",
          alwaysOn: true,
          resettable: true,
          attributes: {
            filterMode: true,
            reference: "channels_globals_as_defaults",
            searchQuery: "q",
            sortBy: ["name"]
          }
        },
        // {
        //   label: i18n.t("integrations.fields.status"),
        //   source: "status",
        //   type: "select",
        //   resettable: true,
        //   attributes: {
        //     choices: [
        //       { text: i18n.t("integrations.shipping_calculations.status.success"), value: "success" },
        //       { text: i18n.t("integrations.shipping_calculations.status.no_rates"), value: "no_rates" }
        //     ]
        //   }
        // },
        {
          label: i18n.t("integrations.filters.created_before"),
          source: "created_before",
          type: "select",
          alwaysOn: true,
          resettable: true,
          attributes: {
            choices: [
              { text: i18n.t("integrations.filters.created_before.1day"), value: "1day" },
              { text: i18n.t("integrations.filters.created_before.1week"), value: "1week" },
              { text: i18n.t("integrations.filters.created_before.2week"), value: "2week" },
              { text: i18n.t("integrations.filters.created_before.1month"), value: "1month" }
            ]
          }
        }
      ]
    };
  },
  methods: {}
};
</script>
