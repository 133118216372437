<template>
  <v-btn text outlined @click="openOrder">{{ orderId }}</v-btn>
</template>
<script>
export default {
  props: ["item"],
  computed: {
    orderId() {
      return this.item.id ? this.item.id.slice(0, -2) : null;
    }
  },
  methods: {
    openOrder() {
      this.$router.push({
        name: "orders_show",
        params: { id: this.orderId }
      });
    }
  }
};
</script>
