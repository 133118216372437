<template>
  <base-material-card :icon="resource.icon" :title="title">
    <template v-slot:subtitle>
      <info-text :resource="resource" crud-type="list" />
    </template>
    <va-list :filters="filters" disable-export disable-create :sort-by="['template_code']" :sort-desc="[false]">
      <va-data-table :fields="fields" disable-show dense disable-clone disable-edit disable-delete disable-select>
        <!-- Link to CMS -->
        <template v-slot:[`field.template_code`]="{ item, value }">
          <cms-link v-if="item.cms_data" text :preset="item.preset.code" content="email_templates" :id="item.cms_data.id">
            {{ value }}
          </cms-link>
          <span v-else>{{ value }}</span>
        </template>
        <!-- Link to Brevo -->
        <template v-slot:[`field.cms_data.layout_id`]="{ value }">
          <a v-if="value" :href="`https://my.brevo.com/camp/template/${value}/message-setup`" target="brevo">{{ $t("integrations.fields.layout") }} ({{ value }})</a>
          <span v-else></span>
        </template>
        <!-- Icon for CMS Template Status (published; draft) -->
        <template v-slot:[`field.cms_data.code`]="{ value, item }">
          <div v-if="value">
            <v-tooltip bottom v-if="item.cms_data.status === 'published'">
              <template v-slot:activator="{ on }">
                <v-icon color="success" v-on="on">mdi-email-check</v-icon>
              </template>
              <span>{{ $t("integrations.emailtemplates.notifications.has_cms_template") }}</span>
            </v-tooltip>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on }">
                <v-icon color="warning" v-on="on">mdi-email-off</v-icon>
              </template>
              <span>{{ $t("integrations.emailtemplates.notifications.has_draft_cms_template") }}</span>
            </v-tooltip>
          </div>
          <v-tooltip bottom v-else>
            <template v-slot:activator="{ on }">
              <v-icon color="error" v-on="on">mdi-close</v-icon>
            </template>
            <span>{{ $t("integrations.emailtemplates.notifications.no_cms_template") }}</span>
          </v-tooltip>
        </template>
        <!-- Send Test Button -->
        <template v-slot:[`item.actions`]="{ item }">
          <link-button
            icon="mdi-email-fast"
            :disabled="item.cms_data == null"
            :label="$t('integrations.emailtemplates.actions.send_test')"
            :route="{ name: 'emailTemplates_emailtester', params: { preset: item.preset.code, templateCode: item.template_code } }"
          />
        </template>
      </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
import i18n from "@/i18n";
import InfoText from "@/components/helper/InfoText";
import LinkButton from "@/components/actions/LinkButton.vue";
import CmsLink from "@/components/helper/CmsLink.vue";

export default {
  components: { CmsLink, LinkButton, InfoText },
  props: ["resource", "title"],
  data() {
    return {
      fields: [
        {
          label: " ",
          source: "cms_data.code",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.preset"),
          type: "function",
          attributes: {
            callback: (value, record) => {
              return `${record.preset.shop_title} (${record.preset.code})`;
            }
          }
        },
        {
          label: i18n.t("integrations.fields.code"),
          source: "template_code",
          sortable: true
        },
        {
          label: " ",
          source: "cms_data.layout_id"
        }
      ],
      filters: [
        {
          label: i18n.t("integrations.fields.preset"),
          source: "preset",
          type: "autocomplete",
          alwaysOn: true,
          resettable: true,
          attributes: {
            reference: "presets",
            searchQuery: "q",
            itemValue: "code",
            itemText: record => record.shop_title + " (" + record.code + ")",
            sortBy: ["name"]
          }
        },
        {
          label: i18n.t("integrations.fields.status"),
          source: "with_template",
          type: "select",
          alwaysOn: true,
          resettable: true,
          attributes: {
            choices: [
              { id: "published", name: i18n.t("integrations.emailtemplates.notifications.has_cms_template") },
              { id: "draft", name: i18n.t("integrations.emailtemplates.notifications.has_draft_cms_template") },
              { id: "none", name: i18n.t("integrations.emailtemplates.notifications.no_cms_template") }
            ],
            itemText: "name",
            itemValue: "id"
          }
        }
      ]
    };
  }
};
</script>
