import filterOperators from "@/providers/filterProvider/filterOperators";

export default {
  batchjobs: {
    type: { operator: filterOperators.equals }
  },
  // if you change budgets, you also need to change budgetHistories (and vice versa)
  budgets: {
    codeEquals: {
      source: "code",
      operator: filterOperators.equals
    },
    channel: { operator: filterOperators.equals },
    tenant: { operator: filterOperators.equals },
    issuer: { operator: filterOperators.equals },
    state: { operator: filterOperators.equals },
    type: { operator: filterOperators.equals },
    restrict: { operator: filterOperators.equals },
    created_at: { operator: filterOperators.equals },
    expiry_date: { operator: filterOperators.equals }
  },
  budgetHistories: {
    codeEquals: {
      source: "code",
      operator: filterOperators.equals
    },
    channel: { operator: filterOperators.equals },
    tenant: { operator: filterOperators.equals },
    issuer: { operator: filterOperators.equals },
    state: { operator: filterOperators.equals },
    type: { operator: filterOperators.equals },
    restrict: { operator: filterOperators.equals },
    created_at: { operator: filterOperators.equals },
    expiry_date: { operator: filterOperators.equals }
  },
  campaigns: {
    channel: { operator: filterOperators.equals },
    status: { operator: filterOperators.equals },
    type: { operator: filterOperators.equals },
    tenant: { operator: filterOperators.equals },
    codeEquals: {
      source: "code",
      operator: filterOperators.equals
    }
  },
  campaignProducts: {
    code: { operator: filterOperators.equals },
    product_code: { operator: filterOperators.equals }
  },
  channels: {
    q: { operator: filterOperators.equals }
  },
  channels_globals_as_defaults: {
    global_channels_as_default: { operator: filterOperators.equals },
    q: { operator: filterOperators.equals }
  },
  companies: {
    group: { operator: filterOperators.equals },
    codeEquals: {
      source: "code",
      operator: filterOperators.equals
    }
  },
  companyAddresses: {
    country: { operator: filterOperators.equals },
    codeEquals: {
      source: "code",
      operator: filterOperators.equals
    },
    company: { operator: filterOperators.equals }
  },
  commands: {
    group: { operator: filterOperators.equals }
  },
  "communication-gateways": {
    company: { operator: filterOperators.equals },
    status: { operator: filterOperators.equals }
  },
  contactrequests: {
    channel: { operator: filterOperators.equals },
    created_since: { operator: filterOperators.equals },
    id: { operator: filterOperators.equals },
    status: { operator: filterOperators.equals }
  },
  "confirmation-requests": {
    company: { operator: filterOperators.equals },
    created_at: { operator: filterOperators.equals },
    type: { operator: filterOperators.equals },
    valid_until: { operator: filterOperators.equals }
  },
  formsubmissions: {
    channel: { operator: filterOperators.equals },
    state: { operator: filterOperators.equals }
  },
  "inventory-blockings": {
    warehouse: { operator: filterOperators.equals },
    product: { operator: filterOperators.equals }
  },
  orderFulfillments: {
    channel: { operator: filterOperators.equals },
    distributor: { operator: filterOperators.equals },
    order_date: { operator: filterOperators.equals },
    restrict: { operator: filterOperators.equals },
    state: { operator: filterOperators.equals },
    tenant: { operator: filterOperators.equals },
    test: { operator: filterOperators.equals },
    type: { operator: filterOperators.equals },
    campaign: { operator: filterOperators.equals },
    currency_code: { operator: filterOperators.equals },
    fulfillment_type: { operator: filterOperators.equals }
  },
  orders: {
    payments_method_code: {
      source: "payments[method_code]",
      operator: filterOperators.equals
    },
    channel: { operator: filterOperators.equals },
    order_date: { operator: filterOperators.equals },
    restrict: { operator: filterOperators.equals },
    source: { operator: filterOperators.equals },
    state: { operator: filterOperators.equals },
    tenant: { operator: filterOperators.equals },
    customer: { operator: filterOperators.equals },
    campaign: { operator: filterOperators.equals },
    currency_code: { operator: filterOperators.equals }
  },
  warehouseProducts: {
    level: { operator: filterOperators.equals },
    _warehouse_code: { operator: filterOperators.equals },
    warehouse_code: { operator: filterOperators.equals }
  },
  "product-variants": {
    q: {
      value: value => value.toLowerCase()
    }
  },
  products: {
    preset: { operator: filterOperators.equals }
  },
  // if you change users, you also need to change userHistories (and vice versa)
  users: {
    created_at: { operator: filterOperators.gt },
    enabled: { operator: filterOperators.equals },
    last_login: { operator: filterOperators.gt },
    groups: { operator: filterOperators.equals },
    roles: { operator: filterOperators.equals },
    source: { operator: filterOperators.equals },
    company: { operator: filterOperators.equals },
    channel: { operator: filterOperators.equals }
  },
  userHistories: {
    created_at: { operator: filterOperators.gt },
    enabled: { operator: filterOperators.equals },
    last_login: { operator: filterOperators.gt },
    groups: { operator: filterOperators.equals },
    roles: { operator: filterOperators.equals },
    source: { operator: filterOperators.equals },
    company: { operator: filterOperators.equals },
    channel: { operator: filterOperators.equals }
  },
  usersSimple: {
    channel: { operator: filterOperators.equals }
  },
  useraddresses: {
    q: { operator: filterOperators.equals },
    company: { operator: filterOperators.equals },
    country: { operator: filterOperators.equals },
    first_name: { operator: filterOperators.beginsWith },
    last_name: { operator: filterOperators.beginsWith },
    user: { operator: filterOperators.equals }
  },
  usergroups: {
    tenant: { operator: filterOperators.equals },
    codeEquals: {
      source: "code",
      operator: filterOperators.equals
    }
  },
  userroles: {
    allowed_for_creation: { operator: filterOperators.equals }
  },
  "temporary-replacements": {
    channel: { operator: filterOperators.equals },
    type: { operator: filterOperators.equals },
    state: { operator: filterOperators.equals },
    replaced_user: { operator: filterOperators.equals }
  },
  packingSpaces: {
    enabled: { operator: filterOperators.equals },
    type: { operator: filterOperators.equals }
  },
  "shipping/calculations": {
    status: { operator: filterOperators.equals },
    created_before: { operator: filterOperators.equals }
  }
};
