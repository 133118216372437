<template>
  <validation-form :id="id" :item="item" v-model="model">
    <base-material-tabs-card :tabs="tabs">
      <template #generalinfos>
        <v-row>
          <v-col md="6" cols="12">
            <validation-provider :name="$t('integrations.fields.replaced_user')" rules="required" v-slot="{ validate, errors }">
              <va-autocomplete-input
                :label="$t('integrations.fields.replaced_user') + ' *'"
                source="replaced_user"
                reference="usersSimple"
                item-value="username"
                :render-callback="record => record.username + ' (' + record.company_name + ')'"
                :readonly="isEdit"
                :filter="{ channel: '*' }"
                @input="validate"
                :error-messages="errors"
                v-model="replacedUser"
                search-query="q"
              ></va-autocomplete-input>
            </validation-provider>
          </v-col>
          <v-col md="6" cols="12">
            <validation-provider :name="$t('integrations.fields.replacer_user')" rules="required" v-slot="{ validate, errors }">
              <va-autocomplete-input
                :label="$t('integrations.fields.replacer_user') + ' *'"
                source="replacer_user"
                v-if="!replacerUserRefresh"
                reference="usersSimple"
                ref="replacerUserSelect"
                :filter="replacerUserFilter"
                :render-callback="record => record.username + ' (' + record.company_name + ')'"
                item-value="username"
                @input="validate"
                :error-messages="errors"
                :disabled="loadReplacerUser || !replacedUser"
                search-query="q"
              ></va-autocomplete-input>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="6" cols="12">
            <va-autocomplete-input
              :label="$t('integrations.fields.channel')"
              source="channel"
              v-model="model.channel"
              disabled
              reference="channels_globals_as_defaults"
            ></va-autocomplete-input>
          </v-col>
          <v-col md="6" cols="12">
            <validation-provider :name="$t('integrations.fields.types')" rules="required" v-slot="{ validate, errors }">
              <va-select-input
                source="types"
                :label="$t('integrations.fields.types')"
                multiple
                filled
                :chips="false"
                :choices="temporaryReplacementTypes"
                item-text="name"
                item-value="id"
                @input="validate"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4" cols="12">
            <validation-provider vid="dateFrom" :name="$t('integrations.fields.date_from')" rules="required" v-slot="{ validate, errors }">
              <va-date-input format="long" :label="$t('integrations.fields.date_from')" source="date_from" @input="validate" :error-messages="errors" />
            </validation-provider>
          </v-col>
          <v-col md="4" cols="12">
            <validation-provider :name="$t('integrations.fields.date_to')" rules="required|dateNotBefore:@dateFrom" v-slot="{ validate, errors }">
              <va-date-input format="long" :label="$t('integrations.fields.date_to')" source="date_to" @input="validate" :error-messages="errors" />
            </validation-provider>
          </v-col>
          <v-col md="4" cols="12">
            <validation-provider :name="$t('integrations.fields.date_timezone')" rules="required" v-slot="{ validate, errors }">
              <va-timezone-input :label="$t('integrations.fields.date_timezone')" source="date_timezone" @input="validate" :error-messages="errors" :value="timezoneOfUser" />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <va-text-input multiline source="comment" />
          </v-col>
        </v-row>
      </template>
      <template #footer>
        <div class="d-flex align-center">
          <va-save-button />
          <va-cancel-button class="ml-2" />
        </div>
      </template>
    </base-material-tabs-card>
  </validation-form>
</template>
<script>
import Vue from "vue";
import TemporaryReplacementTypes from "@/resources/temporaryReplacements/TemporaryReplacementTypes.js";

export default {
  props: ["id", "title", "item", "isEdit"],
  data() {
    return {
      temporaryReplacementTypes: TemporaryReplacementTypes,
      replacedUser: "",
      replacerUserFilter: null,
      loadReplacerUser: false,
      replacerUserRefresh: false,
      model: {
        channel: null
      }
    };
  },
  computed: {
    timezoneOfUser() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    },
    tabs() {
      return [
        {
          id: "generalinfos",
          label: this.isEdit ? this.$t("integrations.temporary_replacement.title.edit", { code: this.item.id }) : this.$t("integrations.temporary_replacement.title.create")
        }
      ];
    }
  },
  watch: {
    async replacedUser(newValue) {
      this.loadReplacerUser = true;
      if (newValue) {
        const response = await this.$admin.http.get("/users/" + newValue);
        const user = response.data;
        this.model.channel = user.channel;
        this.model.replacer_user = "";
        this.replacerUserFilter = { channel: user.channel };
      } else {
        this.replacerUserFilter = null;
      }
      this.replacerUserRefresh = true;
      Vue.nextTick(async () => {
        this.loadReplacerUser = false;
        this.replacerUserRefresh = false;
      });
    }
  }
};
</script>
