<template>
  <v-container fluid v-if="stage">
    <v-row>
      <v-col>
        <batch-job-progress-bar v-if="stage.statistics" :statistics="stage.statistics" @element-clicked="progressBarElementClicked" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-alert v-if="readOnlyView" outlined type="error" border="left">Please start this stage with "Next stage..." from previous stage</v-alert>
        <v-alert v-if="pollingActive" outlined type="info" border="left">
          <template #prepend>
            <v-progress-circular indeterminate class="mr-2" />
          </template>
          {{ $t("integrations.batchjobs.stages.running_info") }}
        </v-alert>
        <v-alert v-if="!pollingActive && stage.messages && stage.messages.length" outlined type="info" border="left">
          <ul>
            <li v-for="(message, index) in stage.messages" :key="index" v-html="translateMessageObject(message, `integrations.batchjobs.messages.`)" />
          </ul>
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="!pollingActive">
      <v-col cols="12" sm="auto">
        <status-filter-select :value="statusFilterValue" @input="filterResults" />
      </v-col>
    </v-row>
    <v-row v-if="!pollingActive">
      <v-col>
        <v-data-table
          :headers="dataTableHeaders"
          :items="dataTableItemsFiltered"
          :footer-props="{ 'items-per-page-options': [100, 500, 1000, -1] }"
          sort-by="meta.linenumber_in_file"
        >
          <template v-slot:item.state.status="{ item }">
            <v-icon :color="statusColor(item.state.status)">{{ statusIcon(item.state.status) }}</v-icon>
            {{ statusTranslation(item.state.status) }}
          </template>
          <template v-slot:item.state.messages="{ value }">
            <ul>
              <li v-for="(message, index) in value" :key="index" v-html="translateMessageObject(message, `integrations.batchjobs.messages.`)" />
            </ul>
          </template>
          <template v-slot:item.state.decisions="{ item }">
            <v-select v-if="item.state.decisions" :items="translateOptions(item.state.decisions)" v-model="item.state.decision" />
            <span v-else-if="item.state.decision">{{ $t(`integrations.batchjobs.decisions.${batchJob.type}.${item.state.decision}`) }}</span>
          </template>
          <template v-if="!stageDone" v-slot:body.append>
            <tr>
              <td colspan="3" />
              <td>
                <v-select :items="translateOptions(globalDecisions)" :value="selectAllValue" @input="selectAll" label="Select all" />
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn v-if="stage.status === 'finished'" color="primary" @click="nextStageAndRun">
          {{ $t("integrations.batchjobs.stages.actions.next_stage") }}
        </v-btn>
        <v-btn v-else color="primary" @click="runStage()" :disabled="pollingActive || !canManuallyRunStage">
          {{ $t("integrations.batchjobs.stages.actions.run_stage") }}
        </v-btn>
        <v-btn class="ml-2" :to="{ name: 'batchjobs_list' }">
          {{ $t("va.actions.back_to_list") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BatchJobProgressBar from "@/resources/batchjobs/components/BatchJobProgressBar";
import StageStatusHelper from "@/resources/batchjobs/mixins/StageStatusHelper";
import StatusFilterSelect from "@/resources/batchjobs/components/StatusFilterSelect";
import TranslationHelper from "@/mixins/TranslationHelper";

export default {
  components: { StatusFilterSelect, BatchJobProgressBar },
  props: ["batchRunId", "stageId"],
  mixins: [StageStatusHelper, TranslationHelper],
  mounted() {
    this.handleInitialStageStatus();
    this.selectAll(5);
  },
  destroyed() {
    clearInterval(this.pollingInterval);
  },
  data() {
    return {
      stage: null,
      resultFilters: [],
      globalDecisions: [],
      selectAllValue: null,
      readOnlyView: false,
      pollingActive: false,
      pollingInterval: null,
      statusFilterValue: null,
      dataTableHeaders: [
        {
          text: this.$t("integrations.batchjobs.results.headers.linenumber_in_file"),
          value: "meta.linenumber_in_file"
        },
        {
          text: this.$t("integrations.batchjobs.results.headers.status"),
          value: "state.status"
        },
        { text: this.$t("integrations.batchjobs.results.headers.message"), value: "state.messages", sortable: false },
        { text: this.$t("integrations.batchjobs.results.headers.decision"), value: "state.decisions", sortable: false }
      ],
      dataTableItems: []
    };
  },
  methods: {
    selectAll(value) {
      if (!this.dataTableItems.length) {
        return;
      }

      let allRowsWithDecisions = 0;
      let appliedDecisions = 0;
      this.dataTableItems
        .filter(item => item.state.status === "need_decision" && item.state.decisions)
        .forEach(item => {
          allRowsWithDecisions++;
          if (item.state.decisions.includes(value)) {
            item.state.decision = value;
            appliedDecisions++;
          }
        });

      this.selectAllValue = null;
      const skippedRows = allRowsWithDecisions - appliedDecisions;

      if (allRowsWithDecisions === 0) {
        this.$admin.toast.info(this.$i18n.t("integrations.batchjobs.decisions.result.none_applied"));
      } else if (skippedRows === 0) {
        this.$admin.toast.success(
          this.$i18n.t("integrations.batchjobs.decisions.result.all_applied", {
            all: allRowsWithDecisions
          })
        );
      } else {
        this.$admin.toast.warning(
          this.$i18n.t("integrations.batchjobs.decisions.result.some_skipped", {
            all: allRowsWithDecisions,
            applied: appliedDecisions,
            skipped: skippedRows
          })
        );
      }
    },
    async runStage() {
      this.pollingActive = true;

      this.$admin.http
        .put(`/batchjobs/${this.batchJob.id}/runs/${this.batchRunId}/stages/${this.stageId}`, this.dataTableItems)
        .then(response => {
          this.stage = response.data;
          this.$store.commit("batch/setStage", this.stage);
          if (this.stage.status === "running") {
            this.startPolling();
          } else if (this.stage.status === "need_decision" || this.stage.status === "error") {
            this.pollingActive = false;
            this.getStageRunResult();
          } else if (this.stage.status === "finished") {
            this.pollingActive = false;
            this.$store.commit("batch/setStartedManually", true);
            this.$store.commit("batch/nextStage");
            this.getStageRunResult();
          }
        })
        .catch(error => {
          this.pollingActive = false;
          this.stage.status = "error";
          let message = error?.response?.data?.message;
          if (!message) {
            message = error.message;
          }
          this.$admin.toast.error(message);
          this.$store.commit("batch/setStage", this.stage);
        });

      this.dataTableItems = [];
    },
    nextStageAndRun() {
      this.$store.commit("batch/setStartedManually", true);
      this.$store.commit("batch/nextStage");
    },
    async loadStage() {
      const response = await this.$admin.http.get(`/batchjobs/${this.batchJob.id}/runs/${this.batchRunId}/stages/${this.stageId}`);
      this.stage = response.data;
      this.$store.commit("batch/setStage", this.stage);
    },
    async poll() {
      await this.loadStage();
      if (this.stage.status !== "running") {
        await this.stopPolling();
      }
    },
    async handleInitialStageStatus() {
      await this.loadStage();
      this.readOnlyView = false;
      if (this.stage.status === "pending") {
        if (this.$store.state.batch.startedManually) {
          this.$store.commit("batch/setStartedManually", false);
          await this.runStage();
        } else {
          this.readOnlyView = true;
        }
      }
      if (this.stage.status === "running") {
        this.startPolling();
      }
      if (this.stage.status === "need_decision" || this.stage.status === "finished" || this.stage.status === "error") {
        await this.getStageRunResult();
      }
    },
    async getStageRunResult() {
      const response = await this.$admin.http.get(`/batchjobs/${this.batchJob.id}/runs/${this.batchRunId}/stages/${this.stageId}/results`);
      this.globalDecisions = response.data.metadata.decisions;
      this.dataTableItems = response.data.data;
      if (this.stage.status === "error") {
        this.externalSetFilter(this.stage.status);
      }
    },
    startPolling() {
      this.pollingActive = true;
      this.pollingInterval = setInterval(
        function() {
          this.poll();
        }.bind(this),
        2000
      );
    },
    async stopPolling() {
      clearInterval(this.pollingInterval);
      this.pollingActive = false;

      if (this.stage.status === "finished") {
        this.$store.commit("batch/setStartedManually", true);
        this.$store.commit("batch/nextStage");
      }
      await this.getStageRunResult();
    },
    translateOptions(options) {
      return options.map(option => ({
        text: this.$t(`integrations.batchjobs.decisions.${this.batchJob.type}.${option}`),
        value: option
      }));
    },
    externalSetFilter(newValue) {
      if (this.statusFilterValue === newValue) {
        this.statusFilterValue = null;
      } else {
        this.statusFilterValue = newValue;
      }
      this.filterResults(this.statusFilterValue);
    },
    filterResults(filterValue) {
      this.resultFilters = [{ field: "status", value: filterValue }];
    },
    hasFilter(filterField) {
      return this.resultFilters.filter(filter => filter.field === filterField).length > 0 && this.resultFilters[0].value !== null;
    },
    progressBarElementClicked(dataset) {
      this.externalSetFilter(dataset.status);
    }
  },
  computed: {
    canManuallyRunStage() {
      return this.stage.status === "need_decision";
    },
    stageDone() {
      return this.stage.status === "finished" || this.stage.status === "error";
    },
    batchJob() {
      return this.$store.state.batch.batchJob;
    },
    dataTableItemsFiltered() {
      return this.dataTableItems.filter(item => {
        if (this.hasFilter("status")) {
          return item.state.status === this.resultFilters[0].value;
        } else {
          return true;
        }
      });
    }
  }
};
</script>
