var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.value,"width":"900"},on:{"input":_vm.closeDialog,"click:outside":_vm.closeDialog}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.createUpdatePackingSpaces)}}},[_c('v-card',[(_vm.isEdit)?_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("integrations.warehouse.edit_packing_space.title", { reference: _vm.item.reference, warehouseCode: _vm.warehouseCode }))+" ")]):_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("integrations.warehouse.create_packing_space.title", { warehouseCode: _vm.warehouseCode }))+" ")]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.reference'),"rules":{
                    required: true,
                    regex: /^[0-9A-Za-z\-\_]+$/
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var validate = ref.validate;
                  var errors = ref.errors;
return [_c('va-text-input',{attrs:{"disabled":_vm.isEdit,"label":_vm.$t('integrations.fields.reference') + ' *',"error-messages":errors},on:{"input":validate},model:{value:(_vm.formData.reference),callback:function ($$v) {_vm.$set(_vm.formData, "reference", $$v)},expression:"formData.reference"}})]}}],null,true)})],1),_c('v-col',[_c('va-text-input',{attrs:{"label":_vm.$t('integrations.fields.description')},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"label":_vm.$t('integrations.fields.type') + ' *',"filled":"","items":_vm.PackageTypesCreateEdit,"error-messages":errors},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                  var item = ref.item;
return [_c('v-icon',[_vm._v(" "+_vm._s(item.icon)+" ")]),_vm._v(" "+_vm._s(item.text)+" ")]}},{key:"selection",fn:function(ref){
                  var item = ref.item;
return [_c('v-icon',[_vm._v(" "+_vm._s(item.icon)+" ")]),_vm._v(" "+_vm._s(item.text)+" ")]}}],null,true),model:{value:(_vm.formData.type),callback:function ($$v) {_vm.$set(_vm.formData, "type", $$v)},expression:"formData.type"}})]}}],null,true)})],1),_c('v-col',[_c('va-select-input',{attrs:{"label":_vm.$t('integrations.fields.measurement_system') + ' *',"choices":[
                    { value: 'metric', text: _vm.$t('integrations.warehouse.packing_space.measurement_system.metric') },
                    { value: 'imperial', text: _vm.$t('integrations.warehouse.packing_space.measurement_system.imperial') }
                  ]},model:{value:(_vm.formData.measurement_system),callback:function ($$v) {_vm.$set(_vm.formData, "measurement_system", $$v)},expression:"formData.measurement_system"}})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"vid":"outerWidth","name":_vm.$t('integrations.fields.outer_width'),"rules":{
                    min_value: 1,
                    required: true,
                    numeric: true
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var validate = ref.validate;
                  var errors = ref.errors;
return [_c('va-text-input',{attrs:{"label":_vm.$t('integrations.fields.outer_width') + ' *',"error-messages":errors,"suffix":_vm.inputSuffixLength},on:{"input":validate},model:{value:(_vm.formData.outer_width),callback:function ($$v) {_vm.$set(_vm.formData, "outer_width", $$v)},expression:"formData.outer_width"}})]}}],null,true)})],1),_c('v-col',[_c('validation-provider',{attrs:{"vid":"outerHeight","name":_vm.$t('integrations.fields.outer_height'),"rules":{
                    min_value: 1,
                    required: true,
                    numeric: true
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var validate = ref.validate;
                  var errors = ref.errors;
return [_c('va-text-input',{attrs:{"label":_vm.$t('integrations.fields.outer_height') + ' *',"error-messages":errors,"suffix":_vm.inputSuffixLength},on:{"input":validate},model:{value:(_vm.formData.outer_height),callback:function ($$v) {_vm.$set(_vm.formData, "outer_height", $$v)},expression:"formData.outer_height"}})]}}],null,true)})],1),_c('v-col',[_c('validation-provider',{attrs:{"vid":"outerLength","name":_vm.$t('integrations.fields.outer_length'),"rules":{
                    min_value: 1,
                    required: true,
                    numeric: true
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var validate = ref.validate;
                  var errors = ref.errors;
return [_c('va-text-input',{attrs:{"label":_vm.$t('integrations.fields.outer_length') + ' *',"error-messages":errors,"suffix":_vm.inputSuffixLength},on:{"input":validate},model:{value:(_vm.formData.outer_length),callback:function ($$v) {_vm.$set(_vm.formData, "outer_length", $$v)},expression:"formData.outer_length"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.empty_weight'),"rules":{
                    min_value: 1,
                    required: true,
                    numeric: true,
                    smallerThanOther: '@maxWeight'
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var validate = ref.validate;
                  var errors = ref.errors;
return [_c('va-text-input',{attrs:{"label":_vm.$t('integrations.fields.empty_weight') + ' *',"error-messages":errors,"suffix":_vm.inputSuffixWeight},on:{"input":validate},model:{value:(_vm.formData.empty_weight),callback:function ($$v) {_vm.$set(_vm.formData, "empty_weight", $$v)},expression:"formData.empty_weight"}})]}}],null,true)})],1),_c('v-col',[_c('validation-provider',{attrs:{"vid":"maxWeight","name":_vm.$t('integrations.fields.max_weight'),"rules":{
                    min_value: 1,
                    required: true,
                    numeric: true
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var validate = ref.validate;
                  var errors = ref.errors;
return [_c('va-text-input',{attrs:{"label":_vm.$t('integrations.fields.max_weight') + ' *',"error-messages":errors,"suffix":_vm.inputSuffixWeight},on:{"input":validate},model:{value:(_vm.formData.max_weight),callback:function ($$v) {_vm.$set(_vm.formData, "max_weight", $$v)},expression:"formData.max_weight"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.inner_width'),"rules":{
                    min_value: 1,
                    numeric: true,
                    smallerThanOtherOrEqual: '@outerWidth'
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var validate = ref.validate;
                  var errors = ref.errors;
return [_c('va-text-input',{attrs:{"label":_vm.$t('integrations.fields.inner_width'),"error-messages":errors,"suffix":_vm.inputSuffixLength},on:{"input":validate},model:{value:(_vm.formData.inner_width),callback:function ($$v) {_vm.$set(_vm.formData, "inner_width", $$v)},expression:"formData.inner_width"}})]}}],null,true)})],1),_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.inner_height'),"rules":{
                    min_value: 1,
                    numeric: true,
                    smallerThanOtherOrEqual: '@outerHeight'
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var validate = ref.validate;
                  var errors = ref.errors;
return [_c('va-text-input',{attrs:{"label":_vm.$t('integrations.fields.inner_height'),"error-messages":errors,"suffix":_vm.inputSuffixLength},on:{"input":validate},model:{value:(_vm.formData.inner_height),callback:function ($$v) {_vm.$set(_vm.formData, "inner_height", $$v)},expression:"formData.inner_height"}})]}}],null,true)})],1),_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.inner_length'),"rules":{
                    min_value: 1,
                    numeric: true,
                    smallerThanOtherOrEqual: '@outerLength'
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var validate = ref.validate;
                  var errors = ref.errors;
return [_c('va-text-input',{attrs:{"label":_vm.$t('integrations.fields.inner_length'),"error-messages":errors,"suffix":_vm.inputSuffixLength},on:{"input":validate},model:{value:(_vm.formData.inner_length),callback:function ($$v) {_vm.$set(_vm.formData, "inner_length", $$v)},expression:"formData.inner_length"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('va-text-input',{attrs:{"multiline":"","label":_vm.$t('integrations.fields.comment')},model:{value:(_vm.formData.comment),callback:function ($$v) {_vm.$set(_vm.formData, "comment", $$v)},expression:"formData.comment"}})],1)],1),_c('v-row',{staticStyle:{"margin-top":"0"}},[_c('v-col',[_c('va-boolean-input',{attrs:{"label":_vm.$t('integrations.fields.enabled')},model:{value:(_vm.formData.enabled),callback:function ($$v) {_vm.$set(_vm.formData, "enabled", $$v)},expression:"formData.enabled"}})],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","disabled":_vm.saving},on:{"click":_vm.closeDialog}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close-circle")]),_vm._v(" "+_vm._s(_vm.$t("integrations.btn.cancel"))+" ")],1),_c('v-btn',{attrs:{"color":"primary","type":"submit","loading":_vm.saving}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v(" "+_vm._s(_vm.$t("integrations.btn.save"))+" ")],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }