<template>
  <v-row justify="center">
    <v-col sm="4">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row
              ><v-col>
                <v-input :label="$t('integrations.fields.order_number')" class="va-input"
                  ><div>
                    <va-text-field source="order_number" />
                  </div>
                </v-input>

                <v-input :label="$t('integrations.fields.order_date')" class="va-input"
                  ><div>
                    <va-date-field source="order_date" />
                  </div>
                </v-input>
                <v-input :label="$t('integrations.fields.channel')" class="va-input"
                  ><div>
                    <va-text-field source="channel.code" />
                  </div>
                </v-input>
                <v-input :label="$t('integrations.fields.email')" class="va-input"
                  ><div>
                    <router-link
                      :to="{
                        name: 'users_list',
                        query: {
                          filter: JSON.stringify({
                            q: item.customer.email
                          })
                        }
                      }"
                      >{{ item.customer.email }}</router-link
                    >
                  </div>
                </v-input>
                <v-input :label="$t('integrations.fields.order_total')" class="va-input"
                  ><div>
                    <va-function-field :callback="formatTotalNet" />
                  </div>
                </v-input>
                <v-input :label="$t('integrations.fields.total')" class="va-input"
                  ><div>
                    <va-function-field :callback="formatTotal" />
                  </div>
                </v-input>
                <v-input :label="$t('integrations.fields.status')" class="va-input"
                  ><div>
                    <va-select-field :choices="stateVariants" source="state" item-text="name" item-value="id"></va-select-field>
                  </div>
                </v-input>
                <v-input :label="$t('integrations.fields.purpose')" class="va-input"
                  ><div>
                    <va-text-field source="attributes.purpose" />
                  </div>
                </v-input>
                <v-input :label="$t('integrations.fields.comment')" class="va-input"
                  ><div>
                    <va-text-field source="notes" />
                  </div>
                </v-input>
                <v-input :label="$t('integrations.fields.currency')" class="va-input"
                  ><div>
                    <va-text-field source="currency_code" />
                  </div>
                </v-input>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import i18n from "@/i18n";
import { stateVariants } from "@/resources/orders/OrderStateVariants";

export default {
  props: ["title", "item"],
  data() {
    return {
      stateVariants: stateVariants
    };
  },
  methods: {
    formatTotalNet(value, record) {
      return new Intl.NumberFormat(i18n.locale, {
        style: "currency",
        currency: record.currency_code
      }).format(record.total_net / 100);
    },
    formatTotal(value, record) {
      return new Intl.NumberFormat(i18n.locale, {
        style: "currency",
        currency: record.currency_code
      }).format(record.total / 100);
    }
  }
};
</script>
