<template>
  <v-container fluid>
    <v-row v-if="$admin.can('ROLE_ADMIN')">
      <v-col>
        <deploy-all-button />
        <deployment-status-button action="enable" icon="mdi-play" label="Enable all" :deploymentId="'*'" />
        <deployment-status-button action="disable" icon="mdi-pause" label="Disable all" :deploymentId="'*'" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <base-material-card :icon="resource.icon" :title="title">
          <template v-slot:subtitle>
            <info-text :resource="resource" crud-type="list" />
          </template>
          <va-list disable-create disable-global-search disable-export :items-per-page="500">
            <va-data-table :fields="fields" disable-show disable-edit disable-clone disable-delete disable-select>
              <template v-slot:[`field.presetHostMapping`]="{ item }">
                <deployment-preset-host-mapping :item="item" />
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <deployment-status-button action="enable" label="Enable" icon="mdi-play" :deploymentId="item.id"/>
                <deployment-status-button action="disable" label="Disable" icon="mdi-pause" :deploymentId="item.id"/>
                <deploy-button v-if="$admin.can('ROLE_ADMIN')" :item="item"/>
                <deploy-log-button v-if="$admin.can('ROLE_ADMIN')" :item="item"/></template
            ></va-data-table>
          </va-list>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import i18n from "@/i18n";
import DeploymentStatusButton from "@/components/deployments/DeploymentStatusButton";
import DeployButton from "@/components/deployments/DeployButton";
import DeployLogButton from "@/components/deployments/DeployLogButton";
import DeployAllButton from "@/components/deployments/DeployAllButton";
import InfoText from "@/components/helper/InfoText";
import DeploymentPresetHostMapping from "@/components/deployments/DeploymentPresetHostMapping.vue";

export default {
  components: {
    DeploymentPresetHostMapping,
    InfoText,
    DeployAllButton,
    DeployLogButton,
    DeployButton,
    DeploymentStatusButton
  },
  props: ["resource", "title"],
  data() {
    return {
      fields: [
        {
          label: i18n.t("integrations.fields.id"),
          source: "id"
        },
        {
          label: i18n.t("integrations.fields.vpn_host"),
          source: "config.vpnHost"
        },
        {
          label: i18n.t("integrations.fields.hosts"),
          source: "presetHostMapping"
        },
        {
          label: i18n.t("integrations.fields.channel"),
          type: "array",
          attributes: {
            column: true
          },
          source: "config.channels"
        },
        {
          label: i18n.t("integrations.fields.presets"),
          source: "config.presetTenantMapping",
          type: "array-list",
          attributes: {
            showIndex: true
          }
        },
        {
          label: i18n.t("integrations.fields.last_deployment"),
          source: "last_deployment",
          type: "date"
        }
      ]
    };
  }
};
</script>
