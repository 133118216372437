<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="error" text v-bind="attrs" v-on="on"> <v-icon left>mdi-trash-can</v-icon> {{ $t("integrations.btn.clear") }} </v-btn>
    </template>
    <v-card>
      <v-card-title> Deleting Inventory "{{ titleSource }}" </v-card-title>

      <v-card-text>
        Are you sure you want to delete all inventory infromation for
        {{ item ? `"${item.product_code}"` : '"product"' }} in warehouse {{ item ? `"${item.warehouse_code}"` : '"warehouse"' }}? This action is permanent.
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="secondary" :disabled="deleting" @click="closeDialog">
          <v-icon left>mdi-close-circle</v-icon>
          {{ $t("integrations.btn.cancel") }}
        </v-btn>
        <v-btn color="primary" :loading="deleting" @click="deleteProduct">
          <v-icon left>mdi-trash-can</v-icon>
          {{ $t("integrations.btn.clear") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import admin from "@/plugins/admin";

export default {
  props: ["item"],
  data() {
    return {
      deleting: false,
      dialog: false
    };
  },
  computed: {
    titleSource() {
      return this.item.product_code ? this.item.product_code : this.item.id ? this.item.id : "- unnamed -";
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    async deleteProduct() {
      const linkDelete = `${process.env.VUE_APP_API_URL}/warehouses/${this.item.warehouse_code}/products/${this.item.product_code}/inventory`;

      this.deleting = true;
      admin.http
        .delete(linkDelete)
        .then(() => {
          this.$store.commit(`messages/showToast`, {
            color: "success",
            message: `Product "${this.item.product_code}" was deleted.`
          });
          this.$emit("productDeleted");
        })
        .catch(() =>
          this.$store.commit(`messages/showToast`, {
            color: "error",
            message: `Error while deleting product "${this.item.product_code}"`
          })
        )
        .finally(() => {
          this.deleting = false;
          this.closeDialog();
        });
    }
  }
};
</script>
