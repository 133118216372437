<template>
  <va-input v-bind="$props" class="va-input">
    <div>{{ renderedValue }}</div>
  </va-input>
</template>
<script>
import Input from "@skuhnow/vuetify-admin/src/mixins/input";

export default {
  mixins: [Input],
  props: {
    value: {
      type: String,
      default: ""
    },
    reference: {
      type: String,
      default: ""
    },
    displayField: {
      type: String
    },
    renderCallback: {
      type: Function
    }
  },
  data() {
    return {
      record: null
    };
  },
  async mounted() {
    await this.loadReference();
  },
  methods: {
    async loadReference() {
      let { data } = await this.$store.dispatch(this.reference + `/getMany`, {
        ids: [this.id]
      });
      this.record = data[0];
    }
  },
  computed: {
    id() {
      return this.commonProps.value;
    },
    renderedValue() {
      if (typeof this.renderCallback === "function") {
        return this.renderCallback(this.id, this.record);
      }
      if (this.record === null) {
        return this.id;
      }
      if (this.displayField.length === 0) {
        return "";
      }
      const path = this.displayField.split(".");
      return path.reduce((obj, segment) => obj && obj[segment], this.record);
    }
  }
};
</script>
