import { GET_LIST, GET_EXPORT, GET_MANY, GET_ONE, CREATE, UPDATE, UPDATE_MANY, DELETE, DELETE_MANY } from "@skuhnow/vuetify-admin/src/providers/data/actions";

import FetchJson from "@skuhnow/vuetify-admin/src/providers/utils/fetchJson";
import qs from "qs";
import filterProvider from "@/providers/filterProvider";
import resourceMapper from "@/providers/resourceMapper";
import dataMapper from "@/providers/dataMapper";
import resourceReferences from "@/providers/resourceReferences";
import sortFieldMapper from "@/providers/sortFieldMapper";
import admin from "@/plugins/admin";

export default httpClient => {
  if (typeof httpClient === "string") {
    httpClient = new FetchJson(httpClient);
  }

  const getMany = async function(resource, params) {
    const mappedResource = resourceMapper.mapResource(resource, params);

    let { pagination, ids, query } = params;
    query = { ...query, ...resolveGetManyField(mappedResource, ids) };

    if (pagination) {
      let { page, perPage } = pagination;

      query = {
        ...query,
        _limit: perPage,
        _offset: perPage * (page - 1)
      };
    }

    const response = await httpClient.get(`${mappedResource}?${qs.stringify(query)}`);
    return response.data;
  };

  function buildFilter(filter, query, resource) {
    for (let fieldname of Object.keys(filter)) {
      // only add filter if first char is not "_" ("_" will be used for url modifying)
      if (fieldname.charAt(0) !== "_") {
        let filterMapping = filterProvider.buildFilter(resource, fieldname, filter[fieldname]);
        query[filterMapping.source] = filterMapping.operator + filterMapping.value;
      }
    }

    filterProvider.mergeFilter(resource, filter, query);
  }

  function mapExportFilter(filter, resource) {
    if (resource === "warehouseProducts" && "_warehouse_code" in filter) {
      filter.warehouse_code = filter["_warehouse_code"];
      delete filter["_warehouse_code"];
    }
    if (resource === "companyAddresses") {
      if ("_tenant" in filter) {
        filter.company = filter["_tenant"];
        delete filter["_tenant"];
      } else {
        filter.company = "*";
      }
    }
  }

  function resolveGetManyField(resource, value) {
    let searchField = "id";

    const resourceWithDeviantIdField = {
      "company-groups": "code",
      companyAddressesWarehouses: "code",
      campaigns: "code",
      "product-variants": "code",
      users: "username",
      "companies/*/addresses": "code",
      warehouses: "code",
      domains: "full_qualified_domain_name"
    };
    if (resource in resourceWithDeviantIdField) {
      searchField = resourceWithDeviantIdField[resource];
    }

    let queryParams = {};
    queryParams[searchField] = value;
    return queryParams;
  }

  function searchForIdInArrayOfObjects(id, arrayToLookup, fieldName) {
    for (let i = 0; i < arrayToLookup.length; i++) {
      if (arrayToLookup[i][fieldName] == id) {
        return arrayToLookup[i];
      }
    }
    return null;
  }

  return {
    [GET_LIST]: async (resource, params) => {
      const requestResource = resourceMapper.mapResource(resource, params);
      let { pagination, sort, filter, query = {} } = params;

      if (filter) {
        buildFilter(filter, query, resource);
      }

      if (pagination) {
        let { page, perPage } = pagination;

        query = {
          ...query,
          _limit: perPage,
          _offset: perPage * (page - 1)
        };
      }

      if (sort && sort.length) {
        query._sort = {};

        sort.forEach(item => {
          let { by, desc } = item;

          query._sort[sortFieldMapper.mapField(resource, by)] = desc ? "desc" : "asc";
        });
      }

      let { data } = await httpClient.get(`${requestResource}?${qs.stringify(query)}`);

      if (resource in resourceReferences) {
        const resourceReferenceMappings = resourceReferences[resource];

        for (const resourceReferenceMapping of resourceReferenceMappings) {
          let granted = true;
          let permissions = resourceReferenceMapping.permissions;
          if (permissions && permissions.length) {
            granted = permissions.filter(permission => !admin.can(permission)).length === 0;
          }

          let ids = [];
          if (data.data) {
            data.data.forEach(row => {
              const idFieldValue = resourceReferenceMapping.lookupField.split(".").reduce((obj, i) => obj[i], row);
              if (idFieldValue) {
                if (Array.isArray(idFieldValue)) {
                  ids = ids.concat(idFieldValue);
                } else {
                  ids.push(idFieldValue);
                }
              }
            });
          }
          let lookupResourceIdField = resourceReferenceMapping.lookupResourceIdField;
          if (!lookupResourceIdField) lookupResourceIdField = "id";

          const uniqueIds = ids.filter((value, index, self) => self.indexOf(value) === index);
          let referenceResult = [];
          if (granted && uniqueIds.length > 0) {
            const result = await getMany(resourceReferenceMapping.lookupResource, {
              pagination: { page: 1, perPage: uniqueIds.length },
              ids: uniqueIds
            });
            referenceResult = result.data;
          }

          if (data.data) {
            data.data.forEach(row => {
              let lookupRows = [];
              const idFieldValue = resourceReferenceMapping.lookupField.split(".").reduce((obj, i) => obj[i], row);
              if (Array.isArray(idFieldValue)) {
                idFieldValue.forEach(idFieldValueRow => {
                  const lookupRow = searchForIdInArrayOfObjects(idFieldValueRow, referenceResult, lookupResourceIdField);
                  if (lookupRow) {
                    lookupRows.push(lookupRow);
                  } else {
                    lookupRows.push(idFieldValueRow);
                  }
                });
              } else {
                const lookupRow = searchForIdInArrayOfObjects(idFieldValue, referenceResult, lookupResourceIdField);
                if (lookupRow) {
                  lookupRows.push(lookupRow);
                } else {
                  lookupRows.push(idFieldValue);
                }
              }
              row[`${resourceReferenceMapping.lookupField}_data`] = lookupRows;
            });
          }
        }
      }

      return {
        data: data.data,
        total: data.metadata.total
      };
    },
    [GET_EXPORT]: async (resource, params) => {
      const { sort, filter } = params;

      let query = {
        _limit: 0,
        _offset: 0
      };

      if (filter) {
        mapExportFilter(filter, resource);
        buildFilter(filter, query, resource);
      }

      if (sort && sort.length) {
        query._sort = {};

        sort.forEach(item => {
          let { by, desc } = item;
          query._sort[by] = desc ? "desc" : "asc";
        });
      }

      const originalQuery = qs.stringify(query);
      let newQuery = {
        id: "ExportCommandConfig",
        name: undefined,
        cli_params: `${resource} ${params.format} '${originalQuery}'`
      };
      return httpClient.post(`/commands/ExportCommandConfig/runs`, newQuery);
    },
    [GET_MANY]: getMany,
    [GET_ONE]: async (resource, params) => {
      // We have to fake GET commands/:id, because the endpoint does not exist
      // but the request is automatically called on the command details page
      if (resource === "commands") {
        return {
          data: {
            id: params.id
          }
        };
      }

      const response = await httpClient.get(`${resourceMapper.mapResource(resource, params)}/${params.id}`);
      response.data = dataMapper.mapDataForForm(resource, response.data);
      return response;
    },
    [CREATE]: (resource, params) => httpClient.post(resourceMapper.mapResource(resource, params), dataMapper.mapDataForEndpoint(resource, params.data)),
    [UPDATE]: (resource, params) => httpClient.put(`${resourceMapper.mapResource(resource, params)}/${params.id}`, dataMapper.mapDataForEndpoint(resource, params.data)),
    [UPDATE_MANY]: (resource, params) =>
      Promise.all(params.ids.map(id => httpClient.put(`${resourceMapper.mapResource(resource, params)}/${id}`, dataMapper.mapDataForEndpoint(resource, params.data)))).then(() =>
        Promise.resolve()
      ),
    [DELETE]: (resource, params) => httpClient.delete(`${resourceMapper.mapResource(resource, params)}/${params.id}`),
    [DELETE_MANY]: (resource, params) => Promise.all(params.ids.map(id => httpClient.delete(`${resourceMapper.mapResource(resource, params)}/${id}`))).then(() => Promise.resolve())
  };
};
