<template>
  <va-show-layout :title="title" disable-edit disable-clone disable-delete disable-list>
    <va-show :item="item">
      <v-row>
        <v-col>
          <command-run-list :item="item" />
        </v-col>
      </v-row>
    </va-show>
  </va-show-layout>
</template>

<script>
import CommandRunList from "@/resources/commands/CommandRunList";

export default {
  components: { CommandRunList },
  props: ["title", "item"]
};
</script>
