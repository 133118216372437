<template>
  <va-action-button icon="mdi-pencil" :label="$t('integrations.btn.edit')" hide-label text @click="goToActions(item)" />
</template>
<script>
import Button from "@skuhnow/vuetify-admin/src/mixins/button";

export default {
  mixins: [Button],
  methods: {
    goToActions() {
      this.$router.push({
        name: "orders_show",
        params: { id: this.item.id },
        hash: "#tab-actions"
      });
    }
  }
};
</script>
