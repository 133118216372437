<template>
  <va-action-button :disabled="!item.id" icon="mdi-attachment" :label="$t('integrations.fields.link_to_json')" hide-label text @click="showLog(item)"></va-action-button>
</template>
<script>
import Button from "@skuhnow/vuetify-admin/src/mixins/button";

export default {
  mixins: [Button],
  methods: {
    showLog() {
      window.open(`${process.env.VUE_APP_API_URL}/orders/*/fulfillments/${this.item.id}`, "_blank");
    }
  }
};
</script>
