<template>
  <v-btn class="ml-2" @click="copyToClipboard(value)" x-small text outlined><v-icon x-small>mdi-content-copy</v-icon></v-btn>
</template>
<script>
export default {
  props: {
    value: String
  },
  methods: {
    copyToClipboard() {
      this.$copyText(this.value).then(() => {
        this.$store.commit(`messages/showToast`, {
          color: "success",
          message: `Text copied!`
        });
      });
    }
  }
};
</script>
