var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.campaign_code'),"rules":{
          required: true,
          regex: /^[a-zA-Z0-9_-]+$/
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"filled":"","label":_vm.$t('integrations.fields.campaign_code') + ' *',"error-messages":errors},on:{"input":_vm.updateAttributes},model:{value:(_vm.attributes.campaign_code),callback:function ($$v) {_vm.$set(_vm.attributes, "campaign_code", $$v)},expression:"attributes.campaign_code"}})]}}])})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.billingaddress_code'),"rules":{
          required: true,
          regex: /^[a-z0-9_-]+$/
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('va-autocomplete-input',{attrs:{"taggable":true,"label":_vm.$t('integrations.fields.billingaddress_code') + ' *',"reference":"companyAddressesSelfTypeBilling","placeholder":"batchjob-billing-default","item-value":"code","search-query":"q","sort-by":['code'],"error-messages":errors},on:{"input":_vm.updateAttributes},model:{value:(_vm.attributes.billingaddress_code),callback:function ($$v) {_vm.$set(_vm.attributes, "billingaddress_code", $$v)},expression:"attributes.billingaddress_code"}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }