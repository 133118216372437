var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-material-card',{attrs:{"icon":_vm.resource.icon,"title":_vm.title},scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_c('info-text',{attrs:{"resource":_vm.resource,"crud-type":"list"}})]},proxy:true}])},[_c('va-list',{attrs:{"filters":_vm.filters,"disable-export":"","items-per-page":100,"default-filter":{ created_before: '1week' }}},[_c('va-data-table',{attrs:{"fields":_vm.fields,"disable-clone":"","disable-delete":"","disable-edit":"","disable-select":""},scopedSlots:_vm._u([{key:"field.status",fn:function(ref){
var value = ref.value;
return [(value)?_c('div',[(value === 'success')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"success"}},on),[_vm._v("mdi-check")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("integrations.shipping_calculations.status.success")))])]):(value === 'no_rates')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"error"}},on),[_vm._v("mdi-close")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("integrations.shipping_calculations.status.no_rates")))])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"warning"}},on),[_vm._v("mdi-alert")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(value))])])],1):_vm._e()]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }