var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-form',{attrs:{"id":_vm.id,"item":_vm.item}},[_c('base-material-tabs-card',{attrs:{"tabs":_vm.tabs},scopedSlots:_vm._u([{key:"usergroups_information",fn:function(){return [_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.channel'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
return [_c('va-autocomplete-input',{attrs:{"label":_vm.$t('integrations.fields.channel') + ' *',"source":"channel","readonly":_vm.isEdit,"autofocus":!_vm.isEdit,"reference":"channels_globals_as_defaults","hint":_vm.$t('integrations.helper.user_group.channel'),"error-messages":errors,"search-query":"q","sort-by":['name']},on:{"input":validate}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
return [_c('va-select-input',{attrs:{"label":_vm.$t('integrations.fields.type') + ' *',"source":"type","hint":_vm.$t('integrations.helper.user_group.type'),"choices":_vm.UserGroupTypeVariants,"item-text":"name","item-value":"id","error-messages":errors},on:{"input":validate}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
return [_c('va-text-input',{attrs:{"label":_vm.$t('integrations.fields.name') + ' *',"source":"name","hint":_vm.$t('integrations.helper.user_group.name'),"error-messages":errors},on:{"input":validate}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.code'),"rules":{
              required: true,
              regex: /^[a-zA-Z0-9_-]+$/,
              uniqueValidator: !_vm.isEdit ? { resource: 'usergroups', field: 'codeEquals' } : false
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var validate = ref.validate;
            var errors = ref.errors;
return [_c('va-text-input',{attrs:{"readonly":_vm.isEdit,"label":_vm.$t('integrations.fields.code') + ' *',"source":"code","error-messages":errors,"hint":_vm.$t('integrations.helper.user_group.code')},on:{"input":validate}})]}}])})],1)],1)]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex align-center"},[_c('va-save-button'),_c('va-cancel-button',{staticClass:"ml-2"})],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }