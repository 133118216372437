import i18n from "@/i18n";

const regex = /^(ssh-ed25519 AAAAC3NzaC1lZDI1NTE5|sk-ssh-ed25519@openssh\.com AAAAGnNrLXNzaC1lZDI1NTE5QG9wZW5zc2guY29t|ssh-rsa AAAAB3NzaC1yc2)[0-9A-Za-z+/]+[=]{0,3}(\s.*)?$/;

export default {
  validate(value) {
    const lines = value.trim().split(/\r?\n/);
    return lines.every(line => regex.test(line));
  },
  message: i18n.t("integrations.error.not_valid_ssh_keys")
};
