<template>
  <base-material-card :icon="resource.icon" :title="title">
    <template v-slot:subtitle>
      <info-text :resource="resource" crud-type="list" />
    </template>
    <va-list :filters="filters" :sort-by="['code']" disable-export>
      <template v-slot:actions="slotProps">
        <export-poll-button :resource="resource.name" text :options="slotProps.listState.options" :filter="slotProps.currentFilter" />
      </template>
      <va-data-table
        :fields="fields"
        disable-show
        disable-clone
        disable-select
        :can-edit="canEditDelete"
        :can-delete="canEditDelete"
        override-delete-title="integrations.usergroups.messages.delete_prompt_title"
        override-delete-message="integrations.usergroups.messages.delete_prompt"
      />
    </va-list>
  </base-material-card>
</template>
<script>
import i18n from "@/i18n";
import { UserGroupTypeVariants } from "@/resources/usergroups/helper";
import InfoText from "@/components/helper/InfoText";
import ExportPollButton from "../../components/export/ExportPollButton";

export default {
  components: { ExportPollButton, InfoText },
  props: ["resource", "title"],
  data() {
    return {
      fields: [
        {
          label: i18n.t("integrations.fields.code"),
          source: "code",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.channel"),
          source: "channel_data",
          type: "reference",
          sortable: true,
          attributes: {
            reference: "channels",
            action: null
          }
        },
        {
          label: i18n.t("integrations.fields.type"),
          source: "type",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.name"),
          source: "name",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.created_at"),
          source: "created_at",
          type: "date",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.updated_at"),
          source: "updated_at",
          type: "date",
          sortable: true
        }
      ],
      filters: [
        {
          label: i18n.t("integrations.fields.channel"),
          source: "channel",
          type: "autocomplete",
          resettable: true,
          alwaysOn: true,
          attributes: {
            emptyOption: true,
            emptyOptionText: i18n.t("va.messages.empty"),
            reference: "channels_globals_as_defaults",
            searchQuery: "q",
            sortBy: ["name"]
          }
        },
        {
          label: i18n.t("integrations.fields.type"),
          source: "type",
          type: "select",
          attributes: {
            choices: UserGroupTypeVariants,
            itemText: "name",
            itemValue: "id"
          },
          alwaysOn: true,
          resettable: true
        }
      ]
    };
  },
  methods: {
    canEditDelete(item) {
      return item && !!item.channel;
    }
  }
};
</script>
