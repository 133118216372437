<template>
  <v-autocomplete v-bind="commonProps" filled :items="timezones" @change="change" @input="update" />
</template>

<script>
import Input from "@skuhnow/vuetify-admin/src/mixins/input";
import Editable from "@skuhnow/vuetify-admin/src/mixins/editable";
import Timezones from "./timezones";

export default {
  mixins: [Input, Editable],
  data() {
    return {
      timezones: Timezones
    };
  },
  props: {
    value: {
      type: String
    }
  }
};
</script>
