<template>
  <validation-form :id="id" :item="item">
    <base-material-tabs-card :tabs="tabs">
      <template v-slot:general>
        <v-row>
          <v-col md="4" cols="12">
            <validation-provider :name="$t('integrations.fields.domain')" rules="required|fqdn" v-slot="{ validate, errors }">
              <va-text-input :autofocus="!isEdit" :label="$t('integrations.fields.domain') + ' *'" source="full_qualified_domain_name" @input="validate" :error-messages="errors" />
            </validation-provider>
          </v-col>
          <v-col md="4" cols="12">
            <va-autocomplete-input
              :label="$t('integrations.fields.company')"
              source="company"
              reference="companies"
              search-query="name"
              clearable
              :sort-by="['name']"
            ></va-autocomplete-input>
          </v-col>
          <v-col md="4" cols="12">
            <va-autocomplete-input
              :label="$t('integrations.fields.sso_connection')"
              source="sso_connection"
              reference="ssoConnections"
              search-query="code"
              item-value="code"
              item-text="code"
              clearable
              :sort-by="['code']"
            ></va-autocomplete-input>
          </v-col>
        </v-row>
      </template>
      <template v-slot:footer>
        <div class="d-flex align-center"><va-save-button /><va-cancel-button class="ml-2" /></div>
      </template>
    </base-material-tabs-card>
  </validation-form>
</template>
<script>
export default {
  props: ["id", "title", "item", "isEdit"],
  computed: {
    tabs() {
      return [
        {
          id: "general",
          label: this.isEdit ? this.$t("integrations.domains.title.edit", { label: this.id }) : this.$t("integrations.domains.title.create")
        }
      ];
    }
  }
};
</script>
