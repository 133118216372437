<template>
  <div>
    <div class="body-1 my-2">{{ $t("integrations.fields.attachments") }}</div>
    <div v-for="document of item.documents" :key="document">
      <files-endpoint-download :uri="document" />
    </div>
  </div>
</template>
<script>
import FilesEndpointDownload from "@/components/Files/FilesEndpointDownload.vue";

export default {
  components: { FilesEndpointDownload },
  props: ["item"]
};
</script>
