function getCurrencyName(currencyCode, locale = "en-US") {
  const currencyName = new Intl.DisplayNames([locale], { type: "currency" });
  return currencyName.of(currencyCode);
}

// Examples
export default [
  { value: "EUR", text: getCurrencyName("EUR") },
  { value: "USD", text: getCurrencyName("USD") },
  { value: "CNY", text: getCurrencyName("CNY") },
  { value: "DKK", text: getCurrencyName("DKK") },
  { value: "AUD", text: getCurrencyName("AUD") },
  { value: "GBP", text: getCurrencyName("GBP") },
  { value: "CHF", text: getCurrencyName("CHF") },
  { value: "BRL", text: getCurrencyName("BRL") }
];
