<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-icon v-on="on">{{ icon }}</v-icon>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>
<script>
export default {
  props: {
    value: {
      required: true
    }
  },
  computed: {
    icon() {
      switch (this.value) {
        case "active":
        case "loaded":
          return "mdi-check";
        default:
          return "mdi-close";
      }
    },
    tooltip() {
      return this.value;
    }
  }
};
</script>
