var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-form',{ref:"validationForm",attrs:{"id":_vm.id,"item":_vm.item},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_c('base-material-tabs-card',{attrs:{"tabs":_vm.tabs},scopedSlots:_vm._u([{key:"communication-gateways_default",fn:function(){return [_c('v-row',[_c('v-col',[_c('va-boolean-input',{attrs:{"source":"enabled","label":_vm.$t('integrations.fields.enabled')}})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.company'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
return [_c('va-autocomplete-input',{attrs:{"label":_vm.$t('integrations.fields.company') + ' *',"source":"company","readonly":_vm.isEdit,"autofocus":!_vm.isEdit,"reference":"companies","error-messages":errors,"search-query":"name","render-callback":function (record) { return record.name + ' (' + record.group + ')'; },"sort-by":['name']},on:{"input":validate,"change":_vm.changeType}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
return [_c('va-select-input',{attrs:{"label":_vm.$t('integrations.fields.type') + ' *',"source":"type","readonly":_vm.isEdit,"choices":_vm.TypeVariants,"item-text":"name","item-value":"id","error-messages":errors},on:{"input":validate,"change":_vm.changeType}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.scopes'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
return [_c('va-select-input',{attrs:{"label":_vm.$t('integrations.fields.scopes') + ' *',"source":"scopes","filled":"","choices":[
                { value: 'shipping', text: _vm.$t('integrations.communication-gateways.scope.shipping') },
                { value: 'voucher', text: _vm.$t('integrations.communication-gateways.scope.voucher') },
                { value: 'payment', text: _vm.$t('integrations.communication-gateways.scope.payment') },
                { value: 'generic', text: _vm.$t('integrations.communication-gateways.scope.generic') }
              ],"multiple":"","error-messages":errors},on:{"input":validate}})]}}])})],1)],1),(_vm.hasEndpoint)?_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.endpoint'),"rules":{
              max: 2083,
              required: true,
              url: _vm.model.type !== 'email',
              email: _vm.model.type === 'email'
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var validate = ref.validate;
            var errors = ref.errors;
return [_c('va-text-input',{attrs:{"label":_vm.$t('integrations.fields.endpoint') + ' *',"readonly":_vm.model.type === 'filesystem',"source":"endpoint","show-copy-paste":"","hint":_vm.$t('integrations.helper.communication-gateways.endpoint'),"error-messages":errors.length ? errors.concat( [_vm.$t('integrations.helper.communication-gateways.endpoint')]) : errors},on:{"input":validate},model:{value:(_vm.model.endpoint),callback:function ($$v) {_vm.$set(_vm.model, "endpoint", $$v)},expression:"model.endpoint"}})]}}],null,false,3943188610)})],1)],1):_vm._e(),(_vm.model.type === 'filesystem')?_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.ssh_public_key'),"rules":{
              required: true,
              sshKeys: true
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var validate = ref.validate;
            var errors = ref.errors;
return [_c('va-text-input',{attrs:{"multiline":"","label":_vm.$t('integrations.fields.ssh_public_key') + ' *',"source":"credentials.public_key","error-messages":errors},on:{"input":validate}})]}}],null,false,2763591938)})],1)],1):_vm._e(),(_vm.hasTopics)?_c('v-row',[_c('v-col',[_c('va-select-input',{attrs:{"label":_vm.$t('integrations.fields.topics') + ' *',"readonly":_vm.isEdit,"source":"topics","choices":_vm.TopicsVariants,"item-text":"name","item-value":"id","multiple":"","filled":"","rules":_vm.rules}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('code-input',{attrs:{"label":_vm.$t('integrations.fields.options'),"value":"","source":"options"}})],1)],1)]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex align-center"},[_c('va-save-button'),_c('va-cancel-button',{staticClass:"ml-2"}),(_vm.isEdit)?_c('test-gateway-button',{attrs:{"item":_vm.item}}):_vm._e()],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }