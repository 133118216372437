var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-material-card',{attrs:{"icon":_vm.resource.icon,"title":_vm.title},scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_c('info-text',{attrs:{"resource":_vm.resource,"crud-type":"list"}})]},proxy:true}])},[_c('va-list',{ref:"batchJobList",attrs:{"filters":_vm.filters,"disable-export":"","disable-create":""},scopedSlots:_vm._u([{key:"actions",fn:function(slotProps){return [_c('export-poll-button',{attrs:{"resource":_vm.resource.name,"text":"","options":slotProps.listState.options,"filter":slotProps.currentFilter}})]}}])},[_c('va-data-table',{attrs:{"fields":_vm.fields,"disable-edit":"","disable-show":"","disable-clone":"","disable-delete":"","item-key":"identifiers.internal"},scopedSlots:_vm._u([{key:"field.stock",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [(value > 0)?_c('ul',_vm._l((item.inventories),function(warehouse,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(warehouse.code)+": "),(value)?_c('router-link',{attrs:{"to":{
                name: "warehouseProducts_list",
                query: {
                  filter: JSON.stringify({
                    _warehouse_code: warehouse.code,
                    product_code: item.identifiers.internal
                  })
                }
              }}},[_vm._v(" "+_vm._s(warehouse.stock)+" ")]):_vm._e()],1)}),0):_c('span',[_vm._v(_vm._s(value))])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }