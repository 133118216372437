var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-form',{attrs:{"id":_vm.id,"item":_vm.item}},[_c('base-material-tabs-card',{attrs:{"tabs":_vm.tabs},scopedSlots:_vm._u([{key:"inventory-blockings_default",fn:function(){return [_c('v-row',[_c('v-col',[(!_vm.isEdit)?_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.sku'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
return [_c('va-autocomplete-input',{attrs:{"label":_vm.$t('integrations.fields.sku') + ' *',"source":"product_code","reference":"product-variants","error-messages":errors,"item-value":"identifiers.internal","render-callback":function (record) { return _vm.productLabel(record); },"search-query":"q"},on:{"input":validate}})]}}],null,false,3589283399)}):_vm._e(),(_vm.isEdit)?_c('va-label-input',{attrs:{"label":_vm.$t('integrations.fields.sku'),"source":"product_code","reference":"product-variants","render-callback":function (id, record) { return (record ? _vm.productLabel(record) : id); }}}):_vm._e()],1),_c('v-col',[(!_vm.isEdit)?_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.warehouse'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
return [_c('va-autocomplete-input',{attrs:{"label":_vm.$t('integrations.fields.warehouse') + ' *',"item-text":"label","source":"warehouse_code","reference":"warehouses","render-callback":function (record) { return (record.label ? record.code + ' (' + record.label + ')' : record.code); },"error-messages":errors},on:{"input":validate}})]}}],null,false,2271495421)}):_vm._e(),(_vm.isEdit)?_c('va-label-input',{attrs:{"label":_vm.$t('integrations.fields.warehouse'),"source":"warehouse_code","reference":"warehouses","render-callback":function (id, record) { return (record ? (record.label ? record.code + ' (' + record.label + ')' : record.code) : id + ' (' + _vm.$t('integrations.warehouse.messages.virtual') + ')'); }}}):_vm._e()],1)],1),_c('v-row',[(_vm.isEdit)?_c('v-col',{attrs:{"md":"6","cols":"12","order":"2"}},[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.original_quantity'),"rules":"required|numeric|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
return [_c('va-number-input',{attrs:{"label":_vm.$t('integrations.fields.original_quantity') + ' *',"source":"original_quantity","error-messages":errors},on:{"input":validate}})]}}],null,false,3529491980)})],1):_vm._e(),_c('v-col',{attrs:{"md":_vm.isEdit ? 6 : 12,"cols":"12","order":"1"}},[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.quantity'),"rules":{
              required: true,
              numeric: true,
              min_value: 0,
              max_value: _vm.isEdit ? '@' + _vm.$t('integrations.fields.original_quantity') : false
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var validate = ref.validate;
            var errors = ref.errors;
return [_c('va-text-input',{attrs:{"label":_vm.$t('integrations.fields.quantity') + ' *',"source":"quantity","error-messages":errors,"persistent-hint":""},on:{"input":validate}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.reference'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var validate = ref.validate;
            var errors = ref.errors;
return [_c('va-text-input',{attrs:{"label":_vm.$t('integrations.fields.reference') + ' *',"source":"reference","multiline":"","error-messages":errors},on:{"input":validate}})]}}])})],1)],1),(_vm.isEdit)?_c('v-row',[_c('v-col',[_c('va-text-input',{attrs:{"label":_vm.$t('integrations.fields.usages'),"source":"usages"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('va-date-input',{attrs:{"label":_vm.$t('integrations.fields.scheduled_for'),"source":"scheduled_for","format":"long"}})],1)],1),_c('v-row',[_c('v-col',[_c('va-array-input',{attrs:{"label":_vm.$t('integrations.fields.attributes'),"source":"attributes_key_value"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.attributes.key'),"rules":{
                    required: true
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var validate = ref.validate;
                  var errors = ref.errors;
return [_c('va-combobox-input',_vm._b({attrs:{"label":_vm.$t('integrations.attributes.key') + ' *',"source":"key","choices":_vm.InventoryBlockingsAttributes,"item-text":"name","item-value":"id","error-messages":errors},on:{"input":validate}},'va-combobox-input',props,false))]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('va-text-input',_vm._b({attrs:{"label":_vm.$t('integrations.attributes.value'),"source":"value"}},'va-text-input',props,false))],1)],1)]}}])})],1)],1)]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex align-center"},[_c('va-save-button'),_c('va-cancel-button',{staticClass:"ml-2"})],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }