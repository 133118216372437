import i18n from "@/i18n";

const BudgetTypeVariants = [
  { id: "single", name: i18n.t("integrations.budget.type.single") },
  { id: "multi", name: i18n.t("integrations.budget.type.multi") },
  { id: "budget", name: i18n.t("integrations.budget.type.budget") }
];

const BudgetScopeVariants = [
  { id: "order", name: i18n.t("integrations.budget.scope.order") },
  { id: "products", name: i18n.t("integrations.budget.scope.products") }
];

const BudgetStateVariants = [
  { id: "pending", name: i18n.t("integrations.budget.state.pending") },
  { id: "approved", name: i18n.t("integrations.budget.state.approved") },
  { id: "declined", name: i18n.t("integrations.budget.state.declined") },
  {
    id: "waiting_for_approval",
    name: i18n.t("integrations.budget.state.waiting_for_approval")
  }
];

export { BudgetTypeVariants, BudgetStateVariants, BudgetScopeVariants };
