<template>
  <v-row justify="center">
    <v-col sm="4">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-input :label="$t('integrations.fields.subject')" class="va-input"
                  ><div>
                    <va-text-field source="subject" />
                  </div>
                </v-input>

                <v-input :label="$t('integrations.fields.description')" class="va-input"
                  ><div>
                    <va-text-field :aria-multiline="true" source="description" />
                  </div>
                </v-input>
                <v-input v-if="item.files.length > 0" :label="$t('integrations.fields.files')" class="va-input">
                  <ul>
                    <li v-for="(file, index) in item.files" :key="index">
                      <files-endpoint-download :uri="file" file-type="issues" />
                    </li>
                  </ul>
                </v-input>

                <additional-data-field v-if="item.additional_data" />

                <v-input :label="$t('integrations.fields.order')" class="va-input"
                  ><div>
                    <va-text-field source="order" />
                  </div>
                </v-input>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import AdditionalDataField from "@/components/fields/AdditionalDataField";
import FilesEndpointDownload from "@/components/Files/FilesEndpointDownload.vue";

export default {
  components: { FilesEndpointDownload, AdditionalDataField },
  props: ["item"]
};
</script>
