<template>
  <a href="#" @click="downloadFile()">{{ resolvedDisplayName }}</a>
</template>
<script>
export default {
  props: {
    uri: {
      type: String,
      required: true
    },
    displayName: {
      type: String
    },
    fileType: {
      type: String,
      default: ""
    }
  },
  methods: {
    downloadFile() {
      window.open(this.downloadUrl, "_blank");
    }
  },
  computed: {
    downloadUrl() {
      return `${process.env.VUE_APP_API_URL}/files/${this.uriTypeName}`;
    },
    uriTypeName() {
      if (this.uri.substring(0, 5) === "s3://") {
        return this.uri.substring(5);
      }
      if (this.fileType && !this.uri.includes(this.fileType)) {
        return `${this.fileType}/${this.uri}`;
      }
      return this.uri;
    },
    resolvedDisplayName() {
      if (this.displayName) {
        return this.displayName;
      }

      // resolve name "download.csv" from url param like "/issues/abc?name=download.csv"
      const url = new URL("/" + this.uriTypeName, "http://localhost");
      const searchParams = url.searchParams;
      if (searchParams.has("name")) {
        return searchParams.get("name");
      }

      // return last part of "issues/foo.png" => "foo.png"
      if (this.uriTypeName.includes("/")) {
        return this.uriTypeName.split("/")[1];
      }

      return this.uriTypeName;
    }
  }
};
</script>
