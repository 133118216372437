<template>
  <v-row>
    <v-col>
      <v-card :loading="isLoading">
        <v-card-title>
          {{ $t("integrations.events.title") }}
        </v-card-title>
        <v-card-actions>
          <v-btn class="ml-3" @click="refreshAll()">{{ $t("integrations.btn.refresh") }}</v-btn>
        </v-card-actions>
        <v-card-text v-if="events">
          <v-container>
            <v-row>
              <v-col cols="12" v-for="(event, index) in events" :key="index">
                <v-card>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <div>
                      <v-card-actions>
                        <v-btn x-small @click="openLink(event.url)"><v-icon x-small>mdi-open-in-new</v-icon></v-btn>
                        <v-btn x-small @click="refresh(event.id)">{{ $t("integrations.btn.refresh") }}</v-btn>
                      </v-card-actions>
                      <v-card-title class="mb-3">
                        {{ event.title }}
                      </v-card-title>
                      <v-card-subtitle v-if="event.id in eventData">
                        {{ eventData[event.id].last.value }} - {{ new Date(eventData[event.id].last.time * 1000).toLocaleDateString() }}
                        {{ new Date(eventData[event.id].last.time * 1000).toLocaleTimeString() }}
                      </v-card-subtitle>
                    </div>
                    <div v-if="event.id in eventData" class="pr-3 py-3">
                      <events-chart :event-data="eventData[event.id].results" />
                    </div>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import Vue from "vue";
import EventsChart from "@/components/dashboard/EventsChart";

export default {
  components: { EventsChart },
  data() {
    return {
      isLoading: false,
      events: null,
      eventData: {}
    };
  },

  async created() {
    this.loadEvents();
  },

  methods: {
    refreshAll() {
      this.events.forEach(event => this.refresh(event.id));
    },
    async refresh(id) {
      this.$admin.http.get(`/events/queries/${id}/charts/histogram?range=2592000&interval=day`).then(response => {
        Vue.set(this.eventData, id, response.data);
      });
    },
    hasEventDataForId(id) {
      return id in this.eventData;
    },
    getEventDataForId(id) {
      return this.eventData[id];
    },
    async loadEvents() {
      const response = await this.$admin.http.get("/events/queries");
      this.events = response.data;
    },
    openLink(link) {
      window.open(link, "_blank");
    }
  }
};
</script>
