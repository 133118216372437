<template>
  <v-dialog width="700" @input="handleFormStatusChange($event)" v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="success" text v-bind="attrs" v-on="on"> <v-icon left>mdi-plus</v-icon> {{ $t("va.actions.create") }} </v-btn>
    </template>
    <validation-observer v-slot="{ handleSubmit, invalid }">
      <v-form ref="form" @submit.prevent="handleSubmit(onSubmit)">
        <v-card>
          <v-card-title>{{ $t("integrations.batchjobs.title.create") }}</v-card-title>
          <v-card-text>
            <v-row class="mt-2">
              <v-col>
                <v-select filled v-model="formType" :items="availableTypes" :label="$t('integrations.batchjobs.form.type')" />
                <v-btn v-if="availableTypes.length && formType" outlined text @click="downloadTemplate">{{ $t("integrations.batchjobs.form.download_template") }}</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <validation-provider :name="$t('integrations.batchjobs.form.file')" immediate rules="required" v-slot="{ errors }">
                  <v-file-input filled v-model="formFile" truncate-length="50" show-size :label="$t('integrations.batchjobs.form.file')" :error-messages="errors" />
                </validation-provider>
              </v-col>
            </v-row>
            <batch-job-attributes class="mt-2" :batch-job-type="formType" v-model="formAttributes" />
            <v-row no-gutters>
              <v-col>
                <v-checkbox v-model="runAfterUpload" :label="$t('integrations.batchjobs.form.run_after_upload')" />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" type="submit" :disabled="invalid" :loading="isLoading">{{ $t("va.actions.create") }}</v-btn>
            <v-btn @click="dialog = false">{{ $t("va.actions.cancel") }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </validation-observer>
  </v-dialog>
</template>
<script>
import Button from "@skuhnow/vuetify-admin/src/mixins/button";
import BatchJobAttributes from "@/resources/batchjobs/components/BatchJobAttributes";

export default {
  components: { BatchJobAttributes },
  mixins: [Button],
  data() {
    return {
      availableTypes: [],
      dialog: false,
      invalid: false,
      isLoading: false,
      runAfterUpload: true,
      formType: null,
      formFile: null,
      formAttributes: {}
    };
  },
  watch: {
    formType() {
      this.resetForm();
    }
  },
  methods: {
    async loadTypes() {
      const response = await this.$admin.http.get("/batchjob_types");
      this.availableTypes = response.data.data.map(item => {
        return {
          value: item.key,
          text: this.$t(`integrations.batchjobs.type.${item.key}`)
        };
      });
      if (this.availableTypes.length) {
        this.formType = this.availableTypes[0].value;
      }
    },
    async handleFormStatusChange(formOpen) {
      if (formOpen) {
        this.resetForm();
        await this.loadTypes();
      }
    },
    async onSubmit() {
      setTimeout(async () => {
        let formData = new FormData();
        formData.append("type", this.formType);

        // map attributes
        formData.append("attributes", JSON.stringify(Object.fromEntries(Object.entries(this.formAttributes).filter(v => v != null))));
        formData.append("file", this.formFile);

        const response = await this.$admin.http.post("/batchjobs", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });
        this.$emit("batchjob-created", { location: response.headers.location, runAfterUpload: this.runAfterUpload });
        this.dialog = false;
      }, 1);
    },
    resetForm() {
      this.formFile = null;
      this.runAfterUpload = true;
      this.formAttributes = {};
    },
    downloadTemplate() {
      window.open(`${process.env.VUE_APP_API_URL}/batchjob_types/${this.formType}/template`, "_blank");
    }
  }
};
</script>
