<template>
  <div v-html="callback(value, record)"></div>
</template>

<script>
import Field from "@skuhnow/vuetify-admin/src/mixins/field";

export default {
  mixins: [Field],
  props: ["callback"]
};
</script>
