import Vue from "vue";
import VueI18n from "vue-i18n";
import * as domainMessages from "./i18n_loco";
import en from "vee-validate/dist/locale/en.json";
import de from "vee-validate/dist/locale/de.json";
import uk from "vee-validate/dist/locale/uk.json";
import zh from "vee-validate/dist/locale/zh_CN.json";
import pt from "vee-validate/dist/locale/pt_PT.json";

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context("./locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = { ...locales(key), ...domainMessages.default[locale] };

      switch (locale) {
        case "en":
          messages[locale].validation = en.messages;
          break;
        case "de":
          messages[locale].validation = de.messages;
          break;
        case "uk":
          messages[locale].validation = uk.messages;
          break;
        case "zh":
          messages[locale].validation = zh.messages;
          break;
        case "pt":
          messages[locale].validation = pt.messages;
          break;
      }
    }
  });
  return messages;
}

export default new VueI18n({
  locale: navigator.language,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: loadLocaleMessages()
});
