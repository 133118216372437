<template>
  <v-stepper v-if="batchJob && batchJobRun && stages && initialized" v-model="stepperIndex" alt-labels>
    <v-stepper-header>
      <template v-for="(stage, index) in stages">
        <v-stepper-step
          :key="`${stage.id}-step`"
          :step="index + 1"
          :complete="isFirstStageOrPreviousStageIsFinished(index)"
          :editable="isFirstStageOrPreviousStageIsFinished(index)"
          :color="statusColor(stage.status)"
          :edit-icon="statusIcon(stage.status)"
        >
          <span class="text-no-wrap">{{ translateStageName(stage.name) }}</span>
          <small class="mt-1">{{ statusTranslation(stage.status) }}</small>
        </v-stepper-step>
        <v-divider v-if="index < stages.length" :key="`${stage.id}-divider`" />
      </template>
      <v-stepper-step
        v-if="batchJobRun"
        :step="stages.length + 1"
        :complete="batchJobRun.status === 'finished'"
        editable
        :color="statusColor(batchJobRun.status)"
        :edit-icon="statusIcon(batchJobRun.status)"
      >
        {{ $t("integrations.batchjobs.title.result") }}
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content v-for="(stage, index) in stages" :key="`${stage.id}-content`" :step="index + 1">
        <stage-result v-if="stepperIndex === index + 1" :batch-run-id="batchRunId" :stage-id="stage.id" />
      </v-stepper-content>
      <v-stepper-content v-if="batchJobRun" :step="stages.length + 1">
        <run-result v-if="stepperIndex === stages.length + 1" :batch-job-id="batchJobId" :batch-run-id="batchRunId" />
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import StageResult from "@/resources/batchjobs/StageResult";
import RunResult from "@/resources/batchjobs/RunResult";
import StageStatusHelper from "@/resources/batchjobs/mixins/StageStatusHelper";

export default {
  components: { RunResult, StageResult },
  props: ["batchJobId", "batchRunId"],
  mixins: [StageStatusHelper],
  destroyed() {
    this.$store.commit("breadcrumbs/setItems", null);
  },
  async created() {
    await this.initialize();
    this.setupBreadcrumbs();
  },
  data() {
    return {
      initialized: false,
      dataTableHeaders: [],
      dataTableItems: []
    };
  },
  computed: {
    batchJob() {
      return this.$store.state.batch.batchJob;
    },
    batchJobRun() {
      return this.$store.state.batch.batchJobRun;
    },
    stages() {
      return this.$store.state.batch.stages;
    },
    stepperIndex: {
      get() {
        return this.$store.state.batch.stepperIndex;
      },
      set(value) {
        this.$store.commit("batch/setStepperIndex", value);
      }
    }
  },
  methods: {
    async initialize() {
      await this.$store.dispatch("batch/loadBatchJob", this.batchJobId);
      await this.$store.dispatch("batch/loadBatchJobRun", {
        batchJobId: this.batchJobId,
        batchRunId: this.batchRunId
      });
      await this.$store.dispatch("batch/loadStages", {
        batchJobId: this.batchJobId,
        batchRunId: this.batchRunId
      });

      if (!this.$route.query.step) {
        let stepperIndex = this.stages.length + 1;

        this.stages.every((stage, index) => {
          if (stage.status !== "finished") {
            stepperIndex = index + 1;
            return false;
          }
          return true;
        });

        this.stepperIndex = stepperIndex;
      }
      this.initialized = true;
    },
    isFirstStageOrPreviousStageIsFinished(index) {
      return index === 0 || this.stages[index - 1].status === "finished";
    },
    translateStageName(stageName) {
      const translationKey = "integrations.batchjobs.stages." + stageName;
      const translation = this.$t(translationKey);
      if (translationKey === translation) {
        return stageName;
      }
      return translation;
    },
    setupBreadcrumbs() {
      this.$store.commit("breadcrumbs/setItems", [
        {
          text: "Start",
          disabled: false,
          href: "/"
        },
        {
          text: this.$i18n.t("integrations.menu.batchjobs"),
          exact: true,
          to: { name: "batchjobs_list" }
        },
        {
          text: `${this.batchJob.filename} (${this.$i18n.t("integrations.batchjobs.type." + this.batchJob.type)})`
        }
      ]);
    }
  }
};
</script>
