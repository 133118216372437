<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            {{ $t("integrations.fields.sku") }}
          </th>
          <th class="text-right">
            {{ $t("integrations.fields.quantity") }}
          </th>
          <th class="text-right">
            {{ $t("integrations.fields.unit_price") }}
          </th>
          <th class="text-right">
            {{ $t("integrations.fields.line_total") }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in item.items" :key="row.id">
          <td>{{ row.variant.code }}</td>
          <td class="text-right">{{ row.quantity }}</td>
          <td class="text-right">{{ formatWithCurrency(row.unit_price, currencyCode) }}</td>
          <td class="text-right">{{ formatWithCurrency(row.unit_price * row.quantity, currencyCode) }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
import MoneyHelper from "@/mixins/MoneyHelper";

export default {
  props: ["item"],
  mixins: [MoneyHelper],
  data() {
    return {};
  },
  computed: {
    currencyCode() {
      if (this.item.payments.length === 0) return "EUR";
      return this.item.payments[0].currency_code;
    }
  }
};
</script>
