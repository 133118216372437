<template>
  <base-material-card :icon="resource.icon" :title="title">
    <template v-slot:subtitle>
      <info-text :resource="resource" crud-type="list" />
    </template>
    <va-list :filters="filters" :sort-by="['created']" :sort-desc="['true']" disable-export>
      <template v-slot:actions="slotProps">
        <export-poll-button :resource="resource.name" text :options="slotProps.listState.options" :filter="slotProps.currentFilter" />
      </template>
      <va-data-table :fields="fields" disable-show />
    </va-list>
  </base-material-card>
</template>

<script>
import i18n from "@/i18n";
import InfoText from "@/components/helper/InfoText";
import ExportPollButton from "../../components/export/ExportPollButton";

export default {
  components: { ExportPollButton, InfoText },
  props: ["resource", "title"],
  data() {
    return {
      fields: [
        {
          label: i18n.t("integrations.fields.company"),
          source: "company_data",
          type: "reference-array",
          sortable: true,
          attributes: {
            reference: "companies",
            action: this.$admin.can(["ROLE_ADMIN"]) ? "edit" : null
          }
        },
        {
          label: i18n.t("integrations.fields.username"),
          source: "user_data",
          type: "reference-array",
          attributes: {
            reference: "users",
            action: this.$admin.can(["ROLE_API_USERS"]) ? "edit" : null
          }
        },
        {
          label: i18n.t("integrations.fields.first_name"),
          source: "first_name",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.last_name"),
          source: "last_name",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.organization"),
          source: "organization",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.street"),
          source: "street",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.postal_code"),
          source: "postal_code",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.city"),
          source: "city",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.country"),
          source: "country"
        },
        {
          label: i18n.t("integrations.fields.created_at"),
          source: "created",
          type: "date",
          sortable: true
        }
      ],
      filters: [
        {
          label: i18n.t("integrations.fields.country"),
          source: "country",
          type: "autocomplete",
          resettable: true,
          alwaysOn: true,
          attributes: {
            reference: "countries",
            searchQuery: "q"
          }
        },
        {
          label: i18n.t("integrations.fields.company"),
          source: "company",
          type: "autocomplete",
          alwaysOn: true,
          resettable: true,
          attributes: {
            reference: "companies",
            searchQuery: "q",
            sortBy: ["name"]
          }
        },
        {
          label: i18n.t("integrations.fields.username"),
          source: "userId",
          type: "autocomplete",
          resettable: true,
          attributes: {
            reference: "users",
            searchQuery: "q"
          }
        }
      ]
    };
  }
};
</script>
