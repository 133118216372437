<template>
  <va-show-layout :title="title" disable-delete disable-edit disable-clone>
    <va-show :item="item">
      <v-row justify="center">
        <v-col sm="10">
          <v-card v-if="item">
            <v-card-text>
              <va-field source="carrier"></va-field>
              <va-display-json-field source="accounts" />
              <va-field source="channel"></va-field>
              <va-field source="order_id"></va-field>
              <va-field source="user"></va-field>
              <va-display-json-field source="request" />
              <va-display-json-field source="rates" />
              <va-display-json-field source="resolutions" />
              <va-display-json-field source="messages" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </va-show>
  </va-show-layout>
</template>

<script>
export default {
  props: ["title", "item"]
};
</script>
