import axios from "axios";

/**
 * Axios instance
 */
const baseURL = process.env.VUE_APP_API_URL || "http://localhost:8000";

export default axios.create({
  baseURL,
  withCredentials: true
});
