<template>
  <base-material-card :icon="resource.icon" :title="title">
    <template v-slot:subtitle>
      <info-text :resource="resource" crud-type="list" />
    </template>
    <va-list :filters="filters" :sort-by="['created_at']" :sort-desc="['true']" disable-global-search disable-export disable-create>
      <va-data-table :fields="fields" disable-edit disable-clone disable-delete>
        <template v-slot:[`field.resultFilename`]="{ item }">
          <files-endpoint-download v-if="item && item.result && item.result.filename" :uri="item.result.filename" />
        </template>
      </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
import InfoText from "@/components/helper/InfoText";
import i18n from "@/i18n";
import FilesEndpointDownload from "../../components/Files/FilesEndpointDownload";

export default {
  components: { FilesEndpointDownload, InfoText },
  props: ["resource", "title"],
  data() {
    return {
      fields: [
        {
          label: i18n.t("integrations.fields.state"),
          source: "state"
        },
        {
          label: i18n.t("integrations.fields.filename"),
          source: "resultFilename"
        },
        {
          label: i18n.t("integrations.fields.created_by"),
          source: "created_by"
        },
        {
          label: i18n.t("integrations.fields.created_at"),
          type: "date",
          source: "created_at",
          sortable: true
        }
      ],
      filters: []
    };
  }
};
</script>
