<template>
  <v-data-table :headers="headers" :items="dataRows">
    <template #item.attributes="{ item }">
      <ul v-if="item.attributes">
        <li v-for="(attributeKey, index) in Object.keys(item.attributes)" :key="index">
          <b>{{ attributeKey }}</b
          >: {{ item.attributes[attributeKey] }}
        </li>
      </ul>
    </template>
    <template v-slot:item.amount="{ item }">
      {{ formatWithCurrency(item.amount, order.currency_code) }}
    </template>
  </v-data-table>
</template>
<script>
import MoneyHelper from "@/mixins/MoneyHelper.js";

export default {
  props: ["order"],
  mixins: [MoneyHelper],
  async created() {
    const response = await this.$admin.http.get(`${process.env.VUE_APP_API_URL}/orders/${this.order.id}/adjustments`);
    this.dataRows = response.data.data;
  },
  data() {
    return {
      dataRows: [],
      headers: [
        { text: this.$i18n.t("integrations.fields.code"), value: "code" },
        { text: this.$i18n.t("integrations.fields.reference"), value: "id" },
        { text: this.$i18n.t("integrations.fields.type"), value: "type" },
        { text: this.$i18n.t("integrations.fields.amount"), value: "amount", align: "right" },
        { text: this.$i18n.t("integrations.fields.label"), value: "label" },
        { text: this.$i18n.t("integrations.fields.attributes"), value: "attributes" }
      ]
    };
  }
};
</script>
