<template>
  <div>
    <info-text :resource="resource" crud-type="create" />
    <va-create-layout>
      <campaigns-form :item="item" />
    </va-create-layout>
  </div>
</template>

<script>
import InfoText from "@/components/helper/InfoText";
export default {
  components: { InfoText },
  props: ["resource", "title", "item"]
};
</script>
