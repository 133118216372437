<template>
  <v-datetime-picker
    @change="change"
    @input="update"
    :datetime="dateTimeFormatted"
    v-bind="commonProps"
    date-format="dd MMMM yyyy"
    time-format="HH:mm"
    :text-field-props="{ filled: true, 'error-messages': errorMessages, hint: hint }"
    :time-picker-props="{ format: '24hr' }"
  />
</template>
<script>
import Input from "@skuhnow/vuetify-admin/src/mixins/input";
import Editable from "@skuhnow/vuetify-admin/src/mixins/input";

export default {
  mixins: [Input, Editable],
  computed: {
    dateTimeFormatted() {
      if (this.input) {
        return new Date(Date.parse(this.input));
      }
      return null;
    }
  },
  props: {
    value: {
      type: String,
      default: ""
    },
    errorMessages: {
      type: Array,
      default: () => []
    },
    hint: {
      type: String,
      default: ""
    }
  }
};
</script>
