<template>
  <validation-observer v-slot="{ invalid }">
    <form @submit.prevent="sendTest">
      <base-material-tabs-card :tabs="tabs">
        <template v-slot:send_test_mail>
          <v-row>
            <v-col md="6" cols="12">
              <v-text-field disabled filled v-model="preset" :label="$t('integrations.fields.preset')" />
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field disabled filled v-model="templateCode" :label="$t('integrations.fields.code')" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <validation-provider :name="$t('integrations.fields.language')" rules="required" v-slot="{ errors }">
                <v-select filled :items="languages" :label="$t('integrations.fields.language')" v-model="form.language" :error-messages="errors" />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <validation-provider :name="$t('integrations.emailtemplates.sendto')" rules="required" v-slot="{ errors }">
                <v-text-field filled :label="$t('integrations.emailtemplates.sendto')" v-model="form.send_to" :error-messages="errors" />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <va-code-input autofocus :label="$t('integrations.fields.payload_json')" v-model="form.payload" editor-style="height: 1000px" />
            </v-col>
          </v-row>
        </template>
        <template v-slot:footer>
          <div class="d-flex align-center">
            <va-action-button
              :label="$t('integrations.emailtemplates.action.send_test')"
              color="primary"
              type="submit"
              :loading="isSubmitting"
              icon="mdi-email-fast"
              :disabled="invalid"
            />
            <div class="ml-2"><va-action-button :label="$t('va.actions.cancel')" icon="mdi-cancel" color="secondary" @click="$router.push({ name: `emailTemplates_list` })" /></div>
            <cms-link v-if="emailTemplate && emailTemplate.cmsData" class="ml-2" :preset="preset" content="email_templates" :id="emailTemplate.cmsData.id">
              {{ $t("integrations.emailtemplates.open_in_cms") }}
            </cms-link>
          </div>
        </template>
      </base-material-tabs-card>
    </form>
  </validation-observer>
</template>
<script>
import admin from "@/plugins/admin";
import CmsLink from "@/components/helper/CmsLink.vue";

export default {
  components: { CmsLink },
  props: ["preset", "templateCode", "templateId"],
  data() {
    return {
      emailTemplate: null,
      languages: [
        { text: "Deutsch", value: "de" },
        { text: "Englisch", value: "en" },
        { text: "Französisch", value: "fr" },
        { text: "Italienisch", value: "it" },
        { text: "Portugiesisch", value: "pt" },
        { text: "Spanisch", value: "es" }
      ],
      isSubmitting: false,
      form: {
        language: "de",
        payload: null,
        send_to: null
      }
    };
  },
  created() {
    admin.http
      .get(`/email-templates/${this.preset}/${this.templateCode}`)
      .then(response => response.data)
      .then(responseData => {
        this.emailTemplate = responseData;
        this.form.payload = this.emailTemplate.template.payloadTemplate;
      });
    this.form.send_to = this.currentUser.username;
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    tabs() {
      return [
        {
          id: "send_test_mail",
          label: this.$t("integrations.emailtemplates.send_test_title")
        }
      ];
    }
  },
  methods: {
    async sendTest() {
      this.isSubmitting = true;
      await admin.http
        .post(`/email-templates/${this.preset}/${this.templateCode}/sendtest`, this.form)
        .then(() => {
          this.$store.commit(`messages/showToast`, {
            color: "success",
            message: `Testmail for template ${this.templateCode} was sent successfully.`
          });
        })
        .catch(() =>
          this.$store.commit(`messages/showToast`, {
            color: "error",
            message: `An error has occurred. The email could not be sent.`
          })
        )
        .finally(() => {
          this.isSubmitting = false;
        });
    }
  }
};
</script>
