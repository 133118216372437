var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"sm":"4"}},[_c('v-card',[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-input',{staticClass:"va-input",attrs:{"label":_vm.$t('integrations.fields.order_number')}},[_c('div',[_c('va-text-field',{attrs:{"source":"order_number"}})],1)]),_c('v-input',{staticClass:"va-input",attrs:{"label":_vm.$t('integrations.fields.order_date')}},[_c('div',[_c('va-date-field',{attrs:{"source":"order_date"}})],1)]),_c('v-input',{staticClass:"va-input",attrs:{"label":_vm.$t('integrations.fields.channel')}},[_c('div',[_c('va-text-field',{attrs:{"source":"channel.code"}})],1)]),_c('v-input',{staticClass:"va-input",attrs:{"label":_vm.$t('integrations.fields.email')}},[_c('div',[_c('router-link',{attrs:{"to":{
                      name: 'users_list',
                      query: {
                        filter: JSON.stringify({
                          q: _vm.item.customer.email
                        })
                      }
                    }}},[_vm._v(_vm._s(_vm.item.customer.email))])],1)]),_c('v-input',{staticClass:"va-input",attrs:{"label":_vm.$t('integrations.fields.order_total')}},[_c('div',[_c('va-function-field',{attrs:{"callback":_vm.formatTotalNet}})],1)]),_c('v-input',{staticClass:"va-input",attrs:{"label":_vm.$t('integrations.fields.total')}},[_c('div',[_c('va-function-field',{attrs:{"callback":_vm.formatTotal}})],1)]),_c('v-input',{staticClass:"va-input",attrs:{"label":_vm.$t('integrations.fields.status')}},[_c('div',[_c('va-select-field',{attrs:{"choices":_vm.stateVariants,"source":"state","item-text":"name","item-value":"id"}})],1)]),_c('v-input',{staticClass:"va-input",attrs:{"label":_vm.$t('integrations.fields.purpose')}},[_c('div',[_c('va-text-field',{attrs:{"source":"attributes.purpose"}})],1)]),_c('v-input',{staticClass:"va-input",attrs:{"label":_vm.$t('integrations.fields.comment')}},[_c('div',[_c('va-text-field',{attrs:{"source":"notes"}})],1)]),_c('v-input',{staticClass:"va-input",attrs:{"label":_vm.$t('integrations.fields.currency')}},[_c('div',[_c('va-text-field',{attrs:{"source":"currency_code"}})],1)])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }