<template>
  <base-material-card :icon="resource.icon" :title="title">
    <va-list disable-export disable-create :filters="filters" :sort-by="['updated_at']" :sort-desc="['true']" :items-per-page-options="[100, 500, 1000, -1]" :items-per-page="500">
      <va-data-table :fields="fields" disable-edit disable-show disable-clone disable-delete> </va-data-table>
    </va-list>
  </base-material-card>
</template>
<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      fields: [
        {
          label: "Key",
          source: "key",
          sortable: true
        },
        {
          label: "Username",
          source: "username",
          sortable: true,
          width: "300px",
          cellClass: "break-all"
        },
        {
          label: "URL",
          source: "url",
          sortable: true,
          width: "300px",
          cellClass: "break-all"
        },
        {
          label: "Note",
          source: "note",
          sortable: true,
          width: "300px",
          cellClass: "break-all"
        },
        {
          label: "Last modified at",
          type: "date",
          source: "updated_at",
          sortable: true
        }
      ],
      filters: []
    };
  }
};
</script>
