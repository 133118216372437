<template>
  <v-row no-gutters>
    <batch-job-attributes-distributed-shipping v-if="batchJobType === 'distributed_shipping'" @input="updateAttributes" :value="value" />
    <batch-job-attributes-distributed-shipping-lite v-if="batchJobType === 'distributed_shipping_lite'" @input="updateAttributes" :value="value" />
    <batch-job-attributes-incentives v-if="batchJobType === 'incentives'" @input="updateAttributes" :value="value" />
    <batch-job-attributes-budget-import v-if="batchJobType === 'budget_import'" @input="updateAttributes" :value="value" />
  </v-row>
</template>
<script>
import BatchJobAttributesDistributedShipping from "@/resources/batchjobs/components/BatchJobAttributesDistributedShipping";
import BatchJobAttributesDistributedShippingLite from "@/resources/batchjobs/components/BatchJobAttributesDistributedShippingLite";
import BatchJobAttributesIncentives from "@/resources/batchjobs/components/BatchJobAttributesIncentives";
import BatchJobAttributesBudgetImport from "@/resources/batchjobs/components/BatchJobAttributesBudgetImport.vue";

export default {
  components: { BatchJobAttributesBudgetImport, BatchJobAttributesDistributedShippingLite, BatchJobAttributesDistributedShipping, BatchJobAttributesIncentives },
  props: ["batchJobType", "value"],
  methods: {
    updateAttributes(attributes) {
      this.$emit("input", attributes);
    }
  }
};
</script>
