<template>
  <div>
    <va-list :resource="resource" :filter="filter" disable-global-search disable-create disable-export disable-persistent-filter disable-query-string>
      <va-data-table :single-expand="false" :fields="fields" disable-show disable-edit disable-clone disable-delete disable-select show-expand>
        <template v-slot:expanded-item="{ item }">
          <audit-history-details v-if="!!Object.keys(item.diffs).length" :diffs="item.diffs" />
          <audit-history-relations v-if="!!Object.keys(item.relations).length" :relations="item.relations" />
        </template>
      </va-data-table>
    </va-list>
  </div>
</template>
<script>
import i18n from "@/i18n";
import AuditHistoryDetails from "@/components/audit/AuditHistoryDetails.vue";
import AuditHistoryRelations from "@/components/audit/AuditHistoryRelations.vue";

export default {
  components: { AuditHistoryDetails, AuditHistoryRelations },
  props: {
    item: {
      type: Object,
      required: true
    },
    resource: {
      type: String,
      required: true
    },
    filter: {
      type: Object,
      required: true
    }
  },
  created() {},
  data() {
    return {
      fields: [
        {
          label: i18n.t("integrations.fields.type"),
          source: "type"
        },
        {
          label: i18n.t("integrations.fields.user"),
          source: "blame_user"
        },
        {
          label: i18n.t("integrations.fields.created_at"),
          source: "created_at",
          type: "date"
        }
      ]
    };
  }
};
</script>
