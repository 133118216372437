<style scoped lang="scss">
.text-field-readonly-copy {
  ::v-deep {
    label {
      top: 0;
    }

    input {
      color: white !important;
      background-color: black !important;
      font-family: monospace !important;
      font-size: 90% !important;
      padding: 10px 10px !important;
      box-sizing: border-box !important;
    }
  }
}
</style>
<template>
  <v-text-field :label="label" :value="value" readonly :hide-details="true" class="text-field-readonly-copy" append-icon="mdi-content-copy" @click:append="copyToClipboard()" />
</template>
<script>
export default {
  props: ["label", "value"],
  methods: {
    copyToClipboard() {
      this.$copyText(this.value).then(() => {
        this.$store.commit(`messages/showToast`, {
          color: "success",
          message: `Text copied!`
        });
      });
    }
  }
};
</script>
