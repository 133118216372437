export default {
  mapResource(resource, params) {
    if (resource === "batchJobRuns") {
      resource = "batchjobs/*/runs";
      if (params.filter && params.filter._batchJobId) {
        resource = `batchjobs/${params.filter._batchJobId}/runs`;
      }
    }
    // only show company addresses from your own company and only of the type "BILLING"
    // used for distributed shipping
    if (resource === "companyAddressesSelfTypeBilling") {
      resource = "companyAddresses";
      if (!params.filter) params.filter = {};
      params.filter.address_types = "BILLING";
    }

    if (resource === "companyAddresses") {
      if (params.filter && params.filter["_tenant"]) {
        resource = "companies/" + params.filter["_tenant"] + "/addresses";
      } else if (params.filter && params.filter.tenant) {
        resource = "companies/" + params.filter.tenant + "/addresses";
      } else if (params.filter && params.filter.company) {
        resource = "companies/" + params.filter.company + "/addresses";
      } else if (params.data && params.data.company) {
        resource = "companies/" + params.data.company + "/addresses";
      } else {
        resource = "companies/*/addresses";
      }

      if (params.filter && params.filter.tenant) {
        params.filter["_tenant"] = params.filter.tenant;
        delete params.filter.tenant;
      }
    }

    if (resource === "channels_globals_as_defaults") {
      resource = "channels";
      if (!params.query) params.query = {};
      params.query.global_channels_as_default = true;
    }

    if (resource === "channels_specific") {
      resource = "channels";
      if (!params.query) params.query = {};
      params.query.type = ["specific", "default"];
      params.query.global_channels_as_default = true;
    }

    if (resource === "commandRuns") {
      resource = "/commands/*/runs";
      if (params.filter && params.filter._id) {
        resource = `/commands/${params.filter._id}/runs`;
      }
    }

    if (resource === "exports") {
      resource = "/commands/ExportCommandConfig/runs";
    }

    if (resource === "orderFulfillments") {
      resource = "orders/*/fulfillments";
    }

    if (resource === "tenants_companies") {
      resource = "companies";
      if (params.filter && params.filter.group === undefined) {
        params.filter.group = "tenant";
      }
    }

    if (resource === "companyAddressesWarehouses") {
      resource = "companies/*/addresses";
      if (params.filter && params.filter.address_types === undefined) {
        params.filter.address_types = "WAREHOUSE";
      }
    }

    if (resource === "companyAddressesOfUsers") {
      if (params.filter && params.filter.companyCodeOfUser) {
        resource = `companies/${params.filter.companyCodeOfUser}/addresses`;
        delete params.filter.companyCodeOfUser;
      } else {
        resource = `companies/*/addresses`;
      }
    }

    if (resource === "companyRegions") {
      if (params.filter && params.filter._companyCode) {
        resource = `companies/${params.filter._companyCode}/regions`;
      } else {
        throw new Error("No filter _companyCode");
      }
    }

    if (resource === "useraddresses") {
      resource = "users/*/addresses";
      if (params.filter && params.filter.userId) {
        resource = `users/${params.filter.userId}/addresses`;
      }
    }

    if (resource === "userHistories") {
      if (params.filter && params.filter.userId) {
        resource = `users/${params.filter.userId}/history`;
      }
    }

    if (resource === "budgetHistories") {
      if (params.filter && params.filter.budgetId) {
        resource = `budgets/${params.filter.budgetId}/history`;
      }
    }

    if (resource === "onboarded-users") {
      resource = "users";
    }
    if (resource === "usersSimple") {
      resource = "users";
    }
    //analog Mcs\PlatformModelBundle\Core\Company::FULFILLER_GROUPS
    if (resource === "fulfiller_companies") {
      resource = "companies";
      if (params.filter) {
        params.filter.group = "servicepartner,distributor,supplier";
      }
    }
    //analog Mcs\PlatformModelBundle\Core\Company::SELLER_GROUPS
    if (resource === "seller_companies") {
      resource = "companies";
      if (params.filter) {
        params.filter.group = "servicepartner,distributor";
      }
    }
    //analog Mcs\PlatformModelBundle\Core\Company::SELLER_GROUPS
    if (resource === "manager_companies") {
      resource = "companies";
      if (params.filter) {
        params.filter.group = "servicepartner,provider";
      }
    }

    if (resource === "domains_entities") {
      resource = "domains";
      if (!params.filter) params.filter = {};
      params.filter.with_entity = true;
    }

    if (resource === "warehouseProducts") {
      if (params.filter._warehouse_code) {
        resource = `warehouses/${params.filter._warehouse_code}/products`;
      } else {
        resource = `warehouses/*/products`;
      }
    }

    if (resource === "packingSpaces") {
      if (params.filter && params.filter._warehouse_code) {
        resource = `warehouses/${params.filter._warehouse_code}/packing-spaces`;
      } else {
        throw new Error("No filter _warehouse_code");
      }
    }

    if (resource === "campaignProducts") {
      resource = `campaigns/${params.filter._campaignCode}/products`;
    }

    if (resource === "emailTemplates") {
      resource = `email-templates`;
    }

    return resource;
  }
};
