import i18n from "@/i18n";

export default {
  validate(value) {
    return value.match(
      /^((s?)ftp:\/\/|http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)([a-z0-9-_]+(:[a-z0-9-_]+)?@)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i
    );
  },
  message: i18n.t("integrations.error.not_valid_url")
};
