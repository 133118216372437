const SsoMappingKeys = [
  { id: "first_name", name: "first_name" },
  { id: "last_name", name: "last_name" },
  { id: "email", name: "email" },
  { id: "username", name: "username" },
  { id: "id_origin", name: "id_origin" },
  { id: "company", name: "company" },
  { id: "currency", name: "currency" },
  { id: "country_origin", name: "country_origin" },
  { id: "locale", name: "locale" },
  { id: "roles", name: "roles" },
  { id: "groups", name: "groups" },
  { id: "phone", name: "phone" },
  { id: "company_unit", name: "company_unit" },
  { id: "organization", name: "organization" },
  { id: "department", name: "department" },
  { id: "costcenter", name: "costcenter" },
  { id: "enabled", name: "enabled" }
];

export { SsoMappingKeys };
