<template>
  <div>Check Auth...</div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions({
      checkAuth: "auth/checkAuth"
    })
  },
  async created() {
    let user = await this.checkAuth();
    if (!user) {
      let redirect = `${process.env.VUE_APP_BASE_URL}/dashboard`;
      const localStorageRedirect = localStorage.getItem("redirectAfterSaml");
      if (localStorageRedirect) {
        redirect = localStorageRedirect;
        localStorage.removeItem("redirectAfterSaml");
      }
      const redirectUrl = new URL(redirect);
      redirectUrl.searchParams.set("login_successful", true);
      window.location.href = `${process.env.VUE_APP_HOST_URL}/auth?redirect=${redirectUrl.href}`;
    }
  }
};
</script>
