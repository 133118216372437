<template>
  <v-data-table :headers="headers" :items="rows" show-expand :expanded.sync="expanded" :disable-pagination="true" item-key="id">
    <template v-slot:item.created_at="{ item }">
      {{ item.created_at ? $d(new Date(item.created_at), "datetime") : "" }}
    </template>
    <template v-slot:item.type="{ value }">
      <lookup-field :lookup-data="messageTypeVariants" :value="value" />
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length"><order-fulfillment-message :item="item" /></td>
    </template>
    <template v-slot:item.message="{ item }">
      {{ item.message ? (item.message.length > 50 ? item.message.slice(0, 50) + "..." : item.message) : "" }}
    </template>
  </v-data-table>
</template>
<script>
import OrderFulfillmentMessage from "@/resources/order-fulfillments/components/OrderFulfillmentMessage";
import LookupField from "@/components/fields/LookupField";
import { messageTypeVariants } from "@/resources/order-fulfillments/MessageTypeVariants";

export default {
  components: { LookupField, OrderFulfillmentMessage },
  props: ["item"],
  data() {
    return {
      messageTypeVariants: messageTypeVariants,
      expanded: [],
      headers: [
        { text: this.$i18n.t("integrations.fields.date"), value: "created_at" },
        { text: this.$i18n.t("integrations.fields.origin"), value: "origin" },
        { text: this.$i18n.t("integrations.fields.type"), value: "type" },
        { text: this.$i18n.t("integrations.fields.message"), value: "message" },
        { text: "", value: "data-table-expand" }
      ],
      rows: this.item.messages.map(message => {
        message.id = Math.floor(Math.random() * Date.now());
        return message;
      })
    };
  }
};
</script>
