<template>
  <div>
    <info-text :resource="resource" crud-type="edit" />
    <va-edit-layout :title="title">
      <useraddresses-form :id="id" :item="item" :isEdit="true"></useraddresses-form>
    </va-edit-layout>
  </div>
</template>

<script>
import InfoText from "@/components/helper/InfoText";
export default {
  components: { InfoText },
  props: ["id", "title", "item", "resource"]
};
</script>
