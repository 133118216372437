<template>
  <va-list
    resource="commandRuns"
    :filters="filters"
    :filter="{
      _id: item ? item.id : ''
    }"
    :sort-by="['created_at']"
    :sort-desc="['true']"
    disable-global-search
    disable-export
    disable-create
  >
    <va-data-table :fields="fields" disable-edit disable-clone disable-delete disable-select> </va-data-table>
  </va-list>
</template>

<script>
import i18n from "@/i18n";

export default {
  props: ["resource", "title", "item"],
  data() {
    return {
      fields: [
        {
          label: i18n.t("integrations.fields.state"),
          source: "state"
        },
        {
          label: i18n.t("integrations.fields.command_name"),
          source: "command_name"
        },
        {
          label: i18n.t("integrations.fields.created_by"),
          source: "created_by"
        },
        {
          label: i18n.t("integrations.fields.created_at"),
          type: "date",
          source: "created_at",
          sortable: true
        }
      ],
      filters: []
    };
  }
};
</script>
