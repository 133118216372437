var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-form',{attrs:{"id":_vm.id,"item":_vm.item}},[_c('base-material-tabs-card',{attrs:{"tabs":_vm.tabs},scopedSlots:_vm._u([{key:"useraddresses_information",fn:function(){return [(_vm.isEdit)?_c('v-row',[_c('v-col',[_c('v-input',{staticClass:"va-input",attrs:{"label":_vm.$t('integrations.fields.user')}},[_c('div',[_c('username-field',{attrs:{"user-id":_vm.item.user}})],1)])],1),_c('v-col',[_c('v-input',{staticClass:"va-input",attrs:{"label":_vm.$t('integrations.fields.company')}},[_c('div',[_vm._v(" "+_vm._s(_vm.item.company)+" ")])])],1)],1):_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.user'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
return [_c('va-autocomplete-input',{attrs:{"readonly":_vm.isEdit,"autofocus":!_vm.isEdit,"label":_vm.$t('integrations.fields.user') + ' *',"source":"user","reference":"onboarded-users","hint":!_vm.isEdit ? _vm.$t('integrations.helper.user_address.user') : '',"render-callback":function (record) { return record.username + ' (' + record.company_name + ')'; },"error-messages":errors,"search-query":"username","sort-by":['username']},on:{"input":validate}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('va-text-input',{attrs:{"label":_vm.$t('integrations.fields.first_name'),"source":"first_name"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.last_name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
return [_c('va-text-input',{attrs:{"label":_vm.$t('integrations.fields.last_name') + ' *',"source":"last_name","error-messages":errors},on:{"input":validate}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('va-text-input',{attrs:{"label":_vm.$t('integrations.fields.organization'),"source":"organization"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('va-text-input',{attrs:{"label":_vm.$t('integrations.fields.department'),"source":"department"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.street'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
return [_c('va-text-input',{attrs:{"label":_vm.$t('integrations.fields.street') + ' *',"source":"street","error-messages":errors},on:{"input":validate}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('va-text-input',{attrs:{"label":_vm.$t('integrations.fields.street2'),"source":"street2"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.postal_code'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
return [_c('va-text-input',{attrs:{"label":_vm.$t('integrations.fields.postal_code') + ' *',"source":"postal_code","error-messages":errors},on:{"input":validate}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.city'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
return [_c('va-text-input',{attrs:{"label":_vm.$t('integrations.fields.city') + ' *',"source":"city","error-messages":errors},on:{"input":validate}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('va-text-input',{attrs:{"label":_vm.$t('integrations.fields.region_text'),"source":"region_text"}})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.country'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
return [_c('va-autocomplete-input',{attrs:{"label":_vm.$t('integrations.fields.country') + ' *',"source":"country","reference":"countries","search-query":"q","error-messages":errors},on:{"input":validate}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('va-text-input',{attrs:{"label":_vm.$t('integrations.fields.phone'),"source":"phone"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('va-array-input',{attrs:{"label":_vm.$t('integrations.fields.tax'),"source":"tax_ids_key_value"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.taxes_key'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
return [_c('va-combobox-input',_vm._b({attrs:{"label":_vm.$t('integrations.fields.tax_number_id'),"source":"key","choices":_vm.TaxIdKeys,"item-text":"name","item-value":"id","error-messages":errors},on:{"input":validate}},'va-combobox-input',props,false))]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('va-text-input',_vm._b({attrs:{"label":_vm.$t('integrations.fields.tax_number_value'),"source":"value"}},'va-text-input',props,false))],1)],1)]}}])})],1)],1)]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex align-center"},[_c('va-save-button'),_c('va-cancel-button',{staticClass:"ml-2"})],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }