<template>
  <validation-form :id="id" :item="item">
    <base-material-tabs-card :tabs="tabs">
      <template v-slot:usergroups_information>
        <v-row>
          <v-col>
            <!-- Company -->
            <validation-provider :name="$t('integrations.fields.channel')" rules="required" v-slot="{ validate, errors }">
              <va-autocomplete-input
                :label="$t('integrations.fields.channel') + ' *'"
                source="channel"
                :readonly="isEdit"
                :autofocus="!isEdit"
                reference="channels_globals_as_defaults"
                :hint="$t('integrations.helper.user_group.channel')"
                @input="validate"
                :error-messages="errors"
                search-query="q"
                :sort-by="['name']"
              ></va-autocomplete-input>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <validation-provider :name="$t('integrations.fields.type')" rules="required" v-slot="{ validate, errors }">
              <va-select-input
                :label="$t('integrations.fields.type') + ' *'"
                source="type"
                :hint="$t('integrations.helper.user_group.type')"
                :choices="UserGroupTypeVariants"
                item-text="name"
                item-value="id"
                @input="validate"
                :error-messages="errors"
              ></va-select-input>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <validation-provider :name="$t('integrations.fields.name')" rules="required" v-slot="{ validate, errors }">
              <va-text-input
                :label="$t('integrations.fields.name') + ' *'"
                source="name"
                :hint="$t('integrations.helper.user_group.name')"
                @input="validate"
                :error-messages="errors"
              ></va-text-input>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <validation-provider
              :name="$t('integrations.fields.code')"
              :rules="{
                required: true,
                regex: /^[a-zA-Z0-9_-]+$/,
                uniqueValidator: !isEdit ? { resource: 'usergroups', field: 'codeEquals' } : false
              }"
              v-slot="{ validate, errors }"
            >
              <va-text-input
                :readonly="isEdit"
                :label="$t('integrations.fields.code') + ' *'"
                source="code"
                @input="validate"
                :error-messages="errors"
                :hint="$t('integrations.helper.user_group.code')"
              ></va-text-input>
            </validation-provider>
          </v-col>
        </v-row>
      </template>
      <template v-slot:footer>
        <div class="d-flex align-center"><va-save-button /><va-cancel-button class="ml-2" /></div>
      </template>
    </base-material-tabs-card>
  </validation-form>
</template>
<script>
import { UserGroupTypeVariants } from "@/resources/usergroups/helper";

export default {
  props: ["id", "title", "item", "isEdit"],
  data() {
    return {
      UserGroupTypeVariants: UserGroupTypeVariants
    };
  },
  computed: {
    tabs() {
      return [
        {
          id: "usergroups_information",
          label: this.isEdit ? this.$t("integrations.user_group.title.edit", { name: this.item.name }) : this.$t("integrations.user_group.title.create")
        }
      ];
    }
  }
};
</script>
