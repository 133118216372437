<template>
  <va-action-button icon="mdi-clipboard-list" :label="$t('integrations.btn.log')" text :hide-label="icon" @click="showLog(item)"></va-action-button>
</template>
<script>
import Button from "@skuhnow/vuetify-admin/src/mixins/button";

export default {
  mixins: [Button],
  methods: {
    showLog() {
      window.open(`${process.env.VUE_APP_API_URL}/commands/${this.item.id}/logs`, "_blank");
    }
  }
};
</script>
