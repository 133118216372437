import i18n from "@/i18n";

const UserGroupTypeVariants = [
  { id: "OTHER", name: i18n.t("integrations.user_group.type.OTHER") },
  {
    id: "DISPOSITION",
    name: i18n.t("integrations.user_group.type.DISPOSITION")
  }
];

export { UserGroupTypeVariants };
