<template>
  <va-show-layout :title="title" disable-edit disable-clone disable-delete>
    <va-show :item="item">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-input :label="$t('integrations.fields.id')" class="va-input">
                  <div>
                    <va-text-field source="id" />
                  </div>
                </v-input>
                <v-input :label="$t('integrations.fields.company')" class="va-input">
                  <div>
                    <va-reference-field source="company" reference="companies" :fetch="true" :action="null" />
                  </div>
                </v-input>
                <v-input :label="$t('integrations.fields.type')" class="va-input">
                  <div>
                    <va-select-field :choices="ConfirmationRequestTypeVariants" source="type" item-text="name" item-value="id" />
                  </div>
                </v-input>
                <v-input :label="$t('integrations.fields.confirm_signature')" class="va-input">
                  <div>
                    <va-text-field source="confirm_signature" />
                  </div>
                </v-input>
                <va-key-value-field source="references" />
                <va-key-value-field source="confirmation_parameters" />
                <v-input :label="$t('integrations.fields.valid_until')" class="va-input">
                  <div>
                    <va-date-field source="valid_until" />
                  </div>
                </v-input>
                <v-input :label="$t('integrations.fields.created')" class="va-input">
                  <div>
                    <va-date-field source="created_at" />
                  </div>
                </v-input>
                <v-input :label="$t('integrations.fields.confirmed_at')" class="va-input">
                  <div>
                    <va-date-field source="confirmed_at" />
                  </div>
                </v-input>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </va-show>
  </va-show-layout>
</template>

<script>
import { ConfirmationRequestTypeVariants } from "@/resources/confirmation-requests/helper";

export default {
  props: ["title", "item"],
  data() {
    return {
      ConfirmationRequestTypeVariants: ConfirmationRequestTypeVariants
    };
  }
};
</script>
