<template>
  <v-row justify="center">
    <v-col sm="4">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-input :label="$t('integrations.fields.id')" class="va-input"
                  ><div>
                    <va-text-field source="id" />
                  </div>
                </v-input>
                <v-input :label="$t('integrations.fields.created')" class="va-input"
                  ><div>
                    <va-date-field source="created" />
                  </div>
                </v-input>
                <v-input :label="$t('integrations.fields.last_updated')" class="va-input"
                  ><div>
                    <va-date-field source="updated" />
                  </div>
                </v-input>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: ["item"]
};
</script>
