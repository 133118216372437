import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import i18n from "@/i18n";
import vuetify from "@/plugins/vuetify";
import admin from "@/plugins/admin";
import VueClipboard from "vue-clipboard2";
import "@/plugins/i18n";
import "@/plugins/base";
import "@/plugins/vee-validate";
import "@/sass/overrides.sass";
import "vue-json-pretty/lib/styles.css";
import ValidationForm from "@/vuetifyAdmin/component/ui/providers/ValidationForm";
import LoginAsButton from "@/components/user/LoginAsButton";
import ArrayNotEmpty from "@/components/fields/ArrayNotEmpty";
import ArrayList from "@/components/fields/ArrayList";
import ProductName from "@/components/products/ProductName";
import DateTimeInput from "@/components/inputs/DateTimeInput";
import FunctionField from "@/components/fields/FunctionField";
import MoneyFormatField from "@/components/fields/MoneyFormatField";
import IconTooltipField from "@/components/fields/IconTooltipField";
import KeyValueField from "@/components/fields/KeyValueField";
import DatetimePicker from "@/components/inputs/DatetimePicker";
import ExportPollButton from "@/components/export/ExportPollButton";
import DisplayJsonField from "@/components/fields/DisplayJsonField";
import TimezoneInput from "@/components/inputs/TimezoneInput";
import LabelInput from "@/components/inputs/LabelInput.vue";
import BestResult from "@/components/batchjobs/BestResult.vue";
import StatusTooltip from "@/components/fields/StatusTooltip.vue";
import CodeInput from "@/components/inputs/CodeInput.vue";
import VueJsonPretty from "vue-json-pretty";

Vue.config.productionTip = false;

Vue.component("v-datetime-picker", DatetimePicker);
Vue.use(VueClipboard);

Vue.component("VaMoneyFormatField", MoneyFormatField);
Vue.component("VaIconTooltipField", IconTooltipField);
Vue.component("VaFunctionField", FunctionField);
Vue.component("VaKeyValueField", KeyValueField);

Vue.component("ValidationForm", ValidationForm);
Vue.component("LoginAsBtn", LoginAsButton);
Vue.component("VaArrayNotEmptyField", ArrayNotEmpty);
Vue.component("VaArrayListField", ArrayList);
Vue.component("VaProductNameField", ProductName);
Vue.component("VaDateTimeInput", DateTimeInput);
Vue.component("VaExportPollBtn", ExportPollButton);
Vue.component("VaDisplayJsonField", DisplayJsonField);
Vue.component("VaTimezoneInput", TimezoneInput);
Vue.component("VaCodeInput", CodeInput);
Vue.component("VaLabelInput", LabelInput);
Vue.component("VaBestResultField", BestResult);
Vue.component("VaStatusTooltipField", StatusTooltip);
Vue.component("VueJsonPretty", VueJsonPretty);

new Vue({
  router,
  store,
  i18n,
  vuetify,
  admin,
  render: h => h(App)
}).$mount("#app");
