<template>
  <va-show-layout :title="title">
    <va-show :item="item">
      {{ item }}
    </va-show>
  </va-show-layout>
</template>

<script>
export default {
  props: ["title", "item"]
};
</script>
