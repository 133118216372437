<template>
  <base-material-card :icon="resource.icon" :title="title">
    <template v-slot:subtitle>
      <info-text :resource="resource" crud-type="list" />
    </template>
    <va-list :filters="filters" :sort-by="['created_at']" :sort-desc="['true']" disable-export :default-filter="{ status: 'active' }">
      <va-data-table :fields="fields" disable-clone disable-show :can-edit="canEdit">
        <template v-slot:[`field.reference`]="{ item, value }">
          <router-link
            v-if="item.attributes && 'order_id' in item.attributes"
            :to="{
              name: `orders_list`,
              query: {
                filter: JSON.stringify({
                  q: item.attributes.order_id
                })
              }
            }"
          >
            {{ value }}
          </router-link>
          <span v-else>{{ value }}</span>
        </template>
        <template v-slot:[`field.warehouse_code_data`]="{ item, value }">
          <router-link
            v-if="value.length > 0 && typeof value[0] === 'object'"
            :to="{
              name: `warehouseProducts_list`,
              query: {
                filter: JSON.stringify({
                  _warehouse_code: value[0].code
                })
              }
            }"
          >
            {{ value[0].label }}
          </router-link>

          <span v-else>{{ value[0] }} ({{ $t("integrations.warehouse.messages.virtual") }})</span>
        </template>
      </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
import i18n from "@/i18n";
import InfoText from "@/components/helper/InfoText";

export default {
  components: { InfoText },
  props: ["resource", "title"],
  methods: {
    canEdit(item) {
      return this.$admin.can(["ROLE_ADMIN"]) || item.created_by === this.currentUser.username;
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  data() {
    return {
      fields: [
        {
          label: i18n.t("integrations.fields.reference"),
          source: "reference",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.sku"),
          source: "product_code",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.name"),
          type: "function",
          attributes: {
            callback: (value, record) => {
              if (!record.product_name) return null;
              let returnValue = record.product_name[i18n.locale];
              return returnValue ? returnValue : record.product_name[i18n.fallbackLocale];
            }
          }
        },
        {
          label: i18n.t("integrations.fields.blocked_quantity"),
          source: "quantity",
          align: "right",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.warehouse"),
          source: "warehouse_code_data",
          type: "reference",
          sortable: true,
          permissions: ["ROLE_ADMIN"],
          attributes: {
            reference: "warehouses",
            action: null,
            itemText: "label",
            displayIdValue: "code"
          }
        },
        {
          label: i18n.t("integrations.fields.created_by"),
          source: "created_by"
        },
        {
          label: i18n.t("integrations.fields.created_at"),
          source: "created_at",
          type: "date",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.cancelled_at"),
          source: "cancelled_at",
          type: "date",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.migrated_at"),
          source: "migrated_at",
          type: "date",
          sortable: true
        }
      ],
      filters: [
        {
          label: i18n.t("integrations.fields.warehouse"),
          source: "warehouse",
          type: "autocomplete",
          alwaysOn: true,
          resettable: true,
          permissions: ["ROLE_ADMIN"],
          attributes: {
            reference: "companyAddressesWarehouses",
            searchQuery: "q",
            itemText: record => record.label + " (" + record.code + ")",
            itemValue: "code",
            sortBy: ["code"]
          }
        },
        {
          label: i18n.t("integrations.fields.sku"),
          source: "product",
          alwaysOn: true,
          resettable: true
        },
        {
          label: i18n.t("integrations.fields.status"),
          source: "status",
          type: "select",
          attributes: {
            choices: [
              { value: "active", text: i18n.t("integrations.inventory-blocking.status.active") },
              { value: "cancelled_not_migrated", text: i18n.t("integrations.inventory-blocking.status.cancelled_not_migrated") },
              { value: "cancelled_migrated", text: i18n.t("integrations.inventory-blocking.status.cancelled_migrated") },
              { value: "active_old", text: i18n.t("integrations.inventory-blocking.status.active_old") }
            ]
          },
          alwaysOn: true,
          resettable: true
        }
      ]
    };
  }
};
</script>
