<template>
  <va-show-layout :title="title" disable-list disable-edit disable-clone disable-delete>
    <va-show :item="item">
      <va-list
        resource="commands"
        :filter="{
          group: 'campaign'
        }"
        disable-global-search
        disable-create
        disable-export
      >
        <va-data-table :fields="fields" disable-show disable-edit disable-clone disable-delete>
          <template v-slot:[`item.actions`]="{ item }">
            <run-command-button :item="item" />
            <command-log-button :item="item" />
          </template>
        </va-data-table>
      </va-list>
    </va-show>
  </va-show-layout>
</template>

<script>
import i18n from "@/i18n";
import RunCommandButton from "@/components/campaign-commands/RunCommandButton";
import CommandLogButton from "@/components/command/CommandLogButton";

export default {
  components: { CommandLogButton, RunCommandButton },
  props: ["title", "item"],
  data() {
    return {
      fields: [
        {
          label: i18n.t("integrations.fields.description"),
          source: "description"
        }
      ]
    };
  }
};
</script>
