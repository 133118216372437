<template>
  <div>
    <base-material-card icon="mdi-format-list-bulleted" :title="$t('integrations.fields.show_logs')">
      <va-list
        resource="batchJobRuns"
        :filter="{
          _batchJobId: batchJobId
        }"
        :filters="filters"
        disable-query-string
        disable-global-search
        disable-export
        disable-create
      >
        <va-data-table :fields="fields" disable-edit disable-show disable-clone disable-delete>
          <template v-slot:[`field.id`]="{ value }">
            {{ getTimestamp(value) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <link-button
              :label="$t('integrations.batchjobs.actions.open_run')"
              icon="mdi-eye"
              :route="{ name: 'batchJobRun', params: { batchJobId: batchJobId, batchRunId: item.id } }"
            />
          </template>
        </va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>
<script>
import i18n from "@/i18n";
import LinkButton from "@/components/actions/LinkButton";

export default {
  components: { LinkButton },
  props: ["batchJobId", "resource", "title"],
  data() {
    return {
      fields: [
        {
          label: i18n.t("integrations.fields.created_by"),
          source: "invoker"
        },
        {
          label: i18n.t("integrations.fields.created_at"),
          source: "id"
        },
        {
          label: i18n.t("integrations.fields.status"),
          type: "function",
          attributes: {
            callback: (value, record) => this.$i18n.t(`integrations.batchjobs.stages.status.${record.status}`)
          }
        }
      ],
      filters: []
    };
  },
  methods: {
    getTimestamp(value) {
      return this.$d(new Date(parseInt(value.substr(4)) * 1000), this.$admin.options.dateFormat);
    }
  }
};
</script>
