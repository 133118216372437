<template>
  <v-data-table :headers="headers" :items="dataRows" disable-pagination>
    <template v-slot:item.unit_price="{ item }">
      {{ formatWithCurrency(item.item.unit_price, order.currency_code) }}
    </template>
    <template v-slot:item.line_total="{ item }">
      {{ formatWithCurrency(item.item.quantity * item.item.unit_price, order.currency_code) }}
    </template>
  </v-data-table>
</template>
<script>
import MoneyHelper from "@/mixins/MoneyHelper";

export default {
  props: ["order"],
  mixins: [MoneyHelper],
  async created() {
    const response = await this.$admin.http.get(`${process.env.VUE_APP_API_URL}/orders/${this.order.id}/items`);
    this.dataRows = response.data.data;
  },
  data() {
    return {
      dataRows: [],
      headers: [
        {
          text: this.$i18n.t("integrations.fields.sku"),
          value: "item.variant.code"
        },
        {
          text: this.$i18n.t("integrations.fields.name"),
          value: "item.variant.name"
        },
        {
          text: this.$i18n.t("integrations.fields.quantity"),
          value: "item.quantity",
          align: "right"
        },
        {
          text: this.$i18n.t("integrations.fields.unit_price"),
          value: "unit_price",
          align: "right"
        },
        {
          text: this.$i18n.t("integrations.fields.line_total"),
          value: "line_total",
          align: "right"
        },
        {
          text: this.$i18n.t("integrations.fields.distributor"),
          value: "item.variant.distributor"
        }
      ]
    };
  }
};
</script>
