<template>
  <va-action-button icon="mdi-attachment" :label="$t('integrations.fields.link_to_json')" :hide-label="hideLabel" text @click="showOrder(item)"></va-action-button>
</template>
<script>
import Button from "@skuhnow/vuetify-admin/src/mixins/button";

export default {
  mixins: [Button],
  props: {
    hideLabel: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    showOrder() {
      window.open(`${process.env.VUE_APP_API_URL}/orders/${this.item.id}`, "_blank");
    }
  }
};
</script>
