<template>
  <va-input v-bind="$props" class="va-input">
    <prism-editor class="prism-code-editor" v-bind="commonProps" :style="editorStyle" :value="inputStr" :highlight="highlighter" line-numbers @input="update"></prism-editor>
  </va-input>
</template>

<script>
import Input from "@skuhnow/vuetify-admin/src/mixins/input";
import Editable from "@skuhnow/vuetify-admin/src/mixins/editable";
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles somewhere
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-solarizedlight.css"; // im

export default {
  mixins: [Input, Editable],
  components: {
    PrismEditor
  },
  methods: {
    highlighter(code) {
      return highlight(code, languages.js);
    }
  },
  computed: {
    inputStr() {
      if (this.input === null) {
        return "";
      }
      if (typeof this.input === "object" || typeof this.input === "number") {
        return JSON.stringify(this.input);
      } else {
        return this.input.toString();
      }
    }
  },
  props: {
    value: {
      type: String
    },
    editorStyle: {
      type: String
    }
  }
};
</script>
<style scoped>
.prism-code-editor {
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
  height: 500px;
  border: 1px solid #eee;
}
</style>
