<template>
  <div>
    <a v-if="text" :target="target" :href="cmsUrl">
      <slot></slot>
    </a>
    <v-btn color="secondary" v-else @click="openCms">
      <v-icon left>mdi-open-in-new</v-icon>
      <slot></slot>
    </v-btn>
  </div>
</template>
<script>
export default {
  props: {
    preset: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true
    },
    id: {
      type: [String, Number]
    },
    target: {
      type: String,
      default: "_blank"
    },
    text: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    cmsUrl() {
      const baseCmsUrl = process.env.VUE_APP_CMS_URL;
      return `${baseCmsUrl}/${this.preset}/admin/content/${this.content}/${this.id}`;
    }
  },
  methods: {
    openCms() {
      window.open(this.cmsUrl, this.target);
    }
  }
};
</script>
