const equals = "";
const gt = ">";
const lt = "<";
const equalsAny = "*";
const beginsWith = "^";
const contains = "~";
const notEquals = "!";

export default {
  equals,
  gt,
  lt,
  equalsAny,
  beginsWith,
  contains,
  notEquals
};
