<template>
  <va-layout>
    <va-app-bar
      slot="app-bar"
      :header-menu="headerMenu"
      dense
      disable-create
      color="secondary"
      dark
      @toggle="$vuetify.breakpoint.lgAndUp ? (mini = !mini) : (drawer = !drawer)"
      dark-mode-switcher
    >
      <template #toolbar-title>
        <router-link :to="{ name: 'dashboard' }" class="hidden-sm-and-down">
          <v-img src="https://res.cloudinary.com/djfwuiclm/image/upload/c_scale,h_35/v1689000038/logos/prominate-logo-light.svg"></v-img>
        </router-link>
      </template>
    </va-app-bar>
    <va-sidebar slot="sidebar" :menu="sidebarMenu" v-model="drawer" :mini-variant="mini"></va-sidebar>
    <va-breadcrumbs slot="header"></va-breadcrumbs>
    <va-aside :width="600" slot="aside"></va-aside>
  </va-layout>
</template>

<script>
import nav from "../_nav";

export default {
  name: "App",
  data() {
    return {
      drawer: null,
      mini: false,
      headerMenu: [
        {
          link: "/",
          text: this.$t("menu.dashboard")
        }
      ],
      sidebarMenu: nav(this.$i18n, this.$admin)
    };
  }
};
</script>
