var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('va-action-button',{attrs:{"to":{ name: 'warehouses_list' },"label":_vm.$t('integrations.warehouse.back_to_warehouses')}}),_c('va-list',{ref:"productList",staticClass:"mt-3",attrs:{"disable-create":"","filters":_vm.filters,"disable-export":""},scopedSlots:_vm._u([{key:"actions",fn:function(slotProps){return [(slotProps.currentFilter && slotProps.currentFilter._warehouse_code)?_c('create-product',{attrs:{"warehouse-id":slotProps.currentFilter._warehouse_code},on:{"productCreated":_vm.refreshList}}):_vm._e(),_c('export-poll-button',{attrs:{"resource":"warehouseProducts","text":"","options":slotProps.listState.options,"filter":slotProps.currentFilter}})]}},{key:"default",fn:function(slotProps){return [_c('va-data-table',{attrs:{"fields":_vm.fields,"disable-show":"","disable-clone":"","disable-delete":"","disable-edit":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('edit-product',{attrs:{"item":item},on:{"productUpdated":_vm.refreshList}}),_c('delete-product',{attrs:{"item":item},on:{"productDeleted":_vm.refreshList}})]}},(slotProps.currentFilter && slotProps.currentFilter._warehouse_code)?{key:"field.blocked_quantity",fn:function(ref){
var localItem = ref.item;
var value = ref.value;
return [(value)?_c('router-link',{attrs:{"to":{
              name: "inventory-blockings_list",
              query: {
                filter: JSON.stringify({
                  warehouse: slotProps.currentFilter._warehouse_code,
                  product: localItem.product_code,
                  status: 'active'
                })
              }
            }}},[_vm._v(_vm._s(value))]):_vm._e()]}}:null],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }