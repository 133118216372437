<template>
  <div>
    <ul v-for="(endPointConfig, index) in item.fulfillment_config.channels" :key="index">
      <li>
        <lookup-field :value="endPointConfig.type" :lookup-data="typeVariants" />
        <span v-if="endPointConfig.endpoint">: {{ endPointConfig.endpoint }}</span>
      </li>
    </ul>
  </div>
</template>
<script>
import { TypeVariants } from "@/resources/communication-gateways/CommunicationChannelChoices";
import LookupField from "@/components/fields/LookupField";

export default {
  components: { LookupField },
  props: ["item"],
  data() {
    return {
      typeVariants: TypeVariants
    };
  }
};
</script>
