<template>
  <div v-if="this.value">
    <div v-if="hasLatestDeliveryDate">
      <v-input :label="$t('integrations.fields.latest_delivery_date')" class="va-input"
        ><div>
          <va-date-field source="additional_data.latest_delivery_date" />
        </div>
      </v-input>
    </div>

    <div v-if="hasSubjects">
      <v-input :label="$t('integrations.fields.subject')" class="va-input">
        <div>
          <v-chip v-for="subject in subjects" :key="subject.id">
            {{ subject.id }}
          </v-chip>
        </div>
      </v-input>
    </div>

    <div v-if="hasRootCategories">
      <v-input :label="$t('integrations.fields.root_categories')" class="va-input"
        ><div>
          <va-array-field source="additional_data.root_categories" />
        </div>
      </v-input>
    </div>

    <div v-if="hasCategories">
      <v-input :label="$t('integrations.fields.category')" class="va-input">
        <div>
          <v-chip v-for="(category, index) in categories" :key="index">
            {{ category.id ? category.id : category }}
          </v-chip>
        </div>
      </v-input>
    </div>

    <div v-for="(additionalField, index) in additionalFields" :key="index">
      <v-input :label="$t(`integrations.fields.${additionalField.key}`)" class="va-input"
        ><div>
          {{ additionalField.value }}
        </div>
      </v-input>
    </div>
  </div>
</template>
<script>
import Field from "@skuhnow/vuetify-admin/src/mixins/field";

export default {
  mixins: [Field],
  data() {
    return {
      hasRootCategories: false,
      latestDeliveryDate: null,
      subjects: [],
      categories: [],
      additionalFields: []
    };
  },
  created() {
    const additionalData = this.value.additional_data;

    for (const [key, value] of Object.entries(additionalData)) {
      if (key === "latest_delivery_date") {
        this.latestDeliveryDate = value;
        continue;
      }

      if (key === "subjects") {
        this.subjects = Array.isArray(value) ? additionalData.subjects : [];
        continue;
      }

      if (key === "root_categories") {
        this.hasRootCategories = true;
        continue;
      }

      if (key === "categories") {
        this.categories = Array.isArray(value) ? additionalData.categories : [];
        continue;
      }

      this.additionalFields.push({ key, value });
    }
  },
  computed: {
    hasLatestDeliveryDate() {
      return !!this.latestDeliveryDate;
    },
    hasSubjects() {
      return this.subjects.length > 0;
    },
    hasCategories() {
      return this.categories.length > 0;
    }
  }
};
</script>
