var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-form',{attrs:{"id":_vm.id,"item":_vm.item},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_c('base-material-tabs-card',{attrs:{"tabs":_vm.tabs},scopedSlots:_vm._u([{key:"generalinfos",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.replaced_user'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
return [_c('va-autocomplete-input',{attrs:{"label":_vm.$t('integrations.fields.replaced_user') + ' *',"source":"replaced_user","reference":"usersSimple","item-value":"username","render-callback":function (record) { return record.username + ' (' + record.company_name + ')'; },"readonly":_vm.isEdit,"filter":{ channel: '*' },"error-messages":errors,"search-query":"q"},on:{"input":validate},model:{value:(_vm.replacedUser),callback:function ($$v) {_vm.replacedUser=$$v},expression:"replacedUser"}})]}}])})],1),_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.replacer_user'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
return [(!_vm.replacerUserRefresh)?_c('va-autocomplete-input',{ref:"replacerUserSelect",attrs:{"label":_vm.$t('integrations.fields.replacer_user') + ' *',"source":"replacer_user","reference":"usersSimple","filter":_vm.replacerUserFilter,"render-callback":function (record) { return record.username + ' (' + record.company_name + ')'; },"item-value":"username","error-messages":errors,"disabled":_vm.loadReplacerUser || !_vm.replacedUser,"search-query":"q"},on:{"input":validate}}):_vm._e()]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('va-autocomplete-input',{attrs:{"label":_vm.$t('integrations.fields.channel'),"source":"channel","disabled":"","reference":"channels_globals_as_defaults"},model:{value:(_vm.model.channel),callback:function ($$v) {_vm.$set(_vm.model, "channel", $$v)},expression:"model.channel"}})],1),_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.types'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
return [_c('va-select-input',{attrs:{"source":"types","label":_vm.$t('integrations.fields.types'),"multiple":"","filled":"","chips":false,"choices":_vm.temporaryReplacementTypes,"item-text":"name","item-value":"id","error-messages":errors},on:{"input":validate}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"4","cols":"12"}},[_c('validation-provider',{attrs:{"vid":"dateFrom","name":_vm.$t('integrations.fields.date_from'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
return [_c('va-date-input',{attrs:{"format":"long","label":_vm.$t('integrations.fields.date_from'),"source":"date_from","error-messages":errors},on:{"input":validate}})]}}])})],1),_c('v-col',{attrs:{"md":"4","cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.date_to'),"rules":"required|dateNotBefore:@dateFrom"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
return [_c('va-date-input',{attrs:{"format":"long","label":_vm.$t('integrations.fields.date_to'),"source":"date_to","error-messages":errors},on:{"input":validate}})]}}])})],1),_c('v-col',{attrs:{"md":"4","cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.date_timezone'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
return [_c('va-timezone-input',{attrs:{"label":_vm.$t('integrations.fields.date_timezone'),"source":"date_timezone","error-messages":errors,"value":_vm.timezoneOfUser},on:{"input":validate}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('va-text-input',{attrs:{"multiline":"","source":"comment"}})],1)],1)]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex align-center"},[_c('va-save-button'),_c('va-cancel-button',{staticClass:"ml-2"})],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }