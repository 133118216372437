<template>
  <base-material-card :icon="resource.icon" :title="title">
    <template v-slot:subtitle>
      <info-text :resource="resource" crud-type="list" />
    </template>
    <va-list :filters="filters" disable-create :sort-by="['order_date']" :sort-desc="[true]" disable-export :default-filter="{ test: 'false' }">
      <template v-slot:actions="slotProps">
        <export-poll-button :resource="resource.name" text :options="slotProps.listState.options" :filter="slotProps.currentFilter" />
      </template>
      <va-data-table :fields="fields" disable-clone disable-edit disable-delete disable-select>
        <template v-slot:[`field.id`]="{ value }">
          <v-btn class="ml-2" @click="copyToClipboard(value)" x-small text outlined><v-icon x-small>mdi-content-copy</v-icon></v-btn>
          {{ value }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <order-link-to-json-button :item="item" hide-label />
          <order-actions-button :item="item" />
          <va-action-button icon="mdi-clipboard-check" text :label="$t('integrations.order.action.go_to_of')" @click="goToFulfillment(item)" hide-label />
        </template>
      </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
import i18n from "@/i18n";
import { stateVariants, stateVariantsFilter } from "@/resources/orders/OrderStateVariants";
import { orderSourceVariants } from "@/resources/orders/OrderSourceVariants";
import { paymentMethodVariants } from "@/resources/orders/OrderPaymentMethodVariants";
import OrderLinkToJsonButton from "@/components/orders/OrderLinkToJsonButton";
import InfoText from "@/components/helper/InfoText";
import { testVariants } from "@/resources/order-fulfillments/OfrTestVariants";
import ExportPollButton from "../../components/export/ExportPollButton";
import OrderActionsButton from "@/components/orders/OrderActionsButton";
import Currencies from "./Currencies";

export default {
  components: { OrderActionsButton, ExportPollButton, InfoText, OrderLinkToJsonButton },
  props: ["resource", "title"],
  methods: {
    refreshList() {
      this.$store.dispatch("api/refresh", this.$route.meta.resource);
    },
    copyToClipboard(value) {
      this.$copyText(value).then(() => {
        this.$store.commit(`messages/showToast`, {
          color: "success",
          message: `Text copied!`
        });
      });
    },
    goToFulfillment(item) {
      const itemIdMatches = item.id.match("^.*-(?<orderId>.{14}-[1,2])$");
      if (itemIdMatches) {
        this.$router.push({
          name: "orderFulfillments_list",
          query: {
            filter: JSON.stringify({ q: itemIdMatches.groups.orderId })
          }
        });
      }
    }
  },
  data() {
    return {
      fields: [
        {
          label: " ",
          source: "attributes.test",
          type: "function",
          attributes: {
            callback: value => (value === true ? "<b>Test</b>" : "")
          }
        },
        {
          label: i18n.t("integrations.fields.order_number"),
          source: "id",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.order_date"),
          type: "date",
          source: "order_date",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.processing_date"),
          type: "date",
          source: "processing_date",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.status"),
          source: "state",
          type: "select",
          attributes: {
            choices: stateVariants,
            itemText: "name",
            itemValue: "id"
          },
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.channel"),
          source: "channel_data",
          type: "reference",
          attributes: {
            reference: "channels",
            action: null
          },
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.user"),
          source: "restrict",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.items_count"),
          source: "items_count",
          align: "right",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.total"),
          type: "function",
          source: "order_total",
          align: "right",
          sortable: true,
          attributes: {
            callback: (value, record) => {
              return new Intl.NumberFormat(i18n.locale, {
                style: "currency",
                currency: record.currency_code
              }).format(record.order_total / 100);
            }
          }
        },
        {
          label: i18n.t("integrations.fields.source"),
          source: "source",
          sortable: true
        }
      ],
      filters: [
        {
          label: i18n.t("integrations.fields.currency"),
          source: "currency_code",
          type: "select",
          alwaysOn: true,
          resettable: true,
          attributes: {
            choices: Currencies
          }
        },
        {
          label: i18n.t("integrations.fields.channel"),
          source: "channel",
          type: "autocomplete",
          alwaysOn: true,
          resettable: true,
          attributes: {
            multiple: true,
            reference: "channels_globals_as_defaults",
            searchQuery: "q",
            sortBy: ["name"]
          }
        },
        {
          label: i18n.t("integrations.fields.order_status"),
          source: "state",
          type: "select",
          alwaysOn: true,
          resettable: true,
          attributes: {
            choices: stateVariantsFilter,
            itemText: "name",
            itemValue: "id"
          }
        },
        {
          label: i18n.t("integrations.fields.payment_method"),
          source: "payments_method_code",
          type: "select",
          resettable: true,
          attributes: {
            choices: paymentMethodVariants,
            itemText: "name",
            itemValue: "id"
          }
        },
        {
          label: i18n.t("integrations.fields.source"),
          source: "source",
          type: "select",
          resettable: true,
          attributes: {
            choices: orderSourceVariants,
            itemText: "name",
            itemValue: "id"
          }
        },
        {
          label: i18n.t("integrations.fields.user"),
          source: "restrict",
          alwaysOn: true,
          resettable: true
        },
        {
          label: i18n.t("integrations.fields.date_from"),
          source: "_date_from",
          type: "date",
          attributes: {
            format: "short"
          }
        },
        {
          label: i18n.t("integrations.fields.date_to"),
          source: "_date_to",
          type: "date",
          attributes: {
            format: "short"
          }
        },
        {
          label: i18n.t("integrations.fields.campaign"),
          source: "campaign",
          type: "autocomplete",
          resettable: true,
          attributes: {
            reference: "campaigns",
            searchQuery: "q",
            itemText: record => record.description + " (" + record.code + ")",
            itemValue: "code",
            sortBy: ["code"]
          }
        },
        {
          label: i18n.t("integrations.fields.test"),
          source: "test",
          type: "select",
          attributes: {
            choices: testVariants,
            itemText: "name",
            itemValue: "id"
          }
        }
      ]
    };
  }
};
</script>
