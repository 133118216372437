import i18n from "@/i18n";

export default [
  { id: "netwave", name: i18n.t("integrations.campaign.type.netwave") },
  {
    id: "consolidated",
    name: i18n.t("integrations.campaign.type.consolidated")
  },
  { id: "custom", name: i18n.t("integrations.campaign.type.custom") }
];
