var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('va-action-button',{attrs:{"icon":"mdi-truck","label":_vm.$t('integrations.btn.create.ofm'),"hide-label":"","text":""},on:{"click":function($event){_vm.dialog = true}}}),_c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.sendFeedback)}}},[_c('v-card',[_c('v-card-title',[_vm._v("Create OrderFulfillmentMessage for "+_vm._s(_vm.item.id))]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.origin'),"rules":{
                      required: true,
                      regex: /^[a-z0-9_-]+$/
                    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('integrations.fields.origin') + ' *',"filled":"","hint":_vm.$t('integrations.helper.ofr.origin'),"error-messages":errors},model:{value:(_vm.formData.origin),callback:function ($$v) {_vm.$set(_vm.formData, "origin", $$v)},expression:"formData.origin"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('va-select-input',{attrs:{"label":_vm.$t('integrations.fields.type') + ' *',"choices":_vm.messageTypeVariants,"item-text":"name","item-value":"id","error-messages":errors,"hint":_vm.$t('integrations.helper.ofr.type'),"persistent-hint":""},model:{value:(_vm.formData.type),callback:function ($$v) {_vm.$set(_vm.formData, "type", $$v)},expression:"formData.type"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$t('integrations.fields.message'),"filled":"","hint":_vm.$t('integrations.helper.ofr.message')},model:{value:(_vm.formData.message),callback:function ($$v) {_vm.$set(_vm.formData, "message", $$v)},expression:"formData.message"}})],1)],1),_c('v-row',[_c('v-col',[_c('va-array-input',{attrs:{"label":_vm.$t('integrations.fields.attributes'),"hint":_vm.$t('integrations.helper.ofr.attributes')},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.attributes.key'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('va-combobox-input',_vm._b({attrs:{"label":_vm.$t('integrations.attributes.key') + ' *',"choices":_vm.OrderFulfillmentMessageAttributeKeys,"item-text":"name","item-value":"id","error-messages":errors},model:{value:(props.item.key),callback:function ($$v) {_vm.$set(props.item, "key", $$v)},expression:"props.item.key"}},'va-combobox-input',props,false))]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('va-text-input',_vm._b({attrs:{"label":_vm.$t('integrations.attributes.value'),"filled":""},model:{value:(props.item.value),callback:function ($$v) {_vm.$set(props.item, "value", $$v)},expression:"props.item.value"}},'va-text-input',props,false))],1)],1)]}}],null,true),model:{value:(_vm.attributesRaw),callback:function ($$v) {_vm.attributesRaw=$$v},expression:"attributesRaw"}})],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","disabled":_vm.sendingFeedback},on:{"click":_vm.closeDialog}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close-circle")]),_vm._v(" "+_vm._s(_vm.$t("integrations.btn.cancel"))+" ")],1),_c('v-btn',{attrs:{"color":"primary","type":"submit","loading":_vm.sendingFeedback}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v(" "+_vm._s(_vm.$t("integrations.btn.save"))+" ")],1)],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }