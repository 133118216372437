<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
  mounted() {
    if (window.location.host === "integrations-gui.platform.docker") {
      this.$vuetify.theme.themes.light.primary = "#260";
      this.$vuetify.theme.themes.dark.primary = "#290";
    }
  }
};
</script>
