<template>
  <div>
    <v-subheader>{{ system.status_provider }} ({{ testCount.succeeded }} / {{ testCount.sum }} checks passed)</v-subheader>
    <assessment-status :assessment="assessment" :system="system" v-for="assessment in system.assessments" :key="assessment.uniqueness" />
  </div>
</template>
<script>
import AssessmentStatus from "@/components/platform-status/AssessmentStatus";
export default {
  components: { AssessmentStatus },
  props: ["system"],
  computed: {
    testCount() {
      let sum = 0;
      let succeeded = 0;
      this.system.assessments.forEach(assessment => {
        sum++;
        if (!assessment.problem) {
          succeeded++;
        }
      });
      return {
        sum,
        succeeded
      };
    }
  }
};
</script>
